import React, { useEffect, useRef, useState } from "react";
import classes from "./ApplicationList.module.css"
import { Form, Table } from "react-bootstrap";
import srName from "../../../../../Assets/Logo/srnum.svg"
import sortName from "../../../../../Assets/Logo/sort.svg";
import actionIcon from "../../../../../Assets/Logo/table-action.svg";
import { useNavigate } from "react-router-dom";
import ApplicationCategory from "../../../../GlobleShared/ApplicationCategoryModal/ApplicationCategory";
import deleteIcon from "../../../../../Assets/Logo/documentDeleteICon.svg";
import editICon from "../../../../../Assets/Logo/editBlueICon.svg";
import ApplicationService from "../../../../../Services/ApplicationServices";
import Loader from "../../../../GlobleShared/Loader/Loader";
import ConfirmationModal from "../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import SearchModal from "../../Search/SearchModal/SearchModal";
import croseIcon from "../../../../../Assets/Logo/cross-icon.svg";
const ApplicationList = () => {
    const navigate= useNavigate();
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [popUpView, setPopUpView] = useState(false);
    const dropdownRef = useRef();
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [tableData, setTableData] = useState([]);
    const [deleteApplicationId, setDeleteApplicationId] = useState();
    const [createDeferralApplication, setCreateDeferralApplication] = useState(null);
    const [intakeList, setIntakeList] = useState();
    const [selectedIntakeId, setSelectedIntakeId] = useState(''); 
    const handleIntakeChange = (event) => {
        setSelectedIntakeId(event.target.value); 
    };
    const columns = [
        { title: "Application ID", dataKey: "ApplicationId" },
        { title: "Status", dataKey: "StatusName" },
        { title: "Application Created", dataKey: "ApplicationDate" },
        { title: "Submission Date", dataKey: "SubmitDate" },
        { title: "Institution Name", dataKey: "InstName" },
        { title: "Email ID", dataKey: "Email" },
        { title: "Contact Number", dataKey: "MobileNo" },
        { title: "Document Status", dataKey: "DocumentsStatus" },
    ];
    const [isLoading, setIsLoading] = useState(false);

    const initial =useRef(null)
    const addApplication =(value)=>{
        navigate('/member/application/add/'+value)

    }
    const getAllApplicationRowData = (value) =>{
        let payload={
          "keyword": "All",
          "status": "",
          "intake": ""
      }
      if (value === 'Online/Blended') {
          setIsLoading(true);
          ApplicationService.getAllApplicationOnline(payload).then((res)=>{
              if (res) {
                  setTableData(res.data);
                  setIsLoading(false);
              }
              setIsLoading(false);
          }).catch((error)=>{
              console.log('error: ', error);
              setIsLoading(false);
          });
      } else {
          setIsLoading(true);
          ApplicationService.getAllApplicationData(payload).then((res)=>{
              if (res) {
                  setTableData(res.data);
                  setIsLoading(false);
              }
              setIsLoading(false);
          }).catch((error)=>{
              console.log('error: ', error);
              setIsLoading(false);
          });
      }
      
    }
    const navigateToEditApplication = (id) => {
        navigate('/member/application/details/' + id);
    };

    const showModalhandler=()=>{
        setModalIsVisible(true);
    }
    const hideModalHandler = (value) => {
        setModalIsVisible(value);
    }
    const deleteApplication = () =>{
        setIsLoading(true)
        ApplicationService.deleteApplication(deleteApplicationId).then((res)=>{
            toast.success("Application deleted successfuly.");
            setIsLoading(false);
        }).catch((error)=>{
            toast.error(error);
            setIsLoading(false);
        })
    }
    const hideConfirmModalhandler = ()=>{
        setDeleteApplicationId(null);
    }
    const getConfirmation = () =>{
        deleteApplication();
        hideConfirmModalhandler();
        getAllApplicationRowData(selectedFilter);
    }
    function onClickOutsideHandler(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setPopUpView(false);
        }
    }
    const handleFilterChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedFilter(selectedValue);
    };
     const getListForDeferApplication = (id) =>{
            setIsLoading(true);
        ApplicationService.getListForDeferApplication(id).then((res)=>{
            console.log('res: ', res);
            setIntakeList(res);
            setIsLoading(false);
        }).catch((error)=>{
            console.log('error: ', error);
            setIsLoading(false);
        })
    }
    const handleClose = () => {
        setCreateDeferralApplication(null);
        setSelectedIntakeId(null);
    }
    const handleSubmit = () => {
        if (createDeferralApplication && selectedIntakeId) {
            setIsLoading(true);
            const payload = {
                "ApplicationId": createDeferralApplication.ApplicationId,
                "IntakeId": selectedIntakeId
            };
            ApplicationService.addDeferApplication(payload)
                .then((res) => {
                    toast.success(res);
                    setSelectedIntakeId(null);
                    getAllApplicationRowData(selectedFilter);
                    setCreateDeferralApplication(null);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log('error: ', error);
                    toast.error("Failed to create deferral request.");
                    setIsLoading(false);
                });
        } else {
            toast.error("Please select an intake.");
        }
    };
    
    
    useEffect(()=>{
        if(createDeferralApplication){
            getListForDeferApplication(createDeferralApplication.ApplicationId)
        }
    },[createDeferralApplication]);
    useEffect(() => {
        document.addEventListener('mousedown', onClickOutsideHandler);
        return () => {
            document.removeEventListener('mousedown', onClickOutsideHandler);
        };
    }, []);
    useEffect(() => {
     if(!initial.current){
        initial.current=true
        getAllApplicationRowData(selectedFilter);

     }
       
    }, [selectedFilter]);
    return (
        <>
            {isLoading && <Loader></Loader>}
            {deleteApplicationId && 
            <ConfirmationModal 
            onClose={hideConfirmModalhandler} 
            getConfirmation={getConfirmation}
            onShow={deleteApplicationId} 
            btnText='Yes'  
            confirmationText='Are you sure, you want to delete application ?'/>
            }
            {createDeferralApplication &&
            <SearchModal onShow={createDeferralApplication} size={'lg'}>
                <div className={classes.searchModalBody}>
                    <div className={classes.searchModalHeader}>
                    <div className={classes.searchModalTitle}>Create Deferral Request</div>
                    <div className={classes.searchModalCroseIcon} onClick={handleClose}><img src={croseIcon}/></div>
                    </div>
                    <div className={classes.searchModalContainer}>
                    <div className={classes.searchModalCurrentIntake}>
                        <div className={classes.searchModalCurrentTitle}>Current Intake</div>
                        <div className={classes.searchModalCurrentValue}>{createDeferralApplication.IntakeName}</div>
                    </div>
                    <div className={classes.searchModalSelectInput}>
                    <Form>
                    <Form.Group className="mb-3">
                            <Form.Select onChange={handleIntakeChange} value={selectedIntakeId}>
                                <option value="">Select an Intake</option>
                                {intakeList && intakeList.length > 0 && intakeList.map((intake) => (
                                    <option key={intake.IntakeId} value={intake.IntakeId}>
                                        {intake.IntakeName}
                                    </option>
                                ))}
                            </Form.Select>
                    </Form.Group>
                    </Form>
                    </div>
                    <div className={classes.searchModalFooter}>
                    <div className={classes.searchModalButtonSection}>
                        <button className={`${classes.buttonHeightWidth} primary-button`} type="button" onClick={()=>handleSubmit()}>save</button>
                    </div>
                    </div>
                    </div>
                </div>
            </SearchModal>
            }
            <div className={classes["application-list-container"]}>
                <div className={classes["section-1"]}>
                    <div className={classes["heading"]}>MSM Application List</div>
                    <div>
                        <button onClick={showModalhandler} className={`${classes["new-application-btn"]} secondary-button`}>+ New Application</button>
                    </div>
                </div>
                <div className={classes["section-2"]}>
                    <Form className={classes["radio-button-block"]}>
                        <Form.Check
                            inline
                            label="All"
                            value="All"
                            checked={selectedFilter === "All"}
                            onChange={handleFilterChange}
                            type="radio"
                            id="radio-1"
                            className={classes["radio-btn"]}
                        />
                        <Form.Check
                            inline
                            label="Online/Blended"
                            value="Online/Blended"
                            checked={selectedFilter === "Online/Blended"}
                            onChange={handleFilterChange}
                            type="radio"
                            id="radio-2"
                            className={classes["radio-btn"]}
                        />
                    </Form>
                </div>
                <div className={classes["section-3"]}>
                    <Table responsive className={classes.customTable}>
                        <thead>
                            <tr>
                                {columns.map((column, index) => (
                                    <th key={index}>
                                        {index === 0 ? (
                                            <div className={classes.thAndIconContainer}>
                                                <span className={classes.columnText}>{column.title}</span>
                                                <img src={srName} alt="Sr Name" className={classes.icon} />
                                            </div>
                                        ) : (
                                            <div className={classes.thAndIconContainer}>
                                                <span className={classes.columnText}>{column.title}</span>
                                                <img src={sortName} alt="Sort" className={classes.icon} />
                                            </div>
                                        )}
                                    </th>
                                ))}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>{tableData.length > 0 ?
                            tableData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {columns.map((column, colIndex) => (
                                    <td
                                    key={colIndex}
                                    onClick={() => {
                                      if (column.dataKey === "ApplicationId") {
                                       navigate(`/member/application/details/${row.ApplicationId}`);
                                      }
                                    }}
                                    style={{ cursor: column.dataKey === "ApplicationId" ? "pointer" : "default" }} 
                                  >{row[column.dataKey] || "N/A"}
                                  </td>
                                    ))}
                                    <td>
                                        <img src={actionIcon} alt="Action" className={classes.icon} onClick={() => setPopUpView(row.ApplicationId)} />
                                        <div className={classes.positionRelative}>
                                            {popUpView === row.ApplicationId && (
                                                <div className={classes.popContainer} ref={dropdownRef}>
                                                    <div className={classes.IConAndText}  onClick={() => { setDeleteApplicationId(row.ApplicationId); setPopUpView(null); }}>
                                                        <div><img className={classes.popUpICon} src={deleteIcon} /></div>
                                                        <div><span className={classes.btnText}>Delete</span></div>
                                                    </div>
                                                    <div className={classes.IConAndText}  onClick={() => { setCreateDeferralApplication(row); setPopUpView(null); }}>
                                                        <div><img className={classes.popUpICon} src={editICon} /></div>
                                                        <div><span className={classes.btnText}>Create Deferral Request</span></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan={columns.length + 1} className={classes.noData}>
                                    No Data Available
                                </td>
                            </tr>

                            }
                        </tbody>
                    </Table>
                </div>

                {modalIsVisible && (
                    <ApplicationCategory onClose={hideModalHandler} onShow={modalIsVisible} navigate={addApplication} />
                )}
            </div>
        </>
    )
}

export default ApplicationList;