import React from 'react'
import Card from 'react-bootstrap/Card';
import classes from './viewDetails.module.css';
const ViewDetails = ({selectedDeferral, columns}) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const month = monthNames[date.getMonth()]; 
    const year = date.getFullYear(); 
    
    return `${day} ${month} ${year}`; 
  };
  const getFullName = (row) => {
    const firstName = row.FirstName || "";
    const middleName = row.MiddleName || "";
    const lastName = row.LastName || "";
    const fullName = `${firstName} ${middleName} ${lastName}`.trim();
    return fullName ? fullName : "NA";
  };
  return (
    <div className={classes.container}>
    <div className={classes.detailContainer}>
    <div className={`${classes.CardLine} row`}>
      {columns.map((column, index) => (
        <div className="col-md-4" key={index}> 
          <Card className={classes.card}>
            <Card.Body>
              <Card.Title className={classes.cardTitle}>{column.title}</Card.Title>
              <Card.Text className={classes.cardText}>
                {column.dataKey === "FullName" ? (
                      getFullName(selectedDeferral)
                    ) : selectedDeferral[column.dataKey] ? (
                      column.dataKey === 'IntakeDate' || column.dataKey === 'AddStamp' || column.dataKey === "ReceivedDate" || column.dataKey === "RejectedDate"|| column.dataKey === "ApprovedDate"|| column.dataKey === "SubmittedDate" || column.dataKey === "InitiateDate" || column.dataKey === "RefundStatusDate"
                        ? formatDate(selectedDeferral[column.dataKey]) 
                        : selectedDeferral[column.dataKey]
                    ) : (
                      "NA"
                    )}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>
  </div>
  </div>
  )
}

export default ViewDetails;