import classes from "./ProgramsModalBody.module.css";
import universityLogo from "../../../../../../Assets/Logo/dougl-icon.svg";
import crossIcon from "../../../../../../Assets/Logo/white-cross-icon.svg";
import likeIcon from "../../../../../../Assets/Logo/like-heart-icon.svg";
import institutesImage from "../../../../../../Assets/Images/institute-image.svg";

function ProgramsModalBody({ onClose, program }) {
    const blobURL = process.env.REACT_APP_URL_PATH;

    return (
        <>
            <div className={classes["card-section"]}>
                <div className={classes["card-header"]}>
                    <div className={classes["section-1"]}>
                        <div className={classes["block-1"]}>
                            <div className={classes["university-icon"]}>
                                <img
                                    src={program?.InstLogoPath ? blobURL + program.InstLogoPath : universityLogo}
                                    alt="Profile"
                                />
                            </div>
                            <div className={classes["university-name"]}>{program?.Instname || 'N/A'}</div>
                        </div>
                        <div className={classes["block-2"]}>
                            <div className={classes["program-name"]}>{program?.ProgramName || 'N/A'}</div>
                            <div className={classes["program-detail-text"]}>
                                {program?.description || 'N/A'}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes["card-body"]}>
                    <div className={classes["section-2"]}>
                        <div className={classes["program-detail-section"]}>
                            <div>
                                <div className={classes["header"]}>Minimum Level of Education Completed</div>
                                <div className={classes["detail"]}>{program?.MinEducationLevel || 'N/A'}</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Minimum GPA</div>
                                <div className={classes["detail"]}>{program?.MinGPA || 'N/A'}</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Program Level</div>
                                <div className={classes["detail"]}>{program?.ProgramLevel || 'N/A'}</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Tuition</div>
                                <div className={classes["detail"]}>{program?.ProgramCurrency || 'N/A'} {program?.FeeDetail[0]?.FeeAmount || 'N/A'} / year</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Cost of Living</div>
                                <div className={classes["detail"]}>{program?.CostOfLiving || 'N/A'} / year</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Program Length</div>
                                <div className={classes["detail"]}>{program?.ProgramLength || 'N/A'}</div>
                            </div>
                        </div>
                        <div className={classes["program-detail-section"]}>
                            <div>
                                <div className={classes["header"]}>Application Fee</div>
                                <div className={classes["detail"]}>{program?.ApplicationFee || 'N/A'}</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>People like You</div>
                                <div className={classes["detail"]}>{program?.PeopleLikeYou || 'N/A'}</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Likelihood of Graduation</div>
                                <div className={classes["detail"]}>{program?.LikelihoodOfGraduation ? `${program.LikelihoodOfGraduation}%` : 'N/A'}</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Rank</div>
                                <div className={classes["detail"]}>
                                    {program?.Rank || 'N/A'}
                                    <ul>
                                        {program?.RankCriteria?.map((criteria, index) => (
                                            <li key={index}>{criteria}</li>
                                        )) || 'N/A'}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes["cross-icon-div"]} onClick={onClose}><img className={classes["cross-icon"]} src={crossIcon} alt="Profile" /></div>
                <div className={classes["card-rank"]}><span className={classes["rank-text"]}>Ranked: {program?.Rank || 'N/A'}</span></div>
                <div className={classes["heart-icon-div"]}><img className={classes["heart-icon"]} src={likeIcon} alt="like" /></div>
            </div>
        </>
    )
}

export default ProgramsModalBody;