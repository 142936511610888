import { useEffect, useRef, useState } from "react";
import classes from "./PendingGroups.module.css";
import GroupService from "../../../../../../Services/GroupService";
import dummyImg from '../../../../../../Assets/Images/profile_avatar.png'
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import { useBehaviorSubject } from "../../../../../GlobleShared/BehaviorSubject/BehaviorSubject";

const PendingGroups = ({ groupId }) => {
  const initial = useRef(null);
  const [pendingGroupList, setPendingGroupList] = useState([]);
  const blobURL= process.env.REACT_APP_URL_PATH
  const [openModal, setOpenModal]=useState(false);
  const [confirmationText, setConfirmationText]=useState('')
  const [requestId, setRequestId]=useState('')
  const [modalValue, setModalValue]=useState('')
  const {updateMember, setUpdateMember}=useBehaviorSubject()


  const pendingGroups = () => {
    GroupService.getPendingGroups(groupId).then((res) => {
      setPendingGroupList(res);
    });
  };

  const openModalBox=(id, value)=>{
    setModalValue(value)
    if(value==='accept'){
        setConfirmationText('Are you sure, you want to accept the selected group?')
    }
    if(value==='dismiss'){
        setConfirmationText('Are you sure, you want to dismiss the selected group?')
    }
    setOpenModal(true)
    setRequestId(id)

  }

  const closeBox=(id)=>{
    setOpenModal(false)

  }

  const getConfirmation=()=>{
    if(modalValue==='accept'){
        GroupService.acceptGroup({RequestID:requestId}).then(res=>{
            toast.success(res?.message)
            setOpenModal(false)
            pendingGroups();
            setUpdateMember(true)
         }).catch(error=>{
            toast.error(error?.message)
         })
    }

    if(modalValue==='dismiss'){
        GroupService.withdrawGroup({RequestID:requestId}).then(res=>{
            toast.success(res?.message)
            setOpenModal(false)
            pendingGroups();
            setUpdateMember(true)
         }).catch(error=>{
            toast.error(error?.message)
         })
    }


  }



  useEffect(() => {
    if (groupId) {
      if (!initial.current) {
        initial.current = true;
        pendingGroups();
      }
    }
  }, []);

  return (
    <>
    {openModal && (
         <ConfirmationModal onShow={openModal} onClose={closeBox}  btnText={"Yes"} 
         confirmationText={confirmationText} getConfirmation={getConfirmation}></ConfirmationModal>
    )}

      <div>
        <div>
          {pendingGroupList.map((item) => (
            <div className={classes["card"]} >
              <div className={classes['card_content']}>
                <div className={classes['imag-text']}>
                 <img className={classes['profileImage']} src={item?.profileImage? blobURL + item?.profileImage :dummyImg} alt=""></img>
                 <div className={classes['card_info']}>
                  <div className={classes["connectionUser-name"]}>{item?.displayName}
                 </div>

                 <div className={classes['description']} title={item?.tagline}>{item?.tagline}</div>
               </div>
                </div>

               <div className="text-end">
                <button onClick={()=>{openModalBox(item?.requestId, 'accept')}} className={`${classes['primary-button-1']} primary-button mb-2` }>Accept</button>
                <button onClick={()=>{openModalBox(item?.requestId, 'dismiss')}} className={`${classes['dismis']} `}>Dismiss</button>
                </div>
                
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default PendingGroups;
