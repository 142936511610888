import React, { useState, useEffect, useRef } from "react";
import classes from "./ChatWindow.module.css";
import croseWhite from "../../../Assets/Logo/crose-white.svg";
import minimizeClose from "../../../Assets/Logo/minimize-close.svg";
import minimizeOpen from "../../../Assets/Logo/minimize-open.svg";
import smileIcon from "../../../Assets/Logo/smile-icon.svg";
import attachmentIcon from "../../../Assets/Logo/attachment-icon.svg";
import gifIcon from "../../../Assets/Logo/gif-icon.svg";
import profileIcon from "../../../Assets/Logo/profile-icon-white.svg";
import SendICon from "../../../Assets/Logo/send-icon.svg";
const ChatWindow = ({ id, name, profile, onClose, onMinimize, isMinimized }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const chatBodyRef = useRef(null);

  const handleSendMessage = () => {
    if (input.trim()) {
      setMessages((prevMessages) => [...prevMessages, { text: input }]);
      setInput("");
    }
  };

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div>
      <div className={`${classes.chatWindow} ${isMinimized ? classes.minimized : ""}`}>
        <div className={classes.chatHeader}>
          <span className={classes.title}>{name}</span>
          <div>
            <span
              className={classes.minimizedBtn}
              onClick={onMinimize}>
              <img title="Minimize"
                src={!isMinimized ? minimizeClose : minimizeOpen}
                alt=""
              />
            </span>
            <span className={classes['pointer']} onClick={onClose}>
              <img title="Close"
                src={croseWhite}
                alt=""
              />
            </span>
          </div>
        </div>
        {!isMinimized && (
          <>
            <div
              className={classes.chatBody}
              ref={chatBodyRef}>
              <img
                className={classes.profilePic}
                src={profile}
                alt=""
              />
              <div>
                <div className={classes.chatMessageOpposite}>Do you have thee notes from class? I had to miss it so I’m going around asking everyone about it. It would help alot if you have it</div>
                <span className={classes.chatUpdateTime}>09:41am Today</span>
              </div>
              <div className={`d-flex justify-content-end`}>
                <div>

                <div className={classes.chatMessage}>Do you have thee notes from class? I had to miss it so I’m going around asking everyone about it. It would help alot if you have it</div>
                
                <span className={classes.chatUpdateTime}>09:41am Today</span>
                </div>
                <div>
                </div>
              </div>
              {messages.map((message, index) => (
                <div className="d-flex justify-content-end">
                  <div
                    key={index}>
                    <div className={classes.chatMessage}>{message.text}</div>
                    <span className={classes.chatUpdateTime}>09:41am Today</span>
                  </div>
                  <div></div>
                </div>
              ))}
            </div>
            <div className={classes.chatInput}>
              <input
                type="text"
                value={input}
                placeholder="Type here"
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
              />
              <div className={`d-flex justify-content-between `}>
                <div className={classes.icons}>
                  <img
                    src={smileIcon}
                    alt=""
                  />
                </div>
                <div className={classes.icons}>
                  <img
                    src={attachmentIcon}
                    alt=""
                  />
                </div>
                <div className={classes.icons}>
                  <img
                    src={gifIcon}
                    alt=""
                  />
                </div>
                <div className={classes.icons}>
                  <img
                    src={profileIcon}
                    alt=""
                  />
                </div>
                <div
                  className={classes.icons}
                  onClick={handleSendMessage}>
                  <img
                    src={SendICon}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatWindow;
