import { Modal } from 'react-bootstrap';
import classes from "./SearchPageModal.module.css";
import corssIcon from "../../../../Assets/Logo/cross-icon.svg"

function SearchPageModal({ onShow, onClose, data }) {
    return (
        <Modal show={onShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered >
            <Modal.Body>
                <div className={classes['cross-icon-position']}>
                <img title="Close" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={onClose}></img>
                </div>
                <div className={classes['modal-body-data']}>
                    {data?.description}
                </div>
               
                <div className={classes['btn-position']}>
                    <button className='primary-button' onClick={onClose}>Close</button>
                </div>

            </Modal.Body>

        </Modal>
    );
}

export default SearchPageModal;