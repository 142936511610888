import React, { useRef, useState } from "react";
import classes from "./GroupsPage.module.css";
import GroupsDashboard from "./GroupsDashboard/GroupsDashboard"
import Sidebar from "./Sidebar/Sidebar";
import CreateGroup from "./CreateGroup/CreateGroup";
import GroupService from "../../../../Services/GroupService";

function GroupsPage() {

  const [currentShowComponent, setCurrentShowComponent] = useState('groupDashboard');
  const [postDashboardData, setPostDashboardData] = useState();

  const sidebarRef = useRef();

  const handleComponentToggle = (component) => {
    setCurrentShowComponent(component);
  };
  const handlePostDashboard = (data) => {
    setPostDashboardData(data)
  }


  const handleGroupCreationSuccess = () => {
    setCurrentShowComponent("groupDashboard");
    if (sidebarRef.current) {
      sidebarRef.current.refreshGroupList();  // Call Sidebar's refresh method
    }
  };


  return (
    <div className={`${classes["main-groups-container"]}`}>
      <div className={`${classes[""]} row`}>

        <div className={`${classes["groups_section"]} col-sm-12 col-md-5 col-lg-3`}>
          <Sidebar ref={sidebarRef} toggleComponent={handleComponentToggle} handlePostDashboard={handlePostDashboard} />
        </div>

        <div className={`${classes["groups_section"]} col-sm-12 col-md-7 col-lg-9`}>
          {currentShowComponent === 'createGroup' && <CreateGroup onGroupCreationSuccess={handleGroupCreationSuccess} />}
          {currentShowComponent === 'updateGroup' && <CreateGroup postDashboardData={postDashboardData} isEditGroup={true} onGroupCreationSuccess={handleGroupCreationSuccess} />}
          {currentShowComponent === 'groupDashboard' && <GroupsDashboard postDashboardData={postDashboardData} handleComponentToggle={handleComponentToggle}/>}
        </div>
      </div>
    </div>
  );
}

export default GroupsPage;
