import React from 'react';
import styles from './RecentlyViewed.module.css'; // Import the CSS module
import item1 from '../../../../../../Assets/Logo/book.svg';
import item2 from '../../../../../../Assets/Logo/edu.svg';
import item3 from '../../../../../../Assets/Logo/college.svg';

const RecentlyViewed = () => {
  const items = [
    {
      icon: item1,
      title: 'Microsoft',
      description: 'Once You have Graduated, come join the Microsoft family! We have opened more positions ...',
    },
    {
      icon: item1,
      title: 'Keen Heldt',
      description: 'Thing I have to do for my course! Hard work but worth it ',
    },
    {
      icon: item2,
      title: 'Douglas College',
      description: 'Computer Science Program',
    },
    {
      icon: item3,
      title: 'Computer Science - Douglas College',
      description: 'The Bachelor of Arts with a Major in Computer Science is composed of courses drawn from ...',
    },
  ];

  return (
    <div className={styles.recentlyViewedContainer}>
      <div className={styles.header}>
        <span>Recently Viewed:</span>
        <a href="/view-more">View More...</a>
      </div>
      <div className={styles.items}>
        {items.map((item, index) => (
          <div key={index} className={styles.item}>
            <img src={item.icon} alt={item.title} className={styles.icon} />
            <div className={styles.details}>
              <span className={styles.title}>{item.title}</span>
              <span className={styles.description}>{item.description}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentlyViewed;
