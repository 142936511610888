import { useState } from "react";
import leftImg from "../../../../Assets/Images/signin-page-img.svg";
import union from "../../../../Assets/Logo/Union.svg";
import classes from "./ForgotPassword.module.css";
import { Link } from "react-router-dom";
import Loader from "../../../GlobleShared/Loader/Loader";
import ApiService from "../../../../Services/APIService";
import { toast } from "react-toastify";

function ForgotPassword() {
  const [form, setForm] = useState({
    email: "",
  });
  const [isLoading, setisLoading] = useState(false);
    const [message, setMessage] = useState(false);
  // state for errors
  const [formErrors, setFormErrors] = useState({
    email: null,
  });
  const validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "email":
        if (!value) errorMsg = "Please enter Email.";
        else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))
          errorMsg = "Please enter a valid Email.";
        break;
      default:
        break;
    }
    return errorMsg;
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    // Update form state with the new value
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    })); 

    // Validate the field and update formErrors state
    if (formErrors[name] !== undefined) {
      const errorMsg = validateField(name, value);
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: errorMsg,
      }));
    }
  };
  const validateForm = (form) => {
    const errorObj = {};
    Object.keys(form).forEach((key) => {
    
      const msg = validateField(key, form[key]);
      if (msg) errorObj[key] = msg;
    });
    return errorObj;
  };
  const handleNext = () => {
    console.log("Submitted");
    const errors = validateForm(form);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      // setIsSubmit(true);
      const formData = {
        email: form.email,
      };

      console.log("Data:", form);

    setisLoading(true);
    ApiService.userForgotPassword(formData)
      .then((res) => {
        setisLoading(false);
        toast.success(res?.message);
        setMessage(true);
      })
      .catch((error) => {
        setisLoading(false);
        toast.error(error?.message);
      });
  };
}
  return (
    <div className={classes["main-container"]}>
      <div className={`${classes.row_hieght} row`}>
        <div className={`${classes.col_img} col-md-7`}>
          <div>
            <img
              className={classes["left-img"]}
              src={leftImg}
              alt=""
            />
          </div>
        </div>
        <div className={`${classes.right_col} col-md-5`}>
          <div className={classes["sign_up-box"]}>
            <div className={classes.header}>
              <span>
                <img
                  src={union}
                  alt=""
                />
              </span>
              <span>MSM Pursuit</span>
            </div>
            <form>
             { message ? 
             <div>
              <span className={classes.resetPasswordText}>Reset password link is sent on your email. Please check</span>
              <div>
             <Link
               to="/sign-in"
               className={`${classes.backToSignInParent}`}>
               <span className={`${classes.backToSignIn}`}> &lt; Back to Login</span>
             </Link>
           </div></div>
             : <div className={classes["form-section"]}>
                <div className={classes["form-content"]}>
                  <div className="form-group">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`${classes.label} form-label`}>
                      Email
                    </label>
                    <input
                      onChange={changeHandler}
                      type="text"
                      placeholder="Type Email Here..."
                      name="email"
                      value={form.email}
                      onBlur={changeHandler}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                    {formErrors.email && <span className={classes.error}>{formErrors.email}</span>}
                  </div>
                </div>
                <div className="mt-2">
                  <button type="button"
                    disabled={
                      !form.email ||
                      //   (currentStep === 2 && (!form.fname || !form.lname)) ||
                      //   (currentStep === 4 && otp?.length < 6) ||
                      Object.keys(formErrors).some((key) => formErrors[key])
                      || isLoading
                    }
                    className={`${classes.primary_button} primary-button`}
                    onClick={()=>handleNext()}>
                    Next
                  </button>
                  <div>
                    <Link
                      to="/sign-in"
                      className={`${classes.backToSignInParent}`}>
                      <span className={`${classes.backToSignIn}`}> &lt; Back to Login</span>
                    </Link>
                  </div>
                </div>
              </div>}
            </form>
          </div>
        </div>
      </div>
      <div>{isLoading && <Loader />}</div>
    </div>
  );
}
export default ForgotPassword;
