import React, { useState } from "react";
import classes from "./Notifications.module.css"; // Assuming you're using CSS Modules
import userPic from "../../../Assets/Images/comment_user_pic.png";

const Notifications = () => {
  const [activeTab, setActiveTab] = useState("all");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const notificationsData = [
    {
      id: 1,
      message: "Waffles chirp at wanting food.",
      time: "Now",
      unread: true,
    },
    { id: 2, message: "Eat fish on floor", time: "6 hours ago", unread: true },
    {
      id: 3,
      message: "Fight an alligator and win",
      time: "yesterday",
      unread: true,
    },
    {
      id: 4,
      message: "Charlie accepted your request",
      time: "2 days ago",
      unread: true,
    },
    {
      id: 5,
      message: "You were invited into a group",
      time: "5 days ago",
      unread: true,
    },
    {
      id: 6,
      message: "Your application was accepted",
      time: "1 week ago",
      unread: true,
    },
    {
      id: 7,
      message: "You were mentioned in this post",
      time: "3 months ago",
      unread: false,
    },
    {
      id: 8,
      message: "You might like this group",
      time: "3 months ago",
      unread: false,
    },
  ];

  const filteredNotifications =
    activeTab === "unread"
      ? notificationsData.filter((notification) => notification.unread)
      : notificationsData;

  return (
    <div className={classes["notifications-container"]}>
      
      <div className={classes["notifications-header-section"]}>
      <div className={classes["notifications-title"]}>Notifications</div>
      <div className={classes["notifications-tabs"]}>
        <button
          className={`${classes["tab"]} ${
            activeTab === "all" ? classes["active"] : ""
          }`}
          onClick={() => handleTabClick("all")}
        >
          All
        </button>
        <button
          className={`${classes["tab"]} ${
            activeTab === "unread" ? classes["active"] : ""
          }`}
          onClick={() => handleTabClick("unread")}
        >
          Unread
        </button>
      </div>
      </div>

      <div className={classes["notifications-list"]}>
        {filteredNotifications.map((notification) => (
          <div key={notification.id} className={classes["notification-card"]}>
            <div className={classes["notification-card_content"]}>
              <div className={classes["notification-card_coverImage"]}>
                <img src={userPic} alt="" />
              </div>
              <div className={classes["notification-card_info"]}>
                <div className={classes["notification-message"]}>
                  {notification.message}
                </div>
                <div className={classes["notification-time"]}>
                  {notification.time}
                </div>
              </div>
            </div>

            {notification.unread && (
              <div className={classes["notification-status"]}>
                <span>Unread</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;
