import React, { useState, useRef, useEffect } from "react";
import classes from "./Posts.module.css";
import PostPic from "../../../Assets/Images/profile_avatar.png";
import PostImage from "../../../Assets/Images/post_image.png";
import TripleDot from "../../../Assets/Logo/triple_dot.svg";
import PostCrossIcon from "../../../Assets/Logo/post_cross_icon.svg";
import CommentPic from "../../../Assets/Images/comment_user_pic.png";
import Like from "../../../Assets/Logo/like.svg";
import Liked from "../../../Assets/Logo/liked.svg";
import Comment from "../../../Assets/Logo/comment.svg";
import Share from "../../../Assets/Logo/share.svg";
import greyHeart from "../../../Assets/Logo/grey-heart.svg";
import greyEmoji from "../../../Assets/Logo/grey-emogi.svg";
import greyLike from "../../../Assets/Logo/grey-like.svg";
 import Mute from "../../../Assets/Logo/mute.svg";
 import report1 from "../../../Assets/Logo/report1.svg";
 import block from "../../../Assets/Logo/block.svg";
 import repost1 from "../../../Assets/Logo/repost.svg";
import useLocalStorage from "../../GlobleShared/CustomHooks/useLocalStorage";
import PostServices from "../../../Services/PostServices";
import { toast } from "react-toastify";
import Loader from "../../GlobleShared/Loader/Loader";
import PositionLoader from "../../GlobleShared/PositionLoader/PositionLoader";
import PostImagesModal from "../../GlobleShared/Modal/PostImagesModal/PostImagesModal";
import SharePostModal from "../../GlobleShared/Modal/SharePostModal/SharePostModal";
import CommonPost from "../../Pages/Home/Groups/CommonPost/CommonPost";
import { useBehaviorSubject } from "../../GlobleShared/BehaviorSubject/BehaviorSubject";
 import ViewMediaModal from "../../GlobleShared/Modal/ViewMediaModal/ViewMediaModal";
import ConfirmationModal from "../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import ModelForMore from "./ModelForMore";
import UserModal from "../../GlobleShared/Modal/UserModal/UserModal";
import CommonServices from "../../../Services/CommonServices";
import GroupService from "../../../Services/GroupService";
import reportICon from "../../../Assets/Logo/report-icon.svg";
import leaveICon from "../../../Assets/Logo/Leave.svg";
import blockICon from "../../../Assets/Logo/Report.svg";
import Dots from "../../../Assets/Logo/dots-icon.svg";


function InnerPost({groupData, post , refreshPostList, repost, isDashboard, isConnectedUserId, isGroupPost, moderatorPostFlag}) {
  const [moreBtnPopupOpen, setMoreBtnPopupOpen] = useState(false);
  const [viewComments, setViewComments] = useState(3);
  const [CommentBox, setCommentBox] = useState(false);
  const [comment, setComment] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [mediaUrl, setMediaUrl] = useState('');
const [mediaItems, setMediaItems] = useState([]); 
const [currentMediaIndex, setCurrentMediaIndex] = useState(0); 
const [userDetail] = useLocalStorage('userDetail');
const [mediaTypeId, setMediaTypeId] = useState(null);
  const userId = userDetail.user_id;
  const { postUpdated,setPostUpdated } = useBehaviorSubject();
  // const [liked, setLiked] = useState(post.likedBy.includes(userId));
  const moreBtnRef = useRef(null);
  const [commentList, setCommentList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(3);
  const [postId, setpostId] = useState();
  const [commentCount, setCommentCount] = useState();
  const [isCommentLoading, setIsCommentLoading] = useState(false);
 
  const [postImagesModalOpen, setPostImagesModalOpen] = useState(false);
  const [postShareModalOpen, setPostShareModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditPost, setIsEditPost] = useState(false);
  const [getEditPost, setGetEditPost] = useState({});
  const [imageMediaList, setImageMediaList] = useState([]);
  const [isLikeLoading, setIsLikeLoading] = useState(false);

  const [OpenConformBox, setOpenConformBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modelOpenForMore, setModelOpenForMore] = useState('');
  const [confirmationRepostModal, setConfirmationRepostModal]=useState(false)
  const [postData, setpostData]=useState("")
  const comfirmModal=useRef(null)
  const [openUserModal, setOpenUserModal]=useState(false)
 const [actionType, setActionType]=useState('')
  const [likeShareUser, setLikeShareUser] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [postTagList,setPostTagList]=useState([])
  const blobURL= process.env.REACT_APP_URL_PATH
  const {groupType, setGroupType, setModaratorPostFlag, pauseFlag, setPauseFlag}=useBehaviorSubject()
  const [openConfirmModal, setOpenConfirmModal]=useState(false)
  const [confirmText, setConfirmText]=useState('')
  const dropdownRef = useRef();
  const [visibleCommentId, setVisibleCommentId] = useState(null); 
  const [tempCommentId, setTempCommentId] = useState(null);
  const [optType, setOptType]=useState(1)
  const [openReportModal, setOpenReportModal]=useState(false)
  const [openPostReport, setOpenPostReport]=useState(false)
  const reportRef=useRef()
  const [sourceId, setSourceId]=useState('')

  const handleShowModal = (actionName, id, type) => {
    setMoreBtnPopupOpen(false);
    setModelOpenForMore(actionName);
    setOpenPostReport(true)
    setSourceId(id)
    setOptType(type)
  };
  const showCommentReport=(actionName, id, type)=>{
    setMoreBtnPopupOpen(false);
    setModelOpenForMore(actionName); 
     setSourceId(id)
    setOptType(type)
    setOpenReportModal(true)
  }

  const reportPost=(id, optType)=>{
    setModelOpenForMore(''); 
    let data={
      optType:optType,
      sourceId:sourceId,
      reportReasonId:id
    }
    setIsLoading(true)
    PostServices.reportPost(data).then(res=>{
      setIsLoading(false)
      toast.success(res?.message)
      // if(optType===2){
      //   getCommentList(postId, page)

      // }else{
      //   setPostUpdated(true)
      // }
      setModelOpenForMore(''); 
      
      setOpenPostReport(false)
      setTempCommentId('')

    }).catch(error=>{
      setIsLoading(false)
      toast.error(error?.message)
    })
  }



  const actionConfirm=(id)=>{
    reportPost(id, optType)
  }
  const handleCloseModal = () => {
    setModelOpenForMore(''); 
    setOpenPostReport(false)
    setOpenReportModal(false)
    setTempCommentId('')
  };
  const showImagesModalhandler = () => {
    setPostImagesModalOpen(true);
  };
  const hideImagesModalhandler = () => {
    setPostImagesModalOpen(false);
  };

  const showConfirmModalhandler = (postDatavalue) => {
    setpostData(postDatavalue)
    setConfirmationRepostModal(true);
  };
  const hideConfirmModalhandler = () => {
    setConfirmationRepostModal(false);
  };

  const showPostShareModalhandler = () => {
    setPostShareModalOpen(true);
  };
  const hidePostShareModalhandler = () => {
    setPostShareModalOpen(false);
  };

  const handleMediaClick = (url, typeId) => {
    setMediaUrl(url);
    setMediaTypeId(typeId);
    setShowModal(true);
  };
  const handleCommentSubmit = (postId, commentId, optTypeValue) => {
    if (comment.trim()) {
      let data ={
        comment: comment,
        optType: optTypeValue,
        postCommentId: commentId>0 ? commentId : 0,
        postId: postId
      };
      setIsCommentLoading(true);
      PostServices.addComments(data)
        .then((res) => {
          setComment("");
          getAddedCommentList(postId);
          setIsCommentLoading(false);
        })
        .catch((error) => {
          toast.error(error?.message);
          setIsCommentLoading(false);
        });
    }
  };
 
  //  const showCommentBox=()=>{
  //   setCommentBox(!CommentBox)
  //  }
 
  const handleViewMoreComments = () => {
    setViewComments((prev) => prev + 4);
  };
 
  const handleShowLessComments = () => {
    setViewComments(3);
  };
 
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };
 
  const toggleMoreBtnPopup = () => {
    setMoreBtnPopupOpen(true);
  };
 
  const handleLike = () => {
    // if (liked) {
    //   post.likedBy = post.likedBy.filter(id => id !== userId);
    // } else {
    //   post.likedBy.push(userId);
    // }
    // setLiked(!liked);
  };
 
  useEffect(() => {
    function handleClickOutside(event) {
      if (moreBtnRef.current && !moreBtnRef.current.contains(event.target)) {
         setMoreBtnPopupOpen(false);
         setOpenReportModal(false)
  
      }
      if(comfirmModal.current && !comfirmModal.current.contains(event.target)) {
        setModelOpenForMore('');
        setOpenPostReport(false)
      }
      if(reportRef.current && !reportRef.current.contains(event.target)) {
        setOpenReportModal(false)
        setTempCommentId('')
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [moreBtnRef, modelOpenForMore]);


    const getAddedCommentList = (postId) => {
    let data = {
      limit: limit * page,
      page: 1,
      postId: postId
    }
    setpostId(postId)
    setCommentBox(true)
    setIsCommentLoading(true)
    PostServices.getComments(data).then(res=>{
      setCommentList(res?.list)
      setCommentCount(res?.totalCount)
      setIsCommentLoading(false)
    }).catch(error=>{
      setIsCommentLoading(false)
      toast.error(error?.message)
    })
  }
  const getCommentList = (postId, pageValue) => {
    let data = {
      limit: limit,
      page: pageValue,
      postId: postId
    }
    setpostId(postId)
    setCommentBox(true)
    setIsCommentLoading(true)
    PostServices.getComments(data).then(res=>{
      setCommentList([...commentList, ...res?.list])
      setCommentCount(res?.totalCount)
      setIsCommentLoading(false)
    }).catch(error=>{
      setIsCommentLoading(false)
      toast.error(error?.message)
    })
  }
  // const handleMediaClick = (url, typeId) => {
  //   setMediaUrl(url);
  //   setMediaTypeId(typeId);
  //   setShowModal(true);
  // };
 
  const viewMore=(postId)=>{
    getCommentList(postId, page+1)
    setPage(page+1)
  }
 
  const updateLike=(postId, islike)=>{
    let data={
      isLike: islike,
      postId: postId
    }
    setIsLikeLoading(true)
    PostServices.likePost(data).then(res=>{
      // refreshPostList()
      if(islike){
        post.likes =post.likes + 1
      }
      else{
        post.likes =post.likes - 1
      }
      post.isLike=islike
      setIsLikeLoading(false)
    }).catch(error=>{
      setIsLikeLoading(false)
      toast.error(error?.message)
    })
  }
  
  const editPost = (data) => {
    setGetEditPost(data);
    setIsEditPost(true);
    setIsModalOpen(true);
    getPostMedia();
    getTagList()
    getPostTag(data?.tags)
  }
 
  const getPostTag=(data)=>{

    data.map(item=>{
        item['value']=item['TagID']
        item['label']=item['TagName']
      })
      setPostTagList(data)
  }
  const closeModal = () => {
    setIsEditPost(false);
    setIsModalOpen(false);
  };
 
  const getPostMedia = () => {
    let images = [];
    if(post.postMediaResponse?.length > 0){
      post.postMediaResponse.forEach((media) => {
        if(media.mediaTypeId && media.mediaTypeId===1){
          images.push(media.mediaUrl);
        }
      })
      setImageMediaList(images);
    }
  }
  const deletePostById = (id) =>{
    setIsLoading(true);
    PostServices.deletePostById(id).then(res=>{
      toast.success(res?.message);
      setIsLoading(false)
      setPostUpdated(true)
      setOpenConformBox(false);
    }).catch(error=>{
      setIsLoading(false)
      toast.success(error?.message);
    })
    }
  const cancelData=()=>{
    setOpenConformBox(true);
  }
  const confrimBox=()=>{
      deletePostById(post.postId)
  }
  const closeDeleteModel=()=>{
    setOpenConformBox(false);
  }

  const repostData =()=>{
    repost(postData);
    hideConfirmModalhandler();
  }

  const getLikeAndShareUser=(value, type)=>{
    setActionType(type)
    if(type==="like"){
      PostServices.getLikesUser(value).then(res=>{
        if(res?.length>0){
          setLikeShareUser(res)
          setOpenUserModal(true)
        }
        
      }).catch(error=>{
        toast.error(error?.message)
      })
    }
    if(type==="share"){
      PostServices.getSharesUser(value).then(res=>{
        if(res?.length>0){
          setLikeShareUser(res)
          setOpenUserModal(true)
        }
      }).catch(error=>{
        toast.error(error?.message)

      })
    }

  }
  const closeUserModel = ()=>{
    setOpenUserModal(false)
  }
  const getTagList=()=>{
    CommonServices.tagList().then(res=>{
      const response=res
      response.map(item=>{
        item['value']=item['TagId']
        item['label']=item['TagName']
      })
      setTagList(response.sort((a, b) => {
        if (a.TagName < b.TagName) return -1;
        if (a.TagName > b.TagName) return 1;
        return 0})
      )
  })
  }


  const formatPostTime = (createdAt) => {
    const now = new Date();
    const postDate = new Date(createdAt);
    const diffInSeconds = Math.floor((now - postDate) / 1000);
    
    if (diffInSeconds < 60) {
      return 'Now';
    } else if (diffInSeconds < 120) {
      return '2 minutes ago';
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 7200) {
      return '1 hour ago';
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 172800) {
      return 'Yesterday';
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 2419200) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 29030400) {
      const months = Math.floor(diffInSeconds / 2419200);
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
      const years = Math.floor(diffInSeconds / 29030400);
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
  };

  const openModal=(data, actType, type)=>{
    setActionType(actType)
    setSourceId(data)
    setOpenConfirmModal(true)
    setOptType(type)
    if(actType===1){
      setConfirmText('Are you sure, you want to ignore this post?')
    
    }
    if(actType===2){
      setConfirmText('Are you sure, you want to delete this post?')
    }
    

  }
  const closeOpenModal=()=>{
    setActionType('')
    setOpenConfirmModal(false)

  }
  const getConfirm=()=>{
    let data={
      sourceId:sourceId,
      optType:optType,
      action:actionType
    
    }
    setIsLoading(true)
    GroupService.reportAction(data).then(res=>{
      toast.success(res?.message)
      setIsLoading(false)
      setOpenConfirmModal(false)
      setModaratorPostFlag(true)
    }).catch(error=>{
      toast.error(error?.message)
      setIsLoading(false)
      setOpenConfirmModal(false)
    })


  }
 
  function onClickOutsideHandler(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setVisibleCommentId('')
    }
  }
  document.addEventListener('mousedown', onClickOutsideHandler);
 
  const getCommentId=(value)=>{
    setVisibleCommentId(value);
    setTempCommentId(value)
  }
  return (
    <>
          {openConfirmModal && (
          <ConfirmationModal onShow={openConfirmModal} getConfirmation={getConfirm} 
          onClose={closeOpenModal} btnText='Yes'  confirmationText={confirmText}></ConfirmationModal>
        )}
    <UserModal onShow={openUserModal} text={actionType} onClose={closeUserModel} data={likeShareUser}></UserModal>
    <CommonPost postTagList={postTagList} tagList={tagList} isModalOpen={isModalOpen} closeModal={closeModal} getPostDetails={getEditPost}
     editPost={isEditPost} imageMediaURLs={getEditPost?.postMediaResponse} updatePostList={refreshPostList} childValue={null} ChildMediaTypeId={null}/>
    {(moderatorPostFlag && post?.statusID===2) && (
    <div className={classes['red-header']}>
    <div className={classes['red-content']}>
      <span className={classes['text-font']}>{post?.reportReasonName}</span>
      <button onClick={()=>{openModal(post?.postId, 2, 1)}} className={classes['delete-btn']}>Delete</button>
      <button onClick={()=>{openModal(post?.postId, 1, 1)}} className={classes['ignor-btn']}>Ignore</button>
    </div>
  </div>
    )}

    <div ref={comfirmModal} className={(moderatorPostFlag && post?.statusID===2) ? classes["post-container1"]: classes["post-container"]}>
    { openPostReport && (
      <ModelForMore 
      title={modelOpenForMore}
      onClose={handleCloseModal}
      confirm={actionConfirm}
      ></ModelForMore>
    )}
    <div className={classes["post-block"]}>
      <div className={classes["header"]}>
        <div className={classes["header-left"]}>
          <img
            src={ post?.userProfileImage ? blobURL + post?.userProfileImage: PostPic}
            alt="Profile-Picture" 
            className={classes["profile_picture"]}
          />
          <div className={classes["user_info"]}>
            <div className={classes["user_name"]}>{post?.userName}</div>
            <div>
            {(post?.postVisibility===1) && (
              <span className={classes['publisher-tag']}>Public</span>
            )}
                        {post?.postVisibility===2 && (
              <span className={classes['publisher-tag']}>ALl my Connections</span>
            )}
                        {post?.postVisibility===3 && (
              <span className={classes['publisher-tag']}>Private</span>
            )}
                        {post?.postVisibility===4 && (
              <span className={classes['publisher-tag']}>Groups</span>
            )}
              </div>
          </div>
        </div>
        <div className={classes["header-right"]}>
          <span className={classes['timing']}>{formatPostTime(post?.createdDate)}  </span>
          {(!groupType && !moderatorPostFlag && !pauseFlag) && (
            <span className={classes["triple-dot"]}
            onClick={() => toggleMoreBtnPopup()}>
              <img src={TripleDot} alt="" />
            </span>
          )}

            {(!groupType && !pauseFlag && !moderatorPostFlag && !isDashboard && !isConnectedUserId) && ( <span className={classes["cross-icon"]} onClick={()=>setOpenConformBox(true)}>
              <img src={PostCrossIcon} alt="" />
            </span>
            )}
            {OpenConformBox && (
                <ConfirmationModal  onShow={OpenConformBox} getConfirmation={confrimBox} 
                  onClose={closeDeleteModel} btnText='Yes'  confirmationText='Are you sure, you want to delete the selected post?'/>
            )}
            {isLoading  && <Loader></Loader>}
            {moreBtnPopupOpen && (
              <div className={classes["MoreBtn"]} ref={moreBtnRef}>
              {isGroupPost && (
                <>
                    <div className={classes["MoreBtnItem"]} onClick={()=>handleShowModal('Report', post?.postId, 1)}>
                      Report
                    </div>
                    <div className={classes["brokenLine"]}></div>
                    </>
                )}
                { (!isDashboard && !isConnectedUserId) && (
                                  <>
                                  { groupData?.UserGroupRole !=='3' && (
                                    <>
                                 <div className={classes["MoreBtnItem"]} onClick={() => editPost(post)}>Edit</div>
                                 <div className={classes["brokenLine"]}></div>
                                 </>
                                  )}
 
                                  <div className={classes["MoreBtnItem"]} onClick={()=>{cancelData()}}>Delete</div>
                                  <div className={classes["brokenLine"]}></div>
                                  <div className={classes["MoreBtnItem"]} onClick={()=>{showConfirmModalhandler(post)}}>Repost</div>
                                  {/* <div className={classes["brokenLine"]}></div>
                                  <div className={classes["MoreBtnItem"]}>Comment</div> */}
                                  </>
                )}

                { (isDashboard || isConnectedUserId) && (
                                <>
                                <div className={classes["MoreBtnItem"]} onClick={()=>handleShowModal('Repost', post)}>
                                  <span className={classes["icon"]}><img src={repost1} alt="Repost Icon" /></span>
                                  Repost
                                </div>
                                <div className={classes["brokenLine"]}></div>
                                <div className={classes["MoreBtnItem"]} onClick={()=>handleShowModal('Mute', post)}>
                                  <span className={classes["icon"]}><img src={Mute} alt="Mute Icon" /></span>
                                  Mute 
                                </div>
                                <div className={classes["brokenLine"]}></div>
                                <div className={classes["MoreBtnItem"]} onClick={()=>handleShowModal('Report', post?.postId, 1)}>
                                  <span className={classes["icon"]}><img src={report1} alt="Report Icon" /></span>
                                  Report
                                </div>
                                <div className={classes["brokenLine"]}></div>
                                <div className={classes["MoreBtnItem"]} onClick={()=>handleShowModal('Block', post)}>
                                  <span className={classes["icon"]}><img src={block} alt="Block Icon" /></span>
                                  Block
                                </div>
                              </>
                )}
             
              </div>
            )}
            </div>
          </div>
 
          <div className={classes["content"]}>
            <div
              className={classes["post_text"]}
              dangerouslySetInnerHTML={{ __html: post.postCaption }}
            />
          </div>
          <div className={classes['tag-item']}>
            {post?.tags?.map(item=>(
              <span className={classes['tag-name']}>#{item?.TagName}  </span>
            ))}
          </div>
 

           { post.postMediaResponse?.length > 0 && (

           <div className={classes["post-image"]}>
            <div className={ post.postMediaResponse?.length === 1 ? classes["post-image-section-1"]  : post.postMediaResponse?.length === 2 
                   ? classes["post-image-section-2"]  : classes["post-image-section-3"] }>
              {post.postMediaResponse.slice(0,3).map((item)=>(
                <div key={item?.postMediaId}>
                  { item.mediaTypeId===1 && (
                        <img onClick={() => handleMediaClick(blobURL+item.mediaUrl, item.mediaTypeId)}
                        key={item.postMediaId}
                        src={blobURL+item.mediaUrl}
                        alt=""
                        className={classes["show-image"]}
                      />
                  )}
                    { item.mediaTypeId===2 && (
                          <video
                          controls
                          width="100%"
                          hieght="auto"
                          className={classes["show-vedio"]}
                          src={blobURL+item.mediaUrl}>
                          </video>
                  )}
              
                  </div>
                  
            
              ))}
                {post.postMediaResponse?.length > 3 && (
                    <div
                      className={classes["more-image"]}
                      onClick={showImagesModalhandler}
                    >
                      +{post.postMediaResponse?.length - 3} more
                    </div>
                 )}

             

            </div>
          </div>
 
           )}



{(!groupType && !pauseFlag) && (
          <div className={classes["actions-data"]}>
          <p className={classes['count-text']} onClick={()=>{getLikeAndShareUser(post?.postId, 'like')}}> 
            <img src={greyLike} alt="" />
            <img className={classes['image-icon']} src={greyEmoji} alt="" />
            <img className={classes['image-icon']} src={greyHeart} alt="" />
          <span> {post?.likes} </span></p>
          <p className={postId===post?.postId?classes['pointer-none']:classes['count-text']} onClick={()=>{getCommentList(post?.postId, 1)}}>
            {commentList?.length>0?commentList?.length:post?.commentCount} Comments
          </p>
          <p className={classes['count-text']} onClick={()=>{getLikeAndShareUser(post?.postId, 'share')}}>
            {post?.shareComment} Shares
           </p>
        </div>
)}

 
{(!groupType && !pauseFlag) && (
      <div className={classes["actions"]}>
     
        <button className={classes["action_button"]} onClick={()=>{updateLike(post?.postId, post?.isLike ? false : true)}}>
        { !isLikeLoading && (
          <span>
        <img src={post?.isLike? Liked : Like} alt="" /> <span className={post?.isLike? classes["activeLikeKeyword"] :''}>Like</span>
        </span>
       )}

       { isLikeLoading && (
        <PositionLoader></PositionLoader>
       )} 
        </button>


        <button disabled={postId===post?.postId} onClick={()=>{getCommentList(post?.postId, 1)}} className={classes["action_button"]}>
          <img src={Comment} alt="" />
           Comments
        </button>
        <button className={classes["action_button"]} onClick={showPostShareModalhandler}>
          <img src={Share} alt="" /> Share
        </button>
      </div>
)}
 
          </div>
          {CommentBox && (
            <>
            { !moderatorPostFlag && (
              <div className={classes["add-comment-container"]}>
              <img src={post?.userProfileImage ? blobURL + post?.userProfileImage: PostPic} alt="User" className={classes["user-pic"]} />
              <input
                type="text"
                placeholder="Add a comment..."
                value={comment}
                onChange={handleCommentChange}
                className={classes["input-field"]}
              />
              <button
                disabled={isCommentLoading}
                onClick={() => {
                  handleCommentSubmit(post?.postId, 0, 1);
                }}
                className={classes["comment-btn"]}
              >
                Comment
              </button>
            </div>
            )}

            </>
          )}
 
          <div className={classes["comments_section"]}>
            <div className={classes["comments_title"]}>
              {post?.postId === postId && commentList?.length > 0 && (
                <div className={classes["text"]}>Top Comment:</div>
              )}
 
        </div>
 
        <div className={classes["comments"]}>
          {commentList.map((comment, index) => (
            <>
                  {(moderatorPostFlag && comment?.statusId===2) && (
                <div className={classes['red-header-comment']}>
                <div className={classes['red-content']}>
                  <span className={classes['text-font']}>{comment?.reportName}</span>
                  <button onClick={()=>{openModal(comment?.postCommentId, 2, 2)}} className={classes['delete-btn']}>Delete</button>
                  <button onClick={()=>{openModal(comment?.postCommentId, 1, 2)}} className={classes['ignor-btn']}>Ignore</button>
                  </div>
                 </div>
                )}
            <div className={(moderatorPostFlag && comment?.statusId===2)? classes['red-comment'] :classes["comment"]} key={index}>
              <div className={classes["comment-image"]}>
                <img src={comment?.profileImage ? blobURL+comment?.profileImage : PostPic} alt="" /> 
              </div>
 
              <div className={classes["comment_content"]}>
                <div className={classes["comment_author"]}>
                  {comment?.displayName}:
                </div>
                <div className={classes['comment_text']}>
                {comment?.comment}
                  </div>
                </div>
            </div>
            <div className={classes['span-margin']}>
              { !moderatorPostFlag && (
            <div className={classes["commentActions"]}>
            <span className={classes["actionButton"]}  onClick={() => getCommentId(comment.postCommentId)}>
              <img
                src={Dots}
                alt=""
                className={classes["dotsIcon"]}
               
              />
            </span>
            {/* <span className={classes["actionButton"]}>Share</span>
            <span className={classes["actionButton"]}>Reply</span> */}
            {visibleCommentId === comment.postCommentId && (
            <div className={classes["moreBtnParent"]} ref={dropdownRef}>
                <div className={classes["moreBtn"]}>
                  {/* <div className={classes["moreBtnItem"]}>
                    <div><img className={classes.imgPadding} src={leaveICon} alt="" /></div><div>Hide</div>
                  </div> */}
                  <div className={classes["moreBtnItem"]} onClick={()=>showCommentReport('Report', comment?.postCommentId, 2)}>
                    <div><img className={classes.imgPadding} src={reportICon}  alt="" /> &nbsp;</div><div>Report</div>
                  </div>
                  {/* <div className={classes["moreBtnItem"]}>
                    <div><img className={classes.imgPadding} src={blockICon} alt="" /></div><div>Block</div>
                  </div> */}
                </div>
              </div>
            )}
            </div>
              )}

      
            {(openReportModal && tempCommentId === comment.postCommentId) && (
                    <div ref={reportRef}>
                <ModelForMore  cssFlag={true}
                title={modelOpenForMore}
                onClose={handleCloseModal}
                confirm={actionConfirm}
                ></ModelForMore>
                    </div>
              )}
            </div>
            </>
              ))}
          </div>
            {isCommentLoading && post?.postId === postId && (
              <PositionLoader></PositionLoader>
            )}
            {CommentBox &&
              commentList?.length !== commentCount &&
              commentList?.length > 0 && (
                <div className="text-center">
                  <button
                    onClick={() => {
                      viewMore(post?.postId);
                    }}
                    className={classes["view-more"]}
                  >
                    View More
                  </button>
                </div>
              )}
 
            {!isCommentLoading &&
              CommentBox &&
              post?.postId === postId &&
              commentList?.length === 0 && (
                <div className="text-center">
                  <p>No comments found</p>
                </div>
              )}
          </div>
        </div>
        <ViewMediaModal
  show={showModal}
  handleClose={() => setShowModal(false)}
  mediaUrl={mediaUrl}
  mediaTypeId={mediaTypeId}
/>
<ViewMediaModal
  show={showModal}
  handleClose={() => setShowModal(false)}
  mediaUrl={mediaUrl}
  mediaTypeId={mediaTypeId}
/>
      {postImagesModalOpen && (
        
        <PostImagesModal
          onClose={hideImagesModalhandler}
          onShow={showImagesModalhandler}
          data={post.postMediaResponse}
        ></PostImagesModal>
      )}

      {postShareModalOpen && (
        <SharePostModal
          onClose={hidePostShareModalhandler}
          onShow={showPostShareModalhandler}
          postIdData={post?.postId}
        ></SharePostModal>
      )}

        {confirmationRepostModal && (
          <ConfirmationModal onShow={showConfirmModalhandler} getConfirmation={repostData} 
          onClose={hideConfirmModalhandler} btnText='Yes'  confirmationText='Are you sure, you want to repost ?'></ConfirmationModal>
        )}
    </>
  );
}
 
function Posts(props) {
  // post api
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [postList, setPostList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { postUpdated,setPostUpdated ,groupIdGlobal ,setGroupIdGlobal} = useBehaviorSubject();
  const initial = useRef(null);
  const {groupType, setGroupType, setPauseFlag, pauseFlag, updatePostDatata}=useBehaviorSubject();

 
  const getPost = (pageValue) => {
    let data = {}
    if(props.connectedUserId){
      data = {
        limit: limit,
        page: pageValue,
        paginated: true,
        searchTerm: "",
        studentId: props.connectedUserId,
      };
    }
    else{
      data = {
        limit: limit,
        page: pageValue,
        paginated: true,
        searchTerm: "",
      };
    }
    setIsLoading(true);
    PostServices.getPostList(data)
      .then((res) => {
        setPostList([...postList, ...res?.postData]);
        setIsLoading(false);
        setTotalCount(res?.totalCount);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };

  const getGroupPost = (id) => {
    setIsLoading(true);
    PostServices.getGroupPosts(id)
      .then((res) => {
        setPostList(res);
        setIsLoading(false);
        setTotalCount(res?.totalCount);
        if(groupType){
          setGroupType(true)
        }else if(pauseFlag){
          setPauseFlag(true)
        }
        
       
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
 

  // repost
  const rePost = (post_data) => {
    let data = {
     ids:[],
     groupId: Number(post_data?.groupId),
     postMedia: post_data?.postMediaResponse,
     postText: post_data?.postCaption,
     postVisibility:post_data?.postVisibility,
     rePostId: post_data?.postId,
     tagIds: post_data?.tags?.map(tag => tag.TagID),
     entityType:0,
    };
    setIsLoading(true);
    PostServices.addPost(data)
      .then((res) => {
        // setPostList([...postList, ...res?.postData]);
        toast.success(res?.message);
        setPostUpdated(true);
        setIsLoading(false);

        // setTotalCount(res?.totalCount);
    
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };


 
  const refreshConnection = (pageValue) => {
    let data = {
      limit: limit,
      page: pageValue,
      paginated: true,
      searchTerm: "",
    };
    setIsLoading(true);
    PostServices.getPostList(data)
      .then((res) => {
        setPostList([...res?.postData]);
        setIsLoading(false);
        setTotalCount(res?.totalCount);
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false);
      });
  };
 
  const viewMore = () => {
    getPost(page + 1);
    setPage(page + 1);
  };
 
  useEffect(() => {
    if(props.groupPostFlag){
      getGroupPost(props.groupId)
    }

    if(props.moderatorPostFlag){
      setPostList(props.moderatorPostdata);
    }
    
    else{
    if (!initial.current) {
      setGroupType(false)
      setPauseFlag(false)
      initial.current = true;
        getPost(page, limit);}
      

  }
  },[updatePostDatata, props.groupId, props.moderatorPostdata, props.moderatorPostFlag])
 
  useEffect(() => {
    if(props.groupPostFlag && postUpdated){
      getGroupPost(props.groupId); 
      setPostUpdated(false);
    }
    if (postUpdated && !props.groupPostFlag) {
      refreshConnection(1)
       setPostUpdated(false); // Reset after fetching
    }
}, [postUpdated]);
 
 
  const getRefrshPage=()=>{
    if(props.groupPostFlag){
      getGroupPost(props.groupId)
    }else{
    getPost(page, limit)
    }
  }

  return (
    <>
    <div className={classes["post-main-container"]}>
      {postList?.length > 0 && ( <>
      {postList.map((post, index) => (
        <InnerPost groupData={props?.groupData} moderatorPostFlag={props.moderatorPostFlag} isGroupPost={props?.groupPostFlag} refreshPostList={getRefrshPage} key={index} post={post} repost={rePost} isDashboard={props.isDashboard} isConnectedUserId={props.connectedUserId}/> 
     ))}</>
        ) }
 
      <div>
      { (!props?.groupPostFlag && !props.moderatorPostFlag && (totalCount!==postList?.length && postList?.length>0)) && (
         <div className="text-center mb-2">
         <button onClick={viewMore} className={classes['view-more']}>View more</button>
          </div>
          )}
        </div>
      </div>
 
      { (!postList || postList?.length===0) && (
        <div className={classes['no-post-data']}>
          <p> No Posts Found</p>
          </div>
      ) }
      {isLoading && <Loader></Loader>}
    </>
  );
}
 
export default Posts;