import React, { useEffect, useRef, useState } from "react";
import classes from "./GroupProfiles.module.css";
import addIcon from "../../../../../Assets/Logo/add-icon.svg";
import moreIcon from "../../../../../Assets/Logo/more-icon.svg";
import crownIcon from "../../../../../Assets/Logo/crown.svg";
import moderatorIcon from "../../../../../Assets/Logo/moderator-icon.svg";
import reportICon from "../../../../../Assets/Logo/report-icon.svg";
import leaveICon from "../../../../../Assets/Logo/Leave.svg";
import blockICon from "../../../../../Assets/Logo/Report.svg";
import pauseICon from "../../../../../Assets/Logo/pause.svg";
import ConfirmationModal from "../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import GroupService from "../../../../../Services/GroupService";
import { toast } from "react-toastify";
import Loader from "../../../../GlobleShared/Loader/Loader";
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import ModelForMore from "../../../../Common/Posts/ModelForMore";
import PostServices from "../../../../../Services/PostServices";


const GroupProfiles = ({ groupData,  name, MembersCount,update, image, id, index, groupLength, groupType, tab, member, UserGroupRole,handleHidePopup,onClick,requestId,getInvitationlist,onTabChange,getSuggestionGroups,getRequestlist}) => {
  const [moreBtnPopUp, setMoreBtnPopUp] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [confirmationText , setConfirmationText]= useState('')
  const [btnTextName, setbtnTextName] = useState();
  const [groupIdvalue, setgroupIdvalue] = useState();
  const [statusId, setStatusId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef();
  const [userDetail] = useLocalStorage('userDetail');
  const studentId = userDetail?.user_id;
  const { setUpdatePostDatata}=useBehaviorSubject()
  const [modelOpenForMore, setModelOpenForMore] = useState('');
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const reportRef=useRef()
  function onClickOutsideHandler(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMoreBtnPopUp(false);
    }
    if (reportRef.current && !reportRef.current.contains(event.target)) {
      setModelOpenForMore('');
     }
  }
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutsideHandler);
    return () => {
        document.removeEventListener('mousedown', onClickOutsideHandler);
    };
  }, []);

  const showModalhandler=( value,status, name)=>{
    setModalIsVisible(true);
    setgroupIdvalue(value);
    setStatusId(status)
    setbtnTextName(name)
    if(status===4){
      setConfirmationText('Are you sure, you want to delete the selected group?')
    }
  
    if(status===5){
      setConfirmationText('Are you sure, you want to pause the selected group?')
    }
  
    if(status===1){
      setConfirmationText('Are you sure, you want to leave the selected group?')
    }
    if(status===2){
      setConfirmationText('Are you sure, you want to report the selected group?')
    }
  
    if(status===3){
      setConfirmationText('Are you sure, you want to block the selected group?')
    }
    if(status===6){
      setConfirmationText('Are you sure, you want to join the selected group?')
    }
    if(status===7){
      setConfirmationText('Are you sure, you want to withdraw the selected group?')
    }
    if(status===10){
      setConfirmationText('Are you sure, you want to archive the selected group?')
    }
    if(name==='Unblock'){
      setConfirmationText('Are you sure, you want to unblock the selected group?')
    }
    if(status===11){
      setConfirmationText('Are you sure, you want to resume the selected group?')
    }
  
  }
  const hideModalhandler=(value)=>{
    setModalIsVisible(false);
  }

  const statusUpdate=()=>{
    let data={
      groupId: groupIdvalue,
      groupStatusId: statusId,
      userId:studentId
    }
    setIsLoading(true)
    GroupService.updateGroups(data).then(res=>{
      setIsLoading(false)
      toast.success(res?.message)
      handleHidePopup()
      setModalIsVisible(false);
      setUpdatePostDatata(true)
    }).catch(error=>{
      setIsLoading(false)
      toast.error(error?.message)
    })
  
  }
  const getConfirm =()=>{
    if(tab === "your-groups"){statusUpdate()}
     if(tab === "requests"){withdrawGroup()}
     if(tab === "invitations"){joinGroup()}
     if(tab === "suggestions"){joinSuggestionGroup()}
  }

  //join
  const joinGroup=()=>{
    let data={
      RequestID: requestId,
    }
    setIsLoading(true)
    GroupService.joinGroup(data).then(res=>{
      setIsLoading(false)
      toast.success(res?.message)
      getInvitationlist()
      setModalIsVisible(false);
    }).catch(error=>{
      setIsLoading(false)
      toast.success(error?.message)
    })
  
  }

  const joinSuggestionGroup=()=>{
    let data = {
      "GroupID": groupIdvalue,
      "UserGroupRole": '3',
      "UserID": studentId
    }
    setIsLoading(true)
    GroupService.joinSuggestionGroup(data).then(res=>{
      setIsLoading(false)
      toast.success(res?.message)
      onTabChange('your-groups')
      setModalIsVisible(false);
    }).catch(error=>{
      setIsLoading(false)
      toast.success(error?.message)
    })
  
  }
  //withdraw
  const withdrawGroup=()=>{
    let data={
      RequestID: requestId,
    }
    setIsLoading(true)
    GroupService.withdrawGroup(data).then(res=>{
      setIsLoading(false)
      toast.success(res?.message)
      getRequestlist()
      setModalIsVisible(false);
    }).catch(error=>{
      setIsLoading(false)
      toast.success(error?.message)
    })
  
  }
  const handleShowModal = (groupId, actionName ) => {
    setSelectedGroupId('')
    setModelOpenForMore(actionName); 
   setSelectedGroupId(groupId)
   setMoreBtnPopUp(false);
  };

  const actionConfirm=(id)=>{
    reportPost(id)
  }
  const handleCloseModal = () => {
    setModelOpenForMore(''); 
  };

  const reportPost=(id)=>{
    setModelOpenForMore(''); 
    let data={
      optType:5,
      sourceId:selectedGroupId,
      reportReasonId:id
    }
    setIsLoading(true)
    PostServices.reportPost(data).then(res=>{
      toast.success(res?.message)
      setModelOpenForMore(''); 
      setSelectedGroupId('')
      handleHidePopup()
      setIsLoading(false)

    }).catch(error=>{
      setIsLoading(false)
      toast.error(error?.message)
    })
  }



  return (
    <div >
      <div
        key={id}
        className={groupData?.UserGroupStatusId==='3' ? classes['red-border-groups']:classes["group-item"]}>
        <img
          src={image}
          alt={name}
          className={classes["group-image"]}
        />
        <div className={ classes["group-details"]} >
          <span className={ classes.nameAndCrown}  onClick={onClick}><h3 className={classes["group-name"]}>
            {name} {groupData?.UserGroupStatusId==='3' && ( <span className={classes['block-text']}>Blocked</span>)}
            {groupData?.StatusId==='5' && (<span className={classes['block-text']}>Paused</span>)}
            </h3>
             </span>
          {groupType != null && <div className={classes["group-update"]}>{`Members: ${MembersCount}`}</div>}
          {/* {tab === "1" ? <div className={classes["group-update"]}>Update: {update}</div> : <div className={classes["group-member"]}>{member} member</div>} */}
        </div>
        <div >
          <div className={classes["group-actions"]}>
          {tab !== "suggestions" && (
            <>
              {UserGroupRole === "1" && (
                <img className={`${classes.imgCrown} mb-2`} src={crownIcon} alt="Crown Icon" />
              )}
              {UserGroupRole === "2" && (
                <img className={`${classes.imgCrown} mb-2`} src={moderatorIcon} alt="Moderator Icon" />
              )}
            </>
          )}

          </div>
          <div className={`${classes["group-actions"]} justify-content-end`}>
          {tab === "your-groups" ? (
            <>
            {/* <button className={classes["action-button"]}>
            <img
              src={addIcon}
              alt="Add"
              className={classes["icon"]}
              />
          </button> */}
          <button className={classes["action-button"]}>
            <img
              src={moreIcon}
              alt="More"
              className={classes["icon"]}
              onClick={() => setMoreBtnPopUp(!moreBtnPopUp)}
              />
          </button>
            {moreBtnPopUp && (
             <div className={classes["moreBtnParent"]} ref={dropdownRef}>
               {UserGroupRole==="1"?(
                <div className={classes["moreBtn"]}> 
                           <div className={classes["moreBtnItem"]}  onClick={()=>{showModalhandler(id, 4, 'Delete')}}><div><img className={classes.imgPadding}src={leaveICon} alt='' /></div><div>Delete</div></div>
                           {groupData?.StatusId !='5'&& (
                             <div className={classes["moreBtnItem"]}  onClick={()=>{showModalhandler(id, 5, 'Pause')}}><div><img className={classes.imgPadding}src={pauseICon} alt=''/></div><div>Pause</div></div>
                           )}
                           {groupData?.StatusId ==='5'&& (
                             <div className={classes["moreBtnItem"]}  onClick={()=>{showModalhandler(id, 11, 'Resume')}}><div><img className={classes.imgPadding}src={pauseICon} alt=''/></div><div>Resume</div></div>
                           )}
                           <div className={classes["moreBtnItem"]}  onClick={()=>{showModalhandler(id, 10, 'Archive')}}><div><img className={classes.imgPadding}src={pauseICon} alt=''/></div><div>Archive</div></div>
                           </div>):( <div className={classes["userMoreBtn"]}>
                           <div className={classes["moreBtnItem"]}  onClick={()=>{showModalhandler(id, 1, 'Leave')}}><div><img className={classes.imgPadding}src={leaveICon} alt=''/></div><div>Leave</div></div>
                           <div className={classes["moreBtnItem"]}  onClick={()=>{handleShowModal(id, 'Report')}}><div><img className={classes.imgPadding}src={reportICon} alt=''/></div><div>Report</div></div>
                           {groupData?.UserGroupStatusId !=='3'&&(
                             <div className={classes["moreBtnItem"]}  onClick={()=>{showModalhandler(id, 3, 'Block')}}><div><img className={classes.imgPadding}src={blockICon} alt=''/></div><div>Block</div></div>
                           )}
                          {groupData?.UserGroupStatusId ==='3'&&(
                           <div className={classes["moreBtnItem"]}  onClick={()=>{showModalhandler(id, 0, 'Unblock')}}><div><img className={classes.imgPadding}src={blockICon} alt=''/></div><div>Unblock</div></div>
                          )}
                           </div>)}
                </div>
            )}
          </>
            ) : (
          <>
            {tab === "requests" && (
              <><span className={classes["withdrawButton"]} onClick={()=>{showModalhandler(id, 7, 'Withdraw')}}>Withdraw</span></>
              )}
              {tab === "invitations" && (
              <>
              <div>
              <button className={classes["primary-button"]} onClick={()=>{showModalhandler(id, 6, 'Accept')}}>Accept</button>
              <p className={classes["cancel-btn"]} onClick={()=>{showModalhandler(id, 7, 'Cancel')}}>Cancel</p>
              </div>
              
              </>
              )}
              
              {tab === "suggestions" && (
              <><button disabled={groupData?.StatusId ==='5'} className={classes["primary-button"]} onClick={()=>{showModalhandler(id, 6, 'Join')}}>Join</button></>
              )}
          </>) }
        </div>
        </div>
      </div>
      {groupData?.UserGroupStatusId==='3' &&  (
      <div className={classes['block-div']}>

      </div>
      )}

      {index < groupLength - 1 && <div className={classes["brokenLine"]}></div>}
      {modalIsVisible && (
        <ConfirmationModal onClose={hideModalhandler} confirmationText={confirmationText} btnText={btnTextName} onShow={modalIsVisible}  getConfirmation={getConfirm} >
        </ConfirmationModal>
      )}
      <div ref={reportRef}>
      {(modelOpenForMore && selectedGroupId=== id) && (
                    <ModelForMore
                    title={modelOpenForMore}
                    onClose={handleCloseModal}
                    confirm={actionConfirm}
                    ></ModelForMore>
                )}

      </div>

      {isLoading && (
        <Loader></Loader>
      )}
    </div>
  );
};

export default GroupProfiles;
