import React, { useEffect, useRef, useState }  from 'react'
import classes from "./NoteSection.module.css";
import { Button, Form, Table } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import ApplicationService from '../../../../../../Services/ApplicationServices';
import { toast } from 'react-toastify';
import Loader from '../../../../../GlobleShared/Loader/Loader';
const NoteSection = ({applicationId}) => {
  const [noteValue, setNoteValue]=useState('')
  const[activityType, setActivityType]=useState(1)
  const[activityList, setActivityList]=useState([])
  const initial=useRef(null)
  const [isLoading, setIsLoading]=useState(false)

      const getNoteValue=(value)=>{
        if(value.trim()){
          setNoteValue(value)
        }
      }
      
      const addNote=()=>{
        let data={
          ActivityType:activityType,
          StatusId:0,
          Priority:-1,
          ApplicationId:applicationId,
          Remark:noteValue
        }
        setIsLoading(true)
        ApplicationService.addActivity(data).then(res=>{
          setNoteValue('')
          setIsLoading(false)
          getAtivity()
        }).catch(error=>{
          setIsLoading(false)
          toast.error(error?.message)

        })
      }

      const getAtivity=()=>{
        let data={
          ActivityId:applicationId,
          ActivityType:activityType

        }
        setIsLoading(true)
        ApplicationService.getActivity(data).then(res=>{
          setIsLoading(false)
          setActivityList(res)

        }).catch(error=>{
          setIsLoading(false)
          toast.error(error?.message)
        })
      }

      const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric'};
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, options) ;
      };

    useEffect(()=>{
      if(!initial.current){
        initial.current=true
        getAtivity()
      }

    }, [])



  return (
    <>

    <div className={classes.container}>
           <p className={classes['note-text-heading']}>Student’s Comments</p>
             <Form.Control
                 className={classes.forTextAreaContainer}
               as="textarea" placeholder='Enter your comments including future request if any'
               style={{ height: '100px' }} onChange={(e)=>{getNoteValue(e.target.value)}}
             />    
        <div className={classes.btnContainer}>
            <Button onClick={addNote} disabled={!noteValue} className={classes.secondaryBtn}>+Add Note</Button>
        </div>
        {activityList?.length>0 && (
     <Table responsive className={classes.customTable}>    
     <thead>
       <tr>
           <th>
             <div className={classes.thAndIconContainer}>
               <span className={classes.columnText}>Student Note's</span>
             </div>
           </th>
           <th>
             <div className={classes.thAndIconContainer}>
               <span className={classes.columnText}>Date</span>
             </div>
           </th>
       </tr>
     </thead>
     <tbody>
       {activityList.map((row, rowIndex) => (
         <tr key={rowIndex}>
             <td> {row?.ActivityRemark}</td>
             <td> {formatDate(row?.ActivityStamp)}</td>
         </tr>
       ))}
     </tbody>
   </Table> 
        )}

        {activityList?.length===0 && (
          <div className={classes.customTable}>
            <h>No Data Found</h>
            </div>
        )}
 
   </div>

   {isLoading && (
    <Loader></Loader>
   )}
   </>
  )
}

export default NoteSection