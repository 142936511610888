import { useEffect, useState } from "react";
import classes from "./ResetPassword.module.css";
import leftImg from "../../../../Assets/Images/signin-page-img.svg";
import union from "../../../../Assets/Logo/Union.svg";
import Loader from "../../../GlobleShared/Loader/Loader";
import {useNavigate, useParams } from "react-router-dom";
import ApiService from "../../../../Services/APIService";
import { toast } from "react-toastify";
const ResetPassword = () => {
  const [passwordValidationErrors, setPasswordValidationErrors] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [tokenStatus, settokenStatus] = useState(false);
  

  const [form, setForm] = useState({
    password: "",
    cpassword: "",
  });

  const { userId, token} = useParams();
  const navigate = useNavigate();
  useEffect(() => {
      tokenValidation()
  },[]);

 const tokenValidation=()=>{
  setisLoading(true)
  ApiService.checkToken(token).then(res=>{
    if(res?.valid){
      settokenStatus(true)
    }
  
    setisLoading(false)
  }).catch(error=>{
    settokenStatus(false)
    setisLoading(false)

  })

  }
  // state for errors
  const [formErrors, setFormErrors] = useState({
    password: null,
    cpassword: null,
  });
  const viewPass = () => {
    setVisible(!visible);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };
  const validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "password":
        if (!value) errorMsg = "Please enter Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password do not match.";
        break;
      case "cpassword":
        if (!value) errorMsg = "Please enter Confirm Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password do not match.";
        break;
      default:
        break;
    }
    return errorMsg;
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    // Update form state with the new value
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    // Validate the field and update formErrors state
    if (formErrors[name] !== undefined) {
      const errorMsg = validateField(
        name,
        value,
        form[name === "password" ? "cpassword" : "password"]
      );
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: errorMsg,
      }));
    }
    const validatePassword = (password) => {
        const errors = {};
        const minLength = 8;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
        const numberRegex = /\d/;
        const uppercaseRegex = /[A-Z]/;
    
        if (password.length < minLength) {
          errors.minLength = true;
        }
        if (!specialCharRegex.test(password)) {
          errors.specialChar = true;
        }
        if (!numberRegex.test(password)) {
          errors.number = true;
        }
        if (!uppercaseRegex.test(password)) {
          errors.uppercase = true;
        }
    
        return errors;
      };
    // Validate password when changing password or confirm password
    if (name === "password" || name === "cpassword") {
      const validationErrors = validatePassword(value);
      setPasswordValidationErrors(validationErrors);
    }
  };
  const viewConfirmPass = () => {
    setVisibleConfirm(!visibleConfirm);
    setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password");
  };

  const handleNext = (e) => {
    e.preventDefault();
    let data={
      password:form.password,
      userId: userId,
      resetToken:token
    }
    setisLoading(true)
    ApiService.resetPassword(data).then(res=>{
      setisLoading(false);
      navigate('/sign-in')
      toast.success(res?.message)
    }).catch(error=>{
      setisLoading(false)
      toast.error(error?.message)

    })
  
  }
  const back=()=>{
    navigate('/forgot-password')
  }

  return (
    <div className={classes["main-container"]}>
      <div className={`${classes.row_hieght} row`}>
        <div className={`${classes.col_img} col-md-7`}>
          <div>
            <img
              className={classes["left-img"]}
              src={leftImg}
              alt=""
            />
          </div>
        </div>
        <div className={`${classes.right_col} col-md-5`}>
          <div className={classes["sign_up-box"]}>
            <div className={classes.header}>
              <span>
                <img
                  src={union}
                  alt=""
                />
              </span>
              <span>MSM Pursuit</span>
            </div>
            {tokenStatus && (
               <form>
               <div className={classes["form-section"]}>
                 <div className={classes["form-content"]}>
                   <div className="form-group">
                     <label
                       htmlFor="exampleInputPassword1"
                       className={`${classes.label} form-label`}>
                       Email
                     </label>
 
                     <div className={classes["input-with-icon"]}>
                       <input
                         onChange={changeHandler}
                         onBlur={changeHandler}
                         type={passwordType}
                         placeholder="Type Password Here..."
                         name="password"
                         value={form.password}
                         className="form-control "
                         id="exampleInputPassword1"
                       />
                       {form.password && (
                         <div className={classes["icon-container"]}>
                           <span
                             className={classes["eyeicon"]}
                             onClick={viewPass}>
                             <i className={`fa ${visible ? "fa-eye" : "fa-eye-slash"}`} />
                           </span>
                         </div>
                       )}
                     </div>
 
                     {formErrors.password && <span className={classes.error}>{formErrors.password}</span>}
                   </div>
                   <div className="form-group">
                     <label
                       htmlFor="exampleInputConfirmPassword"
                       className={`${classes.label} form-label`}>
                       Confirm Password
                     </label>
                     <div className={classes["input-with-icon"]}>
                       <input
                         onChange={changeHandler}
                         onBlur={changeHandler}
                         type={confirmPasswordType}
                         placeholder="Type Password Here..."
                         name="cpassword"
                         value={form.cpassword}
                         className="form-control"
                         id="exampleInputConfirmPassword"
                       />
                       {form.cpassword && (
                         <div className={classes["icon-container"]}>
                           <span
                             className={classes["eyeicon"]}
                             onClick={viewConfirmPass}>
                             <i className={`fa ${visibleConfirm ? "fa-eye" : "fa-eye-slash"}`} />
                           </span>
                         </div>
                       )}
                     </div>
 
                     {formErrors.cpassword && <span className={classes.error}>{formErrors.cpassword}</span>}
                   </div>
 
                   <div className={classes["password-validation"]}>
                     <p className={classes["validation-heading"]}>Password must contain:</p>
 
                     <ul className={classes["validation-item"]}>
                       {!form.password ? (
                         <li>
                           <span>At least 8 characters</span>
                         </li>
                       ) : !passwordValidationErrors.minLength ? (
                         <li style={{ color: "green" }}>
                           <div className="row">
                             <span className="col-9">At least 8 characters</span>
                             <span className="col-3">
                               <i className="fa fa-check" />
                             </span>
                           </div>
                         </li>
                       ) : (
                         <li style={{ color: "red" }}>
                           <div className="row">
                             <span className="col-9">At least 8 characters</span>
                             <span className="col-3">
                               <i className="fa fa-close" />
                             </span>
                           </div>
                         </li>
                       )}
 
                       {!form.password ? (
                         <li>
                           <span>At least 1 special character</span>
                         </li>
                       ) : !passwordValidationErrors.specialChar ? (
                         <li style={{ color: "green" }}>
                           <div className="row">
                             <span className="col-9">At least 1 special character</span>
                             <span className="col-3">
                               <i className="fa fa-check" />
                             </span>
                           </div>
                         </li>
                       ) : (
                         <li style={{ color: "red" }}>
                           <div className="row">
                             <span className="col-9">At least 1 special character</span>
                             <span className="col-3">
                               <i className="fa fa-close" />
                             </span>
                           </div>
                         </li>
                       )}
 
                       {!form.password ? (
                         <li>
                           <span>At least 1 number</span>
                         </li>
                       ) : !passwordValidationErrors.number ? (
                         <li style={{ color: "green" }}>
                           <div className="row">
                             <span className="col-9">At least 1 number</span>
                             <span className="col-3">
                               <i className="fa fa-check" />
                             </span>
                           </div>
                         </li>
                       ) : (
                         <li style={{ color: "red" }}>
                           <div className="row">
                             <span className="col-9">At least 1 number</span>
                             <span className="col-3">
                               <i className="fa fa-close" />
                             </span>
                           </div>
                         </li>
                       )}
 
                       {!form.password ? (
                         <li>
                           <span>At least 1 uppercase letter</span>
                         </li>
                       ) : !passwordValidationErrors.uppercase ? (
                         <li style={{ color: "green" }}>
                           <div className="row">
                             <span className="col-9">At least 1 uppercase letter</span>
                             <span className="col-3">
                               <i className="fa fa-check" />
                             </span>
                           </div>
                         </li>
                       ) : (
                         <li style={{ color: "red" }}>
                           <div className="row">
                             <span className="col-9">At least 1 uppercase letter</span>
                             <span className="col-3">
                               <i className="fa fa-close" />
                             </span>
                           </div>
                         </li>
                       )}
                     </ul>
                   </div>
                 </div>
                 <div className="mt-2">
                   <button
                     disabled={
                       (!form.password || !form.cpassword) || isLoading ||
                     //   (currentStep === 2 && (!form.fname || !form.lname)) ||
                     //   (currentStep === 4 && otp?.length < 6) ||
                       Object.keys(formErrors).some((key) => formErrors[key])
                     }
                     className={`${classes.primary_button} primary-button`}
                     onClick={handleNext}
                   >
                     Next
                   </button>
                 </div>
               </div>
             </form>
            )}
            {!tokenStatus && (
              <div>
                <div className={classes['exprid-text']}>
                  <p>Reset Password link is expired.</p>
                  <p>Please create a new link from Forget Password page.</p>
                  <div className={classes['back-btn']}>
                    <div>
                  <span onClick={back} className={classes['backToSignIn']}> &lt; Back</span>
                  </div>
                  </div>
                </div>
                </div>
            )}
           
          </div>
        </div>
      </div>
      <div>{isLoading && <Loader />}</div>
    </div>
  );
};
export default ResetPassword;
