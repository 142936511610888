import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { Form } from "react-bootstrap";
import classes from "./PersonalInformation.module.css";
import documentDownloadArrow from "../../../../../../Assets/Logo/documentDownloadArrow.svg";
import { useBehaviorSubject } from "../../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import CommonServices from "../../../../../../Services/CommonServices";

const PersonalInformation = ({ personalInfoData, formData, countryList, languageList , getValidation}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [personalData, setPersonalData] = useState(formData);
  const { validationFlag, setValidationFlag } = useBehaviorSubject();
  const [getValidationFlag, setGetValidationFlag]=useState(false)
  const [errorMsg, setErrorMsg]=useState('')

  const [formErrors, setFormErrors] = useState({
    Salution: null,
    FirstName:null,
    LastName:null,
    Gender:null,
    MaritialStatus:null,
    DateOfBirth:null,
    Email:null,
    MobileNoCountryCode:null,
    MobileNo:null,
    Citizenship:null,
    PassportExpiryDate:null,
    PassportNo:null
  });
  
  const validateField = (name, value) => {
    let errorMsg = null;
    switch (name) {
     case "Salution":
        if (!value) errorMsg = "Please select salution";
        break;
     case "LastName":
      value=value.trim()
        if (!value) errorMsg = "Please enter last name";
        else if (/[^a-zA-Z]/.test(value)) errorMsg = 'Please enter a valid last name';
        break;
     case "FirstName":
      value=value.trim()
        if (!value) errorMsg = "Please enter first name";
        else if (/[^a-zA-Z]/.test(value)) errorMsg = 'Please enter a valid first name';
        break;
     case "Gender":
        if (!value) errorMsg = "Please select gender";
        break;
     case "MaritialStatus":
        if (!value) errorMsg = "Please select maritial status";
        break;
     case "DateOfBirth":
        if (!value) errorMsg = "Please select date of birth";
        break;
 
     case "Email":
      value=value.trim()
        if (!value) errorMsg = "Please enter email";
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
        )
          errorMsg = 'Please enter a valid email';
        break;
     case "MobileNoCountryCode":
        if (!value) errorMsg = "Please select mobileNo country code";
        break;  
     case "MobileNo":
      value=value.trim()
        if (!value) errorMsg = "Please enter mobile number";
        else if (!/^\d{10}$/.test(value)) errorMsg = "Please enter a valid 10-digit mobile number";
        break; 
     case "Citizenship":
        if (!value) errorMsg = "Please select citizenship";
        break; 
     case "PassportExpiryDate":
        if (!value) errorMsg = "Please select passport expiry date";
        break; 
     case "PassportNo":
       value=value.trim()
        if (!value) errorMsg = "Please enter passport number";
        break; 
     default:
        break;
        
    }
    return errorMsg;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    personalInfoData({ ...personalData, [name]: value })
    setPersonalData({ ...personalData, [name]: value })
    const errorMsg = validateField(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));

    getValidation({...formErrors, [name]: errorMsg,}, 3)
    
  };



  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const formData = new FormData();
      formData.append("file", file);
      CommonServices.uploadPassport(formData).then(res=>{
        setErrorMsg(res)

      })
    }
  };

  const handleUploadBoxClick = () => {
    fileInputRef.current.click();
  };

  const getvalid=()=>{
    setFormErrors({...formErrors, 
      Salution: personalData?.Salution ? null:'Please select salution',
      FirstName:personalData?.FirstName ? null:'Please enter first name',
      LastName:personalData?.LastName ? null:'Please enter last name',
      Gender:personalData?.Gender ? null:'Please select gender',
      MaritialStatus:personalData?.MaritialStatus ? null:'Please select maritial status',
      DateOfBirth:personalData?.DateOfBirth ? null:'Please select date of birth',
      Email:personalData?.Email ? null:'Please enter email',
      MobileNoCountryCode:personalData?.MobileNoCountryCode ? null:'Please select mobileNo country code',
      MobileNo:personalData?.MobileNo ? null:'Please enter mobile number',
      Citizenship:personalData?.Citizenship ? null:'Please select citizenship',
      PassportExpiryDate:personalData?.PassportExpiryDate ? null:'Please select passport expiry date',
      PassportNo:personalData?.PassportNo ? null:'Please enter passport number'})
    
     getValidation({...formErrors, 
      Salution: personalData?.Salution ? null:'Please select salution',
      FirstName:personalData?.FirstName ? null:'Please enter first name',
      LastName:personalData?.LastName ? null:'Please enter last name',
      Gender:personalData?.Gender ? null:'Please select gender',
      MaritialStatus:personalData?.MaritialStatus ? null:'Please select maritial status',
      DateOfBirth:personalData?.DateOfBirth ? null:'Please select date of birth',
      Email:personalData?.Email ? null:'Please enter email',
      MobileNoCountryCode:personalData?.MobileNoCountryCode ? null:'Please select mobileNo country code',
      MobileNo:personalData?.MobileNo ? null:'Please enter mobile number',
      Citizenship:personalData?.Citizenship ? null:'Please select citizenship',
      PassportExpiryDate:personalData?.PassportExpiryDate ? null:'Please select passport expiry date',
      PassportNo:personalData?.PassportNo ? null:'Please enter passport number'}, 3)
  }

  useEffect(()=>{
    if(formData){
      setPersonalData(formData)
      personalInfoData(formData)
    }
    getValidation(formErrors, 3)

  }, [])


  useEffect(()=>{
    if(validationFlag && !getValidationFlag){
      getvalid()
      setValidationFlag(false)
      setGetValidationFlag(true)
    }
  },[validationFlag])

  return (
    <>
      <div className={classes["mainContainer"]}>
        <div className={classes.headingText}>
          <span>Personal Information</span>
        </div>
        <div className={classes["container"]}>
          <div className="row">
            <div className="col-md-3 form-group">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Title <span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  aria-label="Title"
                  name="Salution"
                  value={personalData?.Salution}
                  onChange={handleChange} onBlur={handleChange}
                >
                  <option value="">Select Tilte</option>
                  <option value="Mr."> Mr.</option>
                  <option value="Ms."> Ms.</option>
                  <option value="Miss"> Miss</option>
                  <option value="Mrs."> Mrs.</option>
                </Form.Select>
                {formErrors.Salution && <div className={classes["errorText"]}>{formErrors.Salution}</div>}
              </Form.Group>
            </div>
            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="FirstName">
                <Form.Label>
                  First Name <span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="text"
                  name="FirstName"
                  className="form-control"
                  value={personalData?.FirstName}
                  onChange={handleChange} onBlur={handleChange}
                />
                {formErrors.FirstName && <div className={classes["errorText"]}>{formErrors.FirstName}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="MiddleName">
                <Form.Label>Middle Name</Form.Label>
                <input
                  type="text"
                  name="MiddleName"
                  className="form-control"
                  value={personalData?.MiddleName}
                  onChange={handleChange} 
                />
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="LastName">
                <Form.Label>
                  Last Name<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="text"
                  name="LastName"
                  className="form-control"
                  value={personalData?.LastName}
                  onChange={handleChange} onBlur={handleChange}
                />
                 {formErrors.LastName && <div className={classes["errorText"]}>{formErrors.LastName}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="Gender">
                <Form.Label>
                  Gender<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  name="Gender"
                  value={personalData?.Gender}
                  onChange={handleChange} onBlur={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Select>
                {formErrors.Gender && <div className={classes["errorText"]}>{formErrors.Gender}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="MaritialStatus">
                <Form.Label>
                  Marital Status<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  name="MaritialStatus"
                  value={personalData?.MaritialStatus}
                  onChange={handleChange} onBlur={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="widowed">Widowed</option>
                  <option value="separated">Separated</option>
                  <option value="common-law">Common-law</option>
                </Form.Select>
                {formErrors.MaritialStatus && <div className={classes["errorText"]}>{formErrors.MaritialStatus}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="DateOfBirth">
                <Form.Label>
                  Date of Birth<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="date"
                  name="DateOfBirth"
                  className="form-control"
                  value={personalData?.DateOfBirth}
                  onChange={handleChange} onBlur={handleChange}
                />
                {formErrors.DateOfBirth && <div className={classes["errorText"]}>{formErrors.DateOfBirth}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="Language">
                <Form.Label>First Language</Form.Label>
                <Form.Select
                  name="Language"
                  value={personalData?.Language}
                  onChange={handleChange} onBlur={handleChange}
                >
                  <option value="">Select Language</option>
                  {languageList.map((language, index) => (
                    <option key={index} value={language?.LanguageName}>
                      {language.LanguageName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="Email">
                <Form.Label>
                  Email<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="Email"
                  name="Email"
                  className="form-control"
                  value={personalData?.Email}
                  onChange={handleChange} onBlur={handleChange}
                  required
                />
                {formErrors.Email && <div className={classes["errorText"]}>{formErrors.Email}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="MobileNoCountryCode">
                <Form.Label>
                  Country Code<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select
                  name="MobileNoCountryCode"
                  value={personalData?.MobileNoCountryCode}
                  onChange={handleChange} onBlur={handleChange}
                  required
                >
                  <option value="">Select Country Code</option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country?.CountryCode}>
                      <span
                        className={`${country?.flag}`}
                        style={{ marginRight: "5px" }}
                      ></span>
                      {country?.CountryCode} ({country?.CountryName})
                    </option>
                  ))}
                </Form.Select>
                {formErrors.MobileNoCountryCode && <div className={classes["errorText"]}>{formErrors.MobileNoCountryCode}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="MobileNo">
                <Form.Label>
                  Phone Number<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="tel"
                  name="MobileNo"
                  className="form-control"
                  value={personalData?.MobileNo}
                  onChange={handleChange} onBlur={handleChange}
                  required
                />
                {formErrors.MobileNo && <div className={classes["errorText"]}>{formErrors.MobileNo}</div>}
              </Form.Group>
            </div>
          </div>
        </div>
    { Number(personalData?.Citizenship)===101 && (
        <div
        className={`${classes.headingText} d-flex align-items-center mb-4 mt-4`}
      >
        <span>Passport Details</span>
        <span className={` ${classes.centerIcon} ${classes.uploadBtn} ms-2`}>
          <input
            type="file"
            id="upload-passport"
            className="d-none"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/png, image/jpeg, application/pdf"
            onChange={handleFileChange}
          />
          <span onClick={handleUploadBoxClick}>
            <img
              src={documentDownloadArrow}
              className={classes.actionICon}
              alt=""
            />
            <span className={`${classes.uploadPassport} ps-2`}>
              Upload Passport
            </span>
          </span>
        </span>
        <div className="d-flex justify-content-center m-2">
          {selectedFile ? selectedFile.name : "No file chosen"}
        </div>
      </div>
     )}

        <div className={classes["container"]}>
          <div className="row">
            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="Citizenship">
                <Form.Label>
                  Country of Citizenship
                  <span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <Form.Select disabled={personalData?.Citizenship}
                  name="Citizenship"
                  value={personalData?.Citizenship}
                  onChange={handleChange} onBlur={handleChange}
                  required
                >
                  <option value="">Select Citizenship</option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country?.CountryId}>
                      <span
                        className={`fi fi-${country?.CShortName.toLowerCase()}`}
                        style={{ marginRight: "5px" }}
                      ></span>
                      {country?.CountryName}
                    </option>
                  ))}
                </Form.Select>
                {formErrors.Citizenship && <div className={classes["errorText"]}>{formErrors.Citizenship}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="PassportNo">
                <Form.Label>
                  Passport Number<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="text"
                  name="PassportNo"
                  className="form-control"
                  value={personalData?.PassportNo}
                  onChange={handleChange} onBlur={handleChange}
                  required
                />
                {formErrors.PassportNo && <div className={classes["errorText"]}>{formErrors.PassportNo}</div>}
              </Form.Group>
            </div>

            <div className="col-md-3 form-group">
              <Form.Group className="mb-3" controlId="PassportExpiryDate">
                <Form.Label>
                  Expiry Date<span className={classes["mandotory"]}>*</span>
                </Form.Label>
                <input
                  type="date"
                  name="PassportExpiryDate"
                  className="form-control"
                  value={personalData?.PassportExpiryDate?personalData?.PassportExpiryDate.split('T')[0] : ''}
                  onChange={handleChange} onBlur={handleChange}
                  required
                />
                {formErrors.PassportExpiryDate && <div className={classes["errorText"]}>{formErrors.PassportExpiryDate}</div>}
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
