import React from "react";
import { Navigate, Route } from 'react-router-dom';

import UnAuthGuard from "../../GlobleShared/Guards/UnAuthGuard";
import SignIn from "../Authorization/SignIn/SignIn";
import SignUp from "../Authorization/SignUp/Signup";



const UnAuthRoutes = [
    <Route path="/" element={<Navigate to="/sign-in" />} />,
    <Route key="Login" path="/sign-in" element={<UnAuthGuard component={<SignIn />} />} />,
    <Route key="SignUp" path="/sign-up" element={<UnAuthGuard component={<SignUp />} />} />,
]

export default UnAuthRoutes;