import React from 'react';
import classes from './Programs.module.css';
import universityLogo from "../../../Assets/Logo/dougl-icon.svg";

const bloburl = process.env.REACT_APP_LOGO_PATH;


function Programs({ program , institutesDetail}) {
  console.log(program);
  // Define the image source
  const imageSrc = program?.Institution?.[0].InstLogoPath
    ? bloburl + program.Institution?.[0].InstLogoPath
    : universityLogo;

  return (
    <div>
      <div className={classes["card-section"]}>
        <div className={classes["section-1"]}>
          <div className={classes["card-header-block-1"]}>
            <div className={classes["card-rank"]}>
              <span className={classes["rank-text"]}>
                {program?.GlobalRank ? `Ranked: #${program.GlobalRank}` : "Ranked: N/A"}
              </span>
            </div>
            <div className={classes["card-header"]}>
              <div className={classes["university-icon"]}>
                <img  style={{ height: '75px', width: '75px' }} src={imageSrc} alt="Profile" />
              </div>
              <div className={classes["university-detail"]}>
                {institutesDetail ? (
                    <>
                        <div className={classes["university-name"]}>
                            {institutesDetail?.InstName || "N/A"}
                        </div>
                        <div className={classes["university-address"]}>
                            {`${institutesDetail?.InstAddress1 || "N/A"}, ${institutesDetail?.InstProvince || "N/A"} ${institutesDetail?.InstCountryName || "N/A"}`}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={classes["university-name"]}>
                            {program?.Institution?.[0].InstName || "N/A"}
                        </div>
                        <div className={classes["university-address"]}>
                            {`${program?.Institution?.[0].InstCity || "N/A"}, ${program?.Institution?.[0].InstProvince || "N/A"}, ${program?.Institution?.[0].InstCountry || "N/A"}`}
                        </div>
                    </>
                )}
            </div>

            </div>
          </div>
          <div className={classes["card-header-block-2"]}>
            <div title={program?.ProgramName} className={classes["program-name"]}>
              {program?.ProgramName || "N/A"}
            </div>
            <div className={classes["program-detail-text"]}>
              {/* {program?.Description || "No description available."} */}
              Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program.
              Students will study a well-rounded curriculum that includes classes in accounting, communications, computing...
            </div>
          </div>
        </div>
        <div className={classes["section-2"]}>
          <div className={classes["program-detail-section-1"]}>
            <div>
              <div className={classes["intake-header"]}>Intake</div>
              {program?.Intakes && program.Intakes.length > 0 ? (
                program.Intakes.slice(0, 2).map((intake, index) => (
                  <div key={index} className={classes["detail"]}>
                    {intake.IntakeName || "N/A"}
                  </div>
                ))
              ) : (
                <div className={classes["detail"]}>No Intakes Available</div>
              )}
            </div>
            <div className={classes["detail"]}>
              {program?.Duration || "N/A"}
            </div>
            <div className={classes["detail"]}>
              {program?.ProgramLevel || "N/A"}
            </div>
            <div className={classes["detail"]}>
              {program?.ProgramCurrency} {program?.FeeDetail?.FeeAmount || "N/A"} / Year
            </div>
          </div>
          <div className={classes["program-detail-section-2"]}>
            <div>
              <div className={classes["header"]}>Application Fee</div>
              <div className={classes["detail"]}>
                {program?.ProgramCurrency} {program?.ApplicationFee || "N/A"}
              </div>
            </div>
            <div>
              <div className={classes["header"]}>People like You</div>
              <div className={classes["detail"]}>
                {program?.PeopleLikeYou || "Data Not Available"}
              </div>
            </div>
            <div>
              <div className={classes["header"]}>Likelihood of Graduation</div>
              <div className={classes["detail"]}>
                {program?.GraduateLikelihood || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Programs;