
import React from "react";
import classes from "./About.module.css"
import iconEdit2 from "../../../../../Assets/Logo/iconEdit2.svg";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";

function About({description, toggleComponent, groupData}) {
  const {groupType, setGroupType, pauseFlag}=useBehaviorSubject()
  return (
    <div  className={classes["about-main-container"]}>
      <div  className={classes["about-content"]}>
        <div  className={classes["about-title"]}>About:</div>
        { (!groupType && groupData?.UserGroupRole==='1' && groupData?.UserGroupStatusId!=='3' && !pauseFlag) && (
           <img src={iconEdit2} alt="Icon" onClick={() =>{toggleComponent('updateGroup');}}/>
        )}
       
      </div>
      <div className={classes["about-description"]}>
       {description}
      </div>
    </div>
  );
}

export default About;
