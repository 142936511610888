import React, { useEffect, useRef, useState } from "react";
import classes from "./Common.module.css";
import userPic from "../../.../../../../../../Assets/Images/profile_image_connections.png";
import userProfilePic from "../../.../../../../../../Assets/Images/profile_avatar.png";
import plusIcon from "../../../../../../Assets/Logo/add-icon.svg";
import removeIcon from "../../../../../../Assets/Logo/crose-whiteMini.svg";
import useLocalStorage from "../../../../../GlobleShared/CustomHooks/useLocalStorage";

const ProfileCard = ({
  ConnectButtonShow,
  buttonShow,
  handleAction,
  withdrawHandler,
  connectionsProfileDetails
}) => {

  const initialized = useRef(false);
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const [profilePhoto, setProfilePhoto] = useState();
  const [backgroundPic, setBackgroundPic] = useState();
  const [userDetail] = useLocalStorage('userDetail');


  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if(connectionsProfileDetails?.profileImage){
        const getProfilePicPath = blobUrl + connectionsProfileDetails.profileImage;
        setProfilePhoto(getProfilePicPath);
      }
      if(connectionsProfileDetails?.profileBGImage){
        const getProfilePicPath = blobUrl + connectionsProfileDetails.profileBGImage;
        setBackgroundPic(getProfilePicPath);
      }
    }
  }, [connectionsProfileDetails?.profileImage, connectionsProfileDetails?.profileBGImage]);

  const viewProfileSection = () => {
    // window.open('/member/profileView', '_blank');
    const userId = userDetail.user_id;
    let profileUrl = ``;
    if (userId === connectionsProfileDetails.senderUserId) {
      // profileUrl = `/member/profileView/${receiverUserId}`;
      profileUrl = `/member/profileView?userId=${connectionsProfileDetails.receiverUserId}&studentId=${connectionsProfileDetails.studentId}`;
    }
    else {
      // profileUrl = `/member/profileView/${senderUserId}`;
      profileUrl = `/member/profileView?userId=${connectionsProfileDetails.senderUserId}&studentId=${connectionsProfileDetails.studentId}`;
    }
    window.open(profileUrl, '_blank');
  }

  return (
    <>
      <div className={classes.card}>
        <div className={classes.coverImage}>
          <span className={classes.backImage}>
            <img src={connectionsProfileDetails?.profileBGImage ? backgroundPic : userPic} />
          </span>
          <span className={classes.profileImage}>
            <img className={classes["profile-photo"]} src={connectionsProfileDetails?.profileImage ? profilePhoto : userProfilePic} />
          </span>
        </div>
        <div className={classes.content}>
          <div className={classes.content_info}>
            <div className={classes.name} title={connectionsProfileDetails?.displayName} onClick={viewProfileSection}>{connectionsProfileDetails?.displayName ? connectionsProfileDetails.displayName : 'Not available'}</div>
            <div className={classes.description}>{connectionsProfileDetails?.aboutUs ? connectionsProfileDetails.aboutUs : 'Not available'}</div>
          </div>
          <div className={classes.content_info}>
            <div className={classes.connectionCount}>
              {connectionsProfileDetails?.mutualConnections?.length || 0} connections
            </div>
            <div className={classes["mutual-connections-profiles"]}>
              {connectionsProfileDetails?.mutualConnections && connectionsProfileDetails.mutualConnections.map((mutualConnection, index) => (
                <img
                  key={index}
                  src={mutualConnection.profileImage ? `${mutualConnection.profileImage}` : userProfilePic}
                  alt={mutualConnection.displayName}
                  className={classes.connectionImage}
                />
              ))}
            </div>
          </div>
        </div>
        <div>
          {buttonShow ? (
            <div className={classes.buttons}>
              {ConnectButtonShow ? <img className={classes.plus} src={plusIcon} alt="Connect" onClick={() => handleAction(connectionsProfileDetails.senderUserId, "Connect")} /> : <button className="primary-button" onClick={() => handleAction(connectionsProfileDetails.userConnectionId, "Accept")}>Accept</button>}
              {!(ConnectButtonShow) && <span className={classes.dismissButton} onClick={() => handleAction(connectionsProfileDetails.userConnectionId, "Dismiss")}>Dismiss</span>}
            </div>
          ) : (
            <div className={classes.buttons}>
              <span className={classes.withdrawButton} onClick={() => withdrawHandler(connectionsProfileDetails.userConnectionId, 7)}>Withdraw</span>
            </div>
          )}
        </div>
        {ConnectButtonShow && (
          <div className={classes["remove-suggestion"]}>
            <img className={classes["remove-icon"]} src={removeIcon} alt="Remove" />
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileCard;
