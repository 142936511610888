import React from "react"
import classes from './PositionLoader.module.css'
import { Spinner } from "react-bootstrap";
function PositionLoader(){
   return(
       <>
         <div className={classes['spinner-container']}>
         <Spinner size="md" animation="border" />
       </div>
       </>
   )
}
export default PositionLoader;