import React, {useState, useEffect} from "react";
import classes from "./Institutes.module.css";
import companyLogoOne from "../../../../../Assets/Logo/comp-logo-1.svg";
import companyLogoTwo from "../../../../../Assets/Logo/comp-logo-2.svg";
import crossIconWhite from "../../../../../Assets/Logo/crose-whiteMini.svg";
import Accordion from "react-bootstrap/Accordion";
import { Form } from "react-bootstrap";
import InstitutesCard from "../../../../Common/InstitutesCard/InstitutesCard";
const Institutes = ({institutes, resetFlag, updateInsFilters,fetchInstituteData}) => {

  const [selectedDuration, setSelectedDuration] = useState([]);
  const [selectedProgramtype, setSelectedProgramtype] = useState([]);
  const [selectedExamAccepted, setSelectedExamAccepted] = useState([]); 
  const [selectedEducationLevel, setSelectedEducationLevel] = useState([]); 
  const [searchInput, setSearchInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const filtersData = institutes?.filters?.[0]|| [];

  const [filterAction, setFilterAction] = useState(null); // 'apply' or 'clear'


  const clearFilters = () => {
  setSelectedDisciplines([]);
  setSelectedDuration([]);
  setSelectedProgramtype([]);
  setSelectedExamAccepted([]);
  setSelectedEducationLevel([]);
  setFilterAction('clear'); // Set filter action to 'clear'
  updateInsFilters({
    selectedDisciplines: [],
    selectedDuration: [],
    selectedProgramtype: [],
    selectedExamAccepted: [],
    selectedEducationLevel: [],
  });
};
const applyFilters = () => {
  setFilterAction('apply'); // Set filter action to 'apply'
  updateInsFilters({
    selectedDisciplines,
    selectedDuration,
    selectedProgramtype,
    selectedExamAccepted,
    selectedEducationLevel,
  });
};
useEffect(() => {
  if (filterAction === 'apply') {
    fetchInstituteData(1); // Apply filters and fetch data
  } else if (filterAction === 'clear') {
    fetchInstituteData(1); // Reset to page 1
  }
  setFilterAction(null); // Reset filter action
}, [selectedDisciplines, selectedDuration, selectedProgramtype, selectedExamAccepted, selectedEducationLevel, filterAction]);
  
  const handleDisciplineChange = (event) => {
    const discipline = event.target.value;
    setSelectedDisciplines((prev) =>
      event.target.checked
        ? [...prev, discipline]
        : prev.filter((d) => d !== discipline)
    );
    console.log(selectedDisciplines)
  };

 

  const removeDiscipline = (discipline) => {
    setSelectedDisciplines((prev) =>
      prev.filter((d) => d !== discipline)
    );
  };

  const handleDurationChange = (durationCategoryId) => {
    setSelectedDuration((prev) =>
      prev.includes(durationCategoryId)
        ? prev.filter((id) => id !== durationCategoryId)
        : [...prev, durationCategoryId] 
    );
    console.log(selectedDuration)
  };

  const handleProgramTypeChange = (programTypeId) => {
    setSelectedProgramtype((prev) =>
      prev.includes(programTypeId)
        ? prev.filter((id) => id !== programTypeId)
        : [...prev, programTypeId] 
    );
    console.log(setSelectedProgramtype)
  };


   const handleExamAcceptedChange = (examTypeId) => {
    setSelectedExamAccepted((prev) =>
      prev.includes(examTypeId)
        ? prev.filter((id) => id !== examTypeId)
        : [...prev, examTypeId]
    );
  };

  
  const handleEducationLevelChange = (eduLevelId) => {
    setSelectedEducationLevel((prev) =>
      prev.includes(eduLevelId)
        ? prev.filter((id) => id !== eduLevelId)
        : [...prev, eduLevelId]
    );
  };


  const filteredDisciplines = filtersData.Discipline?.filter((discipline) =>
    discipline.DisciplineName.toLowerCase().includes(searchInput.toLowerCase())
  );

  
  

  // useEffect(() => {
  //     updateInsFilters({
  //       selectedDisciplines,
  //       selectedDuration,
  //       selectedProgramtype,
  //       selectedExamAccepted,
  //       selectedEducationLevel,
  //     });
  // }, [selectedDisciplines, selectedDuration, selectedProgramtype, selectedExamAccepted, selectedEducationLevel]);
  return (
    <div className="row">
     {resetFlag && (
      <div className="col-md-3 col-lg-2">
        <Accordion
          className="filter-section"
          defaultActiveKey={["0", "1", "2", "3", "4","5"]}
          alwaysOpen>
             <Accordion.Item eventKey="5">
                                        <Accordion.Header className={classes["custom-accordion-header"]}>filters</Accordion.Header>
                                        <Accordion.Body>
                                        <div>
  <span style={{ textDecoration: 'none', color: 'black' }} onMouseOver={(e) => {
    e.target.style.textDecoration = 'underline';
    e.target.style.color = 'blue'
    e.target.style.cursor = 'pointer';
  }} onMouseOut={(e) => {
    e.target.style.textDecoration = 'none';
    e.target.style.color = 'black';
  }} onClick={applyFilters}>apply filter</span>
</div>
<div>
  <span style={{ textDecoration: 'none', color: 'black' }} onMouseOver={(e) => {
    e.target.style.textDecoration = 'underline';
    e.target.style.color = 'blue';
    e.target.style.cursor = 'pointer';
  }} onMouseOut={(e) => {
    e.target.style.textDecoration = 'none';
    e.target.style.color = 'black';
  }}  onClick={clearFilters}>clear filter</span>
</div>
                                        </Accordion.Body>
                                    </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => setIsOpen(!isOpen)}>Discipline</Accordion.Header>
            {!isOpen && (
            <input
                  type="text"
                  className={classes.searchInput}
                  placeholder="Search a Discipline..."
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
            )}
            <Accordion.Body>
              <Form>
                
                {filteredDisciplines?.map((discipline, index) => (
                  <Form.Check
                    type="checkbox"
                    id={`discipline-${index}`}
                    key={index}
                    value={discipline.DisciplineName}
                    title={discipline.DisciplineName}
                    label={discipline.DisciplineName}
                    checked={selectedDisciplines.includes(discipline.DisciplineName)}
                    onChange={handleDisciplineChange}
                  />
                ))}
              </Form>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
              <Accordion.Header>Duration</Accordion.Header>
              <Accordion.Body>
                <Form>
                  {filtersData.Duration?.map((duration) => (
                    <Form.Check
                      type="checkbox"
                      id={`duration-${duration.durationCategoryId}`}
                      key={duration.durationCategoryId}
                      value={duration.durationCategoryId}
                      title={duration.durationCategory}
                      label={duration.durationCategory}
                      checked={selectedDuration.includes(duration.durationCategoryId)}
                      onChange={() => handleDurationChange(duration.durationCategoryId)}
                    />
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>

          
            <Accordion.Item eventKey="2">
              <Accordion.Header>Level of Education</Accordion.Header>
              <Accordion.Body>
                <Form>
                  {filtersData?.Education?.map((eduLevel) => (
                    <Form.Check
                      type="checkbox"
                      id={`education-${eduLevel.eduLevelId}`}
                      key={eduLevel.eduLevelId}
                      value={eduLevel.eduLevelId}
                      label={eduLevel.eduLevel}
                      title={eduLevel.eduLevel}
                      checked={selectedEducationLevel.includes(eduLevel.eduLevelId)}
                      onChange={() => handleEducationLevelChange(eduLevel.eduLevelId)}
                    />
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>

        
            <Accordion.Item eventKey="3">
              <Accordion.Header>Exam Accepted</Accordion.Header>
              <Accordion.Body>
                <Form>
                  {filtersData?.Req?.map((exam) => (
                    <Form.Check
                      type="checkbox"
                      id={`exam-${exam.CountryId}`}
                      key={exam.CountryId}
                      value={exam.CountryId}
                      label={exam.ProgramTypeID}
                      title={exam.ProgramTypeID}
                      checked={selectedExamAccepted.includes(exam.CountryId)}
                      onChange={() => handleExamAcceptedChange(exam.CountryId)}
                    />
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>Course Type</Accordion.Header>
            <Accordion.Body>
              <Form>
                 {filtersData.Program?.map((program, index) => (
                  <Form.Check
                   type="checkbox"
                   id={`programType-${program.programTypeId}`}
                    key={program.programTypeId}
                    value={program.programTypeId}
                    label={program.programType}
                    title={program.programType}
                    checked={selectedProgramtype.includes(program.programTypeId)}
                    onChange={()=>{handleProgramTypeChange(program.programTypeId)}}
                  />
                ))}
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
       )}
            <div className="col-md-9 col-lg-10">
                <div className="d-flex flex-wrap">
                {selectedDisciplines.map((discipline, index) => (
            <button
              key={index}
              className={classes.NameBudge}
              onClick={() => removeDiscipline(discipline)}
            >
              {discipline}
              <span>
                <img src={crossIconWhite} alt="Remove tag" />
              </span>
            </button>
          ))}
                </div>
                <div className={`${classes.container}`}>
                    {institutes?.institution?.map((institute) => (
                        <InstitutesCard key={institute.institutionId} institute={institute} />
                    ))}
                </div>
            </div>
      </div>
  );
};

export default Institutes;
