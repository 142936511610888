import React, { useState } from "react";
import styles from './ZigZagStepper.module.css'
const ZigZagStepper = (props)=>{
    const [stepIndex, setStepIndex]=useState(1);
    const step1=props?.stepper>=1?true:false;
    const step2=props?.stepper>=2?true:false;
    const step3=props?.stepper>=3?true:false;
    const step4=props?.stepper>=4?true:false;
    const step5=props?.stepper>=5?true:false;
    const step6=props?.stepper>=6?true:false;
    const step7 =props?.stepper>=7?true:false;
    const step8 =props?.stepper>=8?true:false;
    const step9 =props?.stepper>=9?true:false;
    const step10 =props?.stepper>=10?true:false;
    const step11 =props?.stepper>=11?true:false;
    const step12 =props?.stepper>=12?true:false;
    const step13=props?.stepper>=13?true:false;
    const step14=props?.stepper>=14?true:false;
    const step15=props?.stepper>=15?true:false;
    const step16=props?.stepper>=16?true:false;
    const step17=props?.stepper>=17?true:false;
    const step18=props?.stepper>=18?true:false;
    const step19=props?.stepper>=19?true:false;
    const step20=props?.stepper>=20?true:false;
    const step21 =props?.stepper>=21?true:false;

    return(
        <>
        <div>
            <div className={styles['zig-zag-section']}>
              <div className={styles['zig-zag']}>
                <div className={step2? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
                <div className={step1? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
            <div className={styles['zig-zag']}>
                <div className={step4? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
                <div className={step3? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
            <div className={styles['zig-zag']}>
                <div className={step6? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
                <div className={step5? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
            <div className={styles['zig-zag']}>
                <div className={step8? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
                <div className={step7? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
            <div className={styles['zig-zag']}>
                <div className={step10? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
                <div className={step9? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
            <div className={styles['zig-zag']}>
                <div className={step12? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
                <div className={step11? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
            <div className={styles['zig-zag']}>
                <div className={step14? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
                <div className={step13? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
            <div className={styles['zig-zag']}>
                <div className={step16? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
                <div className={step15? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
            <div className={styles['zig-zag']}>
                <div className={step18? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
                <div className={step17? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
            <div className={styles['zig-zag']}>
                <div className={step20? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
                <div className={step19? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
            <div className={styles['zig-zag']}>
                <div className={step21? styles['selected-zig-zag-box'] : styles['zig-zag-box']}></div>
            </div>
                
            </div>
        </div>
        </>
    )

}
export default ZigZagStepper;