import React, { useEffect, useState } from 'react';
import classes from './ComposePost.module.css';
import video from "../../../../../Assets/Logo/video.svg";
import photo from "../../../../../Assets/Logo/photo.svg";
import link from "../../../../../Assets/Logo/iconLink.svg";
import camera from "../../../../../Assets/Logo/camera.svg";
import CommonPost from '../CommonPost/CommonPost'; // Import CommonPost
import CommonServices from "../../../../../Services/CommonServices";

function ComposePost({groupId, isGroupData}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMediaSelected, setIsMediaSelected] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [selectedMediaType, setSelectedMediaType] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const getTagList=()=>{
    CommonServices.tagList().then(res=>{
      const response=res
      response.map(item=>{
        item['value']=item['TagId']
        item['label']=item['TagName']
      })
      setTagList(response.sort((a, b) => {
        if (a.TagName < b.TagName) return -1;
        if (a.TagName > b.TagName) return 1;
        return 0})
      )
  })
  }

  // Function to handle the input click
  const handleInputClick = () => {
    setIsModalOpen(true); // Open the modal
    setIsMediaSelected(false);
    getTagList();
  };
  useEffect(()=>{
    setSelectedType(null);
    setSelectedMediaType(null);
  },[]);
   // Function to handle media button click
   const handleMediaClick = (value,mediaType) => {
    setIsModalOpen(true); // Open the modal
    setIsMediaSelected(true); // Set media selected state
    setSelectedType(value);
    setSelectedMediaType(mediaType);
    console.log(isMediaSelected);
  };


  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={classes["composePost_main_container"]}>
      <div className={classes["composePost_block"]}>
        <input
          type="text"
          placeholder="Start a Post"
          className={classes["post_input"]}
          onClick={handleInputClick}
        />
        <div className={classes["button_container"]}>
          <button onClick={()=>handleMediaClick('image',1)} className={classes["post_button"]}>
            <img src={photo} alt="Photo icon" />
            <div>Photo</div>
          </button>
          <button onClick={()=>handleMediaClick('video',2) } className={classes["post_button"]}>
            <img src={video} alt="Video icon" />
            <div>Video</div>
          </button>
          {/* <button onClick={handleInputClick} className={classes["post_button"]}>
            <img src={camera} alt="Camera icon" />
            <div>Camera</div>
          </button>
          <button onClick={handleInputClick} className={classes["post_button"]}>
            <img src={link} alt="Link icon" />
            <div>Link</div>
          </button> */}
        </div>
      </div>

      {/* Render CommonPost */}
      <CommonPost isGroupData={isGroupData} isModalOpen={isModalOpen} closeModal={closeModal} isMediaSelected={isMediaSelected} setIsMediaSelected={setIsMediaSelected}
      tagList={tagList} ChildMediaTypeId={selectedMediaType} childValue={selectedType} groupId={groupId}/>
    </div>
  );
}

export default ComposePost;