import React, { useRef, useState,useEffect } from "react";
import Loader from "../../../../GlobleShared/Loader/Loader";
import { toast } from "react-toastify";
import AIME from "../AIME/AIME"
import ProgramSearchService from "../../../../../Services/ProgramSearchServices";
import ApiService from "../../../../../Services/APIService";
import Programs from "../Programs/Programs";
import Institutes from "../Institutes/Institutes";
import Scholarship from "../Scholarship/ScholarShip";
import styles from './SearchPage.module.css';
import allfilterIcon from '../../../../../Assets/Logo/all-filter.svg'
import alphaBetIcon from '../../../../../Assets/Logo/alphabet-icon.svg'
import dropdownIcon from '../../../../../Assets/Logo/drop-down_icon.svg'
import searchIcon from '../../../../../Assets/Logo/searchIcon.svg'
import updownIcon from '../../../../../Assets/Logo/updownarrow.svg'
import whiteArrow from '../../../../../Assets/Logo/white-down-arrow.svg';
import locattionIcon from '../../../../../Assets/ALL_FIlter/blue-location-icon.svg'
import tutionIcon from '../../../../../Assets/ALL_FIlter/tution-icon.svg'
import courseIcon from '../../../../../Assets/ALL_FIlter/course-type-icon.svg'
import applicationIcon from '../../../../../Assets/ALL_FIlter/application-icon.svg'
import englishIcon from '../../../../../Assets/ALL_FIlter/engilsh-score-icon.svg'
import intakeIcon from '../../../../../Assets/ALL_FIlter/intake-icon.svg'
import schoolIcon from '../../../../../Assets/ALL_FIlter/school-icon.svg'
import gmtIcon from '../../../../../Assets/ALL_FIlter/gmt-icon.svg'
import internshipIcon from '../../../../../Assets/ALL_FIlter/internship-icon.svg'
import desiplineIcon from '../../../../../Assets/ALL_FIlter/decipline-icon.svg'
import durationIcon from '../../../../../Assets/ALL_FIlter/duration-icon.svg'
import SearchModal from "../SearchModal/SearchModal";
import ComparePrograms from "../../../../Common/ComparePrograms/ComparePrograms";
import Paginations from "../../../../Common/Pagination/Paginations";
import { tab } from "@testing-library/user-event/dist/tab";
import Select from "react-select/base";

const sortAlphabet=['Alphabetical', 'Tution', 'Ranking', 'Likes', 'Institute', 'Program'];
const country=['India', 'Canada', 'Nepal', 'US', 'UK']

const SearchPage = () => {
    const initial = useRef(null);

    const shortDiv = useRef(null);
    const countryDiv = useRef(null);
    const inputdiv = useRef(null);
    const allfilterDiv = useRef(null);
    const [tabIndex, setTabIndex] = useState(1);
    const [shordropdown, setShordropdown] = useState(false);
    const [countryFlag, setcountryFlag] = useState(false);
    const [countryName, setCountryName] = useState("Country");
    const [query, setQuery] = useState('');
    const [inputFlag, setInputFlag] = useState(false);
    const [allFilterFlag, setAllFilterFlag] = useState(false);
    const [locationFlag, setlocationFlag] = useState(false);
    const [courseTypeFlag, setcourseTypeFlag] = useState(false);
    const [tutionFlag, settutionFlag] = useState(false);
    const [durationFlag, setdurationFlag] = useState(false);
    const [internshipFlag, setinternshipFlag] = useState(false);
    const [intakeFlag, setintakeFlag] = useState(false);
    const [applicatioFlag, setapplicatioFlag] = useState(false);
    const [greFlag, setgreFlag] = useState(false);
    const [schoolFlag, setschoolFlag] = useState(false);
    const [desipilineFlag, setdesipilineFlag] = useState(false);
    const [englishScorFlag, setenglishScorFlag] = useState(false);
    const [modalIsVisible, setModalIsVisible] = useState();
    const [modalSize, setModalSize] = useState('');
    const [programs, setPrograms] = useState([]);
    const [institutesData, setInstitutesData] = useState([]);
    const [institutes, setInstitutes] = useState([]);
    const [filters, setFilters] = useState([]);
    const [programCount, setProgramCount] = useState([]);
    const [fees, setFees] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [items, setItems] = useState([
        'Apple',
        'Appple',
        'Banana',
        'Cherry',
        'Date',
        'Fig',
        'Grape',
        'Kiwi'
    ]);

    const [programItems, setProgramItems] = useState([]);
    const [instituteItems, setInstituteItems] = useState([]);

    const [countryList, setCountryList] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disciplines, setDisciplines] = useState([]);
    const [eduLevels, setEduLevels] = useState([]);
    const [selectedCountryId, setSelectedCountryId] = useState('');
    const [selectedProgramTypeId, setSelectedProgramTypeId] = useState('');
    const [scholarships, setScholarships] = useState([]);
    const [scholarshipData, setScholarshipData] = useState([]);
    const [programData, setProgramData] = useState([]);
    const [selectedDurationId, setSelectedDurationId] = useState('');
    const [resetFlag, setResetFlag] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paginated, setPaginated] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(10);

    const [selectedTuitionFees, setSelectedTuitionFees] = useState([]);
    const [selectedDisciplines, setSelectedDisciplines] = useState([]);
    const [selectedInsDisciplines, setSelectedInsDisciplines] = useState([]);
    const [selectedInsDuration, setSelectedInsDuration] = useState([]);
    const [selectedInsProgramtype, setSelectedInsProgramtype] = useState([]);
    const [selectedInsEducationLevel, setSelectedInsEducationLevel] = useState([]);
    const [selectedInsExamAccepted, setSelectedInsExamAccepted] = useState([]);
    const [selectedScholarAmounts, setSelectedScholarAmount] = useState([]);
    const [selectedScholarInstitutes, setSelectedScholarInstitutes] = useState([]);
    const [selectedScholarProgram, setSelectedScholarProgram] = useState([]);
    const [selectedScholarEdulevels, setSelectedScholarEdulevels] = useState('');

    const handleDisciplineChange = (discipline) => {
        const updatedSelectedDisciplines = selectedDisciplines.includes(discipline)
            ? selectedDisciplines.filter(d => d !== discipline)
            : [...selectedDisciplines, discipline];
        setSelectedDisciplines(updatedSelectedDisciplines);
    };

    const clearAllSelections = () => {
        setSelectedDisciplines([]);

    };

    const handleTuitionFeeChange = (selectedFees) => {
        setSelectedTuitionFees(selectedFees);
    };

    //   fetch country list


    //   fetch country list
    const fetchCountryList = async (countryFlag) => {
        setIsLoading(true);
        ProgramSearchService.getInstituteCountry(countryFlag)
            .then((res) => {
                setCountryList(res);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    useEffect(() => {
        if (!initial.current) {
            initial.current = true;
            fetchCountryList(false);
            //   fetchInstituteData();
        }
    }, []);



    const fetchProgramDiscipline = (instCountryId, programTypeId, edulevelId) => {
        setIsLoading(true);
        ProgramSearchService.getProgramDiscipline(instCountryId, programTypeId, edulevelId)
            .then((res) => {
                setDisciplines(res);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };


    const fetchEduLevels = (instCountryId, programTypeId) => {
        setIsLoading(true);
        ProgramSearchService.getEducationLevel(instCountryId, programTypeId)
            .then((res) => {
                setEduLevels(res);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };




    // get programs based on search


    const fetchProgramInfo = (pageValue) => {
        if (!selectedCountryId || !selectedProgramTypeId) {
            return;
        }
        setIsLoading(true);

        const searchCriteria = {
            Descipline: selectedDisciplines.length > 0 ? selectedDisciplines : [],
            Duration: selectedDurationId.length > 0 ? selectedDurationId.map(Number) : [],
            EnglishId: [],
            InstCountryId: Number(selectedCountryId),
            Keyword: query || '',
            LevelofEducationId: [],
            OffersDealsId: [],
            PageNumber: pageValue,
            ProgramTypeId: Number(selectedProgramTypeId),
            RowsPerPage: limit,
            TuitionFee: selectedTuitionFees.length > 0 ? selectedTuitionFees : []
        };

        ProgramSearchService.getProgramInfo(searchCriteria)
            .then((res) => {
                if (res) {
                    setPrograms(res);
                    setProgramData(res?.program);
                    setFilters(res.filters[0].Duration);
                    setFees(res.filters[0].Fee);
                    setRequirements(res.filters[0].Req);
                    setTotalPages(Math.ceil((res.programCount[0].TotalRecordCount) / limit));
                    setResetFlag(true);;
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };

    const handleDurationChange = (durationId) => {
        setSelectedDurationId(durationId);
    };


    const fetchInstituteData = (pageValue) => {

        if (!selectedCountryId) {
            return;
        }

        const data = {
            Descipline: selectedInsDisciplines,
            Duration: selectedInsDuration,
            EnglishId: selectedInsExamAccepted,
            InstCountry: Number(selectedCountryId),
            InstName: "",
            LevelofEducationId: selectedInsEducationLevel,
            OffersDealsId: [],
            PageNumber: pageValue,
            ProgramTypeId: selectedProgramTypeId,
            RowsPerPage: limit,
            TuitionFee: []
        };


        setIsLoading(true)
        ProgramSearchService.getInstitutionInfo(data)
            .then((res) => {
                if (res) {
                    setResetFlag(true);
                    setInstitutes(res);
                    setInstitutesData(res?.institution)
                    setFilters(res.filters[0].Duration);
                    // setTotalCount(res.InstituteCount);
                    setTotalPages(Math.ceil((res.InstituteCount[0].TotalRecordCount) / limit));
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };


    const fetchScholarshipData = (pageValue) => {

        if (!selectedCountryId) {
            return;
        }

        const scholarshipSearchRequest = {
            AmtType: selectedScholarAmounts,
            InstCountryId: selectedCountryId,
            InstitutionId: selectedScholarInstitutes,
            LevelofEducationId: selectedScholarEdulevels,
            PageNumber: pageValue,
            ProgramName: selectedScholarProgram,
            RowsPerPage: limit
        };

        setIsLoading(true);
        ProgramSearchService.getScholarshipInfo(scholarshipSearchRequest)
            .then((response) => {
                if (response) {
                    setResetFlag(true);
                    setScholarships(response);
                    setScholarshipData(response?.Institution);
                    setTotalPages(Math.ceil(response?.ScholarShipCount[0]?.TotalRecordCount / limit));
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };


    const updateInsFilters = (filters) => {
        setSelectedInsDisciplines(filters.selectedDisciplines);
        setSelectedInsDuration(filters.selectedDuration);
        setSelectedInsProgramtype(filters.selectedInsProgramtype);
        setSelectedInsExamAccepted(filters.selectedExamAccepted);
        setSelectedInsEducationLevel(filters.selectedEducationLevel);
    };

    const updateScholarFilters = (filters) => {
        setSelectedScholarInstitutes(filters.selectedInstitutes);
        setSelectedScholarAmount(filters.selectedAmounts);
        setSelectedScholarProgram(filters.selectedPrograms)
    };

    const handleEduLevelChange = (event) => {
        const levelId = parseInt(event.target.value);
        setSelectedScholarEdulevels(levelId);
        console.log(levelId);
    };


    const removeEduLevel = () => {
        // debugger
        setSelectedScholarEdulevels('');
    };


    const handleProgramTypeChange = (programTypeId) => {
        const updatedSelectedProgramTypeId = selectedProgramTypeId === programTypeId ? '' : programTypeId;
        setSelectedProgramTypeId(updatedSelectedProgramTypeId);
        fetchProgramDiscipline(selectedCountryId, programTypeId, 0);
    };

    // main search function
    const handleSearch = () => {
        console.log('Selected Program Type ID:', selectedProgramTypeId);


        if (tabIndex === 2) {
            setcountryFlag(false);
            fetchProgramInfo(page);
        }
        else if (tabIndex === 3) {
            setcountryFlag(false);
            fetchInstituteData(page);
        } else if (tabIndex === 4) {
            setcountryFlag(false);
            fetchScholarshipData(page);
        }
        //  else {
        //     setcountryFlag(false);
        //     fetchProgramInfo();
        // }
    };

    //   const viewMore=()=>{
    //     if( tabIndex===2){
    //         fetchProgramInfo(page+1)
    //         setPage(page+1)
    //     }  
    //     if( tabIndex===3){
    //         fetchInstituteData(page+1);
    //         setPage(page+1)
    //     } 

    //     if( tabIndex===4){
    //         fetchScholarshipData(page+1);
    //         setPage(page+1)
    //     } 

    //   }



    const onPageChange = (newPage) => {
        setPage(newPage);

        // Fetch data based on tab index and new page number
        if (tabIndex === 2) {
            fetchProgramInfo(newPage);
        } else if (tabIndex === 3) {
            fetchInstituteData(newPage);
        } else if (tabIndex === 4) {
            fetchScholarshipData(newPage);
        }
    };




    const fetchCourseTypes = (countryId) => {
        // debugger;
        setIsLoading(true);
        ProgramSearchService.getProgramType(countryId)
            .then((res) => {
                console.log('API Response:', res);
                setCourseTypes(res);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.message);
            });
    };



    const showCountry = async (country) => {
        setCountryName(country.InstCountryName);
        setSelectedCountryId(country.InstCountryId);
        setcountryFlag(false);
        // if(tabIndex===4){
        //     setcountryFlag(true);
        // }
        setSelectedProgramTypeId(''); // Reset selected program type
        setSelectedDurationId(''); // Reset selected duration
        if (country.InstCountryId) {
            if (tabIndex === 2) {
                fetchCourseTypes(country.InstCountryId);
                // fetchProgramDiscipline(country.InstCountryId, 1, 48);

            }

            if (tabIndex === 4) {
                fetchEduLevels(country.InstCountryId, 0);
            }

        } else {
            console.error("Invalid country ID");
        }
    };


    //    index tab
    const getTabIndex = (index) => {
        setTabIndex(index);
        setShordropdown(false);
        setResetFlag(false);
        setCountryName("Country");
        setSelectedCountryId("");
        setSelectedProgramTypeId("");
        setQuery("");
        setProgramData([]);
        setInstitutes([]);
        setScholarships([]);
        setPage(1)
        if (index === 2 || index === 3) {
            fetchCountryList(false);
        }
        else if (index === 4) {

            fetchCountryList(true);
        }
    }

    const showSortDrop = () => {
        if (!shordropdown) {
            setShordropdown(true)
        }
        else if (shordropdown) {
            setShordropdown(false)
        }

    }


    const showLocationFilter = () => {
        if (!locationFlag) {
            setlocationFlag(true)
        } else if (locationFlag) {
            setlocationFlag(false)
        }

    }
    const showCourseType = () => {
        if (countryName === 'Country') {
            toast.error("Please select a country first.");
            return;
        }
        if (!courseTypeFlag) {
            setcourseTypeFlag(true)
        } else if (courseTypeFlag) {
            setcourseTypeFlag(false)
        }

    }
    const showtution = () => {
        if (!tutionFlag) {
            settutionFlag(true)
        } else if (tutionFlag) {
            settutionFlag(false)
        }

    }
    const showduration = () => {
        if (!durationFlag) {
            setdurationFlag(true)
        } else if (durationFlag) {
            setdurationFlag(false)
        }

    }
    const showinternship = () => {
        if (!internshipFlag) {
            setinternshipFlag(true)
        } else if (internshipFlag) {
            setinternshipFlag(false)
        }

    }
    const showintake = () => {
        if (!intakeFlag) {
            setintakeFlag(true)
        } else if (intakeFlag) {
            setintakeFlag(false)
        }

    }
    const showapplication = () => {
        if (!applicatioFlag) {
            setapplicatioFlag(true)
        } else if (applicatioFlag) {
            setapplicatioFlag(false)
        }

    }
    const showgre = () => {
        if (!greFlag) {
            setgreFlag(true)
        } else if (greFlag) {
            setgreFlag(false)
        }

    }
    const showschool = () => {
        if (!schoolFlag) {
            setschoolFlag(true)
        } else if (schoolFlag) {
            setschoolFlag(false)
        }

    }
    const showenglish = () => {
        if (!englishScorFlag) {
            setenglishScorFlag(true)
        } else if (englishScorFlag) {
            setenglishScorFlag(false)
        }

    }
    const showDesipiline = () => {
        if (!desipilineFlag) {
            setdesipilineFlag(true)
        } else if (desipilineFlag) {
            setdesipilineFlag(false)
        }

    }

    const allFilterShow = () => {
        setAllFilterFlag(true)
    }
    const filteredItems = (tabIndex === 2 ? programItems : instituteItems).filter(
        (item) => typeof query === "string" && item.toLowerCase().includes(query.toLowerCase())
    );

    const handleClickOutside = (event) => {
        if (shortDiv) {
            if (shortDiv.current && !shortDiv.current.contains(event.target)) {
                setShordropdown(false)
            }
        }
        if (countryDiv) {
            if (countryDiv.current && !countryDiv.current.contains(event.target)) {
                setcountryFlag(false)
            }
        }
        if (inputdiv) {
            if (inputdiv.current && !inputdiv.current.contains(event.target)) {
                setInputFlag(false)
            }
        }
        if (allfilterDiv) {
            if (allfilterDiv.current && !allfilterDiv.current.contains(event.target)) {
                setAllFilterFlag(false)
            }
        }

    };

    const setImputValue = (value) => {
        setQuery(value);

    }
    const getInputValue = (event) => {
        setQuery(event.target.value);
        setInputFlag(true);
    };

    function hideModalhandler() {
        setModalIsVisible(false);
    }

    function showModalhandler(size) {
        setModalIsVisible(true);
        setModalSize(size);
    }


    const handleSort = (order, dataKey, key) => {
        const sortedData = [...dataKey];
    
        if (order === "A-Z") {
            sortedData.sort((a, b) => a[key].localeCompare(b[key]));
        } else if (order === "Z-A") {
            sortedData.sort((a, b) => b[key].localeCompare(a[key]));
        }
    
        return sortedData;
    };


    const handleSortByFeeAmount = (order, dataKey) => {
        const sortedData = [...dataKey];
    
        sortedData.sort((a, b) => {
            const feeA = a.FeeDetail[0]?.FeeAmount || 0;
            console.log(feeA)
            const feeB = b.FeeDetail[0]?.FeeAmount || 0;
            console.log(feeB)
    
            if (order === "Lowest") {
                return feeA - feeB; 
            } else if (order === "Highest") {
                return feeB - feeA;
            }
            return 0;
        });
    
        return sortedData;
    };
    
    


    const handleSortByTab = (order) => {
        if (tabIndex === 3) {
            setInstitutes((prevData) => ({
                ...prevData,
                institution: handleSort(order, prevData.institution, "institutionName"),
            }));
        } else if (tabIndex === 4) {
            setScholarships((prevData) => ({
                ...prevData,
                Institution: handleSort(order, prevData.Institution, "ProgramName"),
            }));
        }else if (tabIndex === 2) {
            if (order === "Lowest" || order === "Highest") {
                setProgramData((prevData) => handleSortByFeeAmount(order, prevData));
            } else {
                setProgramData((prevData) => handleSort(order, prevData, "ProgramName"));
            }
        }
    };
    
    
    


    document.addEventListener('mousedown', handleClickOutside);
    return (
        <>
            {isLoading && <Loader />}
            {modalIsVisible && (
                <SearchModal onShow={showModalhandler} size={modalSize}>
                    <ComparePrograms onClose={hideModalhandler} ProgramList={programData} />
                </SearchModal>
            )}

            {allFilterFlag && (
                <div className={styles['all-filter-background']}>
                    <div ref={allfilterDiv} className={styles['all-filter-section']}>
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showLocationFilter}>
                                <div className={styles['header-align']}> <img src={locattionIcon} alt='' /> <div className={styles['filter-heading']}> Location</div></div>
                                <div><img className={locationFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>

                            {locationFlag && (
                                <div className={styles['dropdown-background']}>
                                    {countryList.map(item => (
                                        <div key={item.InstCountryId} className={styles['checkbox-alignment']}>
                                            <input type="checkbox"></input>
                                            <div>{item.InstCountryName}</div>
                                        </div>
                                    ))}
                                </div>
                            )}


                        </div>
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showCourseType}>
                                <div className={styles['header-align']}>
                                    <img src={courseIcon} alt='' />
                                    <div className={styles['filter-heading']}> Course Type</div>
                                </div>
                                <div><img className={courseTypeFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {courseTypeFlag && courseTypes.length > 0 && (
                                <div className={styles['dropdown-background']}>
                                    {courseTypes.map((item) => (
                                        <div className={styles['checkbox-alignment']} key={item.ProgramTypeId}>
                                            <input type="checkbox"
                                                id={`courseType-${item.ProgramTypeId}`}
                                                name="courseType"
                                                checked={selectedProgramTypeId === item.ProgramTypeId}
                                                onChange={() => handleProgramTypeChange(item.ProgramTypeId)}
                                            />
                                            <div>{item.ProgramType}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {/* <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showDesipiline}>
                <div className={styles['header-align']}> <img src={desiplineIcon} alt='' /> <div className={styles['filter-heading']}>Discipline</div></div>
                    <div><img className={desipilineFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {desipilineFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div> */}
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showDesipiline}>
                                <div className={styles['header-align']}> <img src={desiplineIcon} alt='' /> <div className={styles['filter-heading']}>Discipline</div></div>
                                <div><img className={desipilineFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {desipilineFlag && (
                                <div className={styles['dropdown-background']}>
                                    {disciplines.map((item) => (
                                        <div className={styles['checkbox-alignment']} key={item.DesciplineId}>
                                            <input type="checkbox"
                                                checked={selectedDisciplines.includes(item.DesciplineName)}
                                                onChange={() => handleDisciplineChange(item.DesciplineName)}
                                            />
                                            <div>{item.DesciplineName}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showtution}>
                                <div className={styles['header-align']}> <img src={tutionIcon} alt='' /> <div className={styles['filter-heading']}> Tuition</div></div>
                                <div><img className={tutionFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {tutionFlag && (
                                <div className={styles['dropdown-background']}>
                                    {fees.map(fee => (
                                        <div className={styles['checkbox-alignment']} key={fee.srNo}>
                                            <input type="checkbox"></input>
                                            <div>{fee.feeCaption}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showintake}>
                                <div className={styles['header-align']}> <img src={intakeIcon} alt='' /> <div className={styles['filter-heading']}> Intake</div></div>
                                <div><img className={intakeFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {intakeFlag && (
                                <div className={styles['dropdown-background']}>
                                    {country.map(items => (
                                        <div className={styles['checkbox-alignment']}>
                                            <input type="checkbox"></input>
                                            <div>{items}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showschool}>
                                <div className={styles['header-align']}> <img src={schoolIcon} alt='' /> <div className={styles['filter-heading']}> School</div></div>
                                <div><img className={schoolFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {schoolFlag && (
                                <div className={styles['dropdown-background']}>
                                    {country.map(items => (
                                        <div className={styles['checkbox-alignment']}>
                                            <input type="checkbox"></input>
                                            <div>{items}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {/* <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showduration}>
                <div className={styles['header-align']}> <img src={durationIcon} alt='' /> <div className={styles['filter-heading']}> Duration</div></div>
                    <div><img className={durationFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {durationFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div> */}
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showduration}>
                                <div className={styles['header-align']}> <img src={durationIcon} alt='' /> <div className={styles['filter-heading']}> Duration</div></div>
                                <div><img className={durationFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {durationFlag && (
                                <div className={styles['dropdown-background']}>
                                    {filters.map((item) => (
                                        <div className={styles['checkbox-alignment']} key={item.SrNo}>
                                            <input type="checkbox" />
                                            <div>{item.DurationCaption}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showinternship}>
                                <div className={styles['header-align']}> <img src={internshipIcon} alt='' /> <div className={styles['filter-heading']}> Co-op and Internship</div></div>
                                <div><img className={internshipFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {internshipFlag && (
                                <div className={styles['dropdown-background']}>
                                    {country.map(items => (
                                        <div className={styles['checkbox-alignment']}>
                                            <input type="checkbox"></input>
                                            <div>{items}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showenglish}>
                                <div className={styles['header-align']}> <img src={englishIcon} alt='' /> <div className={styles['filter-heading']}> English Score</div></div>
                                <div><img className={englishScorFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {englishScorFlag && (
                                <div className={styles['dropdown-background']}>
                                    {requirements.map(req => (
                                        <div className={styles['checkbox-alignment']} key={req.ProgramTypeID}>
                                            <input type="checkbox"></input>
                                            <div>{req.ProgramTypeID}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showgre}>
                                <div className={styles['header-align']}> <img src={gmtIcon} alt='' /> <div className={styles['filter-heading']}> GMAT/GRE Score</div></div>
                                <div><img className={greFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {greFlag && (
                                <div className={styles['dropdown-background']}>
                                    {requirements.map(req => (
                                        <div className={styles['checkbox-alignment']} key={req.ProgramTypeID}>
                                            <input type="checkbox"></input>
                                            <div>{req.ProgramTypeID}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={styles['filter-sparate-border']}>
                            <div className={styles['all-filter-content']} onClick={showapplication}>
                                <div className={styles['header-align']}> <img src={applicationIcon} alt='' /><div className={styles['filter-heading']}> Application Fee</div></div>
                                <div><img className={applicatioFlag ? styles['rotateImage'] : ''} src={dropdownIcon} alt='' /></div>
                            </div>
                            {applicatioFlag && (
                                <div className={styles['dropdown-background']}>
                                    {fees.map(fee => (
                                        <div className={styles['checkbox-alignment']} key={fee.srNo}>
                                            <input type="checkbox"></input>
                                            <div>{fee.feeCaption}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div>
                            <div className={styles['clear-btn-postion']}>
                                <button className={styles['clear-btn']} onClick={clearAllSelections}>Clear All</button>
                                <button className={styles['see-program-btn']} onClick={() => fetchProgramInfo(1)}>See Programs</button>
                            </div>
                        </div>
                    </div>

                </div>
            )}

            <div className={`${styles['filter_container']}`}>
                <div className={styles['tabs_container']}>
                    <div onClick={() => { getTabIndex(1) }} className={tabIndex === 1 ? `${styles['selected_tabs_value']}  col-md-3` : `${styles['tabs_value']} col-md-3`}>AIME</div>
                    <div onClick={() => { getTabIndex(2) }} className={tabIndex === 2 ? `${styles['selected_tabs_value']} col-md-3` : `${styles['tabs_value']} col-md-3`}>Programs</div>
                    <div onClick={() => { getTabIndex(3) }} className={tabIndex === 3 ? `${styles['selected_tabs_value']} col-md-3` : `${styles['tabs_value']} col-md-3`}>Institutes</div>
                    <div onClick={() => { getTabIndex(4) }} className={tabIndex === 4 ? `${styles['selected_tabs_value']}  col-md-3` : `${styles['tabs_value']} col-md-3`}>Scholarship</div>
                </div>
                {tabIndex !== 1 && (
                    <div className={`${styles['search_filter_container']}`}>
                        <div className="row mb-2 pb-3">
                            <div className="col-md-12 col-lg-7 mb-2">
                                <div className={styles['search-align']}>
                                    <div>
                                        <button onClick={() => { setcountryFlag(!countryFlag) }} className={countryName === 'Country' ? styles['country-btn'] : styles['selected-country-btn']}>
                                            {countryName} <span><img className={countryFlag ? styles['rotateImage'] : ''} src={countryName === 'Country' ? dropdownIcon : whiteArrow} alt='' /></span>
                                        </button>
                                        {countryFlag && (
                                            <div ref={countryDiv} className={styles['countryDropDown']}>
                                                {countryList.map((item) => (
                                                    <div onClick={() => { showCountry(item) }} className={styles['dropDown-aligment']} key={item.InstCountryId}>
                                                        <div className={styles['dropdown-text']}>{item.InstCountryName}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    {tabIndex === 4 && (
                                        <div className="flex-grow-1" style={{ width: '100%' }}>
                                            <select
                                                name="scholarship"
                                                placeholder="Level of Study"
                                                className={styles["input-serch"]}
                                                disabled={!selectedCountryId || isLoading}
                                                onChange={handleEduLevelChange}
                                            >
                                                <option className={styles["option-default"]} value="">
                                                    Level of Study
                                                </option>

                                                {eduLevels.length > 0 &&
                                                    eduLevels.map((level) => (
                                                        <option key={level.EduLevelId} value={level.EduLevelId}>
                                                            {level.EduLevelName}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    )}

                                    {/* Conditionally render the select input for tabIndex 2 */}
                                    {tabIndex === 2 && (
                                        <div className="flex-grow-1" style={{ width: '100%' }} >
                                            <select
                                                name='courseType'
                                                value={selectedProgramTypeId}
                                                onChange={(e) => handleProgramTypeChange(e.target.value)}
                                                className={styles['input-serch']}
                                                disabled={!selectedCountryId || isLoading}
                                            >
                                                <option value=''>Select Course Type</option>
                                                {courseTypes.map((item) => (
                                                    <option key={item.ProgramTypeId} value={item.ProgramTypeId}>
                                                        {item.ProgramType}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}

                                    {/* Conditionally render search input for tabIndex 2 and 3 */}
                                    {(tabIndex === 2) && (
                                        <div className={`${styles['input-width']} flex-grow-1 position-relative`} style={{ width: '100%' }}>
                                            <input
                                                value={query}
                                                onChange={getInputValue}
                                                name={'disipline' }
                                                placeholder={'Search a Program desipline'}
                                                type="text"
                                                className={styles["input-serch"]}
                                                disabled={!selectedCountryId || isLoading}
                                            />
                                            {inputFlag && filteredItems.length > 0 && query.length > 2 && (
                                                <div ref={inputdiv} className={styles["searchInputValue"]}>
                                                    {filteredItems.map((item, index) => (
                                                        <div
                                                            className={styles["dropdown-text"]}
                                                            key={index}
                                                            onClick={() => setImputValue(item)}
                                                        >
                                                            {item}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {tabIndex !== 4 && (
                                                <img className={styles['search-icon']} src={searchIcon} alt='' />
                                            )}
                                        </div>
                                    )}

                                    {(tabIndex === 3) && (
                                        <div className={`${styles['input-width']} flex-grow-1 position-relative`} style={{ width: '100%' }}>
                                            {/* <Select
                                                options={options}
                                                onInputChange={handleInputChange}
                                                onChange={handleSelectChange}
                                                placeholder="Search a Program"
                                                isClearable
                                                isDisabled={!selectedCountryId}
                                                filterOption={(option, inputValue) =>
                                                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                                className={styles["input-search"]}
                                            /> */}

                                            <input
                                                value={query}
                                                onChange={getInputValue}
                                                name={tabIndex === 2 ? 'program' : 'institute'}
                                                placeholder={tabIndex === 2 ? 'Search a Program' : 'Search an Institute'}
                                                type="text"
                                                className={styles["input-serch"]}
                                                disabled={!selectedCountryId || isLoading}
                                            />
                                            {inputFlag && filteredItems.length > 0 && query.length > 2 && (
                                                <div ref={inputdiv} className={styles["searchInputValue"]}>
                                                    {filteredItems.map((item, index) => (
                                                        <div
                                                            className={styles["dropdown-text"]}
                                                            key={index}
                                                            onClick={() => setImputValue(item)}
                                                        >
                                                            {item}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {tabIndex !== 4 && (
                                                <img className={styles['search-icon']} src={searchIcon} alt='' />
                                            )}
                                        </div>
                                    )}

                                    <div>
                                        <button onClick={handleSearch} className={`${styles['primary-button']} primary-button `}>Search</button>
                                    </div>
                                </div>


                            </div>

                            <div className="col-md-12 col-lg-5">
                                <div className={styles['search-align-filter']}>
                                    {tabIndex === 2 && resetFlag && (
                                        <div>
                                            <button className={styles['commom-btn']} onClick={() => showModalhandler('xl')} >Compare</button>
                                        </div>
                                    )}
                                    {tabIndex === 2 && resetFlag && (
                                        <div>
                                            <button onClick={allFilterShow} className={styles['commom-btn']} > <span><img className={styles['all-filters-icon']} src={allfilterIcon} alt='' /></span> All Filters</button>
                                        </div>
                                    )}

                                    {(tabIndex === 3 || tabIndex === 4 || tabIndex === 2) && resetFlag && (
                                                <div>
                                                    <button
                                                        onClick={() => setShordropdown(!shordropdown)}
                                                        className={styles['commom-btn']}
                                                    >
                                                        Sort by <span><img src={alphaBetIcon} alt="" /></span>
                                                    </button>
                                                    {shordropdown && (
                                                        <div ref={shortDiv} className={styles['sortDropDown']}>
                                                            <div
                                                                onClick={() => {
                                                                    handleSortByTab("A-Z");
                                                                    setShordropdown(false);
                                                                }}
                                                                className={styles['dropDown-aligment']}
                                                            >
                                                                <div><img src={updownIcon} alt=""></img></div>
                                                                <div className={styles['dropdown-text']}> Alphabetical (A-Z)</div>
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    handleSortByTab("Z-A");
                                                                    setShordropdown(false);
                                                                }}
                                                                className={styles['dropDown-aligment']}
                                                            >
                                                                <div><img src={updownIcon} alt=""></img></div>
                                                                <div className={styles['dropdown-text']}> Alphabetical (Z-A)</div>
                                                            </div>

                                                            {tabIndex === 2 && (
                                                                <div
                                                                onClick={() => {
                                                                    handleSortByTab("Highest");
                                                                    setShordropdown(false);
                                                                }}
                                                                className={styles['dropDown-aligment']}
                                                            >
                                                                <div><img src={updownIcon} alt=""></img></div>
                                                                <div className={styles['dropdown-text']}>Fee (High-Low)</div>
                                                            </div>
                                                            )}

                                                            {tabIndex === 2 && (
                                                                <div
                                                                onClick={() => {
                                                                    handleSortByTab("Lowest");
                                                                    setShordropdown(false);
                                                                }}
                                                                className={styles['dropDown-aligment']}
                                                            >
                                                                <div><img src={updownIcon} alt=""></img></div>
                                                                <div className={styles['dropdown-text']}>Fee (Low-High)</div>
                                                            </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                            </div>

                        </div>

                    </div>
                )}

            </div>
            <div>
                <div>
                    {tabIndex === 1 && (
                        <AIME ></AIME>
                    )}

                </div>
                <div>
                    {tabIndex === 2 && selectedCountryId && (
                        <Programs
                            programs={programData}
                            filters={filters}
                            courseTypes={courseTypes}
                            fees={fees}
                            fetchProgramInfo={fetchProgramInfo}
                            onDurationChange={handleDurationChange}
                            onTuitionFeeChange={handleTuitionFeeChange}
                            onProgramTypeChange={handleProgramTypeChange}
                            selectedProgramTypeIds={selectedProgramTypeId}
                            selectedDurationIdss={selectedDurationId}
                            selectedCountryId={selectedCountryId}
                            resetFlag={resetFlag}
                        />
                    )}
                </div>
                <div>
                    {tabIndex === 3 && selectedCountryId && (
                        <Institutes institutes={institutes} resetFlag={resetFlag} updateInsFilters={updateInsFilters} Institutes fetchInstituteData={fetchInstituteData}/>
                    )}
                </div>
                <div>
                    {tabIndex === 4 && selectedCountryId && (
                        <Scholarship scholarships={scholarships} resetFlag={resetFlag} updateScholarFilters={updateScholarFilters}
                            removeEduLevel={removeEduLevel} selectedScholarEdulevels={selectedScholarEdulevels} eduLevels={eduLevels}
                            fetchScholarshipData={fetchScholarshipData} />
                    )}
                </div>


                {/* common for three tabs */}
                {/* <div>
                 {(totalCount!==programs?.program && programs?.program?.length>0) && (
                <div className="text-center mt-2 mb-2">
                <button onClick={viewMore} className={styles['view-more']}>View more</button>
                  </div>
              )}
            </div> */}


                <div>
                    {(resetFlag && (tabIndex === 2 || tabIndex === 3 || tabIndex === 4)) ? (
                        <Paginations currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                    ) : null}
                </div>


            </div>


        </>
    )

}

export default SearchPage;