 import React, { useState } from "react";
 import styles from './Rating.module.css'
 import emogi1 from './../../../../../../Assets/Logo/emogi1.svg'
 import emogi2 from './../../../../../../Assets/Logo/emogi2.svg'
 import emogi3 from './../../../../../../Assets/Logo/emogi3.svg'



 const Rating=(props)=>{
    const [ratingValue, setRatingValue]=useState(0);
    const getRating=(value)=>{
        setRatingValue(value)
        props.getRate(value)
    }
    const ratingNext=()=>{
        props.nextRate()
    }

    return (
        <>
        <div className={styles['heding-section']} >
        <p className={styles['sub-heading']}>Rate these traits</p>
        </div>
        <div className={styles['heding-section']} >
        <p className={styles['sub-heading-account']}>{props?.heading}:</p>
        </div>
        <div className={styles['rating-section-align']}>
       
            <div className={styles['rating-section']}>
                {ratingValue!==5 && (
                 <div className={ratingValue===1? styles['selector1']:ratingValue===2? styles['selector2']:ratingValue===3? styles['selector3']:ratingValue===4? styles['selector4']:styles['selector']}> <div></div></div>
                )}
           
                <div onClick={()=>{getRating(1)}} className={ratingValue>=1? styles['selectedRating1']:styles['rating']}><div className={styles['number-align']}>
                    1
                  <p>Beginner</p>  </div></div>
                <div onClick={()=>{getRating(2)}}  className={ratingValue>=2? styles['selectedRating']:styles['rating']}><div className={styles['number-align']}>
                    2
                    <p>Competent</p> </div></div>
                <div onClick={()=>{getRating(3)}}  className={ratingValue>=3? styles['selectedRating']:styles['rating']}><div className={styles['number-align']}>
                    3 
                    <p>Proficient</p> </div></div>
                <div onClick={()=>{getRating(4)}}  className={ratingValue>=4? styles['selectedRating']:styles['rating']}><div className={styles['number-align']}>
                    4
                    <p>Advanced</p> </div></div>
                <div onClick={()=>{getRating(5)}}  className={ratingValue===5? styles['selectedRating5']:styles['rating']}><div className={styles['number-align']}>
                    5
                    <p>Mastery</p> </div></div>

            </div>
        </div>
        <div className={styles['heding-section']}>
            <p className={styles['sub-heading-rate']}>Rate if you like this trait</p>
        </div>

        <div className={styles['emogi-section']}>
            <div>
                <img src={emogi1} alt=''></img>
            </div>
            <div>
                <img src={emogi2} alt=''></img>
            </div>
            <div>
                <img src={emogi3} alt=''></img>
            </div>
        </div>
        <div className={styles["form-footer"]}>
                                <div className={styles["button-section"]}>
                                    <button  onClick={ratingNext} className={`${styles["next-btn"]} primary-button`} disabled={ratingValue===0}>Next</button>
                                </div>
                                <div className={styles["button-section"]}>
                                    <button onClick={ratingNext}  className={`${styles["next-btn"]} primary-button`}>Skip</button>
                                </div>
        </div>
        </>
    )


 }
 export default Rating;