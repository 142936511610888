import React from 'react';
import styles from './Notification.module.css'; 

const Notification = () => {
  return (
    <div className={styles.notification}>
      <div className={styles.notificationHeader}>
        <span>Notification:</span>
        <a href="/" className={styles.viewMore}>View More...</a>
      </div>
      <ul className={styles.notificationList}>
        <li>
          <div className={styles.groupIcon}></div>
          <span className={styles.notificationText}>Waffles chirp at birds or always wanting food.</span>
        </li>
        <li>
          <div className={styles.groupIcon}></div>
          <span className={styles.notificationText}>Eat fish on floor.</span>
        </li>
        <li>
          <div className={styles.groupIcon}></div>
          <span className={styles.notificationText}>Fight an alligator and win.</span>
        </li>
      </ul>
    </div>
  );
};

export default Notification;
