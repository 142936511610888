import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import classes from './Header.module.css';
import item1 from "../../../Assets/Logo/advanced-settings.svg";
import item2 from "../../../Assets/Logo/home.svg";
import item3 from "../../../Assets/Logo/Union_header.svg";
import item4 from "../../../Assets/Logo/market.svg";
import item5 from "../../../Assets/Logo/jobs.svg";
import item6 from "../../../Assets/Logo/applications.svg";
import item7 from "../../../Assets/Logo/tools.svg";
import item8 from "../../../Assets/Logo/payment.svg";
import item9 from "../../../Assets/Logo/notification_bell.svg";
import item10 from "../../../Assets/Logo/messages.svg";
import item11 from "../../../Assets/Logo/EN.svg";
import item12 from "../../../Assets/Images/profile_avatar.png";
import searchIcon from "../../../Assets/Logo/search-icon.svg";
import dropdownIcon from "../../../Assets/Logo/drop-down_icon.svg";
import { useBehaviorSubject } from '../../GlobleShared/BehaviorSubject/BehaviorSubject';
import { useInput } from '../../Pages/Home/Dashboard/DashboardSearch/GlobalSearch';
import Notifications from "../../Common/Notifications/Notifications"
import Logo from '../../../Assets/Images/msm-pursuit-logo.png'

import Messages from '../Messages/Messages';
import useLocalStorage from '../../GlobleShared/CustomHooks/useLocalStorage';
import ProfileService from '../../../Services/ProfileService';
import Loader from '../../GlobleShared/Loader/Loader';
import { useProfile } from '../../Context/ProfileContext';
const items = [
    'Programs',
    'Institute',
    'Peoples',
  ];

function Header() {
    const [profileDropDown, setprofileDropDown] = useState();
    const dropdownRef = useRef(null);
    const notificationDiv = useRef(null);
    const massageDiv =useRef(null)
    const navigate = useNavigate();
    const initial = useRef(null);

    const { isLoginUser, logout } = useBehaviorSubject();
    const inputdiv=useRef(null);
    const [inputFlag, setInputFlag]= useState(false);
    const [query, setQuery] = useState('');
    const { inputValue, setInputValue } = useInput();
    const [toolsBtnPopupOpen, setToolsBtnPopupOpen] = useState(false);
    const toolsBtnRef = useRef(null);
    const blobUrl = process.env.REACT_APP_URL_PATH;
    const { profileImage, setProfileImage } = useProfile();
    const [userDetail] = useLocalStorage('userDetail');
    const studentId = userDetail.refId;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const defaultProfileImage = item12; // Assuming item12 is the path to the dummy image
const [currentProfileImage, setCurrentProfileImage] = useState(defaultProfileImage);
    const toggleDropdown = () => {
        setDropdownOpen(true);
      };
      const fetchProfileInfo = async () => {
        setIsLoading(true);
        try {
            const response = await ProfileService.getAboutProfileInfo(studentId);
            if (response && response.ProfileImgPath) {
                const profileImgUrl = blobUrl + response.ProfileImgPath;
                setProfileImage(profileImgUrl);
                setCurrentProfileImage(profileImgUrl);
                localStorage.setItem('userInfo', JSON.stringify(response)) // Update the current profile image
            }
        } catch (error) {
            console.error('Error fetching profile image:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        if (!initial.current) {
         initial.current = true;
        fetchProfileInfo();
        }
    }, [setProfileImage]);
    
    const [ massageFlag, setMassageFlag ] = useState(false);
    const route =()=>{
        navigate('/member/dashboard'); 
    }
    const toggleToolsBtnPopup = () => {
        setToolsBtnPopupOpen(true);
    }
    const viewProfileClick = () => {
        setprofileDropDown(false);
        navigate('/member/profile');
    }
    const setImputValue =(value)=>{ 
        setQuery(value);
        setInputFlag(false)
        setInputValue(value);
        if(value.trim()){
            navigate('/member/dashboard');
        }
       
      }
    const filteredItems = items.filter(item =>
        item.toLowerCase().includes(query.toLowerCase())
      );
      const getInputValue=(value)=>{
        setQuery(value);
        setInputFlag(true)
        setInputValue(value);
        if(value.trim()){
            navigate('/member/dashboard');
        }

      }
      const showMassages = ()=>{
        if(!massageFlag){
            setMassageFlag(true)
        }
        if(massageFlag){
            setMassageFlag(false)
        }
    }
      const handleClickOutside = (event) => {
        if(inputdiv){
            if (inputdiv.current && !inputdiv.current.contains(event.target)) {
                setInputFlag(false)
            }
        }
        if(notificationDiv){
            if (notificationDiv.current && !notificationDiv.current.contains(event.target)) {
                setDropdownOpen(false)
            }
        }
        if(massageDiv){
            if (massageDiv.current && !massageDiv.current.contains(event.target)) {
                setMassageFlag(false)
            }
        }
      };
  

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutsideHandler);
        return () => {
            document.removeEventListener('mousedown', onClickOutsideHandler);
        };
    }, []);

    function onProfileHandler() {
        setprofileDropDown(!profileDropDown);
    }

    function onClickOutsideHandler(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setprofileDropDown(false);
            setToolsBtnPopupOpen(false);
        }
    }

    function onLogOutHandler() {
        setprofileDropDown(false);
        logout();
    }

  
    document.addEventListener('mousedown', handleClickOutside);
    return (
        // <div className={styles.headerContainer}>
        //     <div className={styles.logoSection}>
        //         <div className={styles.main_heading}>
        //             Whiteboard
        //         </div>
        //     </div>
        //     <div className={styles.navigationSection}>
        //         <Link to="/sign-in" className={styles.navLink}>
        //             Sign in
        //         </Link>
        //         <Link to="/sign-up" className={styles.navLink}>
        //             Sign up
        //         </Link>
        //     </div>
        // </div>
        <div className={classes["header-container"]}>
        {isLoading && <Loader/>}
            <div className={classes["header-block"]}>
                <div className={classes["heading"]} >
                    <img onClick={route} src={Logo} className={classes['msm-logo']} alt=""></img>
                </div>
                <div className={classes["search-bar"]}>
                    <div className={classes['div-width']}>
                    <input value={query} onChange={(e) => getInputValue(e.target.value)} type="text" placeholder="Search..." />
                    {inputFlag && filteredItems?.length>0 && query?.length>2   && (
                    <div ref={inputdiv} className={classes['searchInputValue']}>
                        {filteredItems.map((item, index) => (
                         <div className={classes['dropdown-text']} key={index} onClick={()=>{setImputValue(item)}}>{item}</div>
                         ))}
                         </div>
                )}
                    </div>
                    <button type="submit"><img src={searchIcon} /></button>
             
                 
                </div>
             
                <div className={classes["nav-item"]}>
                    <div><img src={item1} className={classes["nav-item-logo"]}/></div>
                    <div className={classes["nav-item-detail"]}>Advance</div>
                </div>
                <div className={classes["nav-item"]}>
                    <div><img src={item2} className={classes["nav-item-logo"]}/></div>
                    <div className={classes["nav-item-detail"]}>Home</div>
                </div>
                <div className={classes["nav-item"]}>
                    <div><img src={item3} className={classes["nav-item-logo"]}/></div>
                    <div className={classes["nav-item-detail"]}>AIME</div>
                </div>
                <div className={classes["nav-item"]}>
                    <div><img src={item4} className={classes["nav-item-logo"]}/></div>
                    <div className={classes["nav-item-detail"]}>Market</div>
                </div>
                <div className={classes["nav-item"]}>
                    <div><img src={item5} className={classes["nav-item-logo"]}/></div>
                    <div className={classes["nav-item-detail"]}>Jobs</div>
                </div>
                <div className={classes["nav-item"]}>
                   <NavLink className={classes['remove-line']}  to={'/member/application/list'}>
                    <div><img src={item6} className={classes["nav-item-logo"]}/></div>
                    <div className={classes["nav-item-detail"]}>
                    Applications
                    </div>
                    </NavLink>
                </div>
                <div className={`${classes["nav-item"]}`}>
                    <div><img src={item7} className={classes["nav-item-logo"]} onClick={()=>toggleToolsBtnPopup()}/></div>
                    <div className={classes["nav-item-detail"]} onClick={()=>toggleToolsBtnPopup()}>Tools <img src={dropdownIcon} className={classes["drop-down-icon"]} /></div>
                    <div className={classes["nav-item-position-relative"]}>
                    {toolsBtnPopupOpen && (
                        <div className={classes["ToolBtn"]} ref={dropdownRef}>
                          <div className={classes["ToolBtnItem"]} onClick={()=>navigate('/member/tools/education-calculator')}>Education Calculator</div>
                          <div className={classes["brokenLine"]}></div>
                          <div className={classes["ToolBtnItem"]} onClick={()=>navigate('/member/tools/living-cost-calculator')}>Living Cost Calculator</div>
                          <div className={classes["brokenLine"]}></div>
                          <div className={classes["ToolBtnItem"]} onClick={()=>navigate('/member/tools/currency-converter')}>Currency Converter</div>
                          <div className={classes["brokenLine"]}></div>
                          <div className={classes["ToolBtnItem"]} onClick={()=>navigate('/member/tools/english-score-calculator')}>English Score Calculator</div>
                        </div>
                        )}
                    </div>
                </div>
                <div className={classes["nav-item"]}>
                    <div><img src={item8} className={classes["nav-item-logo"]}/></div>
                    <div className={classes["nav-item-detail"]}>23.05 <img src={dropdownIcon} className={classes["drop-down-icon"]}/></div>
                </div>
                <div  className={`${classes["nav-item"]} ${classes["additionalclass"]}`}>
                    <div><img src={item9} className={classes["nav-item-logo"]}/></div>
                    <div onClick={toggleDropdown} className={classes["nav-item-detail"]}>Notifications</div>
                    {dropdownOpen && (
                        <div ref={notificationDiv} className={classes["dropdown-notify"]}>
                            <Notifications/>
                            hello
                        </div>
                    )}
                    
                </div>
                <div className={classes["nav-item"]}  >
                    <div onClick={showMassages} className='text-center'>
                    <div><img src={item10} className={classes["nav-item-logo"]}/></div>
                    <div className={classes["nav-item-detail"]}>Messages</div>
                    </div>
                    <div ref={massageDiv}  className={classes['massage-position']}>
                        { massageFlag && (
                            <Messages></Messages>
                        )}
                    
                    </div>
                </div>
                <div className={classes["nav-item"]}>
                    <div><img src={item11} className={classes["nav-item-logo"]}/></div>
                    <div><img src={dropdownIcon} className={classes["drop-down-icon"]}/></div>
                </div>
                <div className={classes["Profile-drop-down"]} ref={dropdownRef}>
    <img 
        src={currentProfileImage} 
        alt="Profile" 
        className={classes["profile-placeholder"]} 
        onClick={onProfileHandler} 
    />
    <div><img src={dropdownIcon} className={classes["drop-down-icon"]} onClick={onProfileHandler} /></div>
    {profileDropDown && (
        <div className={classes["dropDownProfile"]}>
            <ul className={classes["drop-down-items"]}>
                <li className={classes["open-dropdown"]}>
                    <img className={classes["profile-placeholder"]} src={currentProfileImage} alt="Profile" />
                    {/* <p className={classes["dropdown-username"]}>{userDetails?.first_name} {userDetails?.last_name}</p> */}
                </li>
                <li className={classes["dropdown-item-text"]} onClick={viewProfileClick}>View Profile</li>
                <li className={classes["dropdown-item-text"]} onClick={onLogOutHandler}>Logout</li>
            </ul>
        </div>
    )}
</div>
            </div>
        </div>
    );
}

export default Header;
