import React, {useState, useEffect,useRef} from 'react';
import classes from './Scholarship.module.css';
import Accordion from 'react-bootstrap/Accordion';
import { Form } from 'react-bootstrap';
import universityLogo from "../../../../../Assets/Logo/dougl-icon.svg";
import crossIconWhite from "../../../../../Assets/Logo/crose-whiteMini.svg";

const Scholarship = ({ scholarships = [], resetFlag, updateScholarFilters, selectedScholarEdulevels, removeEduLevel, eduLevels,fetchScholarshipData }) => {

    const [selectedAmounts, setSelectedAmount] = useState([]);
    const [selectedInstitutes, setSelectedInstitutes] = useState([]);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [searchInput, setSearchInput] = useState(""); // State for search input
    const bloburl = process.env.REACT_APP_LOGO_PATH;
    const [shouldUpdateFilters, setShouldUpdateFilters] = useState(false);
    const [filterAction, setFilterAction] = useState(null); // 'apply' or 'clear'
    const initial = useRef(null);

    // const filtersData = institutes?.filters?.[0]|| [];
    const clearFilters = () => {
        setSelectedAmount([]);
        setSelectedInstitutes([]);
        setSelectedPrograms([]);
        setFilterAction('clear'); // Set filter action to 'clear'
        updateScholarFilters({
            selectedAmounts: [],
            selectedInstitutes: [],
            selectedPrograms: [],
        });
        // fetchScholarshipData(1); // Reset to page 1
    };
    const applyFilters = () => {
        setFilterAction('apply'); // Set filter action to 'apply'
        updateScholarFilters({
            selectedAmounts,
            selectedInstitutes,
            selectedPrograms,
        });
        // fetchScholarshipData(1); // Apply filters and fetch data
    };

    useEffect(() => {
        if (filterAction === 'clear') {
            fetchScholarshipData(1); // Apply filters and fetch data
        } else if (filterAction === 'apply') {
            fetchScholarshipData(1); // Reset to page 1
        }
        setFilterAction(null); 
    }, [selectedAmounts, selectedInstitutes, selectedPrograms, filterAction]);
  
    const handleAmountChange = (amountId) => {
        setSelectedAmount((prev) =>
        prev.includes(amountId)
          ? prev.filter((id) => id !== amountId)
          : [...prev, amountId] 
      );
     
    };

    
    const handleInstituteChange = (instituteId) => {
        setSelectedInstitutes((prev) =>
        prev.includes(instituteId)
          ? prev.filter((id) => id !== instituteId)
          : [...prev, instituteId] 
      );
     
    };
    
    const handleProgramChange = (ProgramId) => {
        setSelectedPrograms((prev) =>
        prev.includes(ProgramId)
          ? prev.filter((id) => id !== ProgramId)
          : [...prev, ProgramId] 
      );
     
    };
    
    
  
    // useEffect(() => {
    //   updateScholarFilters({
    //     selectedAmounts,
    //     selectedInstitutes,
    //     selectedPrograms
    //   });
    // }, [selectedAmounts, selectedAmounts, selectedPrograms]);
    
    
    const filteredInstitutes = scholarships?.Filters?.Institution?.filter((institute) =>
        institute.InstitutionName.toLowerCase().includes(searchInput.toLowerCase())
      );
    return (
        <>
            <div>
                <div className="row">

                    {/* Secondary Filters */}
                    <div className="col-md-3 col-lg-2">
                        <div>
                            <Accordion   className="filter-section" defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>

                            {scholarships?.Filters?.Amount?.length > 0 && (
                                <><Accordion.Item eventKey="3">
                                        <Accordion.Header className={classes["custom-accordion-header"]}>filters</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <span style={{ textDecoration: 'none', color: 'black' }} onMouseOver={(e) => {
                                                    e.target.style.textDecoration = 'underline';
                                                    e.target.style.color = 'blue';
                                                    e.target.style.cursor = 'pointer';
                                                } } onMouseOut={(e) => {
                                                    e.target.style.textDecoration = 'none';
                                                    e.target.style.color = 'black';
                                                }} onClick={applyFilters}>apply filter</span>
                                            </div>
                                            <div>
                                                <span style={{ textDecoration: 'none', color: 'black' }} onMouseOver={(e) => {
                                                    e.target.style.textDecoration = 'underline';
                                                    e.target.style.color = 'blue';
                                                    e.target.style.cursor = 'pointer';
                                                } } onMouseOut={(e) => {
                                                    e.target.style.textDecoration = 'none';
                                                    e.target.style.color = 'black';
                                                }}  onClick={clearFilters}>clear filter</span>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item><Accordion.Item eventKey="0">
                                            <Accordion.Header>Type</Accordion.Header>
                                            <Accordion.Body>
                                                <Form>
                                                    {scholarships?.Filters?.Amount?.map((item, index) => (
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`duration-${item.AmountType}`}
                                                            key={item.AmountType}
                                                            value={item.AmountType}
                                                            label={item.AmountTypeName}
                                                            title={item.AmountTypeName}
                                                            checked={selectedAmounts.includes(item.AmountType)}
                                                            onChange={() => handleAmountChange(item.AmountType)} />
                                                    ))}
                                                </Form>
                                            </Accordion.Body>
                                        </Accordion.Item></>

                            )}

                     {/* {scholarships?.Filters?.Institution?.length > 0 && ( 
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Institute</Accordion.Header>
                                    <Accordion.Body>
                                    <Form>
                                    {scholarships?.Filters?.Institution?.map((item, index) => (
                                        <Form.Check
                                        type="checkbox"
                                        id={`duration-${item.InstitutionId}`}
                                        key={item.InstitutionId}
                                        value={item.InstitutionId}
                                        label={item.InstitutionName}
                                        title={item.InstitutionName}
                                        checked={selectedInstitutes.includes(item.InstitutionId)}
                                        onChange={() => handleInstituteChange(item.InstitutionId)}
                                        />
                                    ))}
                                    </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                     )} */}
                     {scholarships?.Filters?.Institution?.length > 0 && (
  <Accordion.Item eventKey="1">
    <Accordion.Header>Institute</Accordion.Header>
    <Accordion.Body>
      <Form>
        <input
          type="text"
          className={classes.searchInput}
          placeholder="Search an Institute..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          style={{
            borderRadius: "12px",
            margin: "10px 0px 10px 3px",
            height: "40px",
            width: "100%"
          }}
        />
        {filteredInstitutes?.map((item, index) => (
          <Form.Check
            type="checkbox"
            id={`duration-${item.InstitutionId}`}
            key={item.InstitutionId}
            value={item.InstitutionId}
            label={item.InstitutionName}
            checked={selectedInstitutes.includes(item.InstitutionId)}
            onChange={() => handleInstituteChange(item.InstitutionId)}
          />
        ))}
      </Form>
    </Accordion.Body>
  </Accordion.Item>
)}

                     {scholarships?.Filters?.Program?.length > 0 && ( 
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Programs</Accordion.Header>
                                    <Accordion.Body>
                                    <Form>
                                    {scholarships?.Filters?.Program?.map((item, index) => (
                                        <Form.Check
                                        type="checkbox"
                                        id={`duration-${item.ProgramId}`}
                                        key={item.ProgramId}
                                        value={item.ProgramId}
                                        label={item.ProgramName}
                                        title={item.ProgramName}
                                        checked={selectedPrograms.includes(item.ProgramId)}
                                        onChange={() => handleProgramChange(item.ProgramId)}
                                        />
                                    ))}
                                    </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                     )}

                            </Accordion>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="col-md-9 col-lg-10">
                    <div className="d-flex flex-wrap">
                            {selectedScholarEdulevels && (
                                <button
                                    className={classes.NameBudge}
                                    onClick={() => removeEduLevel()} 
                                >
                                    {eduLevels.find(item => item.EduLevelId === selectedScholarEdulevels)?.EduLevelName}
                                    <span>
                                        <img src={crossIconWhite} alt="Remove tag" />
                                    </span>
                                </button>
                            )}
                        </div>
                        <div className={classes["program-cards"]}>
                        {scholarships?.Institution?.map((scholarship)=> (
                                <div className={classes["card-section"]} key={scholarship.ProgramId}>
                                    <div className={classes["section-1"]}>
                                        <div className={classes["card-header-block-1"]}>
                                            <div className={classes["card-rank"]}>
                                                <span className={classes["rank-text"]}>Ranked: {scholarship.rank || 'N/A'}</span>
                                            </div>
                                            <div className={classes["card-header"]}>
                                                <div className={classes["university-icon"]}>
                                                    <img src={scholarship.InstLogoPath ? bloburl + scholarship.InstLogoPath: universityLogo} alt="Profile" />
                                                </div>
                                                <div className={classes["university-detail"]}>
                                                    <div  title={scholarship.InstitutionName} className={classes["university-name"]}>{scholarship.InstitutionName || 'N/A'}</div>
                                                    <div className={classes["university-address"]}>{scholarship.InstCountryName || 'N/A'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes["card-header-block-2"]}>
                                            <div title={scholarship.ProgramName} className={classes["program-name"]}>{scholarship.ProgramName || 'N/A'}</div>
                                            <div className={classes["program-detail-text"]}>{scholarship.description || 'N/A'}</div>
                                        </div>
                                    </div>
                                    <div className={classes["section-2"]}>
                                        <div className={classes["program-detail-section-1"]}>
                                            <div>
                                                <div className={classes["intake-header"]}>Intake</div>
                                                <div className={classes["detail"]}>{scholarship.intake?.startDate || 'N/A'}</div>
                                                <div className={classes["detail"]}>{scholarship.intake?.endDate || 'N/A'}</div>
                                            </div>
                                            <div className={classes["detail"]}>{scholarship.DurationTime || 'N/A'}</div>
                                            <div className={classes["detail"]}>{scholarship.degree || 'N/A'}</div>
                                            <div className={classes["detail"]}>{scholarship.ProgramCurrency} {scholarship.MinAmount} - {scholarship.MaxAmount}</div>
                                        </div>
                                        <div className={classes["program-detail-section-2"]}>
                                            <div>
                                                <div className={classes["header"]}>Application Fee</div>
                                                <div className={classes["detail"]}>{scholarship.applicationFee || 'N/A'}</div>
                                            </div>
                                            <div>
                                                <div className={classes["header"]}>People like You</div>
                                                <div className={classes["detail"]}>{scholarship.graduates || 'N/A'}</div>
                                            </div>
                                            <div>
                                                <div className={classes["header"]}>Likelihood of Graduation</div>
                                                <div className={classes["detail"]}>{scholarship.graduationLikelihood || 'N/A'}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Scholarship;