import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import classes from "./CommonPost.module.css";
import userImage from "../../../../../Assets/Images/picture1.png"; // Path to user image
import crossWhite from "../../../../../Assets/Logo/cross-icon.svg"; // Path to cross white icon
import dropdownIcon from "../../../../../Assets/Logo/drop-down-icon-2.svg"; // Path to dropdown icon
import icon1 from "../../../../../Assets/Logo/post-video-icon.svg";
import icon3 from "../../../../../Assets/Logo/like.svg";
import icon4 from "../../../../../Assets/Logo/heartIcon.svg";
import textIcon from "../../../../../Assets/Logo/like.svg";
import dummyImageIcon from "../../../../../Assets/Logo/dummy-image-icon.svg"; // Path to dummy image icon
import emojiIcon from "../../../../../Assets/Logo/emogi1.svg"; // Path to emoji icon
import { toast } from "react-toastify";
import Picker from 'emoji-picker-react';
import { Modal } from "react-bootstrap";
import ConfirmationModal from "../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import UserListModal from "../../../../GlobleShared/Modal/UserListModal/UserListModal";
import PostServices from "../../../../../Services/PostServices";
import Loader from "../../../../GlobleShared/Loader/Loader";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject"; 
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import Select from 'react-select';
import CommonServices from "../../../../../Services/CommonServices";
import infoICon from "../../../../../Assets/Logo/info-iconBlack.svg";
import Alert from 'react-bootstrap/Alert';
const maxSizeForImage = 5 * 1024 * 1024;
const maxSizeForVideo = 200 * 1024 * 1024;

const accessType=[{id:"1", name:'Public'},   {id:"2", name: 'All my connection'}, {id:"3", name:'Private'}, {id:"4", name:'Groups'}]
function CommonPost({isGroupData, isModalOpen, closeModal, getPostDetails, editPost, imageMediaURLs, updatePostList, isMediaSelected , tagList, postTagList, ChildMediaTypeId, childValue,groupId}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState();
  const [selectedType, setSelectedType] = useState('');
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const reactQuillRef = useRef(null);
  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
  const allowedVideoTypes = ['video/mp4', 'video/quicktime'];
  const [videoList, setVideoList]=useState()
  const [imageList, setImageList]=useState([]);
  const fileInputRef = useRef(null);
  const [confirmationBox, setconfirmationBox]=useState(false)
  const [tempImage, setTempImage]=useState([])
  const [isLoading, setIsLoading]=useState(false);
  const [deleteConfirmationBox, setDeleteConfirmationBox]=useState(false);
  const [mediaIndex, setmediaIndex]=useState();
  const { postUpdated,setPostUpdated } = useBehaviorSubject();
  const [accessTypeValue, setAccessTypeValue]=useState("1")
  const [groupValue, setGroupValue]=useState(groupId||"1")
  const dropDownRef=useRef(null)
  const [selectedMediaType, setSelectedMediaType] = useState(0);
  const [usersListModalOpen, setUsersListModalOpen] = useState(false);
  const [groupType, setGroupType] = useState(false);
  const [userDetail] = useLocalStorage('userDetail');
  const profilePic = userDetail.profilePic;
  const username = (userDetail.first_name ? userDetail?.first_name : '') + ' ' + (userDetail.last_name ? userDetail?.last_name : '');
  const [accessTypeName, setAccessTypeName]=useState('Public')
  const [tempAccesTypeName, setTempAccesTypeName]=useState('Public')
  const [tempAccesTypeid, setTempAccesTypeid]=useState('1')
  const [selectedValue, setSelectedValue] = useState([]);
  const [postToUser, setPostToUser]=useState([])
  const [groups, setGroups]=useState([])
  const [selectedTag, setSelectedTag]=useState([])
  const [entityType, setEntityType]=useState(0)
  const userInfo=JSON.parse(localStorage.getItem('userInfo'))
  const {groupIdGlobal}=useBehaviorSubject();
  const blobURL= process.env.REACT_APP_URL_PATH
  useEffect(() => {
    if (reactQuillRef.current) {
   
      reactQuillRef.current.getEditor();
    }
  
    if(editPost){
      if (imageMediaURLs && Array.isArray(imageMediaURLs) && imageMediaURLs.length > 0) {
        let postImages = [];
        imageMediaURLs.forEach((mediaObj)=>{
          postImages.push({postMediaId:mediaObj['postMediaId'],mediaTypeId:mediaObj['mediaTypeId'],file:mediaObj['mediaUrl']});
        })
        setImageList(postImages);
      }
      if (getPostDetails && typeof getPostDetails.postCaption === 'string' && getPostDetails.postCaption.trim().length > 0) {
        setEditorContent(getPostDetails.postCaption);
      }
      handleChange(postTagList)
    }
    if(isMediaSelected){
      setIsImageModalOpen(isMediaSelected);
    }
  }, [imageMediaURLs,getPostDetails,editPost, isMediaSelected]);
useEffect(()=>{
 if(ChildMediaTypeId !== null && childValue !== null){
  setSelectedImages([]);
  setSelectedVideos([]);
  setSelectedType(childValue);
  setIsImageModalOpen(true);
  setSelectedMediaType(ChildMediaTypeId)
}
},[ChildMediaTypeId,childValue]);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setTempAccesTypeid(accessTypeValue)
  };

  const getTypeName=()=>{
    setAccessTypeValue(tempAccesTypeid)
    setAccessTypeName(tempAccesTypeName)
    setIsDropdownOpen(!isDropdownOpen);
  }

  const closeParentModel = () => {
    closeModal();
    setEditorContent('');
    setSelectedImages([]);
    setSelectedVideos([]);
    setImageList([]);
    setVideoList('')
    //setTempImage([])
    setAccessTypeValue("1")
    setGroupValue(groupId||"1")
    setAccessTypeName('public')
    setTempAccesTypeName("public")
    setTempAccesTypeid('1')
    setPostToUser([])
  };

  const openImageModal = (value,mediaTypeId) => {
    setSelectedImages([]);
    setSelectedVideos([]);
    setSelectedType(value);
    setIsImageModalOpen(true);
    setSelectedMediaType(mediaTypeId)
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  const maxSizeForImage = 10 * 1024 * 1024; 
  const maxSizeForVideo = 200 * 1024 * 1024; 
  const handleImageChange = (event) => {
    const uploadFile=Array.from(event.target.files);
    let typeError = '';
    let sizeError = '';

    let newImageSize = selectedImages
        .filter(item => item.mediaTypeId === 1)
        .reduce((acc, item) => acc + item.mediaFiles.size, 0);
    let newVideoSize = selectedImages
        .filter(item => item.mediaTypeId === 2)
        .reduce((acc, item) => acc + item.mediaFiles.size, 0);
    const invalidFiles = uploadFile.filter(file => {
        let isInvalid = false;

        if (selectedMediaType === 2) { 
            if (!allowedVideoTypes.includes(file.type)) {
                typeError = 'Invalid file type. Allowed formats: MP4, MOV.';
                isInvalid = true;
            }
            if ((newVideoSize + file.size) > maxSizeForVideo) {
                sizeError = 'Total video size should not exceed 200MB.';
                isInvalid = true;
            } else {
                newVideoSize += file.size; // Add valid file size to total
            }
        } else if (selectedMediaType === 1) { // Image
            if (!allowedTypes.includes(file.type)) {
                typeError = 'Invalid file type. Allowed formats: JPEG, PNG, GIF.';
                isInvalid = true;
            }
            console.log('newImageSize + file.size: ',maxSizeForImage, newImageSize + file.size, (maxSizeForImage - newImageSize + file.size));
            if ((newImageSize + file.size) > maxSizeForImage) {
                sizeError = 'Total image size should not exceed 10MB.';
                isInvalid = true;
            } else {
                newImageSize += file.size; // Add valid file size to total
            }
        }

        return isInvalid;
    });

    if (invalidFiles.length > 0) {
        if (typeError) {
            toast.error(typeError);
        } else if (sizeError) {
            toast.error(sizeError);
        }
    } else {
        if (selectedMediaType === 1) {
            const files = Array.from(event.target.files);
            const mediaList = files.map(file => ({
                mediaTypeId: selectedMediaType,
                mediaFiles: file
            }));
            fileInputRef.current.value = null;
            const imagePromises = files.map(file => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => resolve(e.target.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            });
            Promise.all(imagePromises).then(images => {
                let finalList = [];
                images.forEach((image, index) => {
                    finalList.push({
                        mediaTypeId: selectedMediaType,
                        file: image,
                        mediaFiles: mediaList[index]['mediaFiles']
                    });
                });
                setSelectedImages(prevImages => [...prevImages, ...finalList]);
            });
        } else if (selectedMediaType === 2) { 
            const file = event.target.files[0];
            fileInputRef.current.value = null;
            if (file) {
                const fileUrl = URL.createObjectURL(file);
                const finalList = [{
                    mediaTypeId: selectedMediaType,
                    file: fileUrl,
                    mediaFiles: file
                }];
                setSelectedImages(prevImages => [...prevImages, ...finalList]);
            }
        }
    }
};
 

  const insertMedia = () => {
    const quill = reactQuillRef.current.getEditor();
    const length = quill.getLength();

      if(imageList?.length + selectedImages?.length){
        setImageList((prevImages) => [...prevImages,...selectedImages]);
      }
      closeImageModal();
  };

  const deleteImageFromPreview = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const removeImage= (index,media) => {

    if(!media['postMediaId']){
      //new media action
      setImageList((prevImages) => prevImages.filter((_, i) => i !== index));
    }else{
      //update media
      setDeleteConfirmationBox(true);
      setmediaIndex(index);
    }
  };

  const deleteMediaFile = () => {
    setIsLoading(true);
    let id = imageList[mediaIndex].postMediaId;
    PostServices.deletePostMedia(id).then(res=>{
      setIsLoading(false);
      toast.success(res?.message);
    }).catch(error=>{
      setIsLoading(false)
      toast.success(error?.message);
    })
    setImageList((prevImages) => prevImages.filter((_, i) => i !== mediaIndex));
    //setTempImage((prevImages) => prevImages.filter((_, i) => i !== mediaIndex));
    setDeleteConfirmationBox(false);
  }

  const deleteVideoFromPreview = (index) => {
    setSelectedVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
  };

  const toggleEmojiPicker = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };

  const onEmojiClick = (event, emojiObject) => {
    const quill = reactQuillRef.current.getEditor();
    if (quill) {
      quill.focus();
      const selection = quill.getSelection(true); 
      const cursorPosition = selection ? selection.index : quill.getLength(); 
      quill.insertText(cursorPosition, event.emoji);
      quill.setSelection(cursorPosition + event?.emoji?.length);
    }
    setIsEmojiPickerOpen(false);
  };

  const removeVideo=()=>{
    setSelectedVideos('')
  }
  const  removeVideoList=()=>{
    setVideoList('')
  }

 const cancelData=()=>{
    closeModal();
    setEditorContent('');
    setSelectedImages([]);
    setSelectedVideos([]);
    setImageList([]);
    setVideoList('')
    setconfirmationBox(false)
    setTempImage([])
    setAccessTypeValue("1")
    setGroupValue(groupId||"1")
    setAccessTypeName('public')
    setTempAccesTypeName("public")
    setTempAccesTypeid('1')
    setPostToUser([])
    setSelectedTag([])
    setSelectedValue([])
  }
  const closePopUp=()=>{
    setconfirmationBox(true)
  }

  const confrimBox=()=>{
    setconfirmationBox(false)
  }

  const closeDeleteConfirmation=()=>{
    setDeleteConfirmationBox(false);
  }


    const uploadFile = async () => {
        setIsLoading(true)
        let filesToUpload = imageList.filter(mediObj =>{return !mediObj['postMediaId']})
        if(!editPost){
          //insert flow
            const uploadPromises = filesToUpload.map(async (item) => {
              const formData = new FormData();
              formData.append('file', item?.mediaFiles);
              try {
                const res = await CommonServices.uploadFile(formData);
                //setIsLoading(false)
                return { mediaTypeId: item.mediaTypeId, mediaUrl: res?.fileLocation };
              } catch (error) {
                setIsLoading(false)
                return null;
              }
            });
            const results = await Promise.all(uploadPromises);
            const successfulUploads = results.filter(result => result !== null);
            addPost(successfulUploads);
        }else{
          //update flow
            const uploadPromises = filesToUpload.map(async (item) => {
              const formData = new FormData();
              formData.append('file', item?.mediaFiles);
              try {
                const res = await CommonServices.uploadFile(formData);
                //setIsLoading(false)
                return { mediaTypeId: item.mediaTypeId, mediaUrl: res?.fileLocation };
              } catch (error) {
                setIsLoading(false)
                return null;
              }
            });
            const results = await Promise.all(uploadPromises);
            const successfulUploads = results.filter(result => result !== null);
            updatePostContent(successfulUploads);
        }
    }
    const stripHtmlTags = (input) => input.replace(/<\/?[^>]+(>|$)/g, "");


    const addPost=(value)=>{
      const hasMedia = value?.length > 0;
      const hasText = !!editorContent?.trim();
      const sanitizedContent = stripHtmlTags(editorContent);
      if (!hasMedia && !hasText) {
          setIsLoading(false);
          toast.error("Oops! It looks like you’re trying to send a blank post. Please add some content to share");
          return;
      }
      let data={
          postVisibility:Number(accessTypeValue),
          groupId:isGroupData ? groupIdGlobal :Number(groupValue),
          postMedia: value ? value : [],
          postText: sanitizedContent,
          entityType: entityType,
          ids:postToUser,
          tagIds:selectedTag
        }
        setIsLoading(true)
        PostServices.addPost(data).then(res=>{
          setPostUpdated(true)
          setIsLoading(false)
          toast.success(res?.message);
          //updatePostList();
          cancelData();
        }).catch(error=>{
          setIsLoading(false)
          toast.error(error?.message);
        })
    }

    const updatePost = (mediaFiles) => {
      let data={
          optType: 2,
          postId: getPostDetails.postId,
          postMedia: mediaFiles,
          postText: editorContent,
          tagIds:selectedTag
      }
      setIsLoading(true);
      PostServices.updatePost(data).then(res=>{
        updatePostTag(mediaFiles)
        setIsLoading(false);
        // setPostUpdated(true)
        toast.success(res?.message);
        //updatePostList();
        cancelData();
      }).catch(error=>{
        setIsLoading(false)
        toast.error(error?.message)
      })
    }

    const updatePostContent = (media) => {
      let data={
          optType: 1,
          postId: getPostDetails.postId,
          postMedia:[],
          postText: editorContent,
          tagIds:selectedTag
      }
      setIsLoading(true);
      PostServices.updatePost(data).then(res=>{
        if(media && media.length > 0)
            updatePost(media)
        else{
          updatePostTag(media)
          // setPostUpdated(true)
          setIsLoading(false);
          toast.success(res?.message);
          cancelData();
        }
      }).catch(error=>{
        setIsLoading(false)
        toast.error(error?.message)
      })
    }
    const updatePostTag = (mediaFiles) => {
      let data={
          optType: 6,
          postId: getPostDetails.postId,
          postMedia: mediaFiles,
          postText: editorContent,
          tagIds:selectedTag
      }
      setIsLoading(true);
      PostServices.updatePost(data).then(res=>{
        setIsLoading(false);
        setPostUpdated(true)
        cancelData();
      }).catch(error=>{
        setIsLoading(false)
        toast.error(error?.message)
      })
    }

     const getAsccessTYpeValue=(value, name)=>{
      setEntityType(0)
      setTempAccesTypeid(value)
      setTempAccesTypeName(name)
      setPostToUser([])
        if (value === "3") {  
          setGroupType(false)
          setUsersListModalOpen(true);  
          setEntityType(1)
          setIsDropdownOpen(false) 
      }
      if (value === "4") { 
        setGroupType(true)
        setUsersListModalOpen(true); 
        setEntityType(2)
        setIsDropdownOpen(false) 
    }
    }


  const handleClickOutside = (event) => {
    // if(dropDownRef){
    //     if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
    //         setIsDropdownOpen(false) 
    //     }
    // }
  }
  document.addEventListener('mousedown', handleClickOutside);



  const showUsersListModalhandler = () => {
    setUsersListModalOpen(true);
  };
  
  const hideUsersListModalhandler = (value, data) => {
    if(value){
      setUsersListModalOpen(false);
      setPostToUser(data)
      setAccessTypeValue(tempAccesTypeid)
      setAccessTypeName(tempAccesTypeName)
      setIsDropdownOpen(false) 
    }else{
      setUsersListModalOpen(false);
    }
    
  };

  const handleChange = (e) => {
    const data=Array.isArray(e) ? e.map(x => x.value) : []
    setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    setSelectedTag(data);
  }

  return (
    <>
      {isModalOpen && (
        <Modal show={isModalOpen}  size={isImageModalOpen?'lg':'xl'}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
          <Modal.Body>
         <div>
           <div className={classes['header-align']}>
              <div className={classes["user_info"]}>
              <img
                src={profilePic ? profilePic : blobURL + userInfo?.ProfileImgPath}
                alt="User"
                className={classes["user_image"]}
              />
               
                <div className="mx-2">
                <span className={classes["user_name"]}>{username}</span>
                <div>
                {!editPost && (
                  <div>
                  <span className={classes["user_name"]}>To: {accessTypeName}</span>
                <img
                src={dropdownIcon}
                alt="Dropdown"
                className={isDropdownOpen ? classes['dropdown-arrow'] : classes["dropdown_icon"]}
                onClick={toggleDropdown}
              />
              </div>
             
                )}
                 </div>
                </div>
  

                {isDropdownOpen && (
                  <div ref={dropDownRef} className={classes["dropdown_menu"]}>
                    <div className={classes['padding-div']}>
                      {accessType.map(item=>(
                      <div className={classes['input-position']}>
                      <input name="access_type"  type="radio" onChange={()=>{getAsccessTYpeValue(item?.id, item?.name)}} value={accessTypeValue}
                      checked={tempAccesTypeid===item?.id}></input>
                      <label for={item?.name}>{item?.name}</label>
                     </div>
                    )) }
                    {/* <p>Groups</p>
                    {groupData.map(item=>(
                      <div className={classes['input-position']}>
                      <input name='groups_data' type="checkbox" onChange={()=>{handleCheckboxChange(item?.id)}} value={groupValue}
                     ></input>
                      <label for={item?.name}>{item?.name}</label>
                     </div>
                    )) } */}
                    </div>
                    <div className="mx-4 mb-4 mt-4">
                      <button onClick={getTypeName} className="primary-button w-100">Done</button>
                      </div>
                  </div>
                )}
              </div>
              <div>
              <img
                src={crossWhite}
                alt="Close"
                className={classes["close_icon"]}
                onClick={closePopUp}
              />
              </div>
              </div>
              <div className={classes['content-box']}>
            <div className={classes["textarea_container"]}>
              <ReactQuill
                value={editorContent}
                onChange={setEditorContent}
                ref={reactQuillRef}
                modules={{
                  toolbar: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'}, 
                    //  {'indent': '-1'}, {'indent': '+1'}
                    ],
                    // ['link', 'image', 'video'],
                    ['clean']
                  ],
                }}
                formats={[
                  'header', 'font', 'size',
                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                  'list', 'bullet', 'indent',
                  'link', 'image', 'video'
                ]}
                placeholder="Create a post..."
              />
              {/* <div className={classes['tag-align']}>
                <p>Tags: </p>
                <div className="w-100">
                  <Select className="dropdown" placeholder="Please select tags"
                  value={tagList.filter(obj => selectedValue.includes(obj.value))} 
                  options={tagList} onChange={handleChange} isMulti isClearable />
              </div>
                
              </div> */}
              
              <div className={classes['tag-align']}>
  {/* <p>Tags: </p> */}
  <div className={classes["tag-input-container"]}>
    <div className={classes["tag-input-area"]}>
      {selectedTag.length === 0 && (
        <span className={classes["placeholder-text"]}>Please select Tags</span>
      )}
      {tagList
        .filter(tag => selectedTag.includes(tag.value))
        .map((tag, index) => (
          <div key={index} className={classes["tag-pill"]}>
            {tag.label}
            <span 
              className={classes["remove-tag"]} 
              onClick={() => {
                const newSelected = selectedTag.filter(t => t !== tag.value);
                setSelectedTag(newSelected);
                setSelectedValue(newSelected);
              }}
            >
              ×
            </span>  
          </div>
        ))}
    </div>
    
    <div className={classes["suggested-skills-list"]}>
      {tagList
        .filter(tag => !selectedTag.includes(tag.value))
        .map((tag, index) => (
          <div
            key={index}
            className={classes["suggested-skill"]}
            onClick={() => {
              setSelectedTag([...selectedTag, tag.value]);
              setSelectedValue([...selectedTag, tag.value]); 
            }}
          >
            {tag.label}
          </div>
        ))}
    </div>
  </div>
</div>
              {imageList?.length > 0 && (
                <div className={classes["image_preview"]}>
                  {imageList.map((image, index) => (
                     <div key={index} className={classes["image_container"]}>
                      {image['mediaTypeId']===1 &&  (<img src={editPost? blobURL+image['file'] : image['file']} alt={`Preview ${index}`}/>)}
                      {image['mediaTypeId']===2 &&  (<video  controls width="300px" src={editPost? blobURL+image['file'] : image['file']}> </video>)}
                      <img
                        src={crossWhite}
                        alt="Delete"
                        className={classes["delete_icon"]}
                        onClick={() => removeImage(index,image)}
                      />
                    {/* {image['mediaTypeId']==2 && (<div className={classes["video_container"]}>
                        <video  controls width="300px" src={image['file']}> </video>
                        <img
                            src={crossWhite}
                            alt="Delete"
                            className={classes["delete_icon"]}
                            onClick={() => removeImage(index)}
                          />
                        </div>)} */}
                    </div>
                  ))}
                </div>
              )}
 
             {/* {videoList && (
              <div className={classes["image_preview"]}>
                <div  className={classes["video_container"]}>
                  <video
                  controls
                  width="300px"
                  src={videoList}>
                  </video>
                  <img
                      src={crossWhite}
                      alt="Delete"
                      className={classes["delete_icon"]}
                      onClick={removeVideoList}
                    />
                  </div>
                  </div>
             )} */}
             {isEmojiPickerOpen && (
               <div className={classes["emoji_picker_wrapper"]}>
                 <img
                    src={crossWhite}
                   alt="Close"
                   className={classes["close_icon_emojis"]}
                   onClick={toggleEmojiPicker}
                  />
                 <Picker onEmojiClick={onEmojiClick} />
               </div>
             )}
            </div>
            </div>
            <div className={classes["icons_container"]}>
              <div className={classes["text_with_icon"]}>
                {/* <img title="Image Type"
                  src={textIcon}
                  alt="Text Icon"
                  className={classes["text_icon"]}
                /> */}
                {/* <span>Rewrite with AI</span> */}
              </div>
              <img
                src={dummyImageIcon}
                alt="Dummy_Image"
                title="attach image"
                className={classes["icon"]}
                onClick={() => { openImageModal('image',1) }}
              />
              <img onClick={() => { openImageModal('video',2) }} title="attach video" src={icon1} alt="Icon 1" className={classes["icon"]} />
              {/* <img src={icon3} alt="Icon 3" className={classes["icon"]} />
              <img src={icon4} alt="Icon 4" className={classes["icon"]} /> */}
              <img src={emojiIcon} alt="Emoji Icon" className={classes["icon"]} onClick={toggleEmojiPicker} title="attach emojis"/>
            </div>
            <div className={classes["cp_button"]}>
            <button onClick={closePopUp} className={classes['cancel-btn']}>Cancel</button>
            {!editPost && (
              <button onClick={uploadFile} className={classes["post_button"]}>Post</button>
            )}
            {editPost && (
              <button onClick={uploadFile} className={classes["post_button"]}>Update</button>
            )}
            </div>
          </div>
        </Modal.Body>
        </Modal>
     
      )}

      {isImageModalOpen && (
              <Modal show={isModalOpen}  size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
                <Modal.Body>
           <div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
              <div className={classes["modal_header"]}>
              <h2 className={classes['header-text']}>Select {selectedMediaType === 1 ? 'Images' : 'Videos'}</h2>
            </div>
              </div>
              <div>
              <img
              src={crossWhite}
              alt="Close"
              className={classes["close_icon"]}
              onClick={closeImageModal}
            />
              </div>

            </div>
            <div className={classes['select-content-box']}>
 
            <input ref={fileInputRef} type="file" accept={selectedMediaType === 1 ? "image/*" : "video/*"} multiple onChange={handleImageChange} />
            <div className="mt-2">
             {selectedMediaType === 1 && (
        <Alert key={`warning`} variant={`warning`}>
          <div>
            <img src={infoICon} className="mt-2" alt="Info Icon" />
            <span>Please use document name as a file name.</span>
          </div>
          <ul className="ms-2">
            <li>Acceptable file formats: JPEG, PNG, and GIF.</li>
            <li>File size must be less than 10 MB.</li>
          </ul>
        </Alert>
      )}

      {selectedMediaType === 2 && (
        <Alert key={`warning`} variant={`warning`}>
          <div>
            <img src={infoICon} className="mt-2" alt="Info Icon" />
            <span>Please use document name as a file name.</span>
          </div>
          <ul className="ms-2">
            <li>Acceptable file formats: MP4, MOV.</li>
            <li>File size must be less than 200 MB.</li>
          </ul>
        </Alert>
      )}
             </div>
            {selectedImages.length > 0 && (
              <div className={classes["image_preview"]}>
                {selectedImages.map((image, index) => (
                  <div key={index} className={classes["image_container"]}>
                    { image['mediaTypeId']==1 && (<img src={image['file']} alt={`Preview ${index}`}/>)}
                    {image['mediaTypeId']==2  && (<video controlswidth="300px" src={image['file']}></video>)}
                    <img
                      src={crossWhite}
                      alt="Delete"
                      className={classes["delete_icon"]}
                      onClick={() => deleteImageFromPreview(index)}
                    />
                  </div>
                ))}
              </div>
            )}

            {/* {selectedType==='video' && selectedVideos && (
              <div className={classes["image_preview"]}>
                <div  className={classes["video_container"]}>
                  <video
                  controls
                  width="300px"
                  src={selectedVideos}>
                  </video>
                  <img
                      src={crossWhite}
                      alt="Delete"
                      className={classes["delete_icon"]}
                      onClick={removeVideo}
                    />
                  </div>
              </div>
            )} */}
             </div>
             <div className="text-end">
             <button onClick={insertMedia} className={classes["insert_button"]}>
              Insert {selectedType === 'image' ? 'Images' : 'Videos'}
            </button>
             </div>
         
          </div>
        </Modal.Body>
        </Modal>
      )}
         {confirmationBox && (
          <ConfirmationModal onShow={confirmationBox} getConfirmation={cancelData} 
          onClose={confrimBox} btnText='Yes'  confirmationText='Are you sure you want to leave the post ? Your changes will not be saved.'></ConfirmationModal>
        )}
        {deleteConfirmationBox && (
          <ConfirmationModal onShow={deleteConfirmationBox} onClose={closeDeleteConfirmation} getConfirmation={deleteMediaFile} 
            btnText='Yes'  confirmationText='Are you sure you want to delete this media?'></ConfirmationModal>
        )}
         {usersListModalOpen && (
        <UserListModal
          onClose={hideUsersListModalhandler}
          onShow={showUsersListModalhandler}
          
          
          groupType={groupType}
          // postIdData={post?.postId}
        ></UserListModal>
      )}
        {isLoading && (
          <Loader></Loader>
        )}
        
    </>
  );
}

export default CommonPost;