import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from './ApplicationSidbar.module.css'

 const ApplicationSidebar=()=>{
    let location= useLocation();
    const navigate =useNavigate(null);
    const getRouting=(url)=>{
        navigate("/member/application/" +url)

    }
    return(
        <>
             <div className={styles['sidebar-section']}>
                
                <div onClick={()=>{getRouting('list')}} className={location.pathname==='/member/application/list'?styles['highlight']:styles['invactive']}>
                     Applications
                </div>

                <div onClick={()=>{getRouting('deferral-requests')}} className={location.pathname==='/member/application/deferral-requests'?styles['highlight']:styles['invactive']}>
                   Deferral Requests
                </div>

                <div onClick={()=>{getRouting('refund')}} className={location.pathname==='/member/application/refund'?styles['highlight']:styles['invactive']}>
                   Refund
                </div>

                <div onClick={()=>{getRouting('retrive-application')}} className={location.pathname==='/member/application/retrive-application'?styles['highlight']:styles['invactive']}>
                   Retrieve Deleted Applications
                </div>

            </div>
        </>
    )

 }
 export default  ApplicationSidebar;