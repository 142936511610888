import React, { useState } from 'react';
import styles from './AICounsellor.module.css'; // Import the CSS module
import arrow from '../../../../../../Assets/Logo/send-arrow.svg'; // Assume you have this icon file in the same directory
import fullScreen from '../../../../../../Assets/Logo/fullScreen.svg'; // Fullscreen icon
import resize from '../../../../../../Assets/Logo/resize.svg'; // Resize icon
import croseWhite from '../../../../../../Assets/Logo/crose-white.svg'; // White cross icon

const AICounsellor = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [inputMessage, setInputMessage] = useState(''); // State for input message
  const [messages, setMessages] = useState([
    { text: "Do you have the notes from class? I had to miss it so I’m going around asking everyone about it. It would help a lot if you have it.", time: "09:41am Today", sender: "opposite" },
    { text: "Sure, I can share my notes with you. Let me send them over.", time: "09:42am Today", sender: "user" },
  ]); // State for messages

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleResize = () => {
    setIsFullscreen(false);
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      // Add new message to chat messages
      setMessages([...messages, { text: inputMessage, time: new Date().toLocaleTimeString(), sender: "user" }]);
      setInputMessage(''); // Clear input field
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };


  return (
    <div className={`${styles.counsellorContainer} ${isFullscreen ? styles.fullscreen : ''}`}>
      <div className={styles.header}>
        <span>AI Counsellor:</span>
        <div className={styles.iconContainer}>
          <img
            src={resize}
            alt="Resize Icon"
            className={styles.icon}
            onClick={handleResize}
          />
          <img
            src={isFullscreen ? croseWhite : fullScreen} // Toggle between fullscreen and cross icon
            alt="Fullscreen Icon"
            className={styles.icon}
            onClick={handleFullscreenToggle}
          />
        </div>
      </div>

      {/* Chat Container */}
      <div className={styles.chatContainer}>
        {messages.map((msg, index) => (
          <div key={index} className={msg.sender === "user" ? styles.fullscreenMessageDiv : styles.fullscreenMessageUser}>
            <div className={msg.sender === "user" ? styles.chatMessage : styles.chatMessageOpposite}>
              {msg.text}
            </div>
            <span className={msg.sender === "user" ? styles.chatUpdateTimeUser : styles.chatUpdateTimeOpposite}>{msg.time}</span>
          </div>
        ))}
      </div>

      {/* Input Area */}
      <div className={styles.inputArea}>
        <input
          type="text"
          placeholder="Type Here..."
          className={styles.inputField}
          value={inputMessage}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
        />
        <button className={styles.sendButton} onClick={handleSendMessage}>
          <img src={arrow} alt="Send Icon" className={styles.iconsend} />
        </button>
      </div>
    </div>
  );
};

export default AICounsellor;
