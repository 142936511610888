import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Programs from "../../../../Common/Programs/Programs";
import styles from "./SimilarPrograms.module.css";

function SimilarPrograms({programs}) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 2000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1400 },
      items: 3,
    },
    tablet: {
      breakpoint: { max:1400, min: 940 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 940, min: 500 },
      items: 2,
    },
    small: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  // for temperary
  // const programs = [
  //   {
  //     id: 1,
  //   },
  //   {
  //     id: 2,
  //   },
  //   {
  //     id: 3,
  //   },
  //   {
  //     id: 4,
  //   },
  //   {
  //     id: 5,
  //   },
  // ];

  return (
    <div>
     
        {programs.map((level) => (
  <div className={styles["main_card"]} key={level.ProgramLevelId}>
     <Carousel
        responsive={responsive}
        // autoPlaySpeed={1000}
        // transitionDuration={1000}
        // transitionDelay={500}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        infinite={true}
        keyBoardControl={true}
        stagePadding = {"5"}
        itemClass={styles.carouselItem}
      >
    {level.programs.map((program) => (
      <div className={styles["main_card"]} key={program.ProgramId}>
        <Programs key={program.ProgramId} program={program} />
      </div>
    ))}
    </Carousel>
  </div>
))}
           {/* {programs.map((level) => (
            <div key={level.ProgramLevelId}>
            <h3>{level.ProgramLevel}</h3>
            <div>
                        {level.programs.map((program) => (
            <Programs key={program.ProgramId} program={program} />
                        ))}
            </div>
            </div>
            ))} */}
     {/* {programs.map((level) => (
    <div className={styles["main_card"]}>
      {level.programs.map((program) => (
        <Programs key={program.ProgramId} program={program} />
      ))}
    </div>
))} */}
    </div>
  );
}

export default SimilarPrograms;
