import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import classes from "./UserListModal.module.css";
import corssIcon from "../../../../Assets/Logo/cross-icon.svg";
import nameInfoImage from "../../../../Assets/Images/profile_avatar.png";
import searchIcon from "../../../../Assets/Logo/search-icon-2.svg";
import Loader from "../../../GlobleShared/Loader/Loader";
import ConnectionService from "../../../../Services/ConnectionService";
import PostService from "../../../../Services/PostServices";
import { toast } from "react-toastify";
import GroupService from "../../../../Services/GroupService";

function UserListModal({ onShow, onClose, groupType }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [paginated, setPaginated] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const initialized = useRef(false);
  const blobUrl = process.env.REACT_APP_URL_PATH;

  const [profilesData, setProfilesData] = useState([]);
  const [groupData, setGroupData] = useState([]); // New state for group data
  // const [postId, setPostId] = useState(postIdData);
  const [postShare, setPostShare] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchInput1, setSearchInput1] = useState("");

  const handleChange = (e) => {
    setSearchInput(e.target.value);
    // getConnectionList(page);
  };

  const handleGroupChange = (e) => {
    setSearchInput1(e.target.value);
    // getConnectionList(page);
  };

  const handleSearch = (e) => {
    getConnectionList(page);
  };

  // call an api to share post
  // const createPostShare = async () => {
  //   const SharePostData = {
  //     // postId: postId,
  //     postShare: postShare,
  //     shareCount: postShare?.length,
  //   };

  //   setIsLoading(true);
  //   onClose();
  //   PostService.sharePost(SharePostData)
  //     .then((res) => {
  //       setIsLoading(false);

  //       toast.success(res?.message);
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       toast.success(error?.message);
  //     });

  //   console.log(SharePostData);
  // };

  const closePopup = (flag) => {
    if (postShare.length === 0) {
      toast.error("Please select atleast on user or group.");
      return;
    } else {
      onClose(flag, postShare);
    }
  };

  const getUserGroupList = () => {
    setIsLoading(true);
    GroupService.getUserGroupList()
      .then((res) => {
        setGroupData(res || []);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  // i will call this funciton based on grouptype when we have apis to call grouplist and userlist
  const getConnectionList = (pageValue) => {
    let data = {
      limit: limit,
      page: pageValue,
      paginated: paginated,
      searchTerm: searchInput,
    };
    setIsLoading(true);
    ConnectionService.getConnectionList(data)
      .then((res) => {
        setProfilesData([...res?.list]);
        setTotalCount(res?.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleCheckboxChange = (id) => {
    setPostShare((prevPostShare) => {
      const existingEntry = prevPostShare.find((item) => item === id);

      if (existingEntry) {
        return prevPostShare.filter((item) => item !== id);
      } else {
        return [...prevPostShare, id];
      }
      // console.log("postShare", postShare);
    });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getConnectionList(page);
        getUserGroupList(); // Fetch user group list when the component mounts if groupType is true
    }
  }, [searchInput]);

  return (
    <div>
      <Modal
        show={onShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={classes["modal-custom"]} // Apply the custom class here
      >
        <Modal.Body>
          <div className={classes["cross-icon-position"]}>
            <div className={classes["heading"]}>
              {groupType ? "Select Groups" : "Select Users"}
            </div>
            <img
              title="Close"
              className={classes["corss-icon"]}
              src={corssIcon}
              alt=""
              onClick={() => {
                onClose(false);
              }}
            ></img>
          </div>

          {/* main-container */}

          <div className={classes["modal-body-data"]}>
            <div className={classes.main_container}>
              {!groupType && (
                <>
                  {/*for user list  */}
                  {profilesData.length > 0 && (
                    <div className={classes.card}>
                      <div className={`${classes["form-section"]}`}>
                        <div className={classes["invite-section"]}>
                          <div className={classes["heading"]}>Users</div>

                          <div className={`${classes["search_input_content"]}`}>
                            <input
                              type="text"
                              placeholder="Search..."
                              className={`${classes["search-input"]}`}
                              value={searchInput}
                              onChange={handleChange}
                            />
                            <img
                              src={searchIcon}
                              alt="Search"
                              className={`${classes["search-icon"]}`}
                              onClick={handleSearch}
                            />
                          </div>

                          <div className={`${classes["list-content"]}`}>
                            <div className={`${classes["invite-list"]}`}>
                              {profilesData.map((profile, index) => (
                                <div
                                  key={profile?.receiverUserId + "_" + index}
                                  className={classes["name-item"]}
                                >
                                  <div className={classes["name-info"]}>
                                    <img
                                      src={
                                        profile?.profileImage
                                          ? profile?.profileImage
                                          : nameInfoImage
                                      }
                                      alt={profile?.displayName}
                                      className={classes["name-image"]}
                                    />
                                    <span className={classes["name-text"]}>
                                      {profile?.displayName}
                                    </span>
                                  </div>
                                  <div
                                    className={classes["checkbox-container"]}
                                  >
                                    <input
                                      className={classes["checkbox-input"]}
                                      type="checkbox"
                                      checked={postShare.some(
                                        (item) =>
                                          item === profile?.receiverUserId
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          profile?.receiverUserId
                                        )
                                      }
                                      // onChange={() => handleCheckboxChange(profile?.userConnectionId, 2)}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {profilesData.length == 0 && (
                    <div className="text-center">
                      <h4> User Not Found</h4>
                    </div>
                  )}
                </>
              )}

              {groupType && (
                <>
                  <div className={classes.card}>
                    <div className={`${classes["form-section"]}`}>
                      <div className={classes["invite-section"]}>
                        <div className={classes["heading"]}>Groups</div>

                        <div className={`${classes["search_input_content"]}`}>
                          <input
                            type="text"
                            placeholder="Search..."
                            className={`${classes["search-input"]}`}
                            value={searchInput1}
                            onChange={handleGroupChange}
                          />
                          <img
                            src={searchIcon}
                            alt="Search"
                            className={`${classes["search-icon"]}`}
                            onClick={handleSearch}
                          />
                        </div>

                        <div className={`${classes["list-content"]}`}>
                          <div className={`${classes["invite-list"]}`}>
                            {groupData.map((group, index) => (
                              <div
                                key={group?.GroupID}
                                className={classes["name-item"]}
                              >
                                <div className={classes["name-info"]}>
                                  <img
                                    src={
                                      group?.GroupImage
                                        ? `${blobUrl}${group?.GroupImage}`
                                        : nameInfoImage
                                    }
                                    alt={group?.GroupName}
                                    className={classes["name-image"]}
                                  />
                                  <span className={classes["name-text"]}>
                                    {group?.GroupName}
                                  </span>
                                </div>
                                <div className={classes["checkbox-container"]}>
                                  <input
                                    className={classes["checkbox-input"]}
                                    type="checkbox"
                                    checked={postShare.some(
                                      (item) => item === group?.GroupID
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(group?.GroupID)
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {((profilesData.length > 0 && !groupType) || groupType) && (
                <div className={classes["share-section"]}>
                  <button
                    onClick={() => {
                      closePopup(true);
                    }}
                    className={`${classes["share-btn"]}`}
                  >
                    OK
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {isLoading && <Loader></Loader>}
    </div>
  );
}

export default UserListModal;
