import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import classes from "./Pagination.module.css";

function Paginations({ currentPage, totalPages, onPageChange }) {
  const maxVisiblePages = 5;

  const handleShift = (shiftBy) => {
    const newPage = currentPage + shiftBy;
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const getPageItems = () => {
    const items = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(currentPage + 2, totalPages);

    if (currentPage <= 2) {
      endPage = Math.min(maxVisiblePages, totalPages);
    } else if (currentPage + 2 >= totalPages) {
      startPage = Math.max(totalPages - maxVisiblePages + 1, 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <div className={classes["pagination-container"]}>
      <Pagination>
     
        <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
        
       
        <Pagination.Prev onClick={() => handleShift(-1)} disabled={currentPage === 1} />
        
      
        {currentPage > 3 && (
          <>
            <Pagination.Item onClick={() => onPageChange(1)}>1</Pagination.Item>
            <Pagination.Ellipsis onClick={() => handleShift(-2)} />
          </>
        )}
        
        {/* Main Page Items */}
        {getPageItems()}

     
        {currentPage < totalPages - 2 && (
          <>
            <Pagination.Ellipsis onClick={() => handleShift(2)} />
            <Pagination.Item onClick={() => onPageChange(totalPages)}>{totalPages}</Pagination.Item>
          </>
        )}

       
        <Pagination.Next onClick={() => handleShift(1)} disabled={currentPage === totalPages} />
        
        
        <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    </div>
  );
}

export default Paginations;
