import React, { useState, useEffect } from "react";
import classes from "./Section.module.css";
import { Link } from "react-router-dom";
import vectorLeftIcon from "../../../../../../Assets/Logo/Vector_left-icon.svg";
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import useLocalStorage from "../../../../../GlobleShared/CustomHooks/useLocalStorage";

function DeactivateAccount({ onBack }) {
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
    confirmPassword: null,
  });

  const [passwordValidationErrors, setPasswordValidationErrors] = useState({
    minLength: false,
    specialChar: false,
    number: false,
    uppercase: false,
  });

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [visible, setVisible] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [userDetail] = useLocalStorage("userDetail");

  useEffect(() => {
    // Retrieve email from localStorage and set it in the form state
    if (userDetail?.email) {
      setForm((prevForm) => ({
        ...prevForm,
        email: userDetail.email,
      }));
    }
  }, [userDetail]);

  const validateField = (name, value, refValue) => {
    let errorMsg = null;
    const trimmedValue = value.trim();

    switch (name) {
      case "email":
        if (!trimmedValue) errorMsg = "Please enter your email";
        break;
      case "password":
        if (!value) errorMsg = "Please enter a password";
        else {
          const passwordErrors = validatePassword(value);
          if (Object.values(passwordErrors).includes(false)) {
            errorMsg = "Password does not meet the requirements";
          }
        }
        break;
      case "confirmPassword":
        if (!value) errorMsg = "Please enter your password";
        else if (refValue && value !== refValue)
          errorMsg = "Password and confirm password do not match";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  const validatePassword = (password) => {
    const errors = {};
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;

    errors.minLength = password.length >= minLength;
    errors.specialChar = specialCharRegex.test(password);
    errors.number = numberRegex.test(password);
    errors.uppercase = uppercaseRegex.test(password);

    return errors;
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, ""); 
    setForm((prevForm) => ({
      ...prevForm,
      [name]: sanitizedValue,
    }));

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: validateField(
        name,
        sanitizedValue,
        name === "confirmPassword" ? form.password : form.confirmPassword
      ),
    }));

    if (name === "password" || name === "confirmPassword") {
      const passwordErrors = validatePassword(
        name === "password" ? sanitizedValue : form.password
      );
      setPasswordValidationErrors(passwordErrors);

      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        password: validateField(
          "password",
          name === "password" ? sanitizedValue : form.password,
          name === "password" ? form.confirmPassword : sanitizedValue
        ),
        confirmPassword: validateField(
          "confirmPassword",
          name === "confirmPassword" ? sanitizedValue : form.confirmPassword,
          name === "confirmPassword" ? form.password : sanitizedValue
        ),
      }));
    }
  };

  const viewPass = () => {
    setVisible(!visible);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const viewConfirmPass = () => {
    setVisibleConfirm(!visibleConfirm);
    setConfirmPasswordType(
      confirmPasswordType === "password" ? "text" : "password"
    );
  };

  const handleDeactivateClick = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirmation = () => {
    // Add your deactivation logic here
    setShowConfirmationModal(false);
  };

  const closeModalHandler = () => {
    setShowConfirmationModal(false);
  };

  return (
    <div className={`${classes["main-container"]}`}>
      <div className={`${classes["container-block"]} row`}>
        <div className={` ${classes["container-left-section"]} col-8 p-0`}>
          <div className="d-flex justify-content-between align-items-center">
            <div onClick={onBack} className={`${classes["prev-icon"]}`}>
              <img src={vectorLeftIcon} alt="Back" />
            </div>
          </div>
          <div className={classes["container-left-section-content"]}>
            <div>
              <div className={`pt-2 ${classes["changePasswordText"]}`}>
                <b>Deactivate Account</b>
              </div>
              <div className={`pt-2`}>
                Would you like to deactivate your account?
              </div>
              <div></div>
            </div>
            <form>
              <div className="form-group mb-2 pt-4 col-8">
                <label>
                  <b>Type your email:</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type email"
                  name="email"
                  value={form.email}
                  onChange={changeHandler}
                  required
                  disabled
                />
                {formErrors.email && (
                  <span className={classes.error}>{formErrors.email}</span>
                )}
              </div>
              <div className="form-group mb-2 col-8">
                <label>
                  <b>Type your password:</b>
                </label>
                <div className={classes["input-with-icon"]}>
                  <input
                    type={passwordType}
                    className="form-control"
                    placeholder="Type Password..."
                    name="password"
                    value={form.password}
                    onChange={changeHandler}
                    required
                    autoComplete="new-password"
                  />
                  {form.password && (
                    <div className={classes["icon-container"]}>
                      <span className={classes["eyeicon"]} onClick={viewPass}>
                        <i
                          className={`fa ${
                            visible ? "fa-eye" : "fa-eye-slash"
                          }`}
                        />
                      </span>
                    </div>
                  )}
                </div>
                {formErrors.password && (
                  <span className={classes.error}>{formErrors.password}</span>
                )}
              </div>
              <div className="form-group mb-2 col-8 ">
                <label>
                  <b>Re-type your password:</b>
                </label>
                <div className={classes["input-with-icon"]}>
                  <input
                    type={confirmPasswordType}
                    className="form-control"
                    placeholder="Re-Type Password..."
                    name="confirmPassword"
                    value={form.confirmPassword}
                    onChange={changeHandler}
                    required
                    autoComplete="new-password"
                  />
                  {form.confirmPassword && (
                    <div className={classes["icon-container"]}>
                      <span
                        className={classes["eyeicon"]}
                        onClick={viewConfirmPass}
                      >
                        <i
                          className={`fa ${
                            visibleConfirm ? "fa-eye" : "fa-eye-slash"
                          }`}
                        />
                      </span>
                    </div>
                  )}
                </div>
                {formErrors.confirmPassword && (
                  <span className={classes.error}>
                    {formErrors.confirmPassword}
                  </span>
                )}
              </div>
              {/* <div className={classes["password-validation"]}>
                <p className={classes["validation-heading"]}>
                  Password must contain:
                </p>
                <ul className={classes["validation-item"]}>
                  <li
                    style={{
                      color: passwordValidationErrors.minLength
                        ? "green"
                        : "red",
                    }}
                  >
                    <div className="row">
                      <span className="col-9">At least 8 characters</span>
                      <span className="col-3">
                        <i
                          className={`fa ${
                            passwordValidationErrors.minLength
                              ? "fa-check"
                              : "fa-close"
                          }`}
                        />
                      </span>
                    </div>
                  </li>
                  <li
                    style={{
                      color: passwordValidationErrors.specialChar
                        ? "green"
                        : "red",
                    }}
                  >
                    <div className="row">
                      <span className="col-9">
                        At least 1 special character
                      </span>
                      <span className="col-3">
                        <i
                          className={`fa ${
                            passwordValidationErrors.specialChar
                              ? "fa-check"
                              : "fa-close"
                          }`}
                        />
                      </span>
                    </div>
                  </li>
                  <li
                    style={{
                      color: passwordValidationErrors.number ? "green" : "red",
                    }}
                  >
                    <div className="row">
                      <span className="col-9">At least 1 number</span>
                      <span className="col-3">
                        <i
                          className={`fa ${
                            passwordValidationErrors.number
                              ? "fa-check"
                              : "fa-close"
                          }`}
                        />
                      </span>
                    </div>
                  </li>
                  <li
                    style={{
                      color: passwordValidationErrors.uppercase
                        ? "green"
                        : "red",
                    }}
                  >
                    <div className="row">
                      <span className="col-9">At least 1 uppercase letter</span>
                      <span className="col-3">
                        <i
                          className={`fa ${
                            passwordValidationErrors.uppercase
                              ? "fa-check"
                              : "fa-close"
                          }`}
                        />
                      </span>
                    </div>
                  </li>
                </ul>
              </div> */}
            </form>
          </div>
        </div>
        <div className={`${classes["container-right-section"]} col-4`}>
          <div className={`pt-4 mt-4 d-flex justify-content-center`}>
            <div>
              <button
                className={`${classes["btnColorCustom"]}`}
                disabled={
                  !form.email ||
                  !form.password ||
                  !form.confirmPassword ||
                  form.password !== form.confirmPassword ||
                  Object.keys(formErrors).some((key) => formErrors[key])
                }
                onClick={handleDeactivateClick}
              >
                Deactivate
              </button>
              <div className="mt-2 text-center">
                {/* <Link className={`${classes["removeLinkDecoration"]}`}>
                  Cancel
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          confirmationText="Are you sure, you want to deactivate your account?"
          onShow={showConfirmationModal}
          onClose={closeModalHandler}
          getConfirmation={handleConfirmation}
          btnText="Deactivate"
        />
      )}
    </div>
  );
}

export default DeactivateAccount;