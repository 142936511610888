
import React from "react";
import { Outlet } from "react-router-dom";
import styles from './Application.module.css'
import ApplicationSidebar from "./ApplicationSidebar/ApplicationSidebar";

function Applications(){
    return(
        <>
        <div className={`${styles['main-application-section']}`}>
            <div className={styles['side-bar']}>
                <ApplicationSidebar></ApplicationSidebar>
            </div>
            <div className={`${styles['application-area']} `}>
               <Outlet />
            </div>

        </div>
      
        </>
    )
}
export default Applications;