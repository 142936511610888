import React, { useState, useEffect } from "react";
import classes from "./Section.module.css";
import { Link } from "react-router-dom";
import ApiService from "../../../../../../Services/APIService";
import { toast } from "react-toastify";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import vectorLeftIcon from "../../../../../../Assets/Logo/Vector_left-icon.svg";
import { useBehaviorSubject } from "../../../../../GlobleShared/BehaviorSubject/BehaviorSubject";

function ChangePassword({ onBack }) {
  const [form, setForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [touchedFields, setTouchedFields] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
  });

  const [passwordValidationErrors, setPasswordValidationErrors] = useState({
    minLength: false,
    specialChar: false,
    number: false,
    uppercase: false,
  });

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [visible, setVisible] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useBehaviorSubject();

  const viewCurrentPass = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };
  
  const viewNewPass = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };
  
  const viewConfirmPass = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validateField = (name, value, refValue) => {
    let errorMsg = null;
    const trimmedValue = value.trim();

    switch (name) {
      case "currentPassword":
        if (!trimmedValue) errorMsg = "Please enter your current password";
        break;
      case "newPassword":
        if (!value) errorMsg = "Please enter a new password";
        else {
          const passwordErrors = validatePassword(value);
          if (Object.values(passwordErrors).includes(false)) {
            errorMsg = "";
          }
        }
        break;
      case "confirmPassword":
        if (!value) errorMsg = "Please enter your password";
        else if (refValue && value !== refValue)
          errorMsg = "Password and confirm password do not match";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  const validatePassword = (password) => {
    const errors = {};
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;

    errors.minLength = password.length >= minLength;
    errors.specialChar = specialCharRegex.test(password);
    errors.number = numberRegex.test(password);
    errors.uppercase = uppercaseRegex.test(password);

    return errors;
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, ""); 

    setForm((prevForm) => ({
      ...prevForm,
      [name]: sanitizedValue,
    }));

    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [name]: true,
    }));

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: validateField(
        name,
        sanitizedValue,
        name === "confirmPassword" ? form.newPassword : form.confirmPassword
      ),
    }));

    if (name === "newPassword" || name === "confirmPassword") {
      const passwordErrors = validatePassword(
        name === "newPassword" ? sanitizedValue : form.newPassword
      );
      setPasswordValidationErrors(passwordErrors);

      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        newPassword: validateField(
          "newPassword",
          name === "newPassword" ? sanitizedValue : form.newPassword,
          name === "newPassword" ? form.confirmPassword : sanitizedValue
        ),
        confirmPassword: validateField(
          "confirmPassword",
          name === "confirmPassword" ? sanitizedValue : form.confirmPassword,
          name === "confirmPassword" ? form.newPassword : sanitizedValue
        ),
      }));
    }
  };

  const viewPass = () => {
    setVisible(!visible);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  // const viewConfirmPass = () => {
  //   setVisibleConfirm(!visibleConfirm);
  //   setConfirmPasswordType(
  //     confirmPasswordType === "password" ? "text" : "password"
  //   );
  // };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsLoading(true);

    try {
      const response = await ApiService.changePassword({
        currentPassword: form.currentPassword,
        newPassword: form.newPassword,
      });
      toast.success(response.message); // Show success message
      if (response.status === 200 || response.message === "Your password has been updated successfully.") {
        logout(); // Call logout function if password update is successful
      }
    } catch (error) {
      toast.error(error.message || "Failed to update your password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
  <div className={`${classes["main-container"]} `}>
    <form onSubmit={handleSubmit}>
      <div className={`${classes["container-block"]} row`}>
        <div className={`${classes["container-left-section"]} col-8 p-0`}>
          <div className="d-flex justify-content-between align-items-center">
            <div onClick={onBack} className={`${classes["prev-icon"]}`}>
              <img src={vectorLeftIcon} alt="Back" />
            </div>
          </div>
          <div className={classes["container-left-section-content"]}>
            <div>
              <div className={`pt-2 ${classes["changePasswordText"]}`}>
                <b>Change Password</b>
              </div>
              {/* <div className={`pt-2`}>
                Create a new password that is at least 8 characters long
              </div> */}
              <div></div>
            </div>
            <div className="form-group mb-2 pt-4 col-8">
              <label>
                <b>Type your Current Password:</b>
              </label>
              <div className={classes["input-with-icon"]}>
                <input
                  type={currentPasswordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Type your Current Password"
                  name="currentPassword"
                  value={form.currentPassword}
                  onChange={changeHandler}
                  required
                />
                {form.currentPassword && (
                  <div className={classes["icon-container"]}>
                    <span className={classes["eyeicon"]} onClick={viewCurrentPass}>
                      <i
                        className={`fa ${
                          currentPasswordVisible ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </span>
                  </div>
                )}
              </div>
             {touchedFields.currentPassword && formErrors.currentPassword && (
                <span className={classes.error}>
                  {formErrors.currentPassword}
                </span>
              )}
            </div>
            <div className="form-group mb-2 col-8">
              <label>
                <b>Type your New Password:</b>
              </label>
              <div className={classes["input-with-icon"]}>
                <input
                   type={newPasswordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Type your new Password"
                  name="newPassword"
                  value={form.newPassword}
                  onChange={changeHandler}
                  required
                />
                {form.newPassword && (
                  <div className={classes["icon-container"]}>
                    <span className={classes["eyeicon"]} onClick={viewNewPass}>
                      <i
                        className={`fa ${
                          newPasswordVisible ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </span>
                  </div>
                )}
              </div>
              {touchedFields.newPassword && formErrors.newPassword && (
                <span className={classes.error}>
                  {formErrors.newPassword}
                </span>
              )}
            </div>
            <div className="form-group mb-2 col-8">
              <label>
                <b>Re-Type your new Password:</b>
              </label>
              <div className={classes["input-with-icon"]}>
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Re-Type your new Password"
                  name="confirmPassword"
                  value={form.confirmPassword}
                  onChange={changeHandler}
                  required
                />
                {form.confirmPassword && (
                  <div className={classes["icon-container"]}>
                    <span
                      className={classes["eyeicon"]}
                      onClick={viewConfirmPass}
                    >
                      <i
                        className={`fa ${
                          confirmPasswordVisible ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </span>
                  </div>
                )}
              </div>
              {touchedFields.confirmPassword && formErrors.confirmPassword && (
                <span className={classes.error}>
                  {formErrors.confirmPassword}
                </span>
              )}
            </div>
            <div className={classes["password-validation"]}>
              <p className={classes["validation-heading"]}>
                Password must contain:
              </p>
              <ul className={classes["validation-item"]}>
                <li
                  style={{
                    color: passwordValidationErrors.minLength
                      ? "green"
                      : "red",
                  }}
                >
                  <div className="row">
                    <span className="col-9">At least 8 characters</span>
                    <span className="col-3">
                      <i
                        className={`fa ${
                          passwordValidationErrors.minLength
                            ? "fa-check"
                            : "fa-close"
                        }`}
                      />
                    </span>
                  </div>
                </li>
                <li
                  style={{
                    color: passwordValidationErrors.specialChar
                      ? "green"
                      : "red",
                  }}
                >
                  <div className="row">
                    <span className="col-9">
                      At least 1 special character
                    </span>
                    <span className="col-3">
                      <i
                        className={`fa ${
                          passwordValidationErrors.specialChar
                            ? "fa-check"
                            : "fa-close"
                        }`}
                      />
                    </span>
                  </div>
                </li>
                <li
                  style={{
                    color: passwordValidationErrors.number ? "green" : "red",
                  }}
                >
                  <div className="row">
                    <span className="col-9">At least 1 number</span>
                    <span className="col-3">
                      <i
                        className={`fa ${
                          passwordValidationErrors.number
                            ? "fa-check"
                            : "fa-close"
                        }`}
                      />
                    </span>
                  </div>
                </li>
                <li
                  style={{
                    color: passwordValidationErrors.uppercase
                      ? "green"
                      : "red",
                  }}
                >
                  <div className="row">
                    <span className="col-9">At least 1 uppercase letter</span>
                    <span className="col-3">
                      <i
                        className={`fa ${
                          passwordValidationErrors.uppercase
                            ? "fa-check"
                            : "fa-close"
                        }`}
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={` ${classes["container-right-section"]} col-4`}>
          <div className={`pt-4 mt-4 d-flex justify-content-center`}>
            <div>
              <button
                className={`${classes["btnColorCustom"]}`}
                disabled={
                  !form.currentPassword ||
                  !form.newPassword ||
                  !form.confirmPassword ||
                  form.newPassword !== form.confirmPassword ||
                  form.newPassword === form.currentPassword || // Disable if new password is the same as current password
                  Object.keys(formErrors).some((key) => formErrors[key])
                }
                type="submit" // Change to submit button
              >
                Save Password 
              </button>
              <div className="mt-2 text-center">
                <Link
                  to="/forgot-password"
                  className={`${classes["removeLinkDecoration"]}`}
                >
                  Forgot Password
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div>{isLoading && <Loader />}</div>
  </div>
);


}
export default ChangePassword;
