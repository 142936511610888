import React, { useEffect, useRef, useState } from 'react';
import classes from './Comments.module.css';
import Dots from "../../../../../Assets/Logo/dots-icon.svg";
import userPic from "../../../../../Assets/Images/profile_avatar.png";
import CommentForm from './CommentForm';
import GroupService from '../../../../../Services/GroupService';
import ConfirmationModal from '../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal';
import reportICon from "../../../../../Assets/Logo/report-icon.svg";
import leaveICon from "../../../../../Assets/Logo/Leave.svg";
import blockICon from "../../../../../Assets/Logo/Report.svg";
import { toast } from 'react-toastify';
import pauseICon from "../../../../../Assets/Logo/pause.svg";
import { useBehaviorSubject } from '../../../../GlobleShared/BehaviorSubject/BehaviorSubject';
import Loader from '../../../../GlobleShared/Loader/Loader';

const CommentsSection = ({handleOpenModal,groupId, commentsData, discussionID, newComment, setNewComment, replyText, setReplyText, handleAddComment, handleAddReply, setReplyingToclose, handleHidePopup, moderatorPostFlag }) => {
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const [replyingTo, setReplyingTo] = useState(null); // Track the comment being replied to
  const [visibleCommentId, setVisibleCommentId] = useState(null); // Track the comment whose dots menu is visible
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [btnTextName, setbtnTextName] = useState();
  const [commentIdvalue, setCommentIdvalue] = useState();
  const [statusId, setStatusId] = useState();
  const [discussionId, setDiscussionId] = useState();
   const {groupType, setGroupType, pauseFlag}=useBehaviorSubject()
   const [actionType, setActionType]=useState('') 
   const [optType, setOptType]=useState(1)
   const [sourceId, setSourceId]=useState('')
   const [isLoading, setIsLoading]=useState(false)
   const {setModaratorPostFlag}=useBehaviorSubject()


  const handleOpenModalInner= (actionName, id, discussionID) =>{
    handleOpenModal(actionName,id,discussionID);
    setVisibleCommentId(null);
  }
  // Toggle reply input box for a specific comment
  const openReplyBox = (commentId) => {
    setReplyingTo(replyingTo === commentId ? null : commentId);
    setReplyText(""); // Clear reply input when opening
    handleAddReply(commentId); // Set parent ID to this comment ID
  };

  const showModalhandler = (value, status, name,discussionID) => {
    setDiscussionId(discussionID)
    setModalIsVisible(true);
    setCommentIdvalue(value);
    setStatusId(status);
    setbtnTextName(name);
    const messages = {
      9: 'Are you sure, you want to Hide the selected comment?',
      2: 'Are you sure, you want to report the selected comment?',
      3: 'Are you sure, you want to block the selected comment?'
    };
    setConfirmationText(messages[status]);
  };

  const dropdownRef = useRef();
  function onClickOutsideHandler(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setVisibleCommentId(null);
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutsideHandler);
    return () => {
      document.removeEventListener('mousedown', onClickOutsideHandler);
    };
  }, []);

  const hideModalhandler = () => {
    setModalIsVisible(false);
    setStatusId('')
  };

  const statusUpdate = () => {
    let data = {
      CommentId: commentIdvalue,
      StatusId: statusId,
      UserId: 0
    };
    GroupService.updateComment(data).then(res => {
      toast.success(res?.message);
      handleHidePopup(discussionID);
      setModalIsVisible(false);
      setStatusId('')
    }).catch(error => {
      toast.error(error?.message);
    });
  };

  const getConfirmation=()=>{
    let data={
      sourceId:sourceId,
      optType:optType,
      action:actionType
    
    }
    setIsLoading(true)
    GroupService.reportAction(data).then(res=>{
      toast.success(res?.message)
      setIsLoading(false)
      setModalIsVisible(false);
      setModaratorPostFlag(true)
    }).catch(error=>{
      toast.error(error?.message)
      setIsLoading(false)
      setModalIsVisible(false);
    })


  }

  const getConfirm = () => {
    if(statusId){
      statusUpdate();
    }else{
      getConfirmation()
    }
    
    
  };

  const openModal=(data, actType, type)=>{
    setModalIsVisible(true);
    setActionType(actType)
    setSourceId(data)
    setOptType(type)
    setbtnTextName("yes")
    setStatusId('')
    if(actType===1){
      setConfirmationText('Are you sure, you want to ignore this post?')
    
    }
    if(actType===2){
      setConfirmationText('Are you sure, you want to delete this post?')
    }
    

  }

  // Recursive function to display comments and their replies
  const renderComments = (parentID) => {
    return commentsData
      .filter((comment) => comment.ParentCommentID === parentID)
      .map((comment) => (
        <div key={comment.CommentID} className={classes["commentContainer"]} style={{ marginLeft: parentID !== 0 ? "0px" : "0" }}>
          <div className={parentID !== 0 ? classes["verticalLine"] : ''}></div>
          <div className={parentID !== 0 ? classes["horizontalLine"] : ''}></div>

          <div className={classes["comment"]} style={{ marginLeft: parentID !== 0 ? "0px" : "0" }}>
            {(moderatorPostFlag && comment?.StatusID===2)   && (
                <div className={classes['red-header']}>
                <div className={classes['red-content']}>
                  <span className={classes['text-font']}>{comment?.ReportReasonName}</span>
                  <button  onClick={()=>{openModal(comment?.CommentID, 2, 3)}} className={classes['delete-btn']}>Delete</button>
                  <button  onClick={()=>{openModal(comment?.CommentID, 1, 3)}} className={classes['ignor-btn']}>Ignore</button>
                </div>
            </div>
            )}

            <div className={(moderatorPostFlag && comment?.StatusID===2) ? classes["commentHeader1"] : classes["commentHeader"]}>
              <img src={comment.ProfileImage ? blobUrl + comment.ProfileImage : userPic} alt={comment.DisplayName} className={classes["avatar"]} />
              <div className={classes["commentContent"]}>
                <span className={classes["commentAuthor"]}>{`${comment.DisplayName}:`}</span>
                <p className={classes["commentText"]}>{comment.Comments}</p>
              </div>
            </div>
            {(!groupType && !moderatorPostFlag && !pauseFlag) && (
            <div className={classes["commentActions"]}>
            <span className={classes["actionButton"]}  onClick={() => setVisibleCommentId(visibleCommentId === comment.CommentID ? null : comment.CommentID)}>
              <img
                src={Dots}
                alt=""
                className={classes["dotsIcon"]}
               
              />
            </span>
            {visibleCommentId === comment.CommentID && (
              <div className={classes["moreBtnParent"]} ref={dropdownRef}>
                <div className={classes["moreBtn"]}>
                  <div className={classes["moreBtnItem"]} onClick={() => { showModalhandler(comment.CommentID, 9, 'Hide',comment.DiscussionID); }}>
                    <div><img className={classes.imgPadding} src={leaveICon} /></div><div>Hide</div>
                  </div>
                  <div className={classes["moreBtnItem"]} onClick={() => handleOpenModalInner('Report', comment.CommentID,discussionID)}>
                    <div><img className={classes.imgPadding} src={reportICon} /></div><div>Report</div>
                  </div>
                  <div className={classes["moreBtnItem"]} onClick={() => { showModalhandler(comment.CommentID, 3, 'Block',comment.DiscussionID); }}>
                    <div><img className={classes.imgPadding} src={blockICon} /></div><div>Block</div>
                  </div>
                </div>
              </div>
            )}
            {/* <span className={classes["actionButton"]}>Share</span> */}
            <span className={classes["actionButton"]} onClick={() => openReplyBox(comment.CommentID)}>Reply</span>
          </div>
            )}

           

            {/* Conditionally render reply form */}
            {(!groupType && !moderatorPostFlag && !pauseFlag && replyingTo === comment.CommentID) && (
           
              <CommentForm
                onSubmit={(e) => {
                  handleAddComment(discussionID, e, comment.CommentID);
                  setReplyingTo(null); // Close the reply input after adding the comment
                }}
                commentText={replyText}
                setCommentText={setReplyText}
                placeholder="Write a reply..."
              />
            )}
          

            {/* Recursively render replies */}
            {renderComments(comment.CommentID)}
          </div>
        </div>
      ));
  };

  return (
    <div className={classes["commentsSection"]}>
      <div className={classes["commentsContainer"]}>
        {commentsData?.length > 0 ? renderComments(0) : <p>No comments yet.</p>}
      </div>

      {/* Main comment form */}
      {(!groupType && !moderatorPostFlag && !pauseFlag) && (
      <CommentForm
        onSubmit={(e) => handleAddComment(discussionID, e)}
        commentText={newComment}
        setCommentText={setNewComment}
        placeholder="Type your comment here..."
      />
      )}
       {modalIsVisible && (
              <ConfirmationModal onClose={hideModalhandler} confirmationText={confirmationText} btnText={btnTextName} onShow={modalIsVisible} getConfirmation={getConfirm} />
            )}

            {isLoading && (
              <Loader></Loader>
            )}
    </div>
  );
};

export default CommentsSection;
