import React from 'react'
import {NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "./ToolsSidebar.module.css"

function ToolsSidebar() {

    let location= useLocation();
    const navigate =useNavigate(null);
    const getRouting=(url)=>{
        navigate("/member/tools/" +url)

    }
    return(
        <>
             <div className={styles['sidebar-container']}>
                <div  onClick={()=>{getRouting('education-calculator')}} className={location.pathname==='/member/tools/education-calculator'?styles['highlight']:styles['invactive']}>

                    Education Loan Calculator
                    
                </div>
                <div onClick={()=>{getRouting('living-cost-calculator')}}  className={location.pathname==='/member/tools/living-cost-calculator'?styles['highlight']:styles['invactive']}>
            
                 Living Cost Calculator
                    
                </div>
                <div onClick={()=>{getRouting('currency-converter')}}  className={location.pathname==='/member/tools/currency-converter'?styles['highlight']:styles['invactive']}>
                 Currency Converter
                    
                </div>
                <div onClick={()=>{getRouting('english-score-calculator')}}  className={location.pathname==='/member/tools/english-score-calculator'?styles['highlight']:styles['invactive']}>
                 English Score Calculator
                </div>

            </div>
        </>
    )
}

export default ToolsSidebar