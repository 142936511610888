import React, { useEffect, useState } from "react";
import GroupsHeader from "../GroupsHeader/GroupsHeader";
import GroupsTabs from "../GroupsTabs_Info_panel/GroupsTabs_Info_Panel";
import Posts from "../../../../Common/Posts/Posts";
import ComposePost from "../ComposePost/ComposePost";
import About from "../About/About";
import classes from "./GroupsDashboard.module.css";
import backgroundImage from "../../../../../Assets/Images/groups_main_background_image.png";
import GroupMembers from "../GroupsMembers/GroupMembers";
import GroupService from "../../../../../Services/GroupService";
import { toast } from "react-toastify";
import GroupDiscussions from "../GroupDiscussions/GroupDiscussions";
import Loader from "../../../../GlobleShared/Loader/Loader";
import groups_image from "../../.../../../../../Assets/Images/groups_image.png";
import InviteModal from "../../../../GlobleShared/Modal/InviteModal/InviteModal";
import PendingGroups from "../GroupsMembers/PendingGroups/PendingGroups";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";

function GroupsDashboard({postDashboardData,handleComponentToggle}) {
  const [tab, setTab] = useState("Post");
  const [members, setMembers] = useState([]);
  const [discussions, setDiscussions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupPostFlag, setGroupPostFlag] = useState(true);
  const [invitationModal, setInvitationModal] = useState(false);
  const blobUrl = process.env.REACT_APP_URL_PATH;
  const {groupType, setGroupType, modaratorPostFlag, setModaratorPostFlag, pauseFlag, groupIdGlobal}=useBehaviorSubject();
  const [moderatorPostList, setModeratorPostList] = useState([]);
  const [moderatorFlag, setModeratorFlag] = useState(false);
  

  //here we are using temperary data but we use data comes from api.
  const groupDetails = {
    groupAccess: "Private",
    image: { backgroundImage },
    membersCount: 14,
  };
  const fetchDiscussions = () => {
    setIsLoading(true);
   GroupService.getUserGroupDiscussion(postDashboardData?.GroupID)
     .then((res) => {
       setDiscussions(res)
        setIsLoading(false);
        toast.success(res?.message);
     })
     .catch((error) => {
        setIsLoading(false);
       toast.error('Failed to get Discussion. Please try again.');
     });
 };
      useEffect(()=>{
          setTab("Post")
      },[handleComponentToggle]);
//  const fetchMembers = () => {
//   setIsLoading(true);
//  GroupService.getUserGroupMember(postDashboardData?.GroupID)
//    .then((res) => {
//      setMembers(res)
//      console.log()
//       setIsLoading(false);
//       toast.success(res?.message);
//    })
//    .catch((error) => {
//       setIsLoading(false);
//      toast.error('Failed to get Member. Please try again.');
//      console.log(error);
//    });
// };
  useEffect(() => {
    if (postDashboardData?.GroupID) {
      // if(tab === "Members"){
      // fetchMembers();}
      if (tab === "Discussion") {
        fetchDiscussions();
      }

      if (tab === "Moderator") {
        setModeratorFlag(true);
        getModeratorData(postDashboardData?.GroupID);
      }
    }
  }, [postDashboardData?.GroupID, tab]);

  const handleInvite = () =>{
    setInvitationModal(true)
  }
 
  const handleInviteClose = () =>{
    setInvitationModal(false)
  }

  const getModeratorData = (groupId) => {
    setIsLoading(true);
    GroupService.getModeratorList(groupId)
      .then((res) => {
        setModeratorPostList(res.ReportedPost);
        setDiscussions(res?.ReportedDiscussion)
        console.log(res.ReportedPost);
        setIsLoading(false);
        toast.success(res?.message);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error?.message);
      });
  };

  useEffect(()=>{
    if(modaratorPostFlag || groupIdGlobal){
      setModaratorPostFlag(false)
      getModeratorData(postDashboardData?.GroupID)
    }

  }, [modaratorPostFlag, groupIdGlobal])
   return (
    <>{postDashboardData?.GroupID?(
    <div>
      {invitationModal && 
      <InviteModal
        rowData={postDashboardData}
        onShow={invitationModal}
        onClose={handleInviteClose}
      />
      }
      <div className={`${classes["groups_dashboard_sections"]} `}>
        <GroupsHeader groupData={postDashboardData} image={ postDashboardData?.GroupImage?blobUrl+ postDashboardData?.GroupImage:groups_image} toggleComponent={handleComponentToggle}/>
        <GroupsTabs
          tab={tab}
          setTab={setTab}
          name={postDashboardData?.GroupName}
          access={postDashboardData?.GroupType==="1"?"Public":postDashboardData?.GroupType==="2"?"Private":"Exclusive"}
          toggleComponent={handleComponentToggle}
          members={postDashboardData?.MembersCount}
          groupData={postDashboardData}
          handleInvite={handleInvite}
        />
        <div className={classes["compose-post-container"]}>
          <div>


          {tab === "Post" &&  (postDashboardData?.UserGroupStatusId !== '3') && (
            <div className={classes["compose-posts"]}>
              {!groupType &&!pauseFlag && (
                <ComposePost isGroupData={true}  groupId={groupIdGlobal}/>
              )}
             
              <Posts groupData={postDashboardData}  groupId={postDashboardData?.GroupID} groupPostFlag={groupPostFlag}/>
            </div>
          )}


          {(tab === "Discussion") &&  (postDashboardData?.UserGroupStatusId !== '3') && (
            <GroupDiscussions discussions={discussions} postDashboardData={postDashboardData}  onAddDiscussion={fetchDiscussions}/>)}


          {tab === "Members" &&  (postDashboardData?.UserGroupStatusId !== '3') && (
            <div className="row">
              <div  className=" col-md-12 col-lg-12 p-0">
                {(!groupType && !pauseFlag && postDashboardData?.GroupID && (postDashboardData?.UserGroupRole==="1" || postDashboardData?.UserGroupRole==="2")) && (
                <PendingGroups groupId={postDashboardData?.GroupID}></PendingGroups>
                )}
               
                <GroupMembers groupData={postDashboardData} members={postDashboardData?.GroupID} />
              </div>
              <div className="col-md-12 col-lg-5 p-0"></div>
            </div>
          )}

               {tab === "Moderator" && (
                 <div className={classes["compose-posts"]}>
                   <Posts moderatorPostdata={moderatorPostList} moderatorPostFlag={moderatorFlag} />
                   {discussions && discussions?.length>0 && (
                   <GroupDiscussions moderatorPostFlag={moderatorFlag} discussions={discussions} postDashboardData={postDashboardData}  onAddDiscussion={fetchDiscussions}/>
                   )}
                  
                 </div>

               )}
          <div>
       {isLoading && (
          <Loader></Loader>
        )}
    </div>
          </div>

          <div className={classes["about-container"]}>
          <About groupData={postDashboardData} description={postDashboardData?.GroupDesc} toggleComponent={handleComponentToggle}/>
        </div>
        </div>
      </div>
    </div>
    ):(  <div className={`${classes["groups_dashboard_sections"]} ${classes.noData}`}>
      <span>No Data Found</span>
    </div>)}
    </>
  );
}

export default GroupsDashboard;
