import React from 'react';
import classes from './Comments.module.css';
import Send from "../../../../../Assets/Logo/send-icon.svg";
import Mic from "../../../../../Assets/Logo/mic-icon.svg";
import Gif from "../../../../../Assets/Logo/gif-icon.svg";
import Picker from 'emoji-picker-react';
import { useState } from 'react';
import crossWhite from "../../../../../Assets/Logo/cross-icon.svg";

const CommentForm = ({ onSubmit, commentText, setCommentText, placeholder }) => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  const toggleEmojiPicker = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };
  const onEmojiClick = (event, emojiObject) => {
    setCommentText(commentText + ' ' + event.emoji)
    // const quill = reactQuillRef.current.getEditor();
    // if (quill) {
    //   quill.focus();
    //   const selection = quill.getSelection(true); 
    //   const cursorPosition = selection ? selection.index : quill.getLength(); 
    //   quill.insertText(cursorPosition, event.emoji);
    //   quill.setSelection(cursorPosition + event?.emoji?.length);
    // }
    setIsEmojiPickerOpen(false);
  };
  return (
    <form onSubmit={onSubmit} className={classes["commentForm"]}>
      <div className={classes["inputContainer"]}>
        <textarea
          className={classes["commentInput"]}
          placeholder={placeholder || "Type here..."}
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />
        <div className={classes["iconContainer"]}>
          <button className={classes["sendButton"]} type="button">
            <img  onClick={toggleEmojiPicker} src={Gif} className={classes["sendIcon"]} alt="GIF" />
          </button>
          <button className={classes["sendButton"]} type="button">
            <img src={Mic} className={classes["sendIcon"]} alt="Mic" />
          </button>
          <button type="submit" className={classes["sendButton"]}>
            <img src={Send} alt="Send" className={classes["sendIcon"]} />
          </button>
            {isEmojiPickerOpen && (
               <div className={classes["emoji_picker_wrapper"]}>
                 <Picker onEmojiClick={onEmojiClick} />
               </div>
             )}
        </div>
      </div>
    </form>
  );
};

export default CommentForm;
