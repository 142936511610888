import React, { useState } from "react";
import styles from './Messages.module.css'
import editIcon from '../../../Assets/Logo/Edit-massage.svg';
import userIcon from '../../../Assets/Images/picture1.png';
import activeIcon from '../../../Assets/Logo/active-icon.svg'

const Messages=()=>{
const [massageList, setMassageList] =useState([
    {name:'Ken Heldt', description:'Hi mindless mortuis soulless creaturas', time:'Now', wayTime:'',  active:true},
    {name:'Robert Vaughn', description:'imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium', time:'5 mins ago', wayTime:'5m', active:false},
    {name:'Ken Heldt', description:'Hi mindless mortuis soulless creaturas', time:'Now', wayTime:'', active:true},
    {name:'Ken Heldt', description:'Hi mindless mortuis soulless creaturas', time:'Now', wayTime:'9m' , active:false},
    {name:'Ken Heldt', description:'Hi mindless mortuis soulless creaturas', time:'Now', wayTime:'9m', active:false}
])

   return(
       <>
       <div>
        <div className={`${styles['col-width']} col-md-4 col-sm-8`}>
            <div className={styles['header-section']}>
                <div>
                    <p className={styles['heading']}>Messages</p>
                </div>
                <div>
                    <img src={editIcon} alt=''></img>
                </div>
            </div>
            <div className={styles['overflow-div']}>
            {massageList.map((item)=>(
                <div className={styles['content-section']}>
                        <div className={styles['user-name-align']}>
                            <div className={styles['active-icon-positon']}>
                                <img className={styles['user-icon']} src={userIcon} alt=''></img>
                                {item?.active && (
                                    <img className={styles['active-icon']} src={activeIcon}  alt=''></img>
                                )}
                                {item?.wayTime && (
                                    <div className={styles['way-timer']} src={activeIcon} >{item?.wayTime}</div>
                                )}
                                
                            </div>
                            <div>
                            <p className={styles['user-name']}>{item?.name}</p>
                            <p className={styles['description']}>{item?.description}</p>
                            </div>
                           
                        </div>
                        <div className="text-end">
                            <p className={styles['timer']}>{item?.time}</p>
                        </div>
                </div>

            ))}
            </div>

        </div>
       </div>
       </>
   )
}
export default Messages;