import React, {useState, useEffect} from 'react'
import SubConnections from "../../User_Account/Connections/SubComponents/SubConnections"
import SubMembers from './MemberCard';

function GroupMembers(members) {
    const [groupMembersFlag, setgroupMembersFlag] = useState(false);

    useEffect(() => {
        setgroupMembersFlag(true);
      }, []);

  return (
    <div className="mt-2">
        <SubMembers groupData={members?.groupData} groupMembersFlag={groupMembersFlag} membersId={members.members}/>
    </div>
  )
}

export default GroupMembers