import React, { useState } from "react";
import classes from "./Section.module.css";
import LanguageComponent from "./LanguageComponent"; // Import the Language component
import vectorRightIcon from "../.../../../../../../../Assets/Logo/Vector_right.svg";
function Preferences() {
  const [view, setView] = useState("main");

  const handleBack = () => {
    setView("main");
  };

  if (view === "language") {
    return <LanguageComponent onBack={handleBack} />;
  }

  return (
    <>
    <div className={classes["setting-container"]}>

      <div
        className={`${classes["setting-section"]} card`}>
        <div className={classes["setting-title"]}>
        General
        </div>
        <div className="d-flex justify-content-between mt-2">
        <div className={classes["setting-description"]}>Languages</div>
          <span className={classes["next-icon"]} onClick={() => setView("language")}>
            <img src={vectorRightIcon}></img>
          </span>
        </div>
      </div>
      <div className={`${classes["setting-section"]} card mt-3`}>
        <div className={classes["setting-title"]}>Display
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div className={classes["setting-description"]}>Dark Mode</div>
          <div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Preferences;
