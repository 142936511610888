import { Modal } from 'react-bootstrap';
import classes from './UserModal.module.css'
import corssIcon from "../../../../Assets/Logo/cross-icon.svg";
import shareIcon  from '../../../../Assets/Logo/share-blue-icon.svg'
import likeIcon  from '../../../../Assets/Logo/liked.svg'
import postPic from "../../../../Assets/Images/profile_avatar.png";
import { useEffect, useState } from 'react';



const UserModal=(props)=>{
    const blobURL= process.env.REACT_APP_URL_PATH
    const [count, setCount]=useState(0)

    // useEffect(()=>{
    //     if(props?.data?.length<1000){
    //         setCount(props?.data?.length)
    //     }
        
    //     if(props?.data?.length>=1000){
    //         const dataCount=props?.data?.length/1000
    //         setCount(dataCount.toFixed(2) +'K')
    //     }

    // }, [])


    return(
        <>
                <div>
            <Modal show={props.onShow}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered >
            <Modal.Body>
                <div className={classes['header-icon']}>
                    <div>
                    
                        <img className={classes['share-icon']} src={props?.text==='share'? shareIcon : props?.text==='like'? likeIcon :''} alt=''></img> <span className={classes['count-number']}>{props?.data?.length}</span>

                    </div>
                    <div className={classes['cross-icon-position']}>
                    <img title="Close" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={props.onClose}></img>
                </div>

                </div>
             
                <div className={classes['modal-body-data']}>
                    {props?.data.map(item=>(
                        <div className={classes['user-section']}>
                            <div >
                            <img className={classes['img-size']} src={item?.ProfilePic ? blobURL+item?.ProfilePic : postPic} alt=''></img>
                            </div>
                            <div>
                                <p className={classes['user-name']}>{item?.Username}</p>
                                <p className={classes['description']}>{item?.About}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </Modal.Body>

        </Modal>
        </div>
        </>

    )

}
export default UserModal;