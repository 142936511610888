import axiosInstance from "./Interceptor";

const InstituteService = {
  getInstitutionData: async (instituteId) => {
    try {
      const response = await axiosInstance.get('/Institute/ForPageNew?InstitutionId=' + instituteId);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },


};

export default InstituteService;