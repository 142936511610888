import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import classes from "./InviteModal.module.css";
import corssIcon from "../../../../Assets/Logo/cross-icon.svg";
import nameInfoImage from "../../../../Assets/Images/profile_avatar.png";
import searchIcon from "../../../../Assets/Logo/search-icon-2.svg";
import Loader from "../../../GlobleShared/Loader/Loader";
import ConnectionService from "../../../../Services/ConnectionService";
import PostService from "../../../../Services/PostServices";
import { toast } from "react-toastify";
import GroupService from "../../../../Services/GroupService";
import useLocalStorage from "../../CustomHooks/useLocalStorage";

function InviteModal({ onShow, onClose, rowData }) {
    const [moderatorData, setModeratorData] = useState([]); 
    const blobUrl = process.env.REACT_APP_URL_PATH;

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [paginated, setPaginated] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const initialized = useRef(false);
    const [profilesData, setProfilesData] = useState([]);
    const [invitationShare, setInvitationShare] = useState([]);
    console.log('invitationShare: ', invitationShare);
    const [searchInput, setSearchInput] = useState("");
    const [searchInput1, setSearchInput1] = useState("");
    const [userDetail] = useLocalStorage('userDetail');
    const studentId = userDetail.user_id;
    const [selectedItems, setSelectedItems] = useState({});

    const handleChange = (e) => {
      setSearchInput(e.target.value);
      if(e.target.value.trim){
        if(rowData.GroupType==="1"){
          getGroupConnectionListPublic(e.target.value)
        }else{
        getConnectionList(page, e.target.value);
        }
      }

    };

    const handleModeratorChange = (e) => {
      setSearchInput1(e.target.value);
    };

    const handleSearch = (e) => {
      if(rowData.GroupType==="1"){
        getGroupConnectionListPublic(searchInput)
      }else{
      getConnectionList(page, searchInput);
      }
    };

    const submitInvitation = async () => {

      setIsLoading(true);
      const dataPublic = Array.from(invitationShare).map(data => ({
        "GroupID": rowData?.GroupID,
        "UserGroupRole": data.IsModerator === 1 ? 2 : 3,
        "UserID": data.ReceiverUserID,
      }));


      if (invitationShare?.length === 0) {
        toast.error("Please select at least one user.");
        setIsLoading(false);
        return;
      }

      const payload = {
        GroupID: rowData?.GroupID,
        ReceiverUserList: invitationShare,
      };
    
     const apiName  = (rowData?.GroupType === '2' || rowData?.GroupType ==='3') ? GroupService.addCreateGroupRequestForPrivate(payload) : rowData?.GroupType == 1 ? GroupService.addCreateGroupRequestForPublic(dataPublic):'';
     apiName.then((res) => {
          setIsLoading(false);
          onClose();
          toast.success(res?.message);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.message);
        });
    };

    const getModeratorList = () => {
      setIsLoading(true);
      GroupService.getUserGroupList()
        .then((res) => {
          setModeratorData(res || []);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.message);
        });
    };

    const handleCheckboxChange1 = (id) => {
      setSelectedItems(prevSelected => {
        const newSelected = { ...prevSelected };
        if (newSelected[id]) {
          delete newSelected[id]; 
        } else {
          newSelected[id] = true; 
        }
        return newSelected;
      });
    };


    const getGroupConnectionListPublic = (searchTerm, pageValue = 1) => {
      let data = {
        limit: limit,
        page: pageValue,
        paginated: paginated,
        searchTerm: searchTerm,
        studentId: studentId
      }
      setIsLoading(true)
      GroupService.getConnectionList(data)
      .then((res) => {
        setProfilesData([...res?.list]);
        setTotalCount(res?.totalCount);
        setIsLoading(false);
       }).catch(error => {
        setIsLoading(false)
      })
    }

    const getConnectionList = (pageValue, inputValue) => {
      let data = {
        limit: limit,
        page: pageValue,
        paginated: paginated,
        searchTerm: inputValue,
      };
      setIsLoading(true);
      ConnectionService.getConnectionList(data)
        .then((res) => {
          setProfilesData([...res?.list]);
          setTotalCount(res?.totalCount);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };

    const handleCheckboxChange = (id, type) => {
      setInvitationShare((prevInvitationShare) => {
        const existingEntry = prevInvitationShare.find(
          (item) => item.ReceiverUserID === id && item.IsModerator === type
        );

        if (existingEntry) {
          return prevInvitationShare.filter(
            (item) => item.ReceiverUserID !== id || item.IsModerator !== type
          );
        } else {
          return [...prevInvitationShare, { ReceiverUserID: id, IsModerator: type }];
        }
      });
    };

    useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        if(rowData.GroupType==='1'){
           getGroupConnectionListPublic(searchInput)
        }else{
        getConnectionList(page, searchInput);}
        getModeratorList(); // Fetch moderators instead of groups
      }
    }, [searchInput]);
  
    return (
      <div>
        <Modal
          show={onShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName={classes["modal-custom"]}
        >
          <Modal.Body>
            <div className={classes["cross-icon-position"]}>
              <div className={classes["heading"]}>Invite</div>
              <img
                title="Close"
                className={classes["corss-icon"]}
                src={corssIcon}
                alt=""
                onClick={onClose}
              ></img>
            </div>
  
            <div className={classes["modal-body-data"]}>
              <div className={classes.main_container}>
                {/* User List Section */}
                <div className={classes.card}>
                  <div className={`${classes["form-section"]}`}>
                    <div className={classes["invite-section"]}>
                      <div className={classes["heading"]}>Users</div>
  
                      <div className={`${classes["search_input_content"]}`}>
                        <input
                          type="text"
                          placeholder="Search..."
                          className={`${classes["search-input"]}`}
                          value={searchInput}
                          onChange={handleChange}
                        />
                        <img
                          src={searchIcon}
                          alt="Search"
                          className={`${classes["search-icon"]}`}
                          onClick={handleSearch}
                        />
                      </div>
  
                      <div className={`${classes["list-content"]}`}>
                        <div className={`${classes["invite-list"]}`}>
                          {profilesData.map((profile, index) => (
                            <div
                              key={profile?.senderUserId + "_" + index || profile?.UserID + "_" + index}
                              className={classes["name-item"]}
                            >
                              <div className={classes["name-info"]}>
                                <img
                                  src={
                                    profile?.profileImage
                                      ? blobUrl + profile?.profileImage:profile?.ProfileImage?blobUrl + profile?.ProfileImage
                                      : nameInfoImage
                                  }
                                  alt={profile?.displayName||profile.DisplayName}
                                  className={classes["name-image"]}
                                />
                                <span className={classes["name-text"]}>
                                  {profile?.displayName||profile.DisplayName}
                                </span>
                              </div>
                              <div className={classes["checkbox-container"]}>
                                <input
                                  className={classes["checkbox-input"]}
                                  type="checkbox"
                                  checked={selectedItems[profile?.UserID?profile?.UserID: profile.receiverUserId===studentId? profile.senderUserId:profile.receiverUserId] || false}
                                  onChange={() =>{handleCheckboxChange(profile?.senderUserId|| profile?.UserID,0); handleCheckboxChange1(profile?.UserID?profile?.UserID:profile.receiverUserId===studentId? profile.senderUserId:profile.receiverUserId)}}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className={classes["share-section"]}>
                  <button disabled={invitationShare?.length === 0}
                    onClick={submitInvitation}
                    className={`${classes["share-btn"]}`}
                  >
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
  
        {isLoading && <Loader></Loader>}
      </div>
    );
  }
  
export default InviteModal;
