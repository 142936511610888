import { Button, ButtonGroup } from "react-bootstrap";
import styles from "./Button_Group.module.css";

function Button_Group({ optionsList, optionType, selectedValue, selectOptionHandler }) {

    return (
        <>
            <ButtonGroup vertical>
            {optionsList.map((option, index) => (
                    <Button
                        className={`${styles["group-btn"]} mb-2 ${selectedValue === option ? styles.active : ''}`}
                        key={index}
                        onClick={() => selectOptionHandler(option, optionType)}
                    >
                        {option}
                    </Button>
            ))}
            </ButtonGroup>
        </>
    )
}

export default Button_Group;