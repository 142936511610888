import axiosInstance from "./Interceptor";

const PaymentService = {
    getPaymentByStripe: async (data) => {
      try {
        const response = await axiosInstance.post('/stripe/charge', data);
        return response.data;
      } catch (error) {
        throw error.response.data
      }
    },

    getPossibleInstitute: async (data) => {
      try {
        const response = await axiosInstance.post('/FlywirePaymentProcess/link', data);
        return response.data;
      } catch (error) {
        throw error.response.data
      }
    },

    payWithFlywire: async (data) => {
      try {
        const response = await axiosInstance.post('/FlywirePaymentProcess/link', data);
        return response.data;
      } catch (error) {
        throw error.response.data
      }
    },
}

export default PaymentService;