import axiosInstance from "./Interceptor";

const ConnectionService = {
  getConnectionList: async (data) => {
    try {
      const response = await axiosInstance.post('/user/connections', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  
  getInvitationList: async(data)=>{
    try {
      const response = await axiosInstance.post('/user/invitations', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  sentInvitationList: async(data)=>{
    try {
      const response = await axiosInstance.post('/user/sent-invitation', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  updateConnection: async (data) => {
    try {
      const response = await axiosInstance.put('/user/connection-status', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getSuggestions: async (data) => {
    try {
      const response = await axiosInstance.post('/user/suggestions', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  addConnection: async (data) => {
    try {
      const response = await axiosInstance.post('/user/connections/add', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  
}
export default ConnectionService;