import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import classes from './TestScores.module.css';
import UploadDocument from '../../../../../GlobleShared/UploadDocument/UploadDocument';
import Loader from "../../../../../GlobleShared/Loader/Loader";
import ApplicationService from "../../../../../../Services/ApplicationServices";
import useLocalStorage from "../../../../../GlobleShared/CustomHooks/useLocalStorage";
import { toast } from "react-toastify";
import { useBehaviorSubject } from "../../../../../GlobleShared/BehaviorSubject/BehaviorSubject";

// Mock API function


const englishExamRange = [
    {
      Type: -1, AMin: 0, AMax: 0, BMin: 0, BMax: 0, CMin: 0, CMax: 0, DMin: 0, DMax: 0, EMin: 0, EMax: 0, Increment: 0,
      pattern: null,
      showUsername: false,
      ALabel: '', BLabel: '', CLabel: '', DLabel: '', ELabel: '',
      ShowA: false, ShowB: false, ShowC: false, ShowD: false, ShowE: false,
      Name: 'Not Applicable'
    },
    
    {
      Type: 1, AMin: 0, AMax: 9, BMin: 0, BMax: 9, CMin: 0, CMax: 9, DMin: 0, DMax: 9, EMin: 0, EMax: 9, Increment: 0.5,
      pattern: /^[0-9]+(\.[5])?$/,
      showUsername: true,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'IELTS'
    },
    {
      Type: 2, AMin: 10, AMax: 90, BMin: 10, BMax: 90, CMin: 10, CMax: 90, DMin: 10, DMax: 90, EMin: 10, EMax: 90, Increment: 1,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: true,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'PTE'
    },
    {
      Type: 3, AMin: 1, AMax: 30, BMin: 1, BMax: 30, CMin: 1, CMax: 30, DMin: 1, DMax: 30, EMin: 0, EMax: 120, Increment: 1,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: true,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'TOEFL iBT'
    },
    {
      Type: 4, AMin: 310, AMax: 677, BMin: 310, BMax: 677, CMin: 310, CMax: 677, DMin: 310, DMax: 677, EMin: 310, EMax: 677, Increment: 1,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: true,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'TOEFL PBT'
    },
    {
      Type: 5, AMin: 0, AMax: 100, BMin: 0, BMax: 100, CMin: 0, CMax: 97, DMin: 1, DMax: 4, EMin: 0, EMax: 99, Increment: .5,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: false,
      ALabel: 'Listening', BLabel: 'GCVR', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Final MELAB',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'MELAB'
    },
    {
      Type: 6, AMin: 10, AMax: 90, BMin: 10, BMax: 90, CMin: 10, CMax: 90, DMin: 10, DMax: 90, EMin: 10, EMax: 90, Increment: .5,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: false,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'CAEL'
    },
    {
      Type: 7, AMin: 1, AMax: 300, BMin: 1, BMax: 300, CMin: 1, CMax: 300, DMin: 1, DMax: 300, EMin: 1, EMax: 300, Increment: 1,
      pattern: /^[0-9]+(\.[0-9][0-9]?)?$/,
      showUsername: true,
      ALabel: 'Listening', BLabel: 'Reading', CLabel: 'Writing', DLabel: 'Speaking', ELabel: 'Overall',
      ShowA: true, ShowB: true, ShowC: true, ShowD: true, ShowE: true,
      Name: 'TOEFL CBT'
    },
    {
      Type: 8, AMin: 0, AMax: null, BMin: 0, BMax: null, CMin: 0, CMax: null, DMin: 0, DMax: null, EMin: 10, EMax: 160, Increment: .5,
      pattern: null,
      showUsername: true,
      ALabel: '', BLabel: '', CLabel: '', DLabel: '', ELabel: 'Overall',
      ShowA: false, ShowB: false, ShowC: false, ShowD: false, ShowE: true,
      Name: 'Duolingo'
    }
  ];
  

const OtherExams = [
  {
    "OtherExamId": 0,
    "OtherExamName": "None",
  },
  {
    "OtherExamId": 1,
    "OtherExamName": "ACT",
  },
  {
    "OtherExamId": 2,
    "OtherExamName": "GMAT",
  },
  {
    "OtherExamId": 3,
    "OtherExamName": "GRE",
  },
  {
    "OtherExamId": 4,
    "OtherExamName": "SAT",
  },
];


const TestScores = ({ updateValue = false, testScoreData, getTestScore, profileFlag, handleLanguageClose,
   getValidation, disableEditTestScore, editTestScore, isTestScore,}) => {
  const [testScores, setTestScores] = useState({});
  const [selectedExamType, setSelectedExamType] = useState({});
  const [selectedOtherExam, setSelectedOtherExam] = useState({});
  const currentDate = new Date()
  const [selectedDate, setSelectedDate]=useState()
  const [selectedOtherExamDateDate, setselectedOtherExamDateDate]=useState()
  const [isLoading, setIsLoading] = useState();
  const [selectedExamConfig, setSelectedExamConfig] = useState({});
  const [userDetail] = useLocalStorage("userDetail");
  const studentID = userDetail.refId;

  const { validationFlag, setValidationFlag } = useBehaviorSubject();
  const [getValidationFlag, setGetValidationFlag]=useState(false)




  // ********************************

  const [formErrors, setFormErrors] = useState({
      // English Scores Errors
    EnglishExamType: null,
    EnglishExamRemark:null,
    EnglishExamDate: null,
    EnglishScoreL: null,
    EnglishScoreR: null,
    EnglishScoreW: null,
    EnglishScoreS: null,
    EnglishScoreOverall: null,
    EnglishExamUser: null,
    EnglishExamPswd: null,

     // Test Scores Errors
      GMATExam: null,
      GMATExamDate: null,
      ACTScoreEnglish: null,
      ACTScoreMath: null,
      ACTScoreScience: null,
      ACTScoreReading: null,
      ACTScoreWriting: null,
      ACTScoreTotal: null,
      GREScoreV: null,
      GREScoreQ: null,
      GREScoreW: null,
      SATScoreMath: null,
      SATScoreEBRW: null,
      SATScoreTotal: null,
      OtherExamUser: null,
      OtherExamPswd: null,
      EnglishExamDocPath: null,
      OtherExamDocPath: null,
  });


  



  const getTestScores = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(testScoreData);
      }, 1000); 
    });
  };
  
  useEffect(() => {
    fetchTestScores(testScoreData?.EnglishExamType, testScoreData?.GMATExam);
    getTestScore(testScoreData)
  //  setSelectedExamType(4) 

  }, [testScoreData]);
  
  const fetchTestScores = (englishExamType, otherExamTypeName) => {
    const examType = englishExamRange.filter(exam => exam.Type === Number(englishExamType));
    const otherExamType = OtherExams.filter(exam => exam.OtherExamId === Number(otherExamTypeName));
    setSelectedOtherExam(otherExamType[0]);
    setSelectedDate((new Date(testScoreData?.EnglishExamDate)))
    setselectedOtherExamDateDate((new Date(testScoreData?.GMATExamDate)))
    setSelectedExamType(examType[0]);
    setTestScores(testScoreData);
  };

  const handleExamTypeChange = (event) => {
    const { name, value } = event.target;
    // Update form errors state
    setFormErrors({});
    if(!profileFlag){
      getValidation({});
    }
    const errorMsg = validateField(name, value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMsg,
      }));
      console.log(formErrors)
      if(!profileFlag){
        getValidation(formErrors, 5)
      }

    const examType = englishExamRange.find(exam => exam.Type === parseInt(event.target.value));
    testScores.EnglishExamDate=''
    testScores.EnglishScoreOverall='';
    testScores.EnglishScoreL=''
    testScores.EnglishScoreR=''
    testScores.EnglishScoreW=''
    testScores.EnglishScoreS=''
    testScores.EnglishExamRemark=''
    testScores.EnglishExamUser=''
    testScores.EnglishExamPswd=''
    testScores.EnglishExamDocPath=''

    testScores.EnglishExamType=event.target.value
    setSelectedDate()
    setSelectedExamType(examType);
    // const { name, value } = event.target;
    getTestScore({...testScores, [name]: value})
    
  };

  const handleOtherExamTypeChange = (event) => {
    
    const { name, value } = event.target;
    // Update form errors state
    setFormErrors({});
    if(!profileFlag){
      getValidation({});
    }
    // if(value === "0"){
    //   let formErrorsTemp = formErrors;
    //   formErrorsTemp['OtherExamDocPath']=null
    //   setFormErrors(formErrorsTemp);
    // }

      console.log(formErrors)

    testScores.GMATExamDate=''
    testScores.ACTScoreEnglish=''
    testScores.ACTScoreMath=''
    testScores.ACTScoreScience=''
    testScores.ACTScoreReading=''
    testScores.ACTScoreWriting=''
    testScores.ACTScoreTotal=''
    testScores.GREScoreV=''
    testScores.GREScoreQ=''
    testScores.GREScoreW=''
    testScores.SATScoreTotal=''
    testScores.GREScoreV =''
    testScores.GREScoreQ=''
    testScores.GREScoreW=''
    testScores.SATScoreMath=''
    testScores.SATScoreEBRW=''
    testScores.SATScoreTotal=''
    testScores.OtherExamDocPath=''
    testScores.GMATExam=event.target.value
    const otherExamType = OtherExams.find(exam => exam.OtherExamId === parseInt(event.target.value));
    setSelectedOtherExam(otherExamType);
    // const { name, value } = event.target;
    getTestScore({...testScores, [name]: value})
    setselectedOtherExamDateDate()
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const errorMsg = validateField(name, value);
      // Update form errors state
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMsg,
        }));
        console.log(formErrors);
        if(!profileFlag){
          getValidation({...formErrors, [name]: errorMsg}, 6)
        }

    setTestScores(prevState => ({
      ...prevState,
      [name]: value
    }));
    getTestScore({...testScores, [name]: value})
    if(event.target.name==="EnglishExamDate"){
      setSelectedDate((new Date(value)))
    }
    if(event.target.name==="GMATExamDate"){
      setselectedOtherExamDateDate((new Date(value)))
    }
  };



  const englishExamFile=(file)=>{
    console.log("file",file)
    testScores.EnglishExamDocPath=file
     // Validate file upload
      const errorMsg = validateField("EnglishExamDocPath", file);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        EnglishExamDocPath: errorMsg,
      }));
      
    getTestScore({...testScores, ...testScores.EnglishExamDocPath})

  }

  const otherExamFile=(file)=>{
    testScores.OtherExamDocPath=file
    // Validate file upload
    const errorMsg = validateField("OtherExamDocPath", file);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      OtherExamDocPath: errorMsg,
    }));
    getTestScore({...testScores, ...testScores.OtherExamDocPath})

  }


  
  const updateTestScoreData=()=>{
      const errors = validateForm();
      setFormErrors(errors);

      if(selectedOtherExam.OtherExamId === 0){
       formErrors.OtherExamDocPath=null;
      }
      if(selectedExamType?.Type > 0 && selectedDate <= currentDate){
        formErrors.EnglishExamDocPath=null;
      }

  
      if (Object.keys(errors).length > 0) {
        return; 
      }

    const requestData = {
      StudentId: studentID,
      EnglishExamType: Number(testScores?.EnglishExamType),
      EnglishExamDate: testScores?.EnglishExamDate,
      EnglishScoreL: Number(testScores?.EnglishScoreL),
      EnglishScoreR: Number(testScores?.EnglishScoreR),
      EnglishScoreW: Number(testScores?.EnglishScoreW),
      EnglishScoreS: Number(testScores?.EnglishScoreS),
      EnglishScoreOverall: Number(testScores?.EnglishScoreOverall),
      EnglishExamUser: testScores?.EnglishExamUser,
      EnglishExamPswd: testScores?.EnglishExamPswd,
      EnglishExamRemark: testScores?.EnglishExamRemark,
      EnglishExamDocPath: testScores?.EnglishExamDocPath,
      
      GREExamDate: Number(testScores?.GREExamDate),
      GREScoreV: Number(testScores?.GREScoreV),
      GREScoreQ: Number(testScores?.GREScoreQ),
      GREScoreW: Number(testScores?.GREScoreW),
      
      GMATExamDate: testScores?.GMATExamDate,
      GMATScoreA: Number(testScores?.GMATScoreA),
      GMATScoreI: Number(testScores?.GMATScoreI),
      GMATScoreQ: Number(testScores?.GMATScoreQ),
      GMATScoreV: Number(testScores?.GMATScoreV),
      
      GMATExam: Number(testScores?.GMATExam),
      GREExam: Number(testScores?.GREExam),
    
      SATScoreTotal: Number(testScores?.SATScoreTotal),
      SATScoreMath: Number(testScores?.SATScoreMath),
      SATScoreEBRW: Number(testScores?.SATScoreEBRW),
    
      ACTScoreTotal: Number(testScores?.ACTScoreTotal),
      ACTScoreEnglish: Number(testScores?.ACTScoreEnglish),
      ACTScoreMath: Number(testScores?.ACTScoreMath),
      ACTScoreReading: Number(testScores?.ACTScoreReading),
      ACTScoreScience: Number(testScores?.ACTScoreScience),
      ACTScoreWriting: Number(testScores?.ACTScoreWriting),
    
      OtherExamDocPath: testScores?.OtherExamDocPath,
      OtherExamUser: testScores?.OtherExamUser?testScores?.OtherExamUser:'',
      OtherExamPswd: testScores?.OtherExamPswd?testScores?.OtherExamPswd:'',
    };
    
    setIsLoading(true)
    if(isTestScore){
       ApplicationService.udateTestScore(requestData).then(res=>{
          disableEditTestScore()
          toast.success('Test score updated successfully.');
          setIsLoading(false);
        }).catch(error=>{
        toast.error(error?.message);
        setIsLoading(false)
      })
    }else{
      ApplicationService.udateTestScore(requestData).then(res=>{
        toast.success("Language details updated successfully.");
        setIsLoading(false);
        handleLanguageClose();
      }).catch(error=>{
        toast.error("Failed to update language details. Please try again.");
        setIsLoading(false)
      })
    }

  }


  const getSelectedExamConfig = (examType) => {
    const examTypeData = englishExamRange.find((exam) => exam.Type === Number(examType)) || {};
    setSelectedExamConfig(examTypeData);
  };
  

  const validateField = (name, value) => {
    let errorMsg = null;

    const isDateValid = selectedDate === undefined || selectedDate <= currentDate;

    switch (name) {
      // English Scores Validation
      case "EnglishExamType":
        if (!value) errorMsg = "Please select an exam type";
        break;

       case "EnglishExamDate":
            if (selectedExamType?.Type !== -1) {
              if (!value)
                errorMsg = "Please select an exam date";
            }
             // Uncomment to check if the exam date is in the future
          // else if (new Date(value) > new Date()) errorMsg = "Exam date cannot be in the future.";
            break;

        case "EnglishExamRemark":
            if (selectedExamType?.Type === -1) {
              if (!value)
                errorMsg = "Please add a remark";
            }
            break;

           
        // Validate Listening Score
      case "EnglishScoreL":
        if (selectedExamConfig.ShowA && isDateValid) {
          if (!value) {
            errorMsg = "Please provide a valid score for Listening";
          } else if (value < selectedExamConfig.AMin || value > selectedExamConfig.AMax) {
            errorMsg = `score must be between ${selectedExamConfig.AMin} and ${selectedExamConfig.AMax}`;
          }
        }
        break;

      // Validate Reading Score
      case "EnglishScoreR":
        if (selectedExamConfig.ShowB && isDateValid) {
          if (!value) {
            errorMsg = "Please provide a valid score for Reading";
          } else if (value < selectedExamConfig.BMin || value > selectedExamConfig.BMax) {
            errorMsg = `score must be between ${selectedExamConfig.BMin} and ${selectedExamConfig.BMax}`;
          }
        }
        break;

      // Validate Writing Score
      case "EnglishScoreW":
        if (selectedExamConfig.ShowC && isDateValid) {
          if (!value) {
            errorMsg = "Please provide a valid score for Writing";
          } else if (value < selectedExamConfig.CMin || value > selectedExamConfig.CMax) {
            errorMsg = `score must be between ${selectedExamConfig.CMin} and ${selectedExamConfig.CMax}`;
          }
        }
        break;

      // Validate Speaking Score
      case "EnglishScoreS":
        if (selectedExamConfig.ShowD && isDateValid) {
          if (!value) {
            errorMsg = "Please provide a valid score for Speaking";
          } else if (value < selectedExamConfig.DMin || value > selectedExamConfig.DMax) {
            errorMsg = `score must be between ${selectedExamConfig.DMin} and ${selectedExamConfig.DMax}`;
          }
        }
        break;

      // Validate Overall Score
      case "EnglishScoreOverall":
        if (selectedExamConfig.ShowE && isDateValid) {
          if (!value) {
            errorMsg = "Please provide a valid overall score";
          } else if (value < selectedExamConfig.EMin || value > selectedExamConfig.EMax) {
            errorMsg = `score must be between ${selectedExamConfig.EMin} and ${selectedExamConfig.EMax}`;
          }
        }
        break;


      //  case "EnglishExamUser":
      //   if (!updateValue && selectedExamType?.showUsername) {
      //       if (!value) errorMsg = "Please enter your username.";
      //   }
      //   break;

       case "EnglishExamPswd":
        if (!updateValue && selectedExamType?.showUsername && testScores.EnglishExamUser) {
            if (!value) errorMsg = "Please enter your password";
        }
        break;
  
     
  

        // Test Scores Validation
        // case "GMATExam":
        // if (!value) errorMsg = "Please select an exam";
        // break;

        case "GMATExamDate":
          if (selectedOtherExam.OtherExamId > 0 && !value ) errorMsg = "Please select an exam date";
          // Uncomment if you want to check for future dates
          // else if (new Date(value) > new Date()) errorMsg = "Exam date cannot be in the future.";
          break;

        // Validation for ACT exam scores
        case "ACTScoreEnglish":
        case "ACTScoreMath":
        case "ACTScoreScience":
        case "ACTScoreReading":
        case "ACTScoreWriting":
        case "ACTScoreTotal":
          if (selectedOtherExam.OtherExamId === 1 && (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) { // Check if ACT exam is selected
            if (!value || value < 0) errorMsg = `Please provide a valid score`;
            else if (value < 1 || value > 36) {
              errorMsg = `Score must be between 1 and 36`;
          }
          }
          break;


        // Validation for GRE exam scores
        case "GREScoreV":
        case "GREScoreQ":
        case "GREScoreW":
          if ((selectedOtherExam.OtherExamId === 2 && (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) || (selectedOtherExam.OtherExamId === 3 && (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate))) { // Check if GRE exam is selected
            if (!value || value < 0) errorMsg = `Please provide a valid score`;
            else if((selectedOtherExam.OtherExamId === 2 &&  (value < 1 || value > 60))){
              errorMsg = `Score must be between 1 and 60`;
            }
            else if((selectedOtherExam.OtherExamId === 3 &&  (value < 1 || value > 170))){
              errorMsg = `Score must be between 1 and 170`;
            }
          }
         
          break;

        // Validation for SAT exam scores
        case "SATScoreMath":
        case "SATScoreEBRW":
          if (selectedOtherExam.OtherExamId === 4 && (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) { // Check if SAT exam is selected
            if (!value || value < 0) errorMsg = `Please provide a valid score`;
            else if((selectedOtherExam.OtherExamId === 4 &&  (value < 200 || value > 400))){
              errorMsg = `Score must be between 200 and 400`;
            }
          }
          break;

        case "SATScoreTotal":
          if (  (selectedOtherExam.OtherExamId === 2 || selectedOtherExam.OtherExamId === 4) && 
           (selectedOtherExamDateDate === undefined || selectedOtherExamDateDate <= currentDate)) { 
            if (!value || value < 0) errorMsg = `Please provide a valid score`;
            else if((selectedOtherExam.OtherExamId === 2 &&  (value < 200|| value > 800))){
              errorMsg = `Score must be between 200 and 800`;
            }
            else if((selectedOtherExam.OtherExamId === 4 &&  (value < 400|| value > 1600))){
              errorMsg = `Score must be between 400 and 1600`;
            }
          }
          break;

        // case "OtherExamUser":
        case "OtherExamPswd":
        if (selectedOtherExam.OtherExamId > 0 && !value && testScores.OtherExamUser) errorMsg = "Please enter your password";
        break;


        //English Exam Validation
        case "EnglishExamDocPath":
          if(selectedExamType?.Type > 0 && selectedDate > currentDate){
            if (!value) {
              errorMsg = "Please upload the Attachment";
            }
          }

          break;
    
        // Other Exam Validation
        case "OtherExamDocPath":
          if(selectedOtherExam.OtherExamId > 0 && selectedOtherExamDateDate > currentDate){
            if (!value) {
              errorMsg = "Please upload the Attachment";
            }
          }

          break;

        default:
          break;
    }

    return errorMsg;
  };
  

  const validateForm = () => {
    const errors = {};
    Object.keys(testScores).forEach((key) => {
      const errorMsg = validateField(key, testScores[key]);
      if (errorMsg) errors[key] = errorMsg;
    });
    return errors;
  };
  
  
  // ********************************


  useEffect(()=>{
    if(!profileFlag && validationFlag && !getValidationFlag){ 
      const errors = validateForm();
      setFormErrors(errors); 
      getValidation(errors,6); 
      setValidationFlag(false); 
      setGetValidationFlag(false)
    }
  },[validationFlag])

  const cancelData=()=>{
    disableEditTestScore()

  }
  return (
    <>
      <div className={classes['mainContainer']}>
        <div className={classes.headingText}><span>English {!updateValue && `Proficiency Test`}</span></div>
        <div className={classes['container']}>
          <div className="row">
            <div className="col-md-6">
              <Form.Group className="form-group mb-3" controlId="exampleForm.ControlInput1">
                   <Form.Label className={classes['forLabel']}>Select Exam Type<span className={classes["mandotory"]}>*</span></Form.Label>
                <Form.Select 
                disabled={!editTestScore}
                aria-label="Select Exam Type" 
                name="EnglishExamType" 
                value={testScores?.EnglishExamType} 
                onBlur={handleExamTypeChange}
                onChange={(e) => {
                  getSelectedExamConfig(e.target.value); // Get the selected exam config based on exam type
                  handleExamTypeChange(e);  // Ensure this works for onChange
                }}
                >
                <option  value=''>Select an exam</option>
                  {englishExamRange.map((exam, index) => (
                    <option key={index} value={exam.Type}>{exam.Name}</option>
                  ))}
                </Form.Select>
                {formErrors.EnglishExamType && (
                  <div className={classes["errorText"]}>{formErrors.EnglishExamType}</div>
                )}
              </Form.Group>
            </div>
            {selectedExamType?.Type === -1 && (
              <div className="form-group col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInputRemark">
                     <Form.Label className={classes['forLabel']}>Remark<span className={classes["mandotory"]}>*</span></Form.Label>
                  <input
                   disabled={!editTestScore}
                    className={`form-control`}
                    type='text'
                    placeholder='Remark'
                    aria-label="Remark"
                    name="EnglishExamRemark"
                    value={testScores.EnglishExamRemark}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                  />
                  {formErrors.EnglishExamRemark && (
                      <div className={classes["errorText"]}>{formErrors.EnglishExamRemark}</div>
                    )}
                </Form.Group>
              </div>
            )}
            {selectedExamType?.Type !==-1 && (
              <div className="form-group col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label className={classes['forLabel']}>Exam Date<span className={classes["mandotory"]}>*</span></Form.Label>
                  <input
                    disabled={!editTestScore}
                    className={`form-control`}
                    type='date'
                    aria-label="Exam Date"
                    name="EnglishExamDate"
                    value={testScores?.EnglishExamDate? testScores?.EnglishExamDate.split('T')[0] : ''}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                  />
                </Form.Group>
                {formErrors.EnglishExamDate && (
                <div className={classes["errorText"]}>{formErrors.EnglishExamDate}</div>
              )}
              </div>
            )}
           
            {(selectedExamType?.ShowA &&  (selectedDate===undefined || selectedDate <= currentDate)) && (
              <div className="form-group col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label className={classes['forLabel']}>{selectedExamType.ALabel}<span className={classes["mandotory"]}>*</span></Form.Label>
                  <input
                    disabled={!editTestScore}
                    className={`form-control`}
                    type='number'
                    aria-label={selectedExamType.ALabel}
                    name="EnglishScoreL"
                    value={testScores.EnglishScoreL}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                  />
                   {formErrors.EnglishScoreL && (
                      <div className={classes["errorText"]}>{formErrors.EnglishScoreL}</div>
                    )}
                </Form.Group>
              </div>
            )}
            {(selectedExamType?.ShowB &&  (selectedDate===undefined || selectedDate <= currentDate)) &&(
              <div className="form-group col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label className={classes['forLabel']}>{selectedExamType.BLabel}<span className={classes["mandotory"]}>*</span></Form.Label>
                  <input
                   disabled={!editTestScore}
                    className={`form-control`}
                    type='number'
                    aria-label={selectedExamType.BLabel}
                    name="EnglishScoreR"
                    value={testScores.EnglishScoreR}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                  />
                    {formErrors.EnglishScoreR && (
                      <div className={classes["errorText"]}>{formErrors.EnglishScoreR}</div>
                    )}
                </Form.Group>
              </div>
            )}
            {(selectedExamType?.ShowC &&  (selectedDate===undefined || selectedDate <= currentDate)) &&(
              <div className="form-group col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label className={classes['forLabel']}>{selectedExamType.CLabel}<span className={classes["mandotory"]}>*</span></Form.Label>
                  <input
                   disabled={!editTestScore}
                    className={`form-control`}
                    type='number'
                    aria-label={selectedExamType.CLabel}
                    name="EnglishScoreW"
                    value={testScores.EnglishScoreW }
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                  />
                   {formErrors.EnglishScoreW && (
                        <div className={classes["errorText"]}>{formErrors.EnglishScoreW}</div>
                      )}
                </Form.Group>
              </div>
            )}
            {(selectedExamType?.ShowD  &&  (selectedDate===undefined || selectedDate <= currentDate)) &&(
              <div className="form-group col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label className={classes['forLabel']}>{selectedExamType.DLabel}<span className={classes["mandotory"]}>*</span></Form.Label>
                  <input
                   disabled={!editTestScore}
                    className={`form-control`}
                    type='number'
                    aria-label={selectedExamType.DLabel}
                    name="EnglishScoreS"
                    value={testScores.EnglishScoreS}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                  />
                   {formErrors.EnglishScoreS && (
                    <div className={classes["errorText"]}>{formErrors.EnglishScoreS}</div>
                  )}
                </Form.Group>
              </div>
            )}
            {(selectedExamType?.ShowE  &&  (selectedDate===undefined || selectedDate <= currentDate)) &&(
              <div className="form-group col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label className={classes['forLabel']}>{selectedExamType.ELabel}<span className={classes["mandotory"]}>*</span></Form.Label>
                  <input
                   disabled={!editTestScore}
                    className={`form-control`}
                    type='number'
                    aria-label={selectedExamType.ELabel}
                    name="EnglishScoreOverall"
                    value={testScores.EnglishScoreOverall}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                  />
                   {formErrors.EnglishScoreOverall && (
                <div className={classes["errorText"]}>{formErrors.EnglishScoreOverall}</div>
              )}
                </Form.Group>
              </div>
            )}
          </div>
          {!updateValue && selectedExamType?.showUsername && (
            <div className={`row`}>
              <div className="form-group col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label className={classes['forLabel']}>Username</Form.Label>
                  <input
                   disabled={!editTestScore}
                    className={`form-control`}
                    type='text'
                    aria-label="Username"
                    name="EnglishExamUser"
                    value={testScores.EnglishExamUser}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                  />
                  {formErrors.EnglishExamUser && (
                    <div className={classes["errorText"]}>{formErrors.EnglishExamUser}</div>
                  )}
                </Form.Group>
              </div>
              <div className="form-group col-md-6">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                     <Form.Label className={classes['forLabel']}>Password<span className={classes["mandotory"]}>*</span></Form.Label>
                  <input
                    className={`form-control`}
                    type='password'
                    aria-label="Password"
                    name="EnglishExamPswd"
                    value={testScores.EnglishExamPswd}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    disabled={!testScores.EnglishExamUser || !editTestScore}
                  />
                   {formErrors.EnglishExamPswd && (
                      <div className={classes["errorText"]}>{formErrors.EnglishExamPswd}</div>
                    )}
                </Form.Group>
              </div>
            </div>
          )}

          {(profileFlag || (selectedExamType?.Type > 0 && selectedDate > currentDate))  && (
              <div className="col-md-12 mb-4">
                <p className={classes['forLabel']}>
                        {selectedDate === undefined || selectedDate <= currentDate 
                          ? 'Upload score card document' 
                          : 'Upload confirmation document'}
                      </p>
                      <div>
                  <label className={classes["titleLabel"]}>
                    Add your Document here and you can upload up to 5 MB Max
                  </label>
                </div>
                 <UploadDocument filePathUrl={testScoreData.EnglishExamDocPath} btnText="Upload File" uploadFile={englishExamFile}></UploadDocument>
                 {formErrors.EnglishExamDocPath && (
                  <div className={classes["errorText"]}>{formErrors.EnglishExamDocPath}</div>
                )}
              </div>
            )}
        </div>




{/* for other exams */}


        <div className={`${classes.headingText}`}>
          <span>{updateValue ? 'GMAT' : 'Other Exams '}</span>
        </div>
        <div className={classes['container']}>
          <div className="row">
            <div className="form-group col-md-6">
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                   <Form.Label className={classes['forLabel']}>Select an Exam</Form.Label>
                <Form.Select  disabled={!editTestScore} aria-label="Select an Exam" name="GMATExam" value={testScores?.GMATExam} onChange={handleOtherExamTypeChange}>
                  {OtherExams.map((exam) => (
                    <option key={exam.OtherExamId} value={exam.OtherExamId}>{exam.OtherExamName}</option>
                  ))}
                </Form.Select>
                {/* {formErrors.GMATExam && (
                      <div className={classes["errorText"]}>{formErrors.GMATExam}</div>
                    )} */}
              </Form.Group>
            </div>
            {selectedOtherExam.OtherExamId >0 && (
              <>
                <div className="form-group col-md-6">
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                       <Form.Label className={classes['forLabel']}>Exam Date<span className={classes["mandotory"]}>*</span></Form.Label>
                    <input
                     disabled={!editTestScore}
                      className={`form-control`}
                      type='date'
                      aria-label="Exam Date"
                      name="GMATExamDate"
                      value={testScores?.GMATExamDate ? testScores?.GMATExamDate.split('T')[0] : ''}
                      onChange={handleInputChange}
                      onBlur={handleInputChange}
                    />
                     {formErrors.GMATExamDate && (
                        <div className={classes["errorText"]}>{formErrors.GMATExamDate}</div>
                      )}
                  </Form.Group>
                </div>
                {(profileFlag || (selectedOtherExam.OtherExamId > 0 && selectedOtherExamDateDate >= currentDate)) && (
                    <div className="col-md-12 mb-4">
                         <p className={classes['forLabel']}>
                        {selectedOtherExamDateDate === undefined || selectedOtherExamDateDate <= currentDate 
                          ? 'Upload score card document' 
                          : 'Upload confirmation document'}
                      </p>
                      <div>
                  <label className={classes["titleLabel"]}>
                    Add your Document here and you can upload up to 5 MB Max
                  </label>
                </div>
                      <UploadDocument filePathUrl={testScoreData.OtherExamDocPath} btnText="Upload File" uploadFile={otherExamFile}></UploadDocument>
                      {formErrors.OtherExamDocPath && (
                        <div className={classes["errorText"]}>{formErrors.OtherExamDocPath}</div>
                      )}
                 </div>

                )}
 
                {(selectedOtherExam?.OtherExamId === 1 &&  (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) && (
                  <>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>English<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="English"
                          name="ACTScoreEnglish"
                          value={testScores.ACTScoreEnglish}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.ACTScoreEnglish && (
                            <div className={classes["errorText"]}>{formErrors.ACTScoreEnglish}</div>
                          )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Math<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Math"
                          name="ACTScoreMath"
                          value={testScores.ACTScoreMath}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.ACTScoreMath && (
                          <div className={classes["errorText"]}>{formErrors.ACTScoreMath}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Science<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Science"
                          name="ACTScoreScience"
                          value={testScores.ACTScoreScience}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.ACTScoreScience && (
                            <div className={classes["errorText"]}>{formErrors.ACTScoreScience}</div>
                          )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Reading<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Reading"
                          name="ACTScoreReading"
                          value={testScores.ACTScoreReading}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                          {formErrors.ACTScoreReading && (
                        <div className={classes["errorText"]}>{formErrors.ACTScoreReading}</div>
                      )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Writing<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Writing"
                          name="ACTScoreWriting"
                          value={testScores.ACTScoreWriting}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                        {formErrors.ACTScoreWriting && (
                      <div className={classes["errorText"]}>{formErrors.ACTScoreWriting}</div>
                    )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Total Score<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Total Score"
                          name="ACTScoreTotal"
                          value={testScores.ACTScoreTotal}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.ACTScoreTotal && (
                          <div className={classes["errorText"]}>{formErrors.ACTScoreTotal}</div>
                        )}
                      </Form.Group>
                    </div>
                  </>
                )}
                {(selectedOtherExam.OtherExamId === 2 && (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) && (
                  <>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Verbal<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Verbal"
                          name="GREScoreV"
                          value={testScores.GREScoreV}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                          {formErrors.GREScoreV && (
                          <div className={classes["errorText"]}>{formErrors.GREScoreV}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Quantitative<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Quantitative"
                          name="GREScoreQ"
                          value={testScores.GREScoreQ}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.GREScoreQ && (
                          <div className={classes["errorText"]}>{formErrors.GREScoreQ}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Analytic<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Analytic"
                          name="GREScoreW"
                          value={testScores.GREScoreW}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.GREScoreW && (
                            <div className={classes["errorText"]}>{formErrors.GREScoreW}</div>
                          )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Total Score<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Total Score"
                          name="SATScoreTotal"
                          value={testScores.SATScoreTotal}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                        {formErrors.SATScoreTotal && (
                          <div className={classes["errorText"]}>{formErrors.SATScoreTotal}</div>
                        )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Username</Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='text'
                          aria-label="Username"
                          name="OtherExamUser"
                          value={testScores.OtherExamUser}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {/* {formErrors.OtherExamUser && (
                            <div className={classes["errorText"]}>{formErrors.OtherExamUser}</div>
                          )} */}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Password<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                          className={`form-control`}
                          type='password'
                          aria-label="Password"
                          name="OtherExamPswd"
                          value={testScores.OtherExamPswd}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                          disabled={!testScores.OtherExamUser || !editTestScore}
                        />
                          {formErrors.OtherExamPswd && (
                              <div className={classes["errorText"]}>{formErrors.OtherExamPswd}</div>
                            )}
                      </Form.Group>
                    </div>
                  </>
                )}
                {(selectedOtherExam.OtherExamId === 3 &&  (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) &&(
                  <>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Verbal<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Verbal"
                          name="GREScoreV"
                          value={testScores.GREScoreV }
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                          {formErrors.GREScoreV && (
                              <div className={classes["errorText"]}>{formErrors.GREScoreV}</div>
                            )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Quantitative<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Quantitative"
                          name="GREScoreQ"
                          value={testScores.GREScoreQ}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.GREScoreQ && (
                                    <div className={classes["errorText"]}>{formErrors.GREScoreQ}</div>
                                  )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Analytic<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Analytic"
                          name="GREScoreW"
                          value={testScores.GREScoreW}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.GREScoreW && (
                                <div className={classes["errorText"]}>{formErrors.GREScoreW}</div>
                              )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Username</Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='text'
                          aria-label="Username"
                          name="OtherExamUser"
                          value={testScores.OtherExamUser}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.OtherExamUser && (
                                <div className={classes["errorText"]}>{formErrors.OtherExamUser}</div>
                              )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-6">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Password<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input

                          className={`form-control`}
                          type='password'
                          aria-label="Password"
                          name="OtherExamPswd"
                          value={testScores.OtherExamPswd}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                          disabled={!testScores.OtherExamUser ||!editTestScore}
                        />
                          {formErrors.OtherExamPswd && (
                                <div className={classes["errorText"]}>{formErrors.OtherExamPswd}</div>
                              )}
                      </Form.Group>
                    </div>
                  </>
                )}
                {(selectedOtherExam.OtherExamId === 4 &&  (selectedOtherExamDateDate===undefined || selectedOtherExamDateDate <= currentDate)) &&(
                  <>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Math<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Math"
                          name="SATScoreMath"
                          value={testScores.SATScoreMath}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.SATScoreMath && (
                            <div className={classes["errorText"]}>{formErrors.SATScoreMath}</div>
                          )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Evidence Based Reading and Writing<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Evidence Based Reading and Writing"
                          name="SATScoreEBRW"
                          value={testScores.SATScoreEBRW}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                         {formErrors.SATScoreEBRW && (
                                <div className={classes["errorText"]}>{formErrors.SATScoreEBRW}</div>
                              )}
                      </Form.Group>
                    </div>
                    <div className="form-group col-md-3">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                           <Form.Label className={classes['forLabel']}>Total Score<span className={classes["mandotory"]}>*</span></Form.Label>
                        <input
                         disabled={!editTestScore}
                          className={`form-control`}
                          type='number'
                          aria-label="Total Score"
                          name="SATScoreTotal"
                          value={testScores.SATScoreTotal}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                        />
                      {formErrors.SATScoreTotal && (
                          <div className={classes["errorText"]}>{formErrors.SATScoreTotal}</div>
                        )}
                      </Form.Group>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
         {profileFlag && (<div>
         <div className="text-center">
            <button onClick={updateTestScoreData} className="primary-button ">Save Details</button>
          </div>
        </div>)}
      </div>

      {(editTestScore && isTestScore )&& (
                        <div className='text-end'>
                            <button onClick={cancelData} className={classes['cancel-btn']}>Cancel</button>
                            <button onClick={updateTestScoreData} className='primary-button'>Update</button>
                        </div>
      )}

      {isLoading && <Loader />}

    </>
  );
  };

  export default TestScores;