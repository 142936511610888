import React, { useState } from "react";
import classes from "./Connections.module.css";
import SubConnections from "./subConnections/SubConnections";
import Suggestion from "./subConnections/Suggestion";
import searchIcon from "../../.../../../../Assets/Logo/search-icon-2.svg";
import SentComponents from "./subConnections/SentComponent";
import InvitationsComponent from "./subConnections/InvitationsComponent";
const ConnectionsComponents = () => {
  const [tab, setTab] = useState("Connections");

  return (
    <div className={classes["container-fluid"]}>
      <div className={classes["container"]}>
        <div className={classes["headerMange"]}>
          <div className={classes["header-left-part"]}>
            <span className={classes["connectionsText"]}>Connections</span>
          </div>
          <div className={classes["header-right-part"]}>
            <div className={classes["search-bar"]}>
              <input
                type="text"
                placeholder="Search Users"
              />
              <button type="submit">
                <img src={searchIcon} />
              </button>
            </div>
            <div>
            {tab === "Connections" && <span className={classes["searchWithFilters"]}>Search with filters</span>}
            </div>
          </div>
        </div>
        <div className={classes["brokenLine"]}></div>
        <div className={classes["user_navigation"]}>
          <button
            className={tab === "Connections" ? classes["active_button"] : ""}
            onClick={() => setTab("Connections")}>
            Connections
          </button>
          <button 
            className={tab === "Suggestions" ? classes["active_button"] : ""}
            onClick={() => setTab("Suggestions")}>
            Suggestions
          </button>
          <button
            className={tab === "Invitations" ? classes["active_button"] : ""}
            onClick={() => setTab("Invitations")}>
            Invitations
          </button>
          <button
            className={tab === "Sent" ? classes["active_button"] : ""}
            onClick={() => setTab("Sent")}>
            Sent
          </button>
        </div>
      </div>
      <div>
        {tab === "Connections" && <SubConnections />}
        {tab === "Suggestions" && <Suggestion />}
        {tab === "Invitations" && <InvitationsComponent/>}
        {tab === "Sent" && <SentComponents />}
      </div>
    </div>
  );
};

export default ConnectionsComponents;
