// import React, { useState } from "react";
import styles from './ApplicationDetails.module.css';
import institutesImage from "../../../../../Assets/Images/institute-image.svg"
import rightGreen from "../../../../../Assets/Logo/rightGreen.svg"
import crossIcon from "../../../../../Assets/Logo/cross-icon.svg"
import ProgramSection from "../NewApplication/ProgramSection/ProgramSection";
import EducationSection from "../NewApplication/EducationSection/EducationSection";
import TestScores from "../NewApplication/TestScores/TestScores";
import AssignedCounsellor from "../NewApplication/AssignedCounsellor/AssignedCounsellor";
import CreditCardSection from "../NewApplication/CreditCardSection/CreditCardSection";
import StudentDocumentsSection from "../NewApplication/StudentDocumentsSection/StudentDocumentsSection";
import FeeSection from "../NewApplication/FeeSection/FeeSection";
import NoteSection from "../NewApplication/NoteSection/NoteSection";
import editIcon from "../../../../../Assets/Logo/edit-icon.svg"




import React, { useEffect, useRef, useState } from "react";
import ApplicationService from "../../../../../Services/ApplicationServices";
import useLocalStorage from "../../../../GlobleShared/CustomHooks/useLocalStorage";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import StudentServices from "../../../../../Services/StudentServices";
import ApplicationPendingDocument from '../NewApplication/PendingDocumentsSection/ApplicationPendingDocument/ApplicationPendingDocuments';
import InstitutionDocuments from '../../../../GlobleShared/UploadDocument/InstitutionDocument/InstitutionDocument';
import SearchModal from '../../Search/SearchModal/SearchModal';
import ConfirmationModal from '../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal';
import Loader from '../../../../GlobleShared/Loader/Loader';
import ValidationModal from '../../../../GlobleShared/Modal/ValidationModal/ValidationModal';
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
const tabs = [
  { name: "Program Info", id: "Program" },
  { name: "Education Info", id: "Education" },
  { name: "Test Score", id: "Test" },
  { name: "Credit Card", id: "CreditCard" },
  { name: "Student Documents", id: "Documents" },
  { name: "Pending Documents", id: "PendingDocument" },
  { name: "Institution Documents", id: "InstitutionDocument" },
  { name: "Assigned Counsellor", id: "Assigned" },
  { name: "Contact Info", id: "Contact" },
  { name: "Fee", id: "Fee" },
  { name: "Note", id: "Note" }

  
];


   const ApplicationDetails=()=>{

  const [programDetails, setProgramDetails] = useState();
  const [personalFormData , setPersonalFormData]= useState(null)
  const initial = useRef(null);
  const [userDetail] = useLocalStorage("userDetail");
  const [studentId, setStudentId] = useState(userDetail?.refId);
  const params = useParams();
  const [progranTypeId, setProgranTypeId] = useState(null);  
  const [institionList, setInstitionList] = useState([]);
  const [programId, setProgramId] = useState(0);
  const [institution, setInstitution] = useState(null);
  const [studentData, setStudentData] = useState();
  const [programList, setProgramList] = useState([]);
  const [intakeList, setIntakeList] = useState([]);
  const [programMode, setProgramMode] = useState([]);
  const [programSpecialization, setProgramSpecialization] = useState([]);
  const [studentDataBySectionWise, setStudentDataBySectionWise] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [studentDetail, setStudentDetail] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [instituteId, setInstituteId] = useState();
  const [applicationId, setApplicationId] = useState(params.applicationId);
  const [countryId, setCountryId] = useState(0);
  const [Citizenship, setCitizenship] = useState(0);
  const [intakeId, setIntakeID] = useState(0);
  const [ genContectInfoData,  setGenContectInfoData] = useState();
  const [ testScoreData,  setTestScoreData] = useState();
  const [isLoading, setIsLoading]=useState(false)
  const [campusList, setCampusList]=useState([])
  const [educationInfo,setEducationInfo]=useState()
const [schoolData,setSchoolData]=useState()
  const [rowData, setRowData] = useState({});
  const blobURL= process.env.REACT_APP_URL_PATH
 
  const [programIdForUploadDocument, setProgramIdForUploadDocument] = useState({});
  
  const navigate=useNavigate()
  const [educationLevelListbyApllication, setEducationLevelListbyApllication] =  useState([]);


  const [educationLevelData, setEducationLevelData] = useState([]);
  const [grdSchemeData, setGrdSchemeData] = useState([]);

  const [modeId, setModeId]=useState()
  const [campusId, setCampusId]=useState()
  const [specializationId, setSpecializationId]=useState()
  const [residingId, setResidingId]=useState();
  const [personalInfoData, setPersonalInfoData]=useState()
  const [documentList, setDocumentList]=useState([]);
  const [pendingDoc, setPendingDoc]=useState([])
  const [BProgram1, setBProgram1]=useState(0)
  const [BProgram2, setBProgram2]=useState(0)
  const [BProgram3, setBProgram3]=useState(0)
  const[pendingDocumentTypeList, setpendingDocumentTypeList]=useState([])
  const[documentStatusCount, setDocumentStatusCount]=useState(0)
  const [validationModalSize, setValidationModalSize] = useState('');


  const [applicationDetailData, setApplicationDetailData] = useState([]);
  const [feeAndDocumentStatusData, setFeeAndDocumentStatusData] = useState({});
  const [modalSize, setModalSize] = useState('');
  const [confirmationPopupFlag, setConfirmationPopupFlag] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [validationModalIsVisible, setValidationModalIsVisible] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [hintText, setHintText] = useState("");
  const [creditCardEdit, setCreditCardEdit] = useState(false);
  const [programInfoUpdate, setProgramInfoUpdate] = useState(false);
  const [viewApplication, setViewApplication] = useState(false);
  const [editTestScore, setEditTestScore] = useState(false);
  const [isTestScore, setIsTestScore] = useState(false);
  const [editEducation, setEditEducation] = useState(false);
  const [viewEducation, setViewEducation] = useState(false);

 

  const [validationForProgramSetion, setValidationForProgramSetion]=useState({})
  const [validationForPersonalSetion, setValidationForPersonalSetion]=useState({})
  const [validationForEducationSetion, setValidationForEducationSetion]=useState({})
  const [validationForContactSetion, setValidationForContactSetion]=useState({});
  const [validationForTextScoreSetion, setValidationForTextScoreSetion]=useState({});
  const { validationFlag, setValidationFlag } = useBehaviorSubject();

  


  const getInstituteId = (value) => {
    setProgramId(0)
    setIntakeID(0)
    setCampusId(0)
    setModeId(0)
    setSpecializationId(0)
    setProgramList([])
    setIntakeList([])
    setCampusList([])
    setProgramSpecialization([])
    setInstituteId(value);
    getProgramList(value, countryId, progranTypeId);
    getcountryDetails(value);
    getAssignedData(value);
  };

  const getProgramId = (id) => {
    setIntakeID(0)
    setCampusId(0)
    setModeId(0)
    setSpecializationId(0)
    setIntakeList([])
    setCampusList([])
    setProgramSpecialization([])
    setProgramId(id);
    getProgramDetails(id);
    getIntakeList(id);
    getProgramModeList(id);
    getProgramSpecialization(id);
    CheckInstitutionIntakeExists(id, studentId, instituteId, intakeId);
    getAssignedData(instituteId);
    getProgramParentDocumentList(applicationId);
  };

  const getIntakeId = (id) => {
    setIntakeID(id)
    setCampusId(0)
    setModeId(0)
    setSpecializationId(0)
    setCampusList([])
    setProgramSpecialization([])
    CheckInstitutionIntakeExists(programId, studentId, instituteId, id);
    getCampusList(id);
    forApplication(id);
  };

  const addEducationDetail = ()=>{
    getCountryList();
    // getEduLevelList(); need to implemen
    getEduLevelForDDLByApplication(studentId)
    getSubjectList();
    getLanguageList();
    getDegreeList();
    getStreamList();
  }

  const getScroll = (value) => {
    document
      .getElementById(value)
      .scrollIntoView({ behavior: "smooth", block: "nearest" });
  };

  const getProgramDetails = (programIdValue) => {
    setIsLoading(true)
    ApplicationService.getProgramDetails(programIdValue)
      .then((res) => {
        setIsLoading(false)
        setProgramDetails(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getIntakeList = (programId) => {
    setIsLoading(true)
    ApplicationService.getProgramIntake(programId, applicationId, countryId)
      .then((res) => {
        setIsLoading(false)
        setIntakeList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramModeList = (programId) => {
    setIsLoading(true)
    ApplicationService.getProgramMode(programId)
      .then((res) => {
        setIsLoading(false)
        setProgramMode(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramSpecialization = (programId) => {
    setIsLoading(true)
    ApplicationService.getProgramSpecialization(programId)
      .then((res) => {
        setIsLoading(false)
        setProgramSpecialization(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getInstituteList = (progranTypeId) => {
    setIsLoading(true)
    ApplicationService.getInstituteList(studentId, progranTypeId)
      .then((res) => {
        setIsLoading(false)
        setInstitionList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getProgramList = (value, CountryId, ProgramTypeID) => {
    let data = {
        InstitutionId: Number(value),
        CountryId: CountryId ,
        ProgramTypeID: ProgramTypeID,
    };
    setIsLoading(true)
    ApplicationService.getProgramList(data)
      .then((res) => {
        setIsLoading(false)
        setProgramList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getcountryDetails = (value) => {
    ApplicationService.countryDetailsByInstitutionId(value)
      .then((res) => {})
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getStudentData = () => {
    setIsLoading(true)
    ApplicationService.getStudentData(studentId, programId)
      .then((res) => {
        setIsLoading(false)
        setStudentData(res);
        if(res?.genInfo)
            setPersonalFormData(res?.genInfo)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getStudentDataBySectionwise = () => {
    setIsLoading(true)
    ApplicationService.GetSectionWiseWithPreference(applicationId)
      .then((res) => {
        setIsLoading(false)
        setStudentDataBySectionWise(res);
        setCountryId(res?.genInfo?.Citizenship)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getRelation = () => {
    ApplicationService.getRelationList()
      .then((res) => {
        setRelationList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getCountryCode = () => {
    ApplicationService.getCountryCode()
      .then((res) => {
        setCountryCodeList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getCountryList = () => {
    setIsLoading(true)
    ApplicationService.getCountryList()
      .then((res) => {
        setIsLoading(false)
        setCountryList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getLanguageList = () => {
    setIsLoading(true)
    ApplicationService.getLanguageList()
      .then((res) => {
        setIsLoading(false)
        setLanguageList(res);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getSubjectList = () => {
    setIsLoading(true)
    ApplicationService.getSubjectList()
    .then((res) => {
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const getDegreeList = () => {
    setIsLoading(true)
    ApplicationService.getDegreeList()
      .then((res) => {
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };
  const getStreamList = () => {
    setIsLoading(true)
    ApplicationService.getStreamList()
      .then((res) => {
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };

  const getApplicationList = () => {
    ApplicationService.getApplicationByStudentId(studentId)
      .then((res) => {
        setApplicationList(res);
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const CheckInstitutionIntakeExists = (
    programId,
    studentId,
    instituteId,
    intakeId
  ) => {
    let data = {
      InstitutionId: Number(instituteId),
      StudentId: Number(studentId),
      ProgramId: Number(programId),
      IntakeId: Number(intakeId),
    };
    setIsLoading(true)
    ApplicationService.CheckInstitutionIntakeExists(data)
      .then((res) => {
        setIsLoading(false)
        if (res > 0 && res !== applicationId) {
          toast.error("Application already exists with same program and intake.");
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };


  const getProgramParentDocumentList = (studentId,  ProgramId, Country) => {
    setIsLoading(true)
    StudentServices.studentDocumentList(applicationId, 7)
      .then((res) => {
        setDocumentList(res)
        setIsLoading(false)
        getProgramDocumentList(applicationId, ProgramId, Country, res)
      })
      .catch((error) => {
        toast.error(error?.message);
        setIsLoading(false)
      });
  };

  const getProgramDocumentList=(applicationId, programId, countryId, respose)=>{
    setIsLoading(true)
    ApplicationService.getProgramDocumentList(applicationId, programId, countryId).then(res=>{
      setIsLoading(false)
      const data = res.filter(item => {
        return !respose.some(ele => item?.DocumentTypeId === ele?.DocumentTypeId);
      });
      
      setpendingDocumentTypeList(data);
    }).catch(error=>{
      setIsLoading(false)
    })

  }
  const pendingDocumentsCount=()=>{
    setIsLoading(true)
    ApplicationService.pendingDocumentsCount(applicationId).then(res=>{
      setIsLoading(false)
      setDocumentStatusCount(res)
    }).catch(error=>{
      setIsLoading(false)
    })
  }




  const getCampusList = (IntakeId) => {
    ApplicationService.getCampusList(IntakeId)
      .then((res) => {
        setCampusList(res)
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const forApplication = (IntakeId) => {
    let data = {
      IntakeId: IntakeId,
      Nationality: 1,
      ApplicationId: applicationId,
    };
    ApplicationService.forApplication(data)
      .then((res) => {
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const getAssignedData = (instituteId) => {
    ApplicationService.getAssignedCounsellor(studentId, instituteId)
      .then((res) => {
        setRowData(res);
      })
      .catch((error) => {
     
      });
  };

  const getEducationLevelData = () => {
    ApplicationService.getEducationLevelasync()
      .then((res) => {
        setEducationLevelData(res);
      })
      .catch((error) => {
     
      });
  };

  const getEduLevelForDDLByApplication = (studentId) => {
    let data = {
      ParentType: 7,
      ParentId: studentId,
      SchoolId: 0,
    };
    setIsLoading(true)
    ApplicationService.getEduLevelForDDLByApplication(data)
      .then((res) => {
        setIsLoading(false)
        setEducationLevelListbyApllication(res);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };

  const getSchoolDetailList = () => {
    let data = {
      ParentType: 6,
      ParentId: studentId,
    };
    ApplicationService.getSchoolDetailList(data)
      .then((res) => {
        setSchoolList(res);
      })
      .catch((error) =>{
        console.log(error);
      });
  };
  
  const getSchoolDetail = (SchoolId) => {
    setIsLoading(true)
    ApplicationService.getSchoolDetail(SchoolId)
      .then((res) => {
        setIsLoading(false)
        setSchoolData(res);
        console.log(schoolData);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };

  const deleteSchoolDetail = (SchoolId) => {
    setIsLoading(true)
    ApplicationService.deleteSchoolDetail(SchoolId)
      .then((res) => {
        setIsLoading(false)
        // toast.success(res?.message);
        toast.success("School deleted successfully!");
        getSchoolDetailList();
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };

  const addSchoolDetail = (data) => {
    console.log(data);
    setIsLoading(true)
    ApplicationService.addSchoolDetail(data)
      .then((res) => {
        setIsLoading(false)
        toast.success(res?.message);
        getSchoolDetailList();
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };


  const updateSchoolDetail = (data) => {
    console.log(data);
    setIsLoading(true)
    ApplicationService.updateSchoolDetail(data)
      .then((res) => {
        setIsLoading(false)
        // toast.success(res?.message);
        toast.success("School updated successfully!");
        getSchoolDetailList();
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      });
  };



  const onEducationCountryChange = (value) => {
    ApplicationService.getGradingSchemeAsync(value).then((res)=>{
        setGrdSchemeData(res);
      }).catch((error)=>{
       
      })
}


    const getMode=(id)=>{
      setModeId(id)

    }
    const getCampus=(id)=>{
      setCampusId(id)

    }
    const getSpecialization=(id)=>{
      setSpecializationId(id)

    }
    const getResiding=(id)=>{
      setResidingId(id)

    }
    const getPersonalInfoData=(data)=>{
      setPersonalInfoData(data)
    }
    const getGenContectInfo=(data)=>{
      setGenContectInfoData(data)
    }

    const getTestScore=(data)=>{
      setTestScoreData(data)
    }

    const getEducationInfo=(data)=>{
      setEducationInfo(data)
    }
    const getBackupProgramId=(BProgram1Id, BProgram2Id, BProgram3Id)=>{
      setBProgram1(BProgram1Id)
      setBProgram2(BProgram2Id)
      setBProgram3(BProgram3Id)

    }

     const getValidation=(value, type)=>{
      if(type===1){
        setValidationForProgramSetion(value)
      }
      // if(type===3){
      //   setValidationForPersonalSetion(value)
      // }
      // if(type===4){
      //   setValidationForContactSetion(value)
      // }
      if(type===5){
        setValidationForEducationSetion(value)
      }
      if(type===6){
        setValidationForTextScoreSetion(value)
      }
    }

const addApplication=()=>{

  setValidationFlag(true)
  if(validationForProgramSetion.institute!== null || validationForProgramSetion.program!==null || validationForProgramSetion.campus!==null||
    validationForProgramSetion.intake!==null || validationForProgramSetion.specialization!==null || validationForProgramSetion.mode!==null||
    validationForProgramSetion.residing!==null){
      getScroll('Program');
      return
    }
    // else if(
    //   validationForPersonalSetion.Salution!== null || validationForPersonalSetion.FirstName!==null || validationForPersonalSetion.LastName!==null ||
    //   validationForPersonalSetion.Gender!==null || validationForPersonalSetion.MaritialStatus!==null || validationForPersonalSetion.DateOfBirth!==null ||
    //    validationForPersonalSetion.Email!==null ||validationForPersonalSetion.MobileNoCountryCode!==null ||
    //   validationForPersonalSetion.MobileNo!==null || validationForPersonalSetion.Citizenship!==null || validationForPersonalSetion.PassportExpiryDate!==null ||
    //   validationForPersonalSetion.PassportNo!==null){
    //   getScroll('Personal');
   
    //   return
    // }
    // else if(validationForContactSetion.Addres!== null || validationForContactSetion.Country!==null || validationForContactSetion.Province!==null || 
    //   validationForContactSetion.City!==null || validationForContactSetion.Pincode!== null  || validationForContactSetion.MailingAddress!== null ||
    //   validationForContactSetion.MailingCountry!== null || validationForContactSetion.MailingProvince!==null || validationForContactSetion.MailingCity!== null  ||
    //   validationForContactSetion.MailingPincode!== null || validationForContactSetion.EmergencyName!== null  ||  validationForContactSetion.EmergencyRelation!== null  ||
    //   validationForContactSetion.EmergencyEmail!== null ||
    //   validationForContactSetion.EmergencyCellPhoneCountryCode!== null ||validationForContactSetion.EmergencyCellPhone!== null){
    //     getScroll('Contact') 
    //     return
    // }
    else if(validationForEducationSetion.CountryOfEducation!== null || validationForEducationSetion.HighestLevelOfEducation!== null || validationForEducationSetion.GradingScheme!== null || 
      validationForEducationSetion.GradeAverage!== null){
      getScroll('Education') 
      return
    }

    const hasTextScoreErrors = Object.values(validationForTextScoreSetion).some((value) => value !== null);
    if (hasTextScoreErrors) {
      console.log(validationForTextScoreSetion);
      getScroll("TextScore"); // Adjust scroll as needed
      return;
    }


 let data= {
  programInfo: {
    AppSourceCode: "",
    ApplicationId: Number(applicationId),
    ApplicationMode: Number(modeId),
    ApplicationStatus: 0,
    B1ProgramId: Number(BProgram1),
    B2ProgramId: Number(BProgram2),
    B3ProgramId: Number(BProgram3),
    Campus: campusId,
    InstitutionId: Number(instituteId),
    IntekId: Number(intakeId),
    ProgramId: Number(programId),
    ShoreType:Number(residingId),
    SpecializationId: specializationId
  },
  genInfo: {
    About: "",
    Addres: genContectInfoData?.Addres,
    AdmissionExecutive: studentData?.genInfo?.AdmissionExecutive,
    AgentId: Number(studentData?.genInfo?.AgentId),
    App_Password: studentData?.genInfo?.App_Password,
    ApplicationId: applicationId,
    Area: studentData?.genInfo?.Area,
    AssignedTo:Number(studentData?.genInfo?.AssignedTo)?Number(studentData?.genInfo?.AssignedTo):0,
    B2CCounsellorId: Number(studentData?.genInfo?.B2CCounsellorId)?Number(studentData?.genInfo?.B2CCounsellorId):0,
    Citizenship: Number(personalInfoData?.Citizenship),
    City: genContectInfoData?.City,
    ConnectionCount: 0,
    Country:Number(genContectInfoData?.Country),
    CountryName: "",
    DateOfBirth: personalInfoData?.DateOfBirth,
    Email: personalInfoData?.Email,
    EmergencyAddress: genContectInfoData?.EmergencyAddress,
    EmergencyAddressSame: genContectInfoData?.EmergencyAddressSame,
    EmergencyBusinessPhone: studentData?.genInfo?.EmergencyBusinessPhone,
    EmergencyBusinessPhoneCountryCode: studentData?.genInfo?.EmergencyBusinessPhoneCountryCode,
    EmergencyCellPhone:  genContectInfoData?.EmergencyCellPhone,
    EmergencyCellPhoneCountryCode: genContectInfoData?.EmergencyCellPhoneCountryCode,
    EmergencyCity:  genContectInfoData?.EmergencyCity,
    EmergencyCountry:Number(genContectInfoData?.EmergencyCountry)?Number(genContectInfoData?.EmergencyCountry):0,
    EmergencyEmail: genContectInfoData?.EmergencyEmail,
    EmergencyPincode: genContectInfoData?.EmergencyPincode,
    EmergencyProvince: Number(genContectInfoData?.EmergencyProvince)?Number(genContectInfoData?.EmergencyProvince):0,
    EmergencyRelation: genContectInfoData?.EmergencyRelation,
    EmrgencyName: genContectInfoData?.EmrgencyName,
    FirstLogin: false,
    FirstName: personalInfoData?.FirstName,
    Gender: personalInfoData?.Gender,
    GovernmentIdNumber: personalInfoData?.GovernmentIdNumber,
    GovernmentIdType: personalInfoData?.GovernmentIdType,
    HouseNumber: studentData?.genInfo?.HouseNumber,
    Language: personalInfoData?.Language,
    LastName: personalInfoData?.LastName,
    LeadSourceId: 0,
    LeadSourceSecondaryId:0,
    LeadSourceText: "",
    LeadSourceThirdId: 0,
    MailingAddres: genContectInfoData?.MailingAddres,
    MailingAddressSame: genContectInfoData?.MailingAddressSame,
    MailingCity: genContectInfoData?.MailingCity,
    MailingCountry:Number(genContectInfoData?.MailingCountry),
    MailingPincode:genContectInfoData?.MailingPincode,
    MailingProvince:  Number(genContectInfoData?.MailingProvince),
    MailingProvinceName: "",
    MaritialStatus:  personalInfoData?.MaritialStatus,
    MarketingManager: studentData?.genInfo?.MarketingManager,
    MiddleName: personalInfoData?.MiddleName,
    MobileNo: personalInfoData?.MobileNo,
    MobileNoCountryCode: personalInfoData?.MobileNoCountryCode,
    PassportExpiryDate: personalInfoData?.PassportExpiryDate,
    PassportNo: personalInfoData?.PassportNo,
    Pincode: genContectInfoData?.Pincode,
    PreferredCountryId: studentData?.genInfo?.PreferredCountryId,
    PreferredInstitution: studentData?.genInfo?.PreferredInstitution,
    PreferredProgramId: studentData?.genInfo?.PreferredProgramId,
    PreferredProgramLevel: studentData?.genInfo?.PreferredProgramLevel,
    ProgramTypeID: progranTypeId,
    Province: Number(genContectInfoData?.Province),
    ProvinceName: "",
    PunchedFrom: 0,
    Salution: personalInfoData?.Salution,
    SecLanguage: "",
    StreetName: studentData?.genInfo?.StreetName,
    StudentId: studentId,
    TagLine: ""
  },
    eduInfo: {
      ApplicationId: applicationId,
      CountryOfEducation:Number(educationInfo?.CountryOfEducation),
      HighestLevelOfEducation: Number(educationInfo?.HighestLevelOfEducation),
      GradingScheme: Number(educationInfo?.GradingScheme),
      GradeAverage: educationInfo?.GradeAverage,
      GraduatedMostRecent:0,
      EduYearEnd:educationInfo?.EduYearEnd?educationInfo?.EduYearEnd:"",
      EduYearStart: educationInfo?.EduYearStart?educationInfo?.EduYearStart:"",
      StudentId: studentId
    },
    testScore: {
      StudentId: studentId,
      EnglishExamType: Number(testScoreData?.EnglishExamType),
      EnglishExamDate: testScoreData?.EnglishExamDate,
      EnglishScoreL:Number(testScoreData?.EnglishScoreL),
      EnglishScoreR: Number(testScoreData?.EnglishScoreR),
      EnglishScoreW: Number(testScoreData?.EnglishScoreW),
      EnglishScoreS: Number(testScoreData?.EnglishScoreS),
      GREExamDate: testScoreData?.GREExamDate,
      GREScoreV: Number(testScoreData?.GREScoreV),
      GREScoreQ: Number(testScoreData?.GREScoreQ),
      GREScoreW: Number(testScoreData?.GREScoreW),
      GMATExamDate: testScoreData?.GMATExamDate,
      GMATScoreA: Number(testScoreData?.GMATScoreA),
      GMATScoreI: Number(testScoreData?.GMATScoreI),
      GMATScoreQ: Number(testScoreData?.GMATScoreQ),
      GMATScoreV:Number(testScoreData?.GMATScoreV),
      GREExam: Number(testScoreData?.GREExam),
      GMATExam: Number(testScoreData?.GMATExam),
      EnglishScoreOverall: Number(testScoreData?.EnglishScoreOverall),
      EnglishExamUser: testScoreData?.EnglishExamUser,
      EnglishExamPswd: testScoreData?.EnglishExamPswd,
      EnglishExamRemark: testScoreData?.EnglishExamRemark,
      SATScoreTotal: Number(testScoreData?.SATScoreTotal),
      SATScoreMath:Number(testScoreData?.SATScoreMath),
      SATScoreEBRW:Number(testScoreData?.SATScoreEBRW),
      ACTScoreTotal: Number(testScoreData?.ACTScoreTotal),
      ACTScoreEnglish: Number(testScoreData?.ACTScoreEnglish),
      ACTScoreMath:Number(testScoreData?.ACTScoreMath),
      ACTScoreReading:Number(testScoreData?.ACTScoreReading),
      ACTScoreScience: Number(testScoreData?.ACTScoreScience),
      ACTScoreWriting: Number(testScoreData?.ACTScoreWriting),
      EnglishExamDocPath: testScoreData?.EnglishExamDocPath,
      OtherExamDocPath: testScoreData?.OtherExamDocPath,
      OtherExamUser: testScoreData?.OtherExamUser,
      OtherExamPswd:testScoreData?.OtherExamPswd
    }
  }


setIsLoading(true)
ApplicationService.addApplication(data).then(res=>{
  setIsLoading(false)
  if(res?.message==='Program exist for application' || res?.message==='This institution is not accepting application with your citizenship'){
    toast.error(res?.message)
    return;
  }
   setApplicationId(Number(res?.response))
  toast.success("Application created successfully.")
  updateGeninfoData(data?.genInfo);
  updateEducationData(data?.eduInfo);
  updateTestScoreData(data?.testScore)
  preferenceAdd(Number(res?.response))
  navigate('/member/application/applicationstatus/view/' + Number(res?.response))

}).catch(error=>{
  setIsLoading(false)
  toast.error(error?.message)
})

}

const updateGeninfoData=(data)=>{
  setIsLoading(true)
  ApplicationService.getUpdateGenInfo(data).then(res=>{
    setIsLoading(false)
  }).catch(error=>{
    setIsLoading(false)
  })

}

const updateEducationData=(data)=>{
  data.StudentId=studentId
  setIsLoading(true)
  ApplicationService.udateEducation(data).then(res=>{
    setIsLoading(false)
  }).catch(error=>{
    setIsLoading(false)
  })
}

const updateTestScoreData=(data)=>{
  setIsLoading(true)
  ApplicationService.udateTestScore(data).then(res=>{
    setIsLoading(false)
  }).catch(error=>{
    setIsLoading(false)
  })
}

const preferenceAdd=(applicationIdValue)=>{
let data={
  ApplicationId:applicationIdValue,
  ApplicationPreference:[{CampusId:campusId?campusId:0, IndexId:0, ModeId:Number(modeId),
     ProgramId:Number(programId), ShoreType: Number(residingId), SpecializationId:specializationId?specializationId:0}]
}
setIsLoading(true)
ApplicationService.preferenceAdd(data).then(res=>{
  setIsLoading(false)
  uploadBulkDoc(applicationIdValue)

}).catch(error=>{
  setIsLoading(false)
})
}

const getPendingDoc=(data)=>{
  const newArray = data.map(({ DocPath, DocumentTypeId }) => ({
    DocPath,
    DocumentTypeId,
  }));

  setPendingDoc(newArray)
}

const uploadBulkDoc=(applicationIdValue)=>{
let data={
  Documents:pendingDoc,
  ParentId:applicationIdValue,
  ParentType:7
}

StudentServices.pendingDocumentUpload(data).then(res=>{

}).catch(error=>{
  setIsLoading(false)
  toast.error(error?.message)
})
 }


//  fisrt api to call
 const viewV1Application = () => {
    setIsLoading(true)
    ApplicationService.viewV1Application(applicationId)
      .then((res) => {
        if (res) {
           setInstituteId(res?.programInfo?.InstitutionId)
           setProgramId(res?.programInfo?.ProgramId)
           setCountryId(res?.genInfo.Citizenship)
            setApplicationDetailData(res);
            setProgranTypeId((res.genInfo.ProgramTypeID || res.genInfo.ProgramTypeID===0) ? 1 : res.genInfo.ProgramTypeID);
            getInstituteList((res.genInfo.ProgramTypeID || res.genInfo.ProgramTypeID===0) ? 1 : res.genInfo.ProgramTypeID);
            setCountryId(res?.genInfo?.Citizenship)
            setCitizenship(res?.programInfo?.Citizenship);
            getProgramList(res?.programInfo?.InstitutionId, res?.genInfo.Citizenship, res.genInfo.ProgramTypeID ? res.genInfo.ProgramTypeID : 1);
            getIntakeList(res?.programInfo?.ProgramId);
            getProgramModeList(res?.programInfo?.ProgramId);
            getProgramSpecialization(res?.programInfo?.ProgramId);
            setProgramIdForUploadDocument(res?.programInfo?.ProgramId);
            CheckInstitutionIntakeExists(res?.programInfo?.ProgramId, studentId, res?.programInfo?.InstitutionId, res?.programInfo?.IntekId)
            getAssignedData(res?.programInfo?.InstitutionId)
            getProgramParentDocumentList(applicationId, res?.programInfo?.ProgramId, res?.genInfo.Citizenship);
            pendingDocumentsCount(applicationId)
            getFeeAndDocumentStatus(applicationId);   
        } 
        setIsLoading(false)
        setApplicationDetailData(res);
        setProgranTypeId(res.genInfo.progranTypeId? res.genInfo.progranTypeId : 1 );
        getProgramDetails(res.programInfo?.ProgramId);
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(error?.message);
      });
  };


  const getDocumentUpdate = () => {
       pendingDocumentsCount(applicationId);
       getProgramParentDocumentList(applicationId, programId, countryId);
       getFeeAndDocumentStatus(applicationId)
    
  };

  const getFeeAndDocumentStatus = (id) => {
    setIsLoading(true)
    ApplicationService.feeAndDocumentStatus(id)
        .then((res) => {
            if (res) {
                setFeeAndDocumentStatusData(res);
            }
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            toast.error(error?.message);
        });
};

  
  const submitApplicationHandler = () => {
    if (feeAndDocumentStatusData.DocumentsStatus === 'Pending') {
        setValidationModalSize('md');
        setAlertText("Oops! Document(s) is Pending!");
        setHintText("Documents are still pending. Please complete them before submitting.");
        setValidationModalIsVisible(true);
        return;
    }

    else if (feeAndDocumentStatusData.ApplcaitionFeeStatus === 'Pending') {
      setValidationModalSize('md');
        setAlertText("Oops! Application Fee(s) is Pending!");
        setHintText("Application fee is still pending. Please complete it before submitting.");
        setValidationModalIsVisible(true);
        return;
    }

    else {
        setConfirmationText("After submission, you can't change any information in this application. Make sure all information is correct and verified!");
        setConfirmationPopupFlag(true);
    }
};



const validationCloseHandler = () => {
  setModalSize('')
  setValidationModalIsVisible(false);
  if(feeAndDocumentStatusData.DocumentsStatus === 'Pending'){
    getScroll('PendingDocument')
    return
  }
  else if(feeAndDocumentStatusData.ApplcaitionFeeStatus === 'Pending'){
    getScroll('Fee')
    return
  }
}

const closeConfirmationModal = () => {
  setConfirmationText("");
  setConfirmationPopupFlag(false);
}

const getConfirmationHandler = () => {
  setIsLoading(true);
      ApplicationService.submitApplication(applicationId)
      .then((res) => {
          toast.success(res.message);
          setConfirmationText("");
          setConfirmationPopupFlag(false);
          viewV1Application(applicationId);
          setIsLoading(false);
      })
      .catch((error) => {
          setConfirmationText("");
          setConfirmationPopupFlag(false);
          setIsLoading(false);
          toast.error(error?.message);
      });
  }


  const creditCardEditMode=()=>{
    setCreditCardEdit(true)
  }
  
  const disableCreditCard=()=>{
    setCreditCardEdit(false)
  }

  const editProgramInfo=()=>{
    setProgramInfoUpdate(true)
    setViewApplication(true)
  }
  
  const disableProgramInfo=()=>{
    setProgramInfoUpdate(false)
    setViewApplication(false)
    viewV1Application()
  }

  const editTestScoreData=()=>{
    setEditTestScore(true)
    setIsTestScore(true)
  }
  
  const disableEditTestScore=()=>{
    setEditTestScore(false)
    setIsTestScore(false)
    viewV1Application()
    getStudentData();
  }

  const editEducationDetail=()=>{
    setEditEducation(true)
    setViewEducation(true)
  }

  const disableEditEducationDetail=()=>{
    setEditEducation(false)
    viewV1Application()
    setViewEducation(false)
  }


  

useEffect(() => {
  if (!initial.current) {
    initial.current = true;
    viewV1Application();
    getCountryCode();
    getCountryList();
    getStudentData();
    getStudentDataBySectionwise();
    getRelation();
    getLanguageList();
    getApplicationList();
    getEducationLevelData();
    getSchoolDetailList();
 
}
}, []);

const closefeePaymentsuccessModal = () => {
  getFeeAndDocumentStatus(applicationId);
}




   return(
       <>

            {validationModalIsVisible && (
          <ValidationModal onShow={validationModalIsVisible} size={validationModalSize} closeHandler={validationCloseHandler} alertText={alertText} hintText={hintText} />
            )}
            {confirmationPopupFlag && (
                <ConfirmationModal onShow={confirmationPopupFlag} btnText="Yes" confirmationText={confirmationText} onClose={closeConfirmationModal} getConfirmation={getConfirmationHandler} />
            )}
        <div>
            <div>
                <div className={styles['Edit_msm_Container']}>
                    <div className={styles['heading']}>Edit MSM Application</div>
                     <div>
                      { !applicationDetailData?.genInfo?.SubmitoAdminDate && (
                         <span className={styles['warn-message']}>You are yet to submit this application</span>
                      )}
                        

                        
                     { applicationDetailData?.programInfo?.ApplicationStatusName && (
                     <button className={styles['create-app-btn']}>{applicationDetailData?.programInfo?.ApplicationStatusName }</button> 
                      )}

                     
                     <span><img  src={crossIcon} alt="" className={styles['cross-icon']} /> </span>
                     </div>
                </div>
                <div className={styles['status_btn_Container']}>
                    <div className={styles['endContainer']}>

                            <div className="text-center"><input checked={applicationDetailData?.stageInfo?.Submitted === 1} disabled type="checkbox" name="" id="" /><span className={styles['endLabel']}>Submitted to MSM Unify
                            {applicationDetailData?.stageInfo?.Submitted === 1 && (
                            <span>
                              &nbsp;on {new Date(applicationDetailData?.stageInfo?.SubmittedDate).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                              })}
                            </span>
                          )}</span></div>

                            <div className="text-center"><input checked={applicationDetailData?.stageInfo?.ReviewDone === 1} disabled  type="checkbox" name="" id="" /><span className={styles['endLabel']}>Review Done
                            {applicationDetailData?.stageInfo?.ReviewDone === 1 && (
                            <span>
                              &nbsp;on {new Date(applicationDetailData?.stageInfo?.ReviewDoneDate).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                              })}
                            </span>
                          )}</span></div>

                            <div className="text-center"><input checked={applicationDetailData?.stageInfo?.SubmittedToInstitute === 1} disabled  type="checkbox" name="" id="" /><span className={styles['endLabel']}>Submitted to Institution
                            {applicationDetailData?.stageInfo?.SubmittedToInstitute === 1 && (
                            <span>
                              &nbsp;on {new Date(applicationDetailData?.stageInfo?.SubmittedToInstituteDate).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                              })}
                            </span>
                          )}</span></div>

                        </div>
                        { !applicationDetailData?.genInfo?.SubmitoAdminDate && (
                        <div className={styles['text-end-content']}>
                        {/* <button  onClick={addApplication} className={styles['save-btn']}>Save</button> */}
                        <button className={styles['submit-btn']} onClick={submitApplicationHandler}>Submit</button>
                       </div>
                        )}

                </div>

                <div className={styles['tabs-section']}>
                <div className={styles['tab-alignment']}>
                    {tabs.map(item=>(
                        <div onClick={()=>getScroll(item?.id)} className={styles['tab-text']}><p>{item?.name}</p></div>
                    ))}
                </div>

                </div>
                {programDetails && (
                        <div className={styles["program-details"]}>
                        <div className="row">
                            <div
                            className={`${styles["col-white-background"]} col-md-3 col-sm-12`}
                            >
                            <img
                                className={styles["institue-image"]}
                                 src={programDetails?.InstLogoPath ? blobURL + programDetails.InstLogoPath : institutesImage} 
                               
                                alt=""
                            ></img>
                            </div>
                            <div className={`${styles["col-background"]} col-md-9 col-sm-12`}>
                            <div className="row">
                            
                            <div
                            className={`${styles["col-background"]} col-md-4 col-sm-12`}
                            >
                            <p className={styles["sub-heading"]}>Duration</p>
                            <p className={styles["sub-heading-result"]}>
                                {programDetails?.DurationTime}
                            </p>
                            </div>
                          
                            <div
                            className={`${styles["col-background"]} col-md-4 col-sm-12`}
                            >
                            <p className={styles["sub-heading"]}>
                                Average Processing Time
                            </p>
                            <p className={styles["sub-heading-result"]}>{programDetails?.AverageProcessingDay}</p>
                            </div>

                            <div className={`${styles["col-background"]} col-md-4 col-sm-12`}>
                            
                            <p className={styles['sub-heading']}>Cost of Living</p>
                            <p className={styles['sub-heading-result']}>{programDetails?.InstCostofLiving_Year}</p>
                           
                          </div>

                            <div className={`${styles["col-background"]} col-md-4 col-sm-12`}>
                            <p className={styles['sub-heading']}>Submission Deadline</p>
                            <p className={styles['sub-heading-result']}>{applicationDetailData?.programInfo?.SubmissionDeadline}</p>
                            </div>
                            {programDetails?.FeeDetail.map(fee=>(
                            <div
                            className={`${styles["col-background"]} col-md-4 col-sm-12`}
                        >
                            <p className={styles["sub-heading"]}>{fee?.FeeType}</p>
                            <p className={styles["sub-heading-result"]}>
                            {programDetails?.ProgramCurrency} {fee?.FeeAmount} {fee?.FeeBasis}
                            </p>
                          </div>
                          
                            ))}
                            </div>
                           

                        </div>
                        </div>
                        </div>
                      )}
           
                <div id="Program" className="mb-3">
                    <div className={styles['header']}>
                        <p>Program</p>
                        <div>
                        { !applicationDetailData?.genInfo?.SubmitoAdminDate && (
                          <img onClick={editProgramInfo} title='Edit' src={editIcon} alt=''></img>
                        )}
                        </div>
                    </div>
                    {applicationDetailData?.programInfo && 
                    <ProgramSection
                          data={applicationDetailData?.programInfo}
                          institutionData={institionList}
                          programData={programList}
                          intakeData={intakeList}
                          programModeData={programMode}
                          campusList={campusList}
                          programSpecializationData={programSpecialization}
                          onInstituteChnage={getInstituteId}
                          onProgramChnage={getProgramId}
                          onIntakeChange={getIntakeId}
                          onModeChange={getMode}
                          onResidingChange={getResiding}
                          onSpecializationChange={getSpecialization} onCampusChange={getCampus}
                          getBackupProgramId={getBackupProgramId}
                          getValidation={getValidation}
                          disableProgramInfo={disableProgramInfo} editProgramInfo={programInfoUpdate}
                          viewApplication={viewApplication}
                          applicationId={applicationId}
                        />
                        }
                </div>
                <div id="Education" className="mb-3">
                <div className={styles['header']}>
                        <p>Education</p>
                        <div>
                        { !applicationDetailData?.genInfo?.SubmitoAdminDate && (
                          <img onClick={editEducationDetail} title='Edit' src={editIcon} alt=''></img>
                        )}
                        </div>
                </div>
                <div>
                    {/* <EducationSection/> */}
                    {studentData && (
                <EducationSection
                educationLevelData={educationLevelData}
                grdSchemeData={grdSchemeData} 
                onEducationCountryChange={onEducationCountryChange}
                countryList={countryList}
                schoolList={schoolList}
                deleteSchoolDetail={deleteSchoolDetail}
                studentId={studentId}
                educationInfo={studentData?.eduInfo}
                getEducationInfo={getEducationInfo}
                getSchoolDetailList={getSchoolDetailList}
                getValidation={getValidation}
                editEducation={editEducation}
                disableEditEducationDetail={disableEditEducationDetail}
                applicationId={applicationId}
                viewEducation={viewEducation}
              ></EducationSection>
            )}
                </div>
                </div>
                <div id="TestScores" className="mb-3">
                <div className={styles['header']}>
                        <p>Test Scores</p>
                        <div>
                        { !applicationDetailData?.genInfo?.SubmitoAdminDate && (
                          <img onClick={editTestScoreData} title='Edit' src={editIcon} alt=''></img>
                        )}
                        </div>
                    </div>
                   {studentData?.testScore && <TestScores getTestScore={getTestScore} testScoreData={studentData?.testScore} 
                   getValidation={getValidation} editTestScore={editTestScore} disableEditTestScore={disableEditTestScore} 
                   isTestScore={isTestScore} />}
                </div>
                <div id="CreditCard" className="mb-3">
                <div className={styles['header']}>
                        <p>Credit Card</p>
                        <div>
                        { !applicationDetailData?.genInfo?.SubmitoAdminDate && (
                          <img onClick={creditCardEditMode} title='Edit' src={editIcon} alt=''></img>
                        )}
                        </div>
                    </div>
                    <CreditCardSection disableCreditCard={disableCreditCard} creditCardEdit={creditCardEdit} applicationId={applicationId}/>
                </div>
                <div id="Documents" className="mb-3">
                <div className={styles['header']}>
                        <p>Student Documents</p>

                </div>
                    {documentList && <StudentDocumentsSection applicationId={applicationId} getDocumentUpdate={getDocumentUpdate}  rowData={documentList}/>} 
                </div>
                {documentStatusCount > 0 && (
               <div id="PendingDocument" className="mb-3">
               <div className={styles['header']}>
                       <p>Pending Documents</p>
                   </div>
                   { pendingDocumentTypeList.length > 0 && (
                    <ApplicationPendingDocument applicationId={applicationId} getDocumentUpdate={getDocumentUpdate} pendingDocumentTypeList={pendingDocumentTypeList}>

                    </ApplicationPendingDocument>
                   )}
               </div>
                )}
 
                <div id="InstitutionDocument" className="mb-3">
                <div className={styles['header']}>
                        <p>Institution Documents</p>
                    </div>
                    <InstitutionDocuments applicationId={applicationId}></InstitutionDocuments>
                </div>
                {rowData && (
               
                <div id="AssignedCounsellor" className="mb-3">
                <div className={styles['header']}>
                        <p>Assigned Counsellor</p>
                </div>
                <AssignedCounsellor rowData={rowData}></AssignedCounsellor>
                </div>
                 )}
                <div id="Fee" className="mb-3">
                <div className={styles['header']}>
                        <p>Fee</p>
                    </div>
                    {applicationDetailData?.programInfo && (
                    <FeeSection applicationData={applicationDetailData} feeStatus={feeAndDocumentStatusData} feeSuccessToastHandler={closefeePaymentsuccessModal} />
                    )}
                  
                </div>
                <div id="Note" className="mb-3">
                <div className={styles['header']}>
                        <p>Note</p>
                    </div>
                    <div>
                        <NoteSection applicationId={applicationId}></NoteSection>
                    </div>
                </div>
                    
                
            </div>
       </div>
       {isLoading &&(
        <Loader></Loader>
       )}
       </>
   )
}
export default ApplicationDetails;