
import React, { useState } from "react";
import classes from './ConfirmationModal.module.css'
import { Modal } from "react-bootstrap";
import corssIcon from "../../../../Assets/Logo/cross-icon.svg"

const ConfirmationModal =(props)=>{
//     const confirm=()=>{
//         props?.getConfirmation()
//         props.onClose(false)
//     }
//      const onClose=()=>{
//       props.onClose(false)
//  }
    return(
        <div>
            <Modal show={props.onShow}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered >
            <Modal.Body>
                <div className={classes['cross-icon-position']}>
                <img title="Close" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={props.onClose}></img>
                </div>
                <div className={classes['modal-body-data']}>
                    <p>{props.confirmationText}</p>
                </div>
               
                <div className={classes['btn-position']}>
                <button className={classes['primary-btn']} onClick={props.getConfirmation}>{props.btnText}</button>
                </div>
                <div className={classes['btn-position']}>
                <p className={classes['cancel-btn']} onClick={props.onClose}>Cancel</p>
                </div>

            </Modal.Body>

        </Modal>
        </div>
    )
}


export default ConfirmationModal;