import React, { useEffect, useState } from "react";
import classes from "./Section.module.css";
import ChangePassword from "./ChangePassword";
import vectorRightIcon from "../.../../../../../../../Assets/Logo/Vector_right.svg";
import DeactivateAccount from "./DeactivateAccount";
function SettingsComponent() {
  const [view, setView] = useState("main");

  const handleBack = () => {
    setView("main");
  };
  useEffect(() => {}, []);
  if (view === "changePassword") {
    return <ChangePassword onBack={handleBack} />;
  }
  if (view === "accountManagement") {
    return <DeactivateAccount onBack={handleBack} />;
  }
  return (
    <>
    <div className={classes["setting-container"]}>
      <div
        className={`${classes["setting-section"]} card`}>
        <div className={classes["setting-title"]}>Security
        </div>
        <div className="d-flex justify-content-between mt-2">
        <div className={classes["setting-description"]}>Change Password</div>
          <span className={classes["next-icon"]} onClick={() => setView("changePassword")}>
            <img src={vectorRightIcon}></img>
          </span>
        </div>
      </div>
      <div
        className={`${classes["setting-section"]} card mt-3`}>
       <div className={classes["setting-title"]}>
          Account Management
        </div>
        <div className="d-flex justify-content-between mt-2">
        <div className={classes["setting-description"]}>Deactivate Account</div>
          <span className={classes["next-icon"]} onClick={() => setView("accountManagement")}>
            <img src={vectorRightIcon}></img>
          </span>
        </div>
      </div>
      </div>
    </>
  );
}

export default SettingsComponent;
