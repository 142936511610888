import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useLocalStorage = (key, initialValue) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(user));
    } catch (error) {
      console.error(error);
    }
  }, [key, user]);

  const logout = () => {
    setUser(false);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem(key);
    navigate('/');
  };

  return [user, setUser, logout];
};

export default useLocalStorage;