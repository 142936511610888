import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import styles from "./ToolsComponent.module.css";
import ToolsSidebar from "../ToolsSidebar/ToolsSidebar";

function ToolsComponent() {
  return (
    <>
      <>
        <div className={styles["main-application-section"]}>
          <div>
            <ToolsSidebar />
          </div>
          <div className={styles["application-area"]}>
            <Outlet />
          </div>
        </div>
      </>
    </>
  );
}
export default ToolsComponent;
