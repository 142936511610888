import classes from './ValidationModal.module.css';
import redCross from '../../../../Assets/Logo/red-cross-icon.svg';
import cross from '../../../../Assets/Logo/cross-icon.svg';
import { Modal } from 'react-bootstrap';

function ValidationModal({ onShow, size, closeHandler, alertText, hintText }) {
    return (
        <Modal
            show={onShow}
            size={size}
            centered
            className={classes["success-modal-block"]}
        >
            <Modal.Body className={classes["success-modal-body"]}>
                <div className={classes["validation-modal-block"]}>
                    <div className={classes["validation-modal-header"]}>
                        <div><img className={classes["cross-icon"]} src={cross} alt="cross" onClick={closeHandler} /></div>
                    </div>
                    <div className={classes["validation-modal-body"]}>
                        <div className={classes["validation-modal-body-icon"]}>
                            <img src={redCross} alt="Red Cross" />
                        </div>
                        <div className={classes["validation-modal-alert-text"]}>{alertText}</div>
                        <div className={classes["validation-modal-hint"]}>{hintText}</div>
                        {/* <div className={classes["validation-modal-text"]}>You’ll have to wait until the payment goes through</div>
                            <div className={classes["validation-modal-hint"]}>Ensure your Credit Card information is correct and up-to-date</div> */}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ValidationModal;