import React from 'react';
import styles from './Groups.module.css';
import profilePic1 from '../../../../../../../src/Assets/Images/picture1.png';
import profilePic2 from '../../../../../../../src/Assets/Images/picture2.png';
import profilePic3 from '../../../../../../../src/Assets/Images/picture3.png';
import profilePic4 from '../../../../../../../src/Assets/Images/picture4.png';
import profilePic5 from '../../../../../../../src/Assets/Images/picture5.png';
import profilePic6 from '../../../../../../../src/Assets/Images/picture6.png';
import profilePic7 from '../../../../../../../src/Assets/Images/picture7.png';
import profilePic8 from '../../../../../../../src/Assets/Logo/add.svg';
const GroupItem = ({ groupName, message, members }) => (
  <div className={styles.groupItem}>
    <div className={styles.groupIcon}></div>
    <div className={styles.groupContent}>
      <div className={styles.groupName}>{groupName}</div>
      <div 
        className={styles.groupMessage}
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <div className={styles.groupMembers}>
        {members.map((member, index) => (
          <img key={index} src={member} alt="member" className={styles.memberIcon} />
        ))}
      </div>
    </div>
    <div className={styles.groupAction}>
      <button className={styles.addButton}>
        <img src={profilePic8} alt="add" className={styles.addButtonIcon}/>
      </button>
    </div>
  </div>
);

const Groups = () => {


  const groups = [
    {
      groupName: 'Study Group',
      message: '<strong>Duane:</strong> With that, we should be able to complete the project',
      members: [profilePic1, profilePic2, profilePic3, profilePic4],
    },
    {
      groupName: 'Engineer’s Advice',
      message: '<strong>Tim Chow:</strong> That’s all I have time for right now',
      members: [profilePic1, profilePic5, profilePic6, profilePic7, profilePic2],
    },
    {
      groupName: 'Family Chat',
      message: '<strong>Dad:</strong> Good news! Make sure to study hard though',
      members: [profilePic1, profilePic5, profilePic7],
    },
  ];
  return (
    <div className={styles.groupsContainer}>
      <div className={styles.header}>
        <span className={styles.headerText}>Groups:</span>
        <a href="/" className={styles.viewMore}>View More...</a>
      </div>
      {groups.map((group, index) => (
        <GroupItem 
          key={index}
          groupName={group.groupName}
          message={group.message}
          members={group.members}
        />
      ))}
    </div>
  );
};

export default Groups;
