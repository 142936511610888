import React, { useState } from "react";
import classes from "./Settings.module.css";
import SettingsComponent from "./Section/SettingsComponent";
import Preferences from "./Section/Preferences";

function Settings() {
  const [activeSection, setActiveSection] = useState("settings");

  return (
    <div className={`${classes["setting-container"]}`}>
    <div className={`${classes["setting-section"]} row`}>
        <div className={`${classes["setting-left-section"]} col-2 p-0`}>
          <div className={classes["setting-left-content"]}>
            <div
              className={`${classes["alphaComp"]} ${activeSection === "settings" ? classes["activeMenuBar"] : ""}`}
              onClick={() => setActiveSection("settings")}>
              Settings
            </div>
            <div
              className={`${classes["alphaComp"]} ${activeSection === "preferences" ? classes["activeMenuBar"] : ""}`}
              onClick={() => setActiveSection("preferences")}>
              Preferences
            </div>
          </div>
        </div>
        <div className={`${classes["setting-right-section"]} col-10`}>
          {activeSection === "settings" && <SettingsComponent />}
          {activeSection === "preferences" && <Preferences />}
        </div>
      </div>
    </div>
  );
}
export default Settings;