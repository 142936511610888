import React, { useState } from 'react';
import styles from './Network.module.css'; // Import the CSS module
import messageIcon from '../../../../../../Assets/Logo/message.svg'; // Assume you have this icon file in the same directory
import profilePic1 from '../../../../../../Assets/Images/picture1.png'; // Assume you have this image file in the same directory
import dot from '../../../../../../Assets/Logo/dot.svg'; // Assume you have this image file in the same directory

const Network = () => {
  const [activeTab, setActiveTab] = useState('Connections'); // State to handle the active tab

  const users = [
    { name: 'Ken Heldt', description: 'New student studying Computer Science' },
    { name: 'Abby Turner', description: 'Currently going through a fine arts program' },
    { name: 'Addie Rodman', description: 'On here for general studies' },
    { name: 'Kelly Skelton', description: 'Studying political science' },
    { name: 'Duane Buffman', description: 'Looking to connect with other soon-to-be developers' },
    { name: 'Robert Vaughn', description: 'Studying English at St. John’s' },
  ];

  return (
    <div className={styles.network}>
      <div className={styles.header}>
        <span>Network:</span>
        <div className={styles.buttonBox}>
          <button
            className={`${styles.tabButton} ${activeTab === 'Connections' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('Connections')}
          >
            Connections
          </button>
          <button
            className={`${styles.tabButton} ${activeTab === 'Suggestions' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('Suggestions')}
          >
            Suggestions
          </button>
          <button
            className={`${styles.tabButton} ${activeTab === 'Invitations' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('Invitations')}
          >
            Invitations
          </button>
        </div>
        <a href="/" className={styles.viewMore}>View More...</a>
      </div>
      <ul className={styles.userList}>
        {users.map((user, index) => (
          <li key={index} className={styles.userItem}>
            <div className={styles.userInfo}>
              <img src={profilePic1} alt={user.name} className={styles.avatar} />
              <div>
                <h3 className={styles.username}>{user.name}</h3>
                <p className={styles.userdescription}>{user.description}</p>
              </div>
            </div>
            <div className={styles.actions}>
              <button className={styles.iconButton}><img src={dot} alt="dot Icon" className={styles.icondot} /></button>
              <button className={styles.iconButton}>
                <img src={messageIcon} alt="Message Icon" className={styles.iconmessage} />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Network;
