import React from "react";
import classes from "./GroupsHeader.module.css";
import iconEdit from "../../../../../Assets/Logo/iconEdit.svg";
import iconOptions from "../../../../../Assets/Logo/iconOptions.svg";
import iconSearch from "../../../../../Assets/Logo/iconSearch.svg";
import iconShare from "../../../../../Assets/Logo/iconShare.svg";
import backgroundImage from "../../../../../Assets/Images/groups_main_background_image.png";
import { useBehaviorSubject } from "../../../../GlobleShared/BehaviorSubject/BehaviorSubject";

function GroupsHeader({image,toggleComponent, groupData}) {
  const {groupType, setGroupType, pauseFlag}=useBehaviorSubject()
  return (
    <>
    <div className={classes["group_header_container"]}>
    <div className={classes["group_header"]}>
        <img
          src={image}
          className={classes["background_image_pic"]}
          alt="Background"
        />
         { (!groupType && !pauseFlag && groupData?.UserGroupRole==='1') && (
        <img
          src={iconEdit}
          className={`${classes["icon"]} ${classes["bottomLeft"]}`}
          alt="Edit Icon"
          onClick={() =>{toggleComponent('updateGroup');}}
        />
         )}
      </div>
  </div>
    </>
  );
}

export default GroupsHeader;
