import React from 'react';
import { Modal } from 'react-bootstrap';
import classes from './ViewMediaModal.module.css';
import CrossIcon from '../../../../Assets/Logo/crose-white.svg';

const ViewMediaModal = ({ show, handleClose, mediaUrl, mediaTypeId }) => {
  return (
    <Modal show={show} onHide={handleClose} size = 'xl' centered>
      <div className='modal_header'>
      <Modal.Header>
        <Modal.Title>View Media</Modal.Title>
        <div className={classes.close_icon_position}>
        <img
          src={CrossIcon}
          alt="Close"
          className={classes.close_icon}
          onClick={handleClose}
        />
        </div>
      </Modal.Header>
      </div>
      <Modal.Body>
        <div className={classes.modal_body_data}>
        {mediaTypeId === 2 ? (
          <video controls className={classes.media}>
            <source src={mediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : null}

        {mediaTypeId === 1 ? (
          <img src={mediaUrl} alt="Media" className={classes.media} />
        ) : null}
      </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewMediaModal;