import { Modal } from 'react-bootstrap';

function SearchModal({ children, onShow, size }) {
    return (
        <Modal show={onShow}
            size={size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="search-modal">
                {children}
            </Modal.Body>
        </Modal>
    );
}

export default SearchModal;