import classes from './Programs.module.css';
import Accordion from 'react-bootstrap/Accordion';
import { Form } from "react-bootstrap";
import universityLogo from "../../../../../Assets/Logo/dougl-icon.svg";
import { useState,useEffect } from 'react';
import SearchModal from '../SearchModal/SearchModal';
import ProgramsModalBody from './ProgramsModalBody/ProgramsModalBody';

const Programs = ({ programs = [], filters = [], fees = [], courseTypes = [], onTuitionFeeChange, onProgramTypeChange, onDurationChange, selectedProgramTypeIds, selectedDurationIdss, selectedCountryId, resetFlag,fetchProgramInfo }) => {
    const [modalIsVisible, setModalIsVisible] = useState();
    const [programData, setProgramData] = useState({});
    const [modalSize, setModalSize] = useState('');

    const [selectedDurationIds, setSelectedDurationIds] = useState([]);
    const [selectedProgramTypeId, setSelectedProgramTypeId] = useState('');
    const bloburl = process.env.REACT_APP_LOGO_PATH;
    const [selectedTuitionFees, setSelectedTuitionFees] = useState([]);
    const [filterAction, setFilterAction] = useState(null); // 'apply' or 'clear'
    function hideModalHandler() {
        setModalIsVisible(false);
    }
    const clearFilters = () => {
        setSelectedTuitionFees([]);
        // setSelectedProgramTypeId('');
        setSelectedDurationIds([]);
        onTuitionFeeChange([]);
        // onProgramTypeChange('');
        onDurationChange([]);
        setFilterAction('clear'); // Set filter action to 'clear'
    };
    const applyFilters = () => {
        setFilterAction('apply'); // Set filter action to 'apply'
    };
    useEffect(() => {
        if (filterAction === 'apply') {
            fetchProgramInfo(1); // Apply filters and fetch data
        } else if (filterAction === 'clear') {
            fetchProgramInfo(1); // Reset to page 1
        }
        setFilterAction(null); // Reset filter action
    }, [selectedTuitionFees, selectedProgramTypeId, selectedDurationIds, filterAction]);

    function showModalHandler(value, size) {
        setModalIsVisible(true);
        setProgramData(value);
        setModalSize(size);
    }
    const handleProgramTypeSelect = (programTypeId) => {
        const updatedSelectedProgramTypeId = selectedProgramTypeIds === programTypeId ? '' : programTypeId;
        setSelectedProgramTypeId(updatedSelectedProgramTypeId);
        onProgramTypeChange(updatedSelectedProgramTypeId);
    };

    const handleDurationChange = (durationId) => {
        const updatedSelectedDurationIds = selectedDurationIds.includes(durationId)
            ? selectedDurationIds.filter(id => id !== durationId)
            : [...selectedDurationIds, durationId];
        setSelectedDurationIds(updatedSelectedDurationIds);
        onDurationChange(updatedSelectedDurationIds); // Updated to pass array
    };

    const handleTuitionFeeChange = (feeValue) => {
        const updatedSelectedTuitionFees = selectedTuitionFees.includes(feeValue)
            ? selectedTuitionFees.filter(value => value !== feeValue)
            : [...selectedTuitionFees, feeValue];
        setSelectedTuitionFees(updatedSelectedTuitionFees);
        onTuitionFeeChange(updatedSelectedTuitionFees); // Pass the selected fees
    };

    const handleProgramClick = (programId) => {
        const programDetailUrl = `/member/programs/details-program/${programId}`;
        window.open(programDetailUrl, '_blank');
      };

    if (!selectedCountryId) return null;

    return (
        <>
            {modalIsVisible && (
                <SearchModal onShow={showModalHandler} size={modalSize}>
                    <ProgramsModalBody onClose={hideModalHandler} program={programData} />
                </SearchModal>
            )}

            <div>
                <div className="row">
                    {/* Secondary Filters */}
                    {resetFlag && (
                        <div className="col-md-3 col-lg-2">
                            <div>
                                <Accordion className="filter-section" defaultActiveKey={['0', '1', '2','3']} alwaysOpen>
                                <Accordion.Item eventKey="3">
                                        <Accordion.Header className={classes["custom-accordion-header"]}>filters</Accordion.Header>
                                        <Accordion.Body>
                                        <div>
                                        <span style={{ textDecoration: 'none', color: 'black' }} onMouseOver={(e) => {
                                            e.target.style.textDecoration = 'underline';
                                            e.target.style.color = 'blue'
                                            e.target.style.cursor = 'pointer';
                                        }} onMouseOut={(e) => {
                                            e.target.style.textDecoration = 'none';
                                            e.target.style.color = 'black';
                                        }} onClick={applyFilters}>apply filter</span>
                                        </div>
                                        <div>
                                        <span style={{ textDecoration: 'none', color: 'black' }} onMouseOver={(e) => {
                                            e.target.style.textDecoration = 'underline';
                                            e.target.style.color = 'blue';
                                            e.target.style.cursor = 'pointer';
                                        }} onMouseOut={(e) => {
                                            e.target.style.textDecoration = 'none';
                                            e.target.style.color = 'black';
                                        }}  onClick={clearFilters}>clear filter</span>
                                        </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Tuition</Accordion.Header>
                                        <Accordion.Body>
                                            {fees.map(fee => (
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`tutionFee-${fee.srNo}`}
                                                    label={fee.feeCaption}
                                                    title={fee.feeCaption}
                                                    key={fee.srNo}
                                                    checked={selectedTuitionFees.includes(fee.feeValue)}
                                                    onChange={() => handleTuitionFeeChange(fee.feeValue)}
                                                />
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Duration</Accordion.Header>
                                        <Accordion.Body>
                                            <Form>
                                                {filters.map((item) => (
                                                    item.SrNo && item.DurationCaption && (
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`duration-${item.SrNo}`}
                                                            label={item.DurationCaption}
                                                            title={item.DurationCaption}
                                                            key={item.SrNo}
                                                            checked={selectedDurationIds.includes(item.SrNo)}
                                                            onChange={() => handleDurationChange(item.SrNo)}
                                                        />
                                                    )
                                                ))}
                                            </Form>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    )}


                    {/* Main Content */}
                    <div className="col-md-9 col-lg-10">
                        <div className={classes["program-cards"]}>
                            {programs.map((program) => (
                                // <div className={classes["card-section"]} onClick={() => showModalHandler(program, 'md')} key={program?.ProgramId}>
                                <div className={classes["card-section"]} key={program?.ProgramId}>
                                    <div className={classes["section-1"]}>
                                        <div className={classes["card-header-block-1"]}>
                                            <div className={classes["card-rank"]}> <span className={classes["rank-text"]}>Ranked: {program?.rank || 'N/A'}</span></div>
                                            <div className={classes["card-header"]}>
                                                <div className={classes[""]}>
                                                    <img
                                                        src={program?.InstLogoPath ? bloburl + program.InstLogoPath : universityLogo}
                                                        alt="Profile"
                                                        className={classes["university-icon"]}
                                                    />
                                                </div>
                                                <div className={classes["university-detail"]}>
                                                    <div title={program?.Instname} className={classes["university-name"]}>{program?.Instname || 'N/A'}</div>
                                                    <div className={classes["university-address"]}>{program?.InstCountryName || 'N/A'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes["card-header-block-2"]}>
                                            <div title={program?.ProgramName} onClick={() => handleProgramClick(program?.ProgramId)} className={classes["program-name"]}>{program?.ProgramName || 'N/A'}</div>
                                            <div className={classes["program-detail-text"]}>
                                                {program?.description || 'N/A'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes["section-2"]}>
                                        <div className={classes["program-detail-section-1"]}>
                                            <div>
                                                <div className={classes["intake-header"]}>Intake</div>
                                                {program.Intakes.length === 0 ? (
                                                    <div className={classes["detail"]}>N/A</div>
                                                ) : (
                                                    [...new Set(program.Intakes.map(intake => intake.IntakeName))].map((intakeName, index) => (
                                                        <div key={index} className={classes["detail"]}>{intakeName || 'N/A'}</div>
                                                    ))
                                                )}
                                            </div>
                                            <div className={classes["detail"]}>{program?.DurationTime || 'N/A'}</div>
                                            <div className={classes["detail"]}>{program?.ProgramLevel || 'N/A'}</div>
                                            <div className={classes["detail"]}>{program?.ProgramCurrency || 'N/A'} {program?.FeeDetail[0]?.FeeAmount || 'N/A'} / Year</div>
                                        </div>
                                        <div className={classes["program-detail-section-2"]}>
                                            <div>
                                                <div className={classes["header"]}>Application Fee</div>
                                                <div className={classes["detail"]}>{program?.ApplicationFee || 'N/A'}</div>
                                            </div>
                                            <div>
                                                <div className={classes["header"]}>People like You</div>
                                                <div className={classes["detail"]}>{program?.PeopleLikeYou || 'N/A'}</div>
                                            </div>
                                            <div>
                                                <div className={classes["header"]}>Likelihood of Graduation</div>
                                                <div className={classes["detail"]}>{program?.LikelihoodOfGraduation ? `${program.LikelihoodOfGraduation}%` : 'N/A'}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Programs;