import Footer from "../../Common/Footer/Footer";
import Header from "../../Common/Header/Header";
import { BehaviorSubjectProvider } from "../../GlobleShared/BehaviorSubject/BehaviorSubject";
import { Outlet } from "react-router-dom";

function RootLayout() {

    return (
        <BehaviorSubjectProvider>
            <div className="panel-box">
                {/* <div><Header /></div> */}
                <div className="main-content"><Outlet /></div>
                {/* <div className="footer"><Footer /></div> */}
            </div>
        </BehaviorSubjectProvider>
    )
}

export default RootLayout;