import React from "react";
import classes from './Marketing.module.css'

function Marketing() {
  return (
    <>
      <div className={classes['marketing_section']}>
        <div className={classes['section_border']}>
          <p className={classes['heading_text']}>Marketing</p>
        </div>
      </div>
    </>
  );
}
export default Marketing;
