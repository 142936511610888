import React from "react";
import classes from './common.module.css';
import SentComponents from "../../User_Account/Connections/SubComponents/SentComponents";
import dropDown from "../../.../../../../../Assets/Logo/drop-down-icon-2.svg";

const SentComponent = () => {
  return (
    <div className="row mt-4">
      <div className={`${classes.containerForTab} col-md-9`}>
        <div className="d-flex justify-content-end mb-3">
          <div>
            <span className={classes.textSortBy}>Sort By: </span>
            <span className={classes.textRecent}>Recent</span>
            <span>
              <img
                className={classes.imgIcon}
                src={dropDown}
                alt=""
              />
            </span>
          </div>
        </div>
        <SentComponents />
      </div>
    </div>
  );
};

export default SentComponent;
