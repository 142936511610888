import { Form } from "react-bootstrap";
import classes from "./ProgramSection.module.css";
import {useEffect, useState } from "react";
import ApplicationService from "../../../../../../Services/ApplicationServices";
import { toast } from "react-toastify";
import crossIcon from '../../../../../../Assets/Logo/cross-icon.svg'
import { useBehaviorSubject } from "../../../../../GlobleShared/BehaviorSubject/BehaviorSubject";
import Loader from "../../../../../GlobleShared/Loader/Loader";


function ProgramSection({programDetailsData, institutionData, programData, intakeData, programModeData, programSpecializationData, onInstituteChnage,
    onProgramChnage, onIntakeChange, onModeChange, onResidingChange, editProgramInfo, onSpecializationChange, onCampusChange,
    campusList, getBackupProgramId, data, getValidation, disableProgramInfo, viewApplication, applicationId }) {
    const [intakeId, setIntakeId] = useState(0)
    const [institutionId, setInstitutionId] = useState(0);
    const [programId, setProgramId] = useState(0)
    const [mode, setMode] = useState(0)
    const [campus, setCampus] = useState(0)
    
    const [specializationId, setSpecializationId] = useState(0)
    const [shoreType, setShoreType] = useState('')
    const [programBackUp, setProgramBackUp] = useState([])
    const [programBackUp2, setProgramBackUp2] = useState([])
    const [programBackUp3, setProgramBackUp3] = useState([])
    const [response, setResponse] = useState()
    const [showbackupProgram, setShowbackupProgram] = useState(false)
    const [BProgram1, setBProgram1] = useState(0)
    const [BProgram2, setBProgram2] = useState(0)
    const [BProgram3, setBProgram3] = useState(0)
    const { validationFlag, setValidationFlag } = useBehaviorSubject();
    const [campusId, setcampusId]=useState(0)
    const [instituteId, setinstituteId]=useState(0)
    const [modeId, setmodeId]=useState(0)
    const [residingId, setresidingId]=useState('')
    const [getValidationFlag, setGetValidationFlag]=useState(false)
    const [isLoading,  setIsLoading]=useState(false)
    const [initialFlag, setInitialFlag]=useState(true)


    const [formErrors, setFormErrors] = useState({
        institute: null,
        program:null,
        campus:null,
        intake:null,
        specialization:null,
        mode:null,
        residing:null
      });


      const validateField = (name, value) => {
        let errorMsg = null;
        switch (name) {
         case "institute":
            if (!value) errorMsg = "Please select institution";
            break;
        case "program":
            if (!value) errorMsg = "Please select program";
            break;
         case "campus":
            if(campusList?.length>0){
              if (!value) errorMsg = "Please select campus";
            }
           break;
         case "intake":
            if (!value) errorMsg = "Please select intake";
            break;
         case "mode":
            if (!value) errorMsg = "Please select mode";
            break;
         case "residing":
            if (!value) errorMsg = "Please select residing";
            break;
         case "specialization":
            if(programSpecializationData?.length>0){
                if (!value) errorMsg = "Please select specialization";
            }
            break;
         default:
            break;
        }
        return errorMsg;
      };


      const getValid=()=>{
        setFormErrors({...formErrors, 
            institute: institutionId> 0 ? null:'Please select institution',
            program:programId> 0 ? null:'Please select program',
            campus:campusId> 0 && campusList?.length>0? null:'Please select campus',
            intake:intakeId> 0 ? null:'Please select intake',
            specialization:specializationId> 0 ? null:'Please select mode',
            mode:mode ? null:'Please select residing',
            residing:shoreType ? null:'Please select specialization'
        });
    
        getValidation({...formErrors, 
            institute: institutionId> 0 ? null:'Please select institution',
            program:programId> 0 ? null:'Please select program',
            campus:campusId> 0 && campusList?.length>0 ? null:'Please select campus',
            intake:intakeId> 0 ? null:'Please select intake',
            specialization:specializationId> 0 ? null:'Please select mode',
            mode:mode ? null:'Please select residing',
            residing:shoreType ? null:'Please select specialization'
        }, 1);
      }
    

      
   const handleInputChange = (event) => {
    const { name, value } = event.target;
    const errorMsg = validateField(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
}));
getValidation({...formErrors, [name]: errorMsg}, 1)
};


const validateForm = (form) => {
const errorObj = {};
Object.keys(form).forEach((key) => {
let msg;
    msg = validateField(key, form[key]);
if (msg) {
    errorObj[key] = msg;
}
});
return errorObj;
};

    const [programBackFinal1, setProgramBackFinal1] = useState({
        campusList: [],
        modeList: [],
        shoreTypeList: [],
        specializationList: []

    })
    const [programBackFinal2, setProgramBackFinal2] = useState({
        campusList: [],
        modeList: [],
        shoreTypeList: [],
        specializationList: []

    })
    const [programBackFinal3, setProgramBackFinal3] = useState({
        campusList: [],
        modeList: [],
        shoreTypeList: [],
        specializationList: []

    })

    const getCampusId=(value)=>{
        setcampusId(value)

    }

    const getspecializationId=(value)=>{
      setSpecializationId(value)
    }
    const getresidingId=(value)=>{
        setresidingId(value)
      }

      const getmode=(value)=>{
        setmodeId(value)
      }


    useEffect(() => {
        if (data && initialFlag) {
           
            setIntakeId(data?.IntekId)
            setInstitutionId(data?.InstitutionId);
            setProgramId(data?.ProgramId);
            setShoreType(data?.ShoreType?data?.ShoreType:'');
            setSpecializationId(data?.SpecializationId);
            setCampus(data?.Campus);


               const id= programModeData.filter(element => {
                    if (element?.ModeName === data?.ModeName) {
                        setInitialFlag(false)
                        return  element
                    }
                });
                setMode(id[0]?.ModeId)
               
        }
    }, [data, programModeData]);

    const programbackup = (IntakeId) => {
        ApplicationService.programbackup(IntakeId)
            .then((res) => {
                setProgramBackUp(res?.programList)
                setProgramBackUp2(res?.programList)
                setProgramBackUp3(res?.programList)
                setResponse(res)
            })
            .catch((error) => {
                toast.error(error?.message);
            });
    };

    const getIntakeId = (value) => {
        onIntakeChange(value)
        setIntakeId(value)
        programbackup(value)
    }
    const onModeChangeInner = (value) =>{
        onModeChange(value)
        setMode(value);
    }
    const onResidingChangeInner = (value) =>{
        onResidingChange(value);
        setShoreType(value);
    }

     
    const onCampusChangeInner = (value) =>{
        onCampusChange(value);
        setCampus(value);
    }

     
    const getProgramId = (value) => {
        onProgramChnage(value)
        setProgramId(value)
        setIntakeId(0)
        programbackup(0)
        setMode(0)
        setShoreType('')
        setSpecializationId(0)
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            campus:campusList?.length>0?"Please select campus":null,
            intake:"Please select intake",
            specialization:programSpecializationData?.length>0?"Please select specialization":null,
            mode:"Please select mode",
            residing:"Please select mode",
        }))
    }
    const getInstitutionId = (value) => {
        setInstitutionId(value)
        onInstituteChnage(value)
        setProgramId(0)
        setIntakeId(0)
        setMode(0)
        setShoreType('')
        setSpecializationId(0)
        setProgramBackUp([])
        setProgramBackUp2([])
        setProgramBackUp3([])
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            program:"Please select program",
            campus:campusList?.length>0?"Please select campus":null,
            intake:"Please select intake",
            specialization:programSpecializationData?.length>0?"Please select specialization":null,
            mode:"Please select mode",
            residing:"Please select mode",
        }))
    }

    const getBackUpProgram1 = (programID) => {
        setBProgram1(programID)
        getBackupProgramId(programID, BProgram2, BProgram3)
        let data = {
            campusList: response.campusList.filter(item => item?.ProgramId === Number(programID)),
            modeList: response.modeList.filter(item => item?.ProgramId === Number(programID)),
            shoreTypeList: response.shoresList.filter(item => item?.ProgramId === Number(programID)),
            specializationList: response?.specializationList.filter(item => item?.ProgramId === Number(programID))
        }
        setProgramBackFinal1(data)
        setProgramBackUp2(response.programList.filter(item => (item?.ProgramId !== Number(programID) && item?.ProgramId !== Number(BProgram3))))
        setProgramBackUp3(response.programList.filter(item => (item?.ProgramId !== Number(programID) && item?.ProgramId !== Number(BProgram2))))
    }
    const getBackUpProgram2 = (programID) => {
        setBProgram2(programID)
        getBackupProgramId(BProgram1, programID, BProgram3)
        let data = {
            campusList: response.campusList.filter(item => item?.ProgramId === Number(programID)),
            modeList: response.modeList.filter(item => item?.ProgramId === Number(programID)),
            shoreTypeList: response.shoresList.filter(item => item?.ProgramId === Number(programID)),
            specializationList: response?.specializationList.filter(item => item?.ProgramId === Number(programID))
        }
        setProgramBackFinal2(data)
        setProgramBackUp(response.programList.filter(item => (item?.ProgramId !== Number(programID) && item?.ProgramId !== Number(BProgram3))))
        setProgramBackUp3(response.programList.filter(item => (item?.ProgramId !== Number(programID) && item?.ProgramId !== Number(BProgram1))))
    }

    const getBackUpProgram3 = (programID) => {
        setBProgram3(programID)
        getBackupProgramId(BProgram1, BProgram2, programID)
        let data = {
            campusList: response?.campusList.filter(item => item?.ProgramId === Number(programID)),
            modeList: response?.modeList.filter(item => item?.ProgramId === Number(programID)),
            shoreTypeList: response?.shoresList.filter(item => item?.ProgramId === Number(programID)),
            specializationList: response?.specializationList.filter(item => item?.ProgramId === Number(programID))
        }
        setProgramBackFinal3(data)
        setProgramBackUp(response.programList.filter(item => (item?.ProgramId !== Number(programID) && item?.ProgramId !== Number(BProgram2))))
        setProgramBackUp2(response.programList.filter(item => (item?.ProgramId !== Number(programID) && item?.ProgramId !== Number(BProgram1))))
    }

    const backUPprogramShow = (value) => {
        setShowbackupProgram(value)
    }

    const clearBProgram2 = () => {
        setBProgram2(0)
        setProgramBackFinal2({
            campusList: [],
            modeList: [],
            shoreTypeList: [],
            specializationList: []
        })

    }
    const clearBProgram3 = () => {
        setBProgram3(0)
        setProgramBackFinal3({
            campusList: [],
            modeList: [],
            shoreTypeList: [],
            specializationList: []
        })

    }
    const updateData=()=>{
        if(institutionId===0  || intakeId===0 || programId ===0  || mode===0 || shoreType==='' || (campusList?.length > 0 && campusId===0) || (programSpecializationData?.length>0 && specializationId===0)){
            getValid()
            return 
        }

        let  payload={
                AppCreatedDate:data?.AppCreatedDate,
                AppSourceCode:data?.AppSourceCode,
                ApplicationId: applicationId,
                ApplicationMode: data?.ApplicationMode?data?.ApplicationMode :0,
                ApplicationStatus: data?.ApplicationStatus,
                ApplicationStatusName: data?.ApplicationStatusName,
                AverageProcessingDay: data?.AverageProcessingDay,
                B1DurationTime: data?.B1DurationTime,
                B1ProgramId: BProgram1,
                B1ProgramName: data?.B1ProgramName,
                B1TuitionFee: data?.B1TuitionFee,
                B2DurationTime: data?.B2DurationTime,
                B2ProgramId: BProgram1,
                B2ProgramName: data?.B2ProgramName,
                B2TuitionFee: data?.B2TuitionFee,
                B3DurationTime: data?.B3DurationTime,
                B3ProgramId: BProgram3,
                B3ProgramName: data?.B3ProgramName,
                B3TuitionFee: data?.B3TuitionFee,
                Campus: campusId,
                CampusName: data?.CampusName,
                Citizenship: data?.Citizenship,
                ConditionalApplication: data?.ConditionalApplication,
                DurationTime: data?.DurationTime,
                DurationType: data?.DurationType,
                InstCostofLiving_Year: data?.DurationType,
                InstCountry:  data?.InstCountry,
                InstCurrency: data?.InstCurrency,
                InstLogoPath: data?.InstLogoPath,
                InstName: data?.InstLogoPath,
                InstitutionId: instituteId,
                IntakeName:data?.IntakeName,
                IntekId: intakeId,
                ModeName:data?.ModeName,
                OnshoreSubmissionDeadline: data?.OnshoreSubmissionDeadline,
                PartnerTypeId: data?.PartnerTypeId,
                PartnerTypeName: data?.PartnerTypeName,
                PrimaryDurationTime: data?.PrimaryDurationTime,
                PrimaryProgramId: programId,
                PrimaryProgramName: data?.PrimaryProgramName,
                PrimaryTuitionFee: 0,
                ProgramId: programId,
                ProgramName: data?.ProgramName,
                ReviewStatus: data?.ReviewStatus,
                ServiceProviderName: data?.ServiceProviderName,
                ShoreType: shoreType ? shoreType:0,
                SpecializationId: specializationId,
                SpecializationName:data?.SpecializationName,
                SubmissionDeadLine: data?.SubmissionDeadLine,
                isCreditCardRequired: data?.isCreditCardRequired,
                isSubmited: data?.isSubmited,
                isSubmitedToInstitute: data?.isSubmitedToInstitute,
                termsAccepted: data?.termsAccepted,
              
        }
        setIsLoading(true)
        ApplicationService.updateProgramInfo(payload).then(res=>{
            setIsLoading(false)
            if(res!==0){
                toast.error(res)
            }else{
                preferenceAdd(applicationId)
                disableProgramInfo()
                toast.success("Program information updated sucessfully.") 
            }

        }).catch(error=>{
            setIsLoading(false)
            toast.error(error?.message)
        })
    }

    const preferenceAdd=(applicationIdValue)=>{
        let data={
          ApplicationId:applicationIdValue,
          ApplicationPreference:[{CampusId:campusId?campusId:0, IndexId:0, ModeId:Number(modeId),
             ProgramId:Number(programId), ShoreType: Number(shoreType), SpecializationId:specializationId?specializationId:0}]
        }
        setIsLoading(true)
        ApplicationService.preferenceAdd(data).then(res=>{
          setIsLoading(false)
        
        }).catch(error=>{
          setIsLoading(false)
          toast.success(error?.message)
        })
        }

    const cancelData=()=>{
            setIntakeId(data?.IntekId)
            setInstitutionId(data?.InstitutionId);
            setProgramId(data?.ProgramId);
            setShoreType(data?.ShoreType?data?.ShoreType:'');
            setSpecializationId(data?.SpecializationId);
            setCampus(data?.Campus);


            if (programModeData.length > 0 && data?.ModeName != null) {
                const id= programModeData.filter(element => {
                     if (element.ModeName === data.ModeName) {
                         return  element
                     }
                 });
                 setMode(id[0].ModeId)
             }
            disableProgramInfo()
            getValid()
    }




useEffect(()=>{
    if(validationFlag && !getValidationFlag){
        getValid()
        setValidationFlag(false)
        setGetValidationFlag(true)
    }  
},[validationFlag])





    return (
        <>
            <div className={classes["program-info-container"]}>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Institute <span className={classes['extrik']}>*</span></Form.Label>
                            <Form.Select disabled={applicationId>0} aria-label="Institute" name='institute' onChange={(e)=>{getInstitutionId(e.target.value); handleInputChange(e)}}   onBlur={handleInputChange} value={institutionId} required>
                                <option className={classes["option-default"]} value="">Select an Institute</option>
                                {institutionData.map(institute => (

                                    <option value={institute?.InstitutionId}>{institute?.InstName}</option>
                                ))}

                                -                            </Form.Select>
                                {formErrors.institute && <div className={classes["errorText"]}>{formErrors.institute}</div>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6 form-group">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>Program <span className={classes['extrik']}>*</span></Form.Label>
                            <Form.Select disabled={!editProgramInfo} aria-label="Program"  name="program"  onChange={(e)=>{getProgramId(e.target.value); handleInputChange(e)}}   onBlur={handleInputChange} value={programId} required>
                                <option className={classes["option-default"]} value="">Select an Program</option>
                                {programData.map(program => (
                                    <option value={program?.ProgramId}>{program?.ProgramName}</option>
                                ))}
                            </Form.Select>
                            {formErrors.program && <div className={classes["errorText"]}>{formErrors.program}</div>} 
                        </Form.Group>
                    </div>
                    <div className="col-md-6 form-group">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label>Intake<span className={classes['extrik']}>*</span></Form.Label>
                            <Form.Select disabled={!editProgramInfo} aria-label="Intake"  name="intake" onChange={(e)=>{getIntakeId(e.target.value); handleInputChange(e)}}   onBlur={handleInputChange} value={intakeId} required>
                                <option className={classes["option-default"]} value="">Select an Intake</option>
                                {intakeData.map(intake => (
                                    <option value={intake?.IntekId}>{intake?.IntakeName}</option>
                                ))}
                            </Form.Select>
                            {formErrors.intake && <div className={classes["errorText"]}>{formErrors.intake}</div>} 
                        </Form.Group>
                    </div>
                    {campusList && campusList?.length > 0 && (
                        <div className="col-md-6 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label>Campus <span className={classes['extrik']}>*</span></Form.Label>
                                <Form.Select disabled={!editProgramInfo} aria-label="Campus"  name="campus" onChange={(e)=>{onCampusChangeInner(e.target.value); handleInputChange(e); getCampusId(e.target.value)}}   onBlur={handleInputChange}  value={campus} required>
                                    <option className={classes["option-default"]} value="">Select a Campus</option>
                                    {campusList.map(campus => (
                                        <option value={campus?.CampusId}>{campus?.CampusName}</option>
                                    ))}
                                </Form.Select>
                                {formErrors.campus && <div className={classes["errorText"]}>{formErrors.campus}</div>} 
                            </Form.Group>
                        </div>
                    )}
                    <div className="col-md-6 form-group">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                            <Form.Label>Residing <span className={classes['extrik']}>*</span></Form.Label>
                            <Form.Select disabled={!editProgramInfo} aria-label="Residing"  name='residing' onChange={(e)=>{onResidingChangeInner(e.target.value); handleInputChange(e); getresidingId(e.target.value)}} onBlur={handleInputChange} value={shoreType} required>
                                <option className={classes["option-default"]} value="">Select a Residing</option>
                                <option value={0}>Offshore</option>
                                <option value={1}>Onshore</option>
                            </Form.Select>
                            {formErrors.residing && <div className={classes["errorText"]}>{formErrors.residing}</div>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6 form-group">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">

                            <Form.Label>Mode<span className={classes['extrik']}>*</span></Form.Label>
                            <Form.Select disabled={!editProgramInfo} aria-label="Mode" name='mode' onChange={(e)=>{onModeChangeInner(e.target.value); handleInputChange(e); getmode(e.target.value);}} onBlur={handleInputChange}  value={mode} >
                                <option className={classes["option-default"]} >Select a Mode</option>
                                {programModeData.map(mode => (
                                    <option value={mode?.ModeId}>{mode?.ModeName}</option>

                                ))}
                            </Form.Select>
                            {formErrors.mode && <div className={classes["errorText"]}>{formErrors.mode}</div>}
                        </Form.Group>
                    </div>
                    {programSpecializationData && programSpecializationData?.length > 0 && (
                        <div className="col-md-6 form-group">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3" required>
                                <Form.Label>Specialization  <span className={classes['extrik']}>*</span></Form.Label>
                                <Form.Select disabled={!editProgramInfo} aria-label="Specialization" name="specialization"  onChange={(e)=>{onSpecializationChange(e.target.value); handleInputChange(e); getspecializationId(e.target.value)}}   onBlur={handleInputChange} value={specializationId}>
                                    <option className={classes["option-default"]} value="">Select an Specialization</option>
                                    {programSpecializationData.map(item => (
                                        <option value={item?.SpecializationId}>{item?.SpecializationName}</option>
                                    ))}
                                </Form.Select>
                                {formErrors.specialization && <div className={classes["errorText"]}>{formErrors.specialization}</div>}
                            </Form.Group>
                        </div>
                    )}
                </div>
                <div className="row mb-4 mt-5">
                    <Form.Check disabled={Number(programId) <= 0 || !editProgramInfo}
                        label="Add backup programs"
                        type="checkbox"
                        name="backup"
                        onChange={(e) => { backUPprogramShow(e.target.checked) }}
                        className={classes[""]}
                    />
                </div>
                {showbackupProgram && (
                    <div>
                        <div className="row">
                            <div className="col-md-1 d-flex align-items-center justify-content-center">
                                <div className={`${classes['backprogram-number']} d-flex justify-content-center align-items-center`}>#1</div>
                            </div>
                            <div className="col-md-11">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div>
                                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram1Intake">
                                                <Form.Label>Intake</Form.Label>
                                                <Form.Select disabled aria-label="Intake" value={intakeId}>
                                                    <option className={classes["option-default"]} value="">Select an Intake</option>
                                                    {intakeData.map(intake => (
                                                        <option value={intake?.IntekId}>{intake?.IntakeName}</option>
                                                    ))}

                                                    -                            </Form.Select>
                                            </Form.Group>
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram1">
                                            <Form.Label>Program</Form.Label>
                                            <Form.Select aria-label="Program1" value={BProgram1} onChange={(e) => { getBackUpProgram1(e.target.value) }} required>
                                                <option className={classes["option-default"]} value="">Select an Program</option>
                                                {programBackUp.map(program => (
                                                    <option value={program?.ProgramId}>{program?.ProgramName}</option>
                                                ))}

                                                -                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-3">
                                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram1Campus">
                                            <Form.Label>Campus</Form.Label>
                                            <Form.Select aria-label="Campus" required>
                                                <option className={classes["option-default"]} value="">Select an Campus</option>
                                                {programBackFinal1?.campusList.map(campus => (
                                                    <option value={campus?.CampusId}>{campus?.CampusName}</option>
                                                ))}

                                                -                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    {programBackFinal1?.shoreTypeList?.length > 0 && (
                                        <div className="col-md-3">
                                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram1Residing">
                                                <Form.Label>Student Residing</Form.Label>
                                                <Form.Select aria-label="Student Residing" required>
                                                    <option className={classes["option-default"]} value="">Student Residing</option>
                                                    {programBackFinal1?.shoreTypeList.map(shortype => (
                                                        <option value={shortype?.OffShore}>OffShore</option>

                                                    ))}
                                                    {programBackFinal1?.shoreTypeList.map(shortype => (
                                                        <option value={shortype?.OnShore}>OnShore</option>

                                                    ))}

                                                    -                            </Form.Select>
                                            </Form.Group>
                                        </div>
                                    )}

                                    {programBackFinal1?.specializationList?.length > 0 && (
                                        <div className="col-md-3">
                                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram1Specialization">
                                                <Form.Label>Specialization</Form.Label>
                                                <Form.Select aria-label="Specialization" required>
                                                    <option className={classes["option-default"]} value="">Select an Specialization</option>
                                                    {programBackFinal1?.specializationList.map(specialization => (

                                                        <option value={specialization?.SpecializationId}>{specialization?.SpecializationName}</option>
                                                    ))}

                                                    -                            </Form.Select>
                                            </Form.Group>

                                        </div>
                                    )}

                                    <div className="col-md-3">
                                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram1Mode">
                                            <Form.Label>Mode</Form.Label>
                                            <Form.Select aria-label="Mode" required>
                                                <option className={classes["option-default"]} value="">Select an Mode</option>
                                                {programBackFinal1?.modeList.map(mode => (

                                                    <option value={mode?.ModeId}>{mode?.ModeName}</option>
                                                ))}

                                                -                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1 d-flex align-items-center justify-content-center">
                                <div className={`${classes['backprogram-number']} d-flex justify-content-center align-items-center`}>#2</div>
                            </div>
                            <div className="col-md-11">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div>
                                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram2Intake">
                                                <Form.Label>Intake</Form.Label>
                                                <Form.Select disabled aria-label="Intake" value={intakeId}>
                                                    <option className={classes["option-default"]} value="">Select an Intake</option>
                                                    {intakeData.map(intake => (
                                                        <option value={intake?.IntekId}>{intake?.IntakeName}</option>
                                                    ))}

                                                    -                            </Form.Select>
                                            </Form.Group>
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram2">
                                            <Form.Label>Program</Form.Label>
                                            <Form.Select aria-label="Program2" value={BProgram2} onChange={(e) => { getBackUpProgram2(e.target.value) }} required>
                                                <option className={classes["option-default"]} value="">Select an Program</option>
                                                {programBackUp2.map(program => (
                                                    <option value={program?.ProgramId}>{program?.ProgramName}</option>
                                                ))}

                                                -                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-3">
                                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram2Campus">
                                            <Form.Label>Campus</Form.Label>
                                            <Form.Select aria-label="Campus" required>
                                                <option className={classes["option-default"]} value="">Select an Campus</option>
                                                {programBackFinal2?.campusList.map(campus => (
                                                    <option value={campus?.CampusId}>{campus?.CampusName}</option>
                                                ))}

                                                -                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    {programBackFinal2?.shoreTypeList?.length > 0 && (
                                        <div className="col-md-3">
                                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram2Residing">
                                                <Form.Label>Student Residing</Form.Label>
                                                <Form.Select aria-label="Student Residing" required>
                                                    <option className={classes["option-default"]} value="">Student Residing</option>
                                                    {programBackFinal2?.shoreTypeList.map(shortype => (
                                                        <option value={shortype?.OffShore}>OffShore</option>

                                                    ))}
                                                    {programBackFinal2?.shoreTypeList.map(shortype => (
                                                        <option value={shortype?.OnShore}>OnShore</option>

                                                    ))}

                                                    -                            </Form.Select>
                                            </Form.Group>
                                        </div>
                                    )}



                                    {programBackFinal2?.specializationList?.length > 0 && (
                                        <div className="col-md-3">
                                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram2Specialization">
                                                <Form.Label>Specialization</Form.Label>
                                                <Form.Select aria-label="Specialization" required>
                                                    <option className={classes["option-default"]} value="">Select an Specialization</option>
                                                    {programBackFinal2?.specializationList.map(specialization => (

                                                        <option value={specialization?.SpecializationId}>{specialization?.SpecializationName}</option>
                                                    ))}

                                                    -                            </Form.Select>
                                            </Form.Group>
                                        </div>
                                    )}
                                    <div className="col-md-3">
                                        <div className="d-flex align-items-center">
                                            <div className="w-100">
                                                <Form.Group className="mb-3" controlId="exampleForm.backupProgram2Mode">
                                                    <Form.Label>Mode</Form.Label>
                                                    <Form.Select aria-label="Mode" required>
                                                        <option className={classes["option-default"]} value="">Select an Mode</option>
                                                        {programBackFinal2?.modeList.map(mode => (

                                                            <option value={mode?.ModeId}>{mode?.ModeName}</option>
                                                        ))}

                                                        -                            </Form.Select>
                                                </Form.Group>
                                            </div>
                                            {BProgram2 > 0 && (
                                                <div>
                                                    <img onClick={clearBProgram2} className={classes['clearIcon']} title="Clear" src={crossIcon} alt=''></img>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1 d-flex align-items-center justify-content-center">
                                <div className={`${classes['backprogram-number']} d-flex justify-content-center align-items-center`}>#3</div>
                            </div>
                            <div className="col-md-11">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div>
                                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram3Intake">
                                                <Form.Label>Intake</Form.Label>
                                                <Form.Select disabled aria-label="Intake" value={intakeId}>
                                                    <option className={classes["option-default"]} value="">Select an Intake</option>
                                                    {intakeData.map(intake => (
                                                        <option value={intake?.IntekId}>{intake?.IntakeName}</option>
                                                    ))}

                                                    -                            </Form.Select>
                                            </Form.Group>
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram3">
                                            <Form.Label>Program</Form.Label>
                                            <Form.Select disabled={BProgram2 <= 0} aria-label="Program2" value={BProgram3} onChange={(e) => { getBackUpProgram3(e.target.value) }} required>
                                                <option className={classes["option-default"]} value="">Select an Program</option>
                                                {programBackUp3.map(program => (
                                                    <option value={program?.ProgramId}>{program?.ProgramName}</option>
                                                ))}

                                                -                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-3">
                                        <Form.Group className="mb-3" controlId="exampleForm.backupProgram3Campus">
                                            <Form.Label>Campus</Form.Label>
                                            <Form.Select disabled={BProgram2 <= 0} aria-label="Campus" required>
                                                <option className={classes["option-default"]} value="">Select an Campus</option>
                                                {programBackFinal3?.campusList.map(campus => (
                                                    <option value={campus?.CampusId}>{campus?.CampusName}</option>
                                                ))}

                                                -                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    {programBackFinal3?.shoreTypeList?.length > 0 && (
                                        <div className="col-md-3">
                                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram3Residing">
                                                <Form.Label>Student Residing</Form.Label>
                                                <Form.Select disabled={BProgram2 <= 0} aria-label="Student Residing" required>
                                                    <option className={classes["option-default"]} value="">Student Residing</option>
                                                    {programBackFinal3?.shoreTypeList.map(shortype => (
                                                        <option value={shortype?.OffShore}>OffShore</option>

                                                    ))}
                                                    {programBackFinal2?.shoreTypeList.map(shortype => (
                                                        <option value={shortype?.OnShore}>OnShore</option>

                                                    ))}

                                                    -                            </Form.Select>
                                            </Form.Group>
                                        </div>
                                    )}



                                    {programBackFinal3?.specializationList?.length > 0 && (
                                        <div className="col-md-3">
                                            <Form.Group className="mb-3" controlId="exampleForm.backupProgram3Specialization">
                                                <Form.Label>Specialization</Form.Label>
                                                <Form.Select disabled={BProgram2 <= 0} aria-label="Specialization" required>
                                                    <option className={classes["option-default"]} value="">Select an Specialization</option>
                                                    {programBackFinal3?.specializationList.map(specialization => (

                                                        <option value={specialization?.SpecializationId}>{specialization?.SpecializationName}</option>
                                                    ))}

                                                    -                            </Form.Select>
                                            </Form.Group>
                                        </div>
                                    )}
                                    <div className="col-md-3">
                                        <div className="d-flex align-items-center">
                                            <div className="w-100">
                                                <Form.Group className="mb-3" controlId="exampleForm.backupProgram3Mode">
                                                    <Form.Label>Mode</Form.Label>
                                                    <Form.Select disabled={BProgram2 <= 0} aria-label="Mode" required>
                                                        <option className={classes["option-default"]} value="">Select an Mode</option>
                                                        {programBackFinal3?.modeList.map(mode => (

                                                            <option value={mode?.ModeId}>{mode?.ModeName}</option>
                                                        ))}

                                                        -                            </Form.Select>
                                                </Form.Group>
                                            </div>
                                            {BProgram3 > 0 && (
                                                <div>
                                                    <img onClick={clearBProgram3} className={classes['clearIcon']} title="Clear" src={crossIcon} alt=''></img>
                                                </div>
                                            )}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                )}
                    {(editProgramInfo && viewApplication) && (
                        <div className='text-end'>
                            <button onClick={cancelData} className={classes['cancel-btn']}>Cancel</button>
                            <button onClick={updateData} className='primary-button'>Update</button>
                        </div>
                    )}

            </div>

            {isLoading && (
                <Loader></Loader>
            )}
        </>
    );
};

export default ProgramSection;