import classes from './ApplicationPendingDocuments.module.css';
import upload from '../../../../../../../Assets/Logo/uploadBlueIcon.svg';
import uploadSuccessful from '../../../../../../../Assets/Logo/document-icon-blue.svg';
import {useRef, useState } from 'react';
import {Table } from 'react-bootstrap';
import Loader from '../../../../../../GlobleShared/Loader/Loader';
import UploadApplicationDoucment from '../../../../../../GlobleShared/UploadDocument/UploadApplicationDocument/UplaodApplicationDocuemnt';
import ProfileService from '../../../../../../../Services/ProfileService';

const ApplicationPendingDocument = ({ pendingDocumentTypeList, applicationId, getDocumentUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const[parentType, setparentType]=useState(7)
    const[selectedData,setSelectedData]=useState('')

    const openModel=(data)=>{
        setModalOpen(true)
        setSelectedData(data)

    }
    const close=()=>{
        setModalOpen(false)

    }

    
    const getConfirmation=(value)=>{
    
        const data = {
            DocPath: value,
            DocumentId: 0,
            DocumentName: selectedData?.DocumentName,
            DocumentTypeId: Number(selectedData?.DocumentTypeId),
            DueDate: "",
            ExpiryDate: "",
            ParentId: applicationId,
            ParentType: parentType
        }
        setIsLoading(true)
        ProfileService.addStudentDocument(data).then(res=>{
            setIsLoading(false)
            getDocumentUpdate()
            setModalOpen(false)
        }).catch(error=>{
            setIsLoading(false)
            
        })

    }

  

    return (
        <>
        {modalOpen && (
         <UploadApplicationDoucment onShow={modalOpen} onClose={close} getConfirmation={getConfirmation} data={selectedData}></UploadApplicationDoucment>
        )}
       
            <div className={classes["upload-document-modal-block"]}>
            <div className={classes["upload-document-modal-body"]}>
                <div className={classes["upload-document-table"]}>
                    <Table responsive className={classes.customTable}>
                        <thead>
                            <tr>
                                <th>Attachment</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendingDocumentTypeList && pendingDocumentTypeList.length > 0 ? (
                                pendingDocumentTypeList.map((document, index) => (
                                    <tr key={document.DocumentTypeId || index}>
                                        <td>{document.DocumentName || 'N/A'}</td>
                                        <td>
                                                <div  className={classes["action-column"]}>
                                                    <div><img className={classes['upload-icon']} onClick={()=>openModel(document)}  src={upload} alt="Upload" /></div>
                                                </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className={classes.noData}>
                                        No Data Available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
            {/* }  */}
            {isLoading && (
                <Loader></Loader>
            )}
        </>
    )
}

export default ApplicationPendingDocument;