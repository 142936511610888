import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBehaviorSubject } from '../BehaviorSubject/BehaviorSubject';

const AuthGuard = ({ component }) => {
    const [status, setStatus] = useState(false);
    const { isLoginUser } = useBehaviorSubject();
    const navigate = useNavigate();

    useEffect(() => {
        checkToken();
    }, [component]);

    const checkToken = async () => {
        try {
            let user = await isLoginUser;
            if (!user) {
                navigate(`/sign-in`);
            }
            setStatus(true);
            return;
        } catch (error) {
            navigate(`/sign-in`);
        }
    }

    return status ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
}

export default AuthGuard;